import { CONSUMER_DEMOGRAPHICS_URL } from 'environment/constants/url';

import axios from '../axios';

export const getConsumerDemographicsGraphRequest = (
  businessUnitId: string,
  startDate: string | undefined,
  endDate: string | undefined,
) => {
  return axios.get(
    CONSUMER_DEMOGRAPHICS_URL(businessUnitId, startDate, endDate).GRAPH,
  );
};

export const getConsumerDemographicsCountersRequest = (
  businessUnitId: string,
  startDate: string | undefined,
  endDate: string | undefined,
) => {
  return axios.get(
    CONSUMER_DEMOGRAPHICS_URL(businessUnitId, startDate, endDate).COUNTERS,
  );
};
