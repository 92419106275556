import { Box, Typography } from '@mui/material';

import { getDefaultAvatarByRating } from 'environment/utils/getDefaultAvatarByRating';
import { getRatingColor } from 'environment/utils/getRatingColor';

import styles from './WaterfallInfo.module.scss';

interface IProps {
  userAvatar?: string;
  firstName: string;
  lastName: string;
  placeImage: string;
  placeName: string;
  country: string;
  caption: string;
  userRating: number;
}

const WaterfallInfo = ({
  userAvatar,
  firstName,
  lastName,
  placeImage,
  placeName,
  country,
  caption,
  userRating,
}: IProps) => {
  return (
    <Box className={styles.wrapper}>
      <Box className={styles.user}>
        <>
          {userAvatar ? (
            <Box
              component="img"
              src={userAvatar}
              className={`${styles.image}`}
            />
          ) : (
            <Box
              component="img"
              src={getDefaultAvatarByRating(userRating)}
              width={22}
              height={22}
            />
          )}
        </>
        <Typography
          color={getRatingColor(userRating)}
          variant="text-base-light"
        >{`${firstName} ${lastName}`}</Typography>
      </Box>
      <Box className={styles.place}>
        <Box
          component="img"
          src={placeImage}
          alt="user"
          width={32}
          height={32}
        />
        <Typography variant="text-xs">{`${placeName}, ${country}`}</Typography>
      </Box>
      <Typography variant="text-xs" sx={{ fontWeight: 300 }}>
        {caption}
      </Typography>
    </Box>
  );
};

export default WaterfallInfo;
