import i18n from 'environment/plugins/i18n';

export const categories = {
  likes: i18n.t('Likes'),
  comments: i18n.t('Comments'),
  share: i18n.t('Share'),
  review: i18n.t('Review'),
  view: i18n.t('View'),
  storyTag: i18n.t('Story.tag'),
  forumTag: i18n.t('Forum.tag'),
  pageVisit: i18n.t('Page.visit'),
};

export const mockData = {
  likes: 90000,
  comments: 0,
  share: 0,
  review: 0,
  view: 0,
  storyTag: 0,
  forumTag: 0,
  pageVisit: 0,
};

export const mockData2 = {
  likes: 0,
  comments: 0,
  share: 0,
  review: 0,
  view: 0,
  storyTag: 0,
  forumTag: 0,
  pageVisit: 0,
};
