import { createModel } from '@rematch/core';

import { fetchMyBusiness } from 'environment/api/services/business';
import {
  createMember,
  fetchMembers,
  updateMember,
} from 'environment/api/services/settings';
import {
  CreateMemberRequestBody,
  UpdateMemberRequestBody,
} from 'environment/types/api/business';
import { Member } from 'environment/types/business';
import { IBusiness } from 'types';

import { RootModel } from '.';

type State = {
  business: IBusiness | null;
  members: Member[];
};

const initialState: State = {
  business: null,
  members: [],
};

export const business = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setBusiness: (state, business: IBusiness) => {
      return { ...state, business };
    },
    setMembers: (state, members: Member[]) => {
      return { ...state, members };
    },
    addMember: (state, member: Member) => {
      return { ...state, members: [...state.members, member] };
    },
    editMember: (state, member: Member) => {
      const members = state.members.map((m) =>
        m.id === member.id ? member : m,
      );
      return { ...state, members };
    },
  },
  effects: (dispatch) => ({
    getBusiness: async () => {
      try {
        const { data } = await fetchMyBusiness();
        dispatch.business.setBusiness(data);
        return Promise.resolve(data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getMembers() {
      try {
        const { data } = await fetchMembers();
        dispatch.business.setMembers(data);
        return Promise.resolve(data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async createMember(payload: CreateMemberRequestBody) {
      try {
        const { data } = await createMember(payload);
        dispatch.business.addMember(data);
        return Promise.resolve(data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async updateMember(
      payload: UpdateMemberRequestBody & { memberId: string },
    ) {
      try {
        const { data } = await updateMember(payload);
        dispatch.business.editMember(data);
        return Promise.resolve(data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  }),
});
