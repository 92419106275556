import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import IconMark from 'components/atoms/icon/IconMark';

import styles from './Switcher.module.scss';

interface IProps {
  firstTitle: string;
  secondTitle: string;
  firstIcon: string;
  secondIcon: string;
  handleSwitch: (isActive: string) => void;
  activeButton: string;
}

const Switcher = ({
  firstTitle,
  secondTitle,
  firstIcon,
  secondIcon,
  handleSwitch,
  activeButton,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <Box className={styles.wrapper}>
      <Button
        size="small"
        onClick={() => handleSwitch('first')}
        className={`${styles.button} ${
          activeButton === 'first' ? styles.active : ''
        }`}
      >
        <IconMark
          name={firstIcon}
          color={activeButton === 'first' ? '#080808' : ''}
          size="16px"
        />
        <Typography color="inherit" variant="text-sm-light">
          {t(firstTitle)}
        </Typography>
      </Button>

      <Button
        size="small"
        onClick={() => handleSwitch('second')}
        className={`${styles.button} ${
          activeButton === 'second' ? styles.active : ''
        }`}
      >
        <IconMark
          name={secondIcon}
          color={activeButton === 'second' ? '#080808' : ''}
          size="12px"
        />
        <Typography color="inherit" variant="text-sm-light">
          {t(secondTitle)}
        </Typography>
      </Button>
    </Box>
  );
};

export default Switcher;
