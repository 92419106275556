import { Box, Table, Typography } from '@mui/material';
import React from 'react';

import styles from './TableRoot.module.scss';

interface IProps {
  children: React.ReactNode;
  title?: string;
  pagination?: React.ReactNode;
}

const TableRoot = ({ children, title, pagination }: IProps) => {
  return (
    <Box className={styles.wrapper}>
      {title && <Typography variant="text-lg">{title}</Typography>}
      <Box className={styles.scrollableContainer}>
        <Table>{children}</Table>
      </Box>
      {pagination && <Box>{pagination}</Box>}
    </Box>
  );
};

export default TableRoot;
