import { Box, Typography } from '@mui/material';

import { Link } from 'components/atoms';

import styles from '../Agreements.module.scss';
import {
  Container,
  DashedList,
  Italic,
  LinkTypography,
  LowerLatinOrderedList,
  LowerRomanOrderedList,
} from '../components';

export const TERMS_AND_CONDITIONS = [
  {
    id: 1,
    title: 'ACCEPTANCE',
    content: (
      <Container>
        <Typography component="span">
          These Terms and Conditions (the “Terms”) govern your visits to{' '}
          <Link target="_blank" navigateTo="https://www.nolemon.app">
            <LinkTypography>https://www.nolemon.app/</LinkTypography>
          </Link>{' '}
          and/or nolemon (the “App”). The Site and the App are referred to
          collectively herein as the “Platform.” NOLEMON NOMELON INC (“we,”
          “us,” or “our”) owns and operates this Platform. The term
          &quot;you&quot; refers to any user of the Platform.
        </Typography>
        <Typography>
          These Terms have a force of a legally binding agreement, even if you
          are simply browsing without intention to contact us or register an
          account. Privacy Policy is another important document that you should
          familiarize yourself with because it describes our practices with
          respect to your personal information. You cannot visit the Platform if
          you do not agree to these Terms or the Privacy Policy. Sometimes we
          modify these Terms. We don’t notify users about every change to the
          Terms but you can see the date of the last update at the top of this
          page. If you still wish to visit the Platform after said date, that
          constitutes your agreement to the updates.
        </Typography>
      </Container>
    ),
  },
  {
    id: 2,
    title: 'NEUTRAL VENUE',
    content: (
      <LowerLatinOrderedList>
        <Typography component="li">
          <Italic>Venue.</Italic> We are a platform that allows businesses to
          endogenize marketing to their customers through user-generated reviews
          and incentivized user engagement. As a neutral venue, we do not
          endorse any listed businesses, services or posted reviews. Our role is
          limited to providing neutral technology to connect users. Our Platform
          enables users to share experiences and opinions about businesses and
          services. Content appears on our Platform on an “as is” basis, to be
          relied at your own risk. We shall not be held liable for what users
          decide to do with the information presented on our Platform, third
          party links and services, reviews, users’ transactions, losses and any
          consequences of reliance on our Platform’s content.
        </Typography>
        <Typography component="li">
          <Italic>Interactive Features.</Italic> This Platform includes
          interactive features that allow users to communicate with us,
          businesses and each other. You agree that, because of the limited
          nature of such communication, any guidance you may receive can be
          incomplete and may even be misleading. Therefore, any assistance you
          may receive using any our Platform’s interactive features does not
          constitute specific advice and should not be relied upon without
          further competent independent confirmation.
        </Typography>
        <Typography component="li">
          <Italic>Pricing Disclaimers.</Italic> The Platform&apos;s pricing for
          customer engagement services (including Boost) and promotional
          features is dynamically calculated based on factors like market
          conditions, demand, and Platform traffic. As a result, the final
          charges on a business’s invoice may be higher or disproportionate
          compared to any preliminary price expectations. Businesses agree to
          pay the full calculated charges and are advised to understand
          potential pricing fluctuations before using any service on the
          Platform. While Boosting enhances visibility, it does not guarantee
          user engagement. Businesses are advised to carefully consider the
          value of Boost before utilizing it.
        </Typography>
        <Typography component="li">
          <Italic>DRIVING.</Italic> DO NOT USE THE PLATFORM IN ANY MANNER THAT
          DISTRACTS YOU FROM DRIVING OR IS ILLEGAL (E.G., IN JURISDICTIONS THAT
          DO NOT ALLOW THE USE OF MOBILE DEVICES WHILE DRIVING). WE SHALL NOT BE
          LIABLE FOR YOUR COMPLIANCE WITH TRAFFIC LAWS.
        </Typography>
      </LowerLatinOrderedList>
    ),
  },
  {
    id: 3,
    title: 'INTELLECTUAL PROPERTY',
    content: (
      <>
        <LowerLatinOrderedList>
          <Typography component="li">
            <Italic>Our Intellectual Property.</Italic> We and our content
            suppliers own all intellectual property rights in our Platform
            contents, logos, trademarks (whether registered or unregistered) and
            data. Our IP rights are protected by U.S. law and international IP
            conventions. By using our Platform you do not acquire any of our IP
            rights. Nevertheless, you can view and print out this Platform’s
            content for personal use. We reserve all rights that are not
            expressly granted under these Terms or other written agreements
            between you and us.
          </Typography>
          <Typography component="li">
            <Italic>Your Submissions.</Italic> We do not claim ownership rights
            over your content. What’s yours remains yours. However, if you
            upload any content to the public areas of our Platform, you state
            that: (i) you have all necessary rights to that content, (ii) we can
            display, transmit, modify and distribute this content without
            compensation to you, and (iii) businesses that you review can reuse
            your content for their own promotional purposes. We can use and
            implement any feedback that you voluntarily provide, without
            compensation to you.
          </Typography>
          <Typography component="li">
            <Italic>Copyright Infringement.</Italic> We take copyright
            infringement seriously. Report it to us if you see it on our
            Platform and we will investigate. In accordance with the Digital
            Millennium Copyright Act of 1998, the text of which may be found on
            the U.S. Copyright Office website at{' '}
            <Link
              navigateTo="https://www.copyright.gov/legislation/dmca.pdf"
              target="_blank"
            >
              <LinkTypography>
                https://www.copyright.gov/legislation/dmca.pdf
              </LinkTypography>
            </Link>
            , we will promptly investigate claims of copyright infringement
            committed using our Platform if such claims are reported to
            <Link navigateTo="mailto:contact@nolemon.app">
              <LinkTypography> contact@nolemon.app</LinkTypography>
            </Link>
            . If you hold copyright or are authorized to act on behalf of the
            copyright holder, you can report alleged copyright infringements as
            follows:
          </Typography>
        </LowerLatinOrderedList>
        <LowerRomanOrderedList>
          <Typography component="li">
            Identify the copyrighted work that you claim has been infringed.
          </Typography>
          <Typography component="li">
            Identify the material or link you claim is infringing.
          </Typography>
          <Typography component="li">
            Provide your company affiliation (if applicable), mailing address,
            telephone number, and, if available, email address.
          </Typography>
          <Typography component="li">
            Include both of the following statements in the body of your report:
            <DashedList>
              <Typography component="li">
                “I hereby state that I have a good faith belief that the
                disputed use of the copyrighted material is not authorized by
                the copyright owner, its agent, or the law (e.g., as a fair
                use)”
              </Typography>
              <Typography component="li">
                “I hereby state that the information in this report is accurate
                and, under penalty of perjury, that I am the owner, or
                authorized to act on behalf of, the owner, of the copyright or
                of an exclusive right under the copyright that is allegedly
                infringed.”
              </Typography>
            </DashedList>
          </Typography>
          <Typography component="li">
            Provide your full legal name and your electronic or physical
            signature.
          </Typography>
        </LowerRomanOrderedList>
      </>
    ),
  },
  {
    id: 4,
    title: 'REGISTRATION AND MEMBERSHIP',
    content: (
      <Container>
        <Typography>
          To access certain features and resources on the Platform, you must
          create a profile account as either a talent or a business and be at
          least 12 years of age. By registering, you agree to provide accurate
          and complete information about yourself and to maintain and update
          this information as necessary. You are responsible for maintaining the
          confidentiality of your account and password, as well as for all
          activities that occur under your account.
        </Typography>
        <Typography>
          Each individual is allowed to maintain only one account for personal
          use. Creation of multiple accounts to exploit the system&apos;s
          features is expressly forbidden.
        </Typography>
      </Container>
    ),
  },
  {
    id: 5,
    title: 'USER REVIEWS AND CONTENT',
    content: (
      <Container>
        <Typography>
          Users may submit reviews, comments, product listings, photos, images,
          videos and other content to the Platform so long as the content
          complies with the following requirements:
        </Typography>
        <LowerLatinOrderedList>
          <Typography component="li">
            <LinkTypography>Legality and Decency:</LinkTypography> Content must
            not be illegal, obscene, threatening, defamatory, invasive of
            privacy, or otherwise injurious to third parties.
          </Typography>
          <Typography component="li">
            <LinkTypography>
              Authenticity and Lawfulness of Content:
            </LinkTypography>{' '}
            Reviews and content must originate from real experiences and genuine
            transactions, be in compliance with the law, and be devoid of spam
            or unwarranted solicitations. Users are prohibited from generating
            content related to businesses where they have not engaged as
            legitimate customers or made bona fide purchases. This aligns with
            the principle of Legality and Decency as stated in clause a).
          </Typography>
          <Typography component="li">
            <LinkTypography>Tagging Integrity:</LinkTypography> Users are
            strictly forbidden from manipulating the platform’s
            &apos;tagging&apos; system in a manner that falsely represents or
            exaggerates their association with a business. This includes, but is
            not limited to, inaccurately tagging multiple businesses. This
            clause complements the guidelines for Legality and Decency and
            Commercial Integrity as outlined in clauses (a) and (f).
          </Typography>
          <Typography component="li">
            <LinkTypography>
              Prohibition of Verification Manipulation:
            </LinkTypography>{' '}
            Any use of fraudulent receipts, or sharing receipts with the
            intention of manipulating the platform’s verification systems, is
            explicitly prohibited. This provision is consistent with the
            principles of Legality and Decency and Accuracy and Review
            Requirements as stated in clauses (a) and (g).
          </Typography>
          <Typography component="li">
            <LinkTypography>Intellectual Property Rights:</LinkTypography> By
            contributing content, users affirm that they possess the rightful
            authority to do so, and that the content does not infringe upon any
            third-party intellectual property rights.
          </Typography>
          <Typography component="li">
            <LinkTypography>Commercial Integrity:</LinkTypography> Content must
            be free of political campaigning, commercial solicitation, chain
            letters, mass mailings, or any form of &quot;spam.&quot;
          </Typography>
          <Typography component="li">
            <LinkTypography>Accuracy and Review Requirements:</LinkTypography>{' '}
            Reviews and tagged photos and videos must be based on genuine
            experiences and require submission of a valid receipt or location
            verification associated with the business being reviewed. False or
            fraudulent submissions are prohibited.
          </Typography>
          <Typography component="li">
            <LinkTypography>License to the Platform:</LinkTypography> Users
            provide the Platform a non-exclusive, royalty-free, global license
            to utilize, adapt, and showcase their content.
          </Typography>
          <Typography component="li">
            <LinkTypography>Discretion to Monitor and Remove:</LinkTypography>{' '}
            The Platform reserves the right, but not the obligation, to monitor
            and remove or edit content that, in its sole discretion, violates
            these Terms or is otherwise objectionable.
          </Typography>
          <Typography component="li">
            <LinkTypography>Content Accuracy:</LinkTypography> The Platform is
            not responsible for the accuracy, integrity, quality, or legality of
            any user-generated content, including but not limited to reviews,
            comments, photos and videos. Users are solely responsible for the
            content they post, and the Platform does not endorse any opinions
            expressed.
          </Typography>
        </LowerLatinOrderedList>
        <Typography>
          By using the Platform and contributing content, users agree to adhere
          to these guidelines and acknowledge that failure to comply may result
          in termination of access to the Platform and forfeiture of any earned
          incentives.
        </Typography>
      </Container>
    ),
  },
  {
    id: 6,
    title: 'ACCEPTABLE USE POLICY',
    content: (
      <Container>
        <Typography>
          By visiting this Platform, you represent and agree that:
        </Typography>
        <LowerLatinOrderedList>
          <Typography component="li">
            You have a full capacity to enter into a legally binding agreement,
            such as these Terms.
          </Typography>
          <Typography component="li">
            You will not let others use your account, except as may be
            explicitly authorized by us. Everything that happens under your
            account is your responsibility. Registering duplicate accounts is
            not allowed.
          </Typography>
          <Typography component="li">
            If you make a submission, it shall be truthful and not misleading.
            We can terminate any account for writing untruthful reviews,
            comments or other content. We reserve the right to edit, reject or
            erase anything submitted to us without prior notice. You will not
            send spam, anything defamatory, vulgar, racist, abusive or hateful.
          </Typography>
          <Typography component="li">
            You will not use our Platform in connection with any sexually
            explicit material, illegal drugs, promotion of alcohol to persons
            under 21 years of age, pirated computer programs, viruses or other
            harmful code, disclosure of anyone&apos;s private information
            without consent, pyramid schemes, multilevel-marketing, &quot;get
            rich quick&quot; offerings, encouragement of violence.
          </Typography>
          <Typography component="li">
            You will ask for our permission before copying anything from our
            Platform for republication.
          </Typography>
          <Typography component="li">
            You will not use our Platform for anything illegal.
          </Typography>
          <Typography component="li">
            We reserve the right to terminate any account using our sole
            reasonable discretion and without notice or liability.
          </Typography>
          <Typography component="li">
            Bots, crawlers, indexers, web spiders, harvesters or any similar
            automatic processes are not allowed on our Platform.
          </Typography>
          <Typography component="li">
            You will not impede the proper functioning of the Platform.
          </Typography>
        </LowerLatinOrderedList>
      </Container>
    ),
  },
  {
    id: 7,
    title: 'INCENTIVE PROGRAM',
    content: (
      <Container>
        <Typography>
          We provide an incentive program designed to encourage meaningful and
          lawful user engagement on the Platform. Participation in this program
          is governed by the following conditions:
        </Typography>
        <LowerLatinOrderedList>
          <Typography component="li">
            <LinkTypography>Prohibited Conduct:</LinkTypography> Abuse of the
            program through fraudulent activities, spam-like behavior, or
            violations of these Terms may result in the forfeiture of accrued
            rewards and may expose the user to potential legal action. Users are
            contractually obligated to ensure that all engagement activities on
            the platform, including but not limited to likes, comments, shares,
            waterfalls, and reviews, are authentic, and based on real and lawful
            experiences. Any automated engagement methods or behaviors solely
            aimed at reward accumulation without authentic interaction are
            strictly prohibited and constitute a violation of these Terms.
          </Typography>
          <Typography component="li">
            <LinkTypography>Withdrawal Requirements:</LinkTypography>
            <LowerRomanOrderedList inner gap>
              <Box />
              <Typography component="li">
                <LinkTypography>Minimum Withdrawal Amount: </LinkTypography>
                Users must accrue a minimum balance of $25 USD in their
                incentive account before initiating a withdrawal request.
                Withdrawal requests below $25 USD will be declined.
              </Typography>
              <Typography component="li">
                <LinkTypography>60 Day Waiting Period: </LinkTypography>
                Users may not make a withdrawal request during the first 60 days
                of participating in the incentive program. The 60 day period
                starts on the date the user creates their incentive program
                account.
              </Typography>
              <Typography component="li">
                <LinkTypography>
                  Limit of One Monthly Withdrawal:{' '}
                </LinkTypography>
                Users may only make one withdrawal request per calendar month.
                Additional requests within 30 days will be declined.
              </Typography>
              <Typography component="li">
                <LinkTypography>
                  Balance Deduction for Violations:{' '}
                </LinkTypography>
                The Company reserves the right to deduct from a user&apos;s
                available incentive balance if we determine they have violated
                these Terms, including by fraudulent activities or abuse.
              </Typography>
            </LowerRomanOrderedList>
          </Typography>
          <Typography component="li">
            <LinkTypography>Modification of Requirements: </LinkTypography>We
            reserve the right to modify the incentive program and the withdrawal
            requirements at any time in its sole discretion. Users will be
            notified of changes prior to implementation.
          </Typography>
        </LowerLatinOrderedList>
      </Container>
    ),
  },
  {
    id: 8,
    title: 'REFUND POLICY',
    content: (
      <Typography>
        If a user is determined by the Platform to have engaged in activities
        that constitute an abuse of the incentive system, including but not
        limited to fraudulent actions, manipulation, or other conduct in
        violation of these Terms, and a business incurs charges as a result of
        such activities, the affected business shall be eligible for a refund.
        The refund shall be issued in the full amount of the applicable charges
        or, at the Platform&apos;s sole discretion, the corresponding amount may
        be deducted from the business&apos;s subsequent payment obligations to
        the Platform. We reserve the right to define and interpret what
        constitutes abuse of the incentive system and to make determinations
        regarding refunds in its sole discretion, subject to applicable law.
      </Typography>
    ),
  },
  {
    id: 9,
    title: 'LOCATION VERIFICATION',
    content: (
      <Typography>
        You acknowledge that there may be occasional inaccuracies in location
        tagging. We will make reasonable efforts to allow businesses to be
        tagged within a reasonable radius from the user&apos;s current location.
        However, we are not liable for any mis-tagging or errors in location
        verification.
      </Typography>
    ),
  },
  {
    id: 10,
    title: 'CONFIDENTIALITY',
    content: (
      <Typography>
        You cannot use or disclose any confidential information relating to our
        business, users, operations and properties for any purpose without our
        express prior written authorization. You agree to take all reasonable
        measures to protect the secrecy of and avoid disclosure or use of our
        confidential information.
      </Typography>
    ),
  },
  {
    id: 11,
    title: 'BREACH OF THESE TERMS',
    content: (
      <Typography>
        If any user violates these Terms or any law, we can, without limitation:
        (i) ban that user from the Platform; (ii) disclose the user’s identity
        to authorities and assist in investigations; (iii) delete or moderate
        the user’s content; (iv) take any other action available under law.
      </Typography>
    ),
  },
  {
    id: 12,
    title: 'DISCLAIMER OF WARRANTY; LIMITATION OF LIABILITY',
    content: (
      <LowerLatinOrderedList>
        <Typography component="li">
          EVERYTHING WE PROVIDE ON THIS PLATFORM IS ON AN “AS IS” BASIS, TO BE
          RELIED ON AT YOUR OWN RISK. DO YOUR OWN RESEARCH BEFORE RELYING ON
          ANYTHING ON THIS PLATFORM. WE DISCLAIM ALL WARRANTIES, EXPRESS OR
          IMPLIED, INCLUDING ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, QUALITY, NON-INFRINGEMENT, SAFETY, FREEDOM FROM
          DEFECTS OR THAT DEFECTS WILL BE CORRECTED, UNINTERRUPTED, VIRUS-FREE
          OR ERROR-FREE PERFORMANCE.
        </Typography>
        <Typography component="li">
          The Platform does not guarantee the accuracy, reliability, or
          completeness of content on the platform, which includes, but is not
          limited to, product listings, reviews, or any user-generated content.
          We neither endorse nor assume responsibility for any products,
          sellers, or transactions resulting from the use of the Platform. Users
          bear the sole responsibility for verifying the accuracy and
          suitability of product listings, reviews, and any transaction-related
          information.
        </Typography>
        <Typography component="li">
          In no circumstance will our company, its directors, officers,
          employees, or agents be held accountable for any damages, whether they
          be direct, indirect, incidental, consequential, or punitive, stemming
          from your interaction with or inability to use the Platform. This
          encompasses, but is not limited to, potential inaccuracies in content,
          loss of data, or any harm sustained.
        </Typography>
      </LowerLatinOrderedList>
    ),
  },
  {
    id: 13,
    title: 'INDEMNIFICATION',
    content: (
      <Typography>
        You agree to defend, indemnify and hold harmless us, our company, its
        officers, directors, employees and agents, from and against any and all
        claims, damages, obligations, losses, liabilities, costs or debt, and
        expenses (including but not limited to attorney&apos;s fees) arising
        from: (i) your use of and access to the Platform; (ii) your violation of
        any provision of these Terms; (iii) your violation of any third party
        right, including without limitation any copyright, property, or privacy
        right; or (iv) any claim that one of your user submissions caused damage
        to a third party.
      </Typography>
    ),
  },
  {
    id: 14,
    title: 'APPLE APP STORE',
    content: (
      <Container>
        <Typography>
          By downloading the Platform from a device made by Apple, Inc.
          (“Apple”) or from Apple’s App Store, you specifically acknowledge and
          agree that:
        </Typography>
        <LowerLatinOrderedList>
          <Typography component="li">
            Apple is not a party to these Terms. Apple is not responsible for
            the Platform or the content thereof and has no obligation whatsoever
            to furnish any maintenance or support services with respect to the
            Platform.
          </Typography>
          <Typography component="li">
            The license granted to you hereunder is limited to a personal,
            limited, non-exclusive, non-transferable right to install the
            Platform on the Apple device(s) authorized by Apple that you own or
            control for personal, non-commercial use, subject to the Usage Rules
            set forth in Apple’s App Store Terms of Services.
          </Typography>
          <Typography component="li">
            In the event of any failure of the Platform to conform to any
            applicable warranty, you may notify Apple, and Apple will refund the
            purchase price for the Platform, if any, to you. To the maximum
            extent permitted by applicable law, Apple will have no other
            warranty obligation whatsoever with respect to the Platform.
          </Typography>
          <Typography component="li">
            Apple is not responsible for addressing any claims by you or a third
            party relating to the Platform or your possession or use of the
            Platform, including without limitation (a) product liability claims;
            (b) any claim that the Platform fails to conform to any applicable
            legal or regulatory requirement; and (c) claims arising under
            consumer protection or similar legislation.
          </Typography>
          <Typography component="li">
            In the event of any third party claim that the Platform or your
            possession and use of the Platform infringes such third party’s
            intellectual property rights, Apple is not responsible for the
            investigation, defense, settlement or discharge of such intellectual
            property infringement claim.
          </Typography>
          <Typography component="li">
            You represent and warrant that (a) you are not located in a country
            that is subject to a U.S. Government embargo, or that has been
            designated by the U.S. Government as a “terrorist supporting”
            country; and (b) you are not listed on any U.S. Government list of
            prohibited or restricted parties.
          </Typography>
          <Typography component="li">
            Apple and its subsidiaries are third party beneficiaries of these
            Terms and upon your acceptance of the terms and conditions of these
            Terms, Apple will have the right (and will be deemed to have
            accepted the right) to enforce these Terms against you as a
            third-party beneficiary hereof.
          </Typography>
        </LowerLatinOrderedList>
      </Container>
    ),
  },
  {
    id: 15,
    title: 'GOOGLE PLAY',
    content: (
      <Container>
        <Typography>
          By downloading the Platform from Google Play (or its successors)
          operated by Google, Inc. or one of its affiliates (“Google”), you
          specifically acknowledge and agree that:
        </Typography>
        <LowerLatinOrderedList>
          <Typography component="li">
            to the extent of any conflict between (a) the Google Play Terms of
            Services and the Google Play Business and Program Policies or such
            other terms which Google designates as default end user license
            terms for Google Play (all of which together are referred to as the
            “Google Play Terms”), and (b) the other terms and conditions in
            these Terms, the Google Play Terms shall apply with respect to your
            use of the Platform that you download from Google Play, and
          </Typography>
          <Typography component="li">
            you hereby acknowledge that Google does not have any responsibility
            or liability related to compliance or non-compliance by us or you
            (or any other user) under these Terms or the Google Play Terms.
          </Typography>
        </LowerLatinOrderedList>
      </Container>
    ),
  },
  {
    id: 16,
    title: 'ARBITRATION; CLASS ACTION WAIVER',
    content: (
      <LowerLatinOrderedList>
        <Typography component="li">
          <Italic>Arbitration</Italic>. Any controversy or claim arising out of
          or relating to these Terms, or the breach thereof, shall be settled by
          arbitration administered by the American Arbitration Association in
          accordance with its Commercial Arbitration Rules and judgment on the
          award rendered by the arbitrator(s) may be entered in any court having
          jurisdiction thereof. The arbitration shall be governed by the laws of
          the State of Florida. The arbitration will be based on the submission
          of documents and there shall be no in-person or oral hearing. Except
          as may be required by law, neither a party nor an arbitrator may
          disclose the existence, content, or results of any arbitration
          hereunder without the prior written consent of both parties.{' '}
          <span className={styles.weirdTypography}>
            You understand that this Section means that, by using the Platform,
            you agree to arbitrate, thus, waiving your rights to sue in court
            and have a jury trial.
          </span>
        </Typography>
        <Typography component="li" className={styles.weirdTypography}>
          <Italic>Class Action Waiver.</Italic> You acknowledge and agree that
          you waive your right to participate as a plaintiff or class member in
          any purported class action or representative proceeding. Further,
          unless both you and us otherwise agree in writing, the arbitrator may
          not consolidate more than one person&apos;s claims, and may not
          otherwise preside over any form of any class or representative
          proceeding.
        </Typography>
      </LowerLatinOrderedList>
    ),
  },
  {
    id: 17,
    title: 'GENERAL',
    content: (
      <LowerLatinOrderedList>
        <Typography component="li">
          <Italic>Communications.</Italic> You agree that we can communicate
          with you electronically, via SMS, push notifications, email or phone
          calls. All electronic communications shall have the same legal force
          as if they were in paper form.
        </Typography>
        <Typography component="li">
          <Italic>Relationship of the Parties.</Italic> You and us are in an
          independent contractor relationship with respect to each other. That
          means that there is no partnership, joint venture, employer/employee
          or any similar arrangement.
        </Typography>
        <Typography component="li">
          <Italic>Force Majeure.</Italic> We will not be liable for failure to
          perform any obligations to the extent that the failure is caused by a
          Force Majeure event such as, without limitation, act of God, riot,
          civil disturbances, acts of terrorism, fire, explosion, flood,
          epidemic, national mourning, theft of essential equipment, malicious
          damage, strike, lock out, weather, third party injunction, acts or
          regulations of national or local governments.
        </Typography>
        <Typography component="li">
          <Italic>Hyperlinks.</Italic> Linking to our Platform is allowed,
          however, it must always be done in a way that does not adversely
          affect our business or implies some form of association when there is
          none.
        </Typography>
        <Typography component="li">
          <Italic>Severability.</Italic> If any part of these Terms is found to
          be unenforceable, then only that particular portion, and not the
          entire Terms, will be unenforceable.
        </Typography>
        <Typography component="li">
          <Italic>Assignment.</Italic> We have the right, at our sole
          discretion, to assign or subcontract our rights or obligations
          outlined in these Terms.
        </Typography>
        <Typography component="li">
          <Italic>Waiver.</Italic> Our failure to exercise any of our rights
          under these Terms shall not be considered a waiver to exercise them in
          other instances. No waiver shall be effective unless it is in writing
          signed by us.
        </Typography>
        <Typography component="li">
          <Italic>Prevailing Language.</Italic> If there are any inconsistencies
          or conflicts between the English original of these Terms and any
          foreign language translation, the English version shall prevail.
        </Typography>
      </LowerLatinOrderedList>
    ),
  },
  {
    id: 18,
    title: 'CONTACT US',
    content: (
      <Typography>
        Please address your questions and feedback to:{' '}
        <Link navigateTo="mailto:message@nolemon.app">
          <LinkTypography>message@nolemon.app</LinkTypography>
        </Link>
      </Typography>
    ),
  },
];
