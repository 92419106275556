import {
  Box,
  TablePagination as MUITablePagination,
  Typography,
} from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ExpandMore } from 'assets/icons/expandMore.svg';
import { Pagination } from 'components/atoms';

import styles from './TablePagination.module.scss';

interface IProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (value: number) => void;
  onChangeRowsPerPage: (value: string) => void;
}

const TablePagination = ({
  count,
  page,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
}: IProps) => {
  const { t } = useTranslation();

  const handleChangePage = useCallback(
    (_event: React.ChangeEvent<unknown>, newPage: number) => {
      onChangePage(newPage);
    },
    [onChangePage],
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChangeRowsPerPage(event.target.value);
    },
    [onChangeRowsPerPage],
  );

  return (
    <>
      <MUITablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        labelRowsPerPage={
          <Typography variant="text-xs">{t('Rows.per.page')}</Typography>
        }
        count={count}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={() => {}}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={() => (
          <Box sx={{ ml: 'auto' }}>
            <Pagination
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              onChange={handleChangePage}
            />
          </Box>
        )}
        // todo: migrate deprecated
        SelectProps={{
          MenuProps: {
            PaperProps: { className: styles.menu },
          },
          className: styles.select,
          inputProps: {
            className: styles.input,
          },
          // eslint-disable-next-line react/prop-types
          IconComponent: ({ className }) => (
            <ExpandMore className={`${className} ${styles.arrow}`} />
          ),
        }}
        classes={{
          root: styles.wrapper,
          toolbar: styles.toolbar,
          spacer: styles.spacer,
          actions: styles.actionButton,
          displayedRows: styles.displayedRows,
        }}
      />
    </>
  );
};

export default TablePagination;
