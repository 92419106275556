import { Typography } from '@mui/material';
import { FC, memo } from 'react';

import Colors from 'styles/colors.scss';

import styles from './Tooltip.module.scss';

interface Props {
  value: number;
  change: number;
}

const Tooltip: FC<Props> = ({ value, change }) => {
  const decreased = change < 0;

  return (
    <div className={styles.wrapper}>
      <div className={styles.gradientBorderContainer}>
        <div className={styles.container}>
          <Typography variant="text-xs-semibold" component="p">
            {value}
          </Typography>
          {!!change && (
            <div className={styles.percentageContainer}>
              <svg
                transform={decreased ? 'rotate(180)' : undefined}
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="6"
                viewBox="0 0 7 6"
                fill="none"
              >
                <path
                  d="M3.5 0L6.9641 6H0.0358984L3.5 0Z"
                  fill={decreased ? Colors['profile-red'] : Colors.HEXB8FE61}
                />
              </svg>
              <Typography
                className={styles.percentage}
                component="p"
                color={decreased ? Colors['profile-red'] : Colors.HEXB8FE61}
              >
                {`${Math.abs(change)}%`}
              </Typography>
            </div>
          )}
        </div>
      </div>
      <GreenArrow />
    </div>
  );
};

const GreenArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="5"
      viewBox="0 0 8 5"
      fill="none"
    >
      <path
        d="M4.40192 4.5C4.01702 5.16667 3.05477 5.16667 2.66987 4.5L0.0717959 6.05683e-07L7 0L4.40192 4.5Z"
        fill={Colors['chateau-green']}
      />
    </svg>
  );
};

export default memo(Tooltip);
