export const EXAMPLE_MANTRAS = [
  {
    id: '1',
    title: 'mantrasQuestions.i-bet-you-cannot',
    text: 'Handle.our.triple.shot.ethiopian.latte',
  },
  {
    id: '2',
    title: 'mantrasQuestions.our-mantra-is',
    text: 'Youre.not.really.drinking.alone.if.your.dogs.are.home.',
  },
  {
    id: '3',
    title: 'mantrasQuestions.visiting-me-is-like',
    text: 'A.rollercoaster.Ill.make.it.a.wild.ride.',
  },
];
