import { Box, Typography } from '@mui/material';

import { Mark } from 'components/atoms';
import { ViewOnlyGraph } from 'components/molecules';
import { COLORS_VIEW_CHARTS } from 'environment/constants/colorsViewCharts';

import styles from './PlaceCard.module.scss';

interface IPops {
  id: number;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any; // TODO add type
  graphData: number[];
  onClick: () => void;
  isActive: boolean;
}

const PlaceCard = ({ id, name, graphData, data, onClick, isActive }: IPops) => {
  return (
    <Box
      id={`placeCard_${id}`}
      className={`${styles.container} ${isActive ? styles.active : ''}`}
      onClick={onClick}
    >
      <Box className={styles.topBlock}>
        <Typography variant="text-xs">{name}</Typography>
        <Box className={styles.chartContainer}>
          <ViewOnlyGraph
            type="areaspline"
            data={graphData}
            colors={[COLORS_VIEW_CHARTS[0].color]}
            gradient={COLORS_VIEW_CHARTS[0].gradient}
          />
        </Box>
      </Box>
      <Box className={styles.numbers}>
        <Typography variant="text-lg">{data.amount}</Typography>
        <Mark percentage={data.percent} />
      </Box>
    </Box>
  );
};

export default PlaceCard;
