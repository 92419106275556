import { Box, Typography } from '@mui/material';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import emptyCircle from 'assets/icons/emptyCircle.svg';
import { DashboardCardWrapper, TitleAndProgress } from 'components/atoms';
import { DonutChart } from 'components/molecules';
import { ROUTES } from 'environment/constants/routes';
import { IComparedToWaterfalls } from 'store/models/dashboard';
import Colors from 'styles/colors.scss';

import {
  donutChartColors,
  donutChartColorsFirst,
  donutChartColorsSecond,
  mockData,
  progressBarText,
} from './constants';
import styles from './FeaturedWaterfallsCard.module.scss';

interface IProps {
  data: IComparedToWaterfalls;
}

const FeaturedWaterfallsCard = ({ data }: IProps) => {
  const { t } = useTranslation();

  const {
    totalItems,
    featuredPercentage,
    nonFeaturedPercentage,
    percentageChangeToLastWeek,
    totalEngagementScore,
    featuredEngagementScorePercentage,
    nonFeaturedEngagementScorePercentage,
    percentageEngagementScoreChangeToLastWeek,
  } = data;

  const numberOfWaterfall = {
    mainWaterfall: nonFeaturedPercentage !== null ? nonFeaturedPercentage : 0,
    newWaterfalls: featuredPercentage !== null ? featuredPercentage : 0,
  };

  const engagementScope = {
    mainWaterfall:
      nonFeaturedEngagementScorePercentage !== null
        ? nonFeaturedEngagementScorePercentage
        : 0,
    newWaterfalls:
      featuredEngagementScorePercentage !== null
        ? featuredEngagementScorePercentage
        : 0,
  };

  const engagementScoreCoefficient =
    nonFeaturedEngagementScorePercentage !== null
      ? (
          (nonFeaturedEngagementScorePercentage /
            (nonFeaturedEngagementScorePercentage +
              featuredEngagementScorePercentage)) *
          100
        ).toFixed(2)
      : 0;

  const renderProgressBar = useCallback(
    (name: keyof typeof mockData, index: number, percent: number) => {
      return (
        <Box className={styles.progressBarBlock}>
          <Box
            sx={{ background: donutChartColors[index] }}
            className={styles.circle}
          />
          <Typography className={styles.progressBarText} variant="text-xs">
            {progressBarText[index]}
          </Typography>
          <Box className={styles.progressBar}>
            <Box
              sx={{
                background: donutChartColors[index],
                width: `${percent}%`,
                boxShadow: `0px 0px 2px 0.5px ${
                  index === 0 ? Colors['HEXB8FE61'] : donutChartColors[index]
                }`,
              }}
              className={styles.progress}
            />
          </Box>
        </Box>
      );
    },
    [],
  );

  return (
    <DashboardCardWrapper
      title={t('Waterfalls')}
      navigateTo={ROUTES.dashboard.featuredWaterfalls}
    >
      <Box className={styles.chartWrapper}>
        <Box className={styles.chartBlock}>
          {totalItems > 0 ? (
            <DonutChart
              data={Object.values(numberOfWaterfall)}
              rounded={true}
              colors={donutChartColorsFirst}
              height={104}
              size="small"
              tooltip={false}
              title={`${
                nonFeaturedPercentage !== null
                  ? Number(nonFeaturedPercentage.toFixed(1))
                  : 0
              }%`}
            />
          ) : (
            <Box className={styles.emptyBlock}>
              <Box component="img" src={emptyCircle} sx={{ width: 97 }} />
              <Box className={styles.zeroUsersBlock}>
                <Typography variant="text-lg">0%</Typography>
              </Box>
            </Box>
          )}

          <TitleAndProgress
            position="flex-start"
            title={t('Number.of.waterfalls')}
            titleVariant="text-base-light"
            data={Number(totalItems.toFixed(2))}
            percent={percentageChangeToLastWeek}
            isPercentValue={false}
          />
        </Box>
        <Box className={styles.chartBlock}>
          {totalEngagementScore > 0 ? (
            <DonutChart
              data={Object.values(engagementScope)}
              rounded={true}
              colors={donutChartColorsSecond}
              height={104}
              size="small"
              tooltip={false}
              title={`${Number(engagementScoreCoefficient).toFixed(0)}%`}
            />
          ) : (
            <Box className={styles.emptyBlock}>
              <Box component="img" src={emptyCircle} sx={{ width: 97 }} />
              <Box className={styles.zeroUsersBlock}>
                <Typography variant="text-lg">0%</Typography>
              </Box>
            </Box>
          )}

          <TitleAndProgress
            position="flex-start"
            title={t('Engagement.score')}
            titleVariant="text-base-light"
            data={Number(totalEngagementScore.toFixed(2))}
            percent={percentageEngagementScoreChangeToLastWeek}
            isPercentValue={false}
          />
        </Box>
      </Box>

      <Box className={styles.progressBarWrapper}>
        <Box className={styles.progressBarContainer}>
          {renderProgressBar('mainWaterfall', 0, nonFeaturedPercentage)}
          {renderProgressBar('newWaterfalls', 1, featuredPercentage)}
        </Box>
        <Box className={styles.progressBarContainer}>
          {renderProgressBar(
            'addFeaturedWaterfalls',
            2,
            nonFeaturedEngagementScorePercentage,
          )}
          {renderProgressBar(
            'timelineLastWaterfall',
            3,
            featuredEngagementScorePercentage,
          )}
        </Box>
      </Box>
    </DashboardCardWrapper>
  );
};

export default memo(FeaturedWaterfallsCard);
