import * as React from 'react';

function Pin() {
  return (
    <svg
      width="41"
      height="39"
      viewBox="0 0 41 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="41" height="33" rx="4" fill="#080808" />
      <path
        d="M13.2812 24.375H27.7188M13.9375 8.625H27.0625M14.5938 8.625V24.375M26.4062 8.625V24.375M17.875 11.9062H19.1875M17.875 14.5312H19.1875M17.875 17.1562H19.1875M21.8125 11.9062H23.125M21.8125 14.5312H23.125M21.8125 17.1562H23.125M17.875 24.375V21.4219C17.875 20.8785 18.316 20.4375 18.8594 20.4375H22.1406C22.684 20.4375 23.125 20.8785 23.125 21.4219V24.375"
        stroke="url(#paint0_linear_21956_102456)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M15 32H27L21 38.5L15 32Z" fill="#080808" />
      <defs>
        <linearGradient
          id="paint0_linear_21956_102456"
          x1="33.3133"
          y1="16.5"
          x2="11.883"
          y2="8.94665"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#34D399" />
          <stop offset="1" stopColor="#FFF961" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default React.memo(Pin);
