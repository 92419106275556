import { TextField, TextFieldProps } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import styles from './Textarea.module.scss';

interface IProps
  extends Omit<TextFieldProps, 'error' | 'helperText' | 'multiline' | 'rows'> {
  showPassword?: boolean;
  name: string;
  rows: number;
}

const Textarea = ({ name, rows, sx = {}, ...rest }: IProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <TextField
            {...field}
            className={styles.wrapper}
            error={Boolean(error)}
            helperText={error?.message && error.message}
            multiline
            variant="standard"
            rows={rows}
            sx={{ ...sx }}
            InputProps={{ disableUnderline: true }}
            {...rest}
          />
        );
      }}
    />
  );
};

export default Textarea;
