import { Box, IconButton, Typography } from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';

import { IconMark } from 'components/atoms';
import { GradientIcon } from 'components/molecules';
import { COLORS_VIEW_CHARTS } from 'environment/constants/colorsViewCharts';
import { getMonthArray } from 'environment/utils/getMonthsArray';
import { handleMonthsForCharts } from 'environment/utils/handleMonthsForCharts';
import { repeatIndexByCircle } from 'environment/utils/repeatIndexByCircle';
import { IMonthData } from 'store/models/engagement';
import Colors from 'styles/colors.scss';

import ViewOnlyGraph from '../charts/view-only-graph/ViewOnlyGraph';

import { monthsItem } from './constants';
import styles from './ViewMonthlyCharts.module.scss';

export interface IProps {
  data: IMonthData;
  currentUnitCreatedAt: string;
}

const ViewMonthlyCharts = ({ data, currentUnitCreatedAt }: IProps) => {
  const { t } = useTranslation();
  const slidesToShow = 3;

  const monthsData = handleMonthsForCharts(currentUnitCreatedAt, data);

  const sliderRef = useRef<Slider | null>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(
    monthsData.length - slidesToShow,
  );

  const handleClickBack = () => {
    sliderRef.current?.slickPrev();
    setCurrentSlide(currentSlide - 1);
  };

  const handleClickForward = () => {
    sliderRef.current?.slickNext();
    setCurrentSlide(currentSlide + 1);
  };

  const handleBeforeChange = useCallback(
    (_currentSlide: number, nextSlide: number) => {
      setCurrentSlide(nextSlide);
    },
    [],
  );

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.header}>
        <Typography component="h2" variant="text-sm-light">
          {t('Daily.data.is.recorded.in.the.Pacific.time.zone')}
        </Typography>
        <Box className={styles.buttonsContainer}>
          <IconButton
            className={styles.arrowButton}
            disabled={currentSlide <= 0}
            onClick={handleClickBack}
          >
            {currentSlide <= 0 ? (
              <IconMark
                name="arrow-left1"
                size="24px"
                color={Colors['default-gray-400']}
              />
            ) : (
              <GradientIcon name="arrow-left1" size="24px" />
            )}
          </IconButton>
          <IconButton
            className={styles.arrowButton}
            disabled={currentSlide >= monthsData.length - slidesToShow}
            onClick={handleClickForward}
          >
            {currentSlide >= monthsData.length - slidesToShow ? (
              <IconMark
                name="arrow-right1"
                size="24px"
                color={Colors['default-gray-400']}
              />
            ) : (
              <GradientIcon name="arrow-right1" size="24px" />
            )}
          </IconButton>
        </Box>
      </Box>
      <Slider
        className={styles.slider}
        ref={sliderRef}
        swipe={false}
        beforeChange={handleBeforeChange}
        arrows={false}
        slidesToShow={slidesToShow}
        slidesToScroll={1}
        initialSlide={currentSlide}
        infinite={false}
      >
        {!!monthsData?.length &&
        monthsData.every((monthData) => {
          const hasNonZeroValues = (data: { [key: string]: number[] }) => {
            for (const key in data) {
              if (data[key].some((value) => value !== 0)) {
                return true;
              }
            }
            return false;
          };
          return !monthData || !hasNonZeroValues(monthData);
        })
          ? getMonthArray(currentUnitCreatedAt).map((item, index) => {
              const colorIndex = repeatIndexByCircle(
                index,
                COLORS_VIEW_CHARTS.length,
              );

              const monthTitle = monthsItem.find(
                (i) => i.value === item.toString(),
              )?.title;

              const dataChart = [100, 100, 100];

              return (
                <React.Fragment key={index}>
                  <Box className={styles.viewGraphWrapper}>
                    <ViewOnlyGraph
                      type="area"
                      data={dataChart}
                      colors={[COLORS_VIEW_CHARTS[colorIndex].color]}
                      gradient={COLORS_VIEW_CHARTS[colorIndex].gradient}
                    />
                  </Box>
                  <Typography
                    component={'p'}
                    className={styles.monthText}
                    variant="text-xs"
                  >
                    {monthTitle && t(monthTitle)}
                  </Typography>
                </React.Fragment>
              );
            })
          : monthsData.map((monthData, index) => {
              const [month, dataChart] = Object.entries(monthData)[0];
              const monthTitle = monthsItem.find(
                (item) => item.value === month,
              )?.title;
              const colorIndex = repeatIndexByCircle(
                index,
                COLORS_VIEW_CHARTS.length,
              );

              return (
                <React.Fragment key={index}>
                  <Box className={styles.viewGraphWrapper}>
                    <ViewOnlyGraph
                      type="area"
                      data={
                        dataChart.every((item) => item === 0)
                          ? [100, 100, 100]
                          : dataChart
                      }
                      colors={[COLORS_VIEW_CHARTS[colorIndex].color]}
                      gradient={COLORS_VIEW_CHARTS[colorIndex].gradient}
                    />
                  </Box>
                  <Typography
                    component={'p'}
                    className={styles.monthText}
                    variant="text-xs"
                  >
                    {monthTitle}
                  </Typography>
                </React.Fragment>
              );
            })}
      </Slider>
    </Box>
  );
};

export default ViewMonthlyCharts;
