import axios from 'axios';
import { FC, useState } from 'react';
import { type SubmitHandler, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { LoaderOverlay } from 'components/atoms';
import { SignUpMainTemplate } from 'components/templates';
import { signUpGeneral } from 'environment/api/services/auth';
import { type SignUpFormData } from 'hooks/forms';
import { useSignUpRedirect, useSignUpRoutes } from 'hooks/signUpNavigation';

interface IProps {
  hideRedirectToSignIn?: boolean;
}

const SignUpMain: FC<IProps> = ({ hideRedirectToSignIn }) => {
  const signUpRoutes = useSignUpRoutes();
  const signUpRedirect = useSignUpRedirect();

  const { clearErrors, setError, handleSubmit } =
    useFormContext<SignUpFormData>();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit: SubmitHandler<SignUpFormData> = async ({
    email,
    password,
  }) => {
    try {
      setIsLoading(true);

      const data = (await signUpGeneral({ email: email.trim(), password }))
        .data;
      const token = data.token;

      if (token) {
        localStorage.setItem('access_token', token);
        signUpRedirect(data.stages);
      } else {
        navigate(signUpRoutes.otp);
      }

      clearErrors('root.serverError');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError('root.serverError', {
          type: error.code,
          message: error.response?.data?.message ?? error.message,
        });
      } else {
        console.error(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <LoaderOverlay />}
      <SignUpMainTemplate
        onSubmit={handleSubmit(onSubmit)}
        hideRedirectToSignIn={hideRedirectToSignIn}
      />
    </>
  );
};

export default SignUpMain;
