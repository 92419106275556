import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import LogoBordered from 'assets/icons/logo-bordered.svg';
import LogoNormalReversed from 'assets/icons/logo-normal-reversed.svg';
import { ROUTES } from 'environment/constants/routes';
import Colors from 'styles/colors.scss';

import styles from './SplashScreen.module.scss';

const SplashScreenTemplate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box
      bgcolor={Colors['bg-color']}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      flex={1}
      maxHeight="673px"
      position="relative"
      px="12px"
      margin="0 0 auto 0"
    >
      <Box
        p="20px 64px"
        bgcolor={Colors['default-gray-700']}
        position="absolute"
        top={0}
        left={0}
        right={0}
        display="flex"
        gap="15.108px"
      >
        <Box component="img" src={LogoBordered} />
        <Box component="img" src={LogoNormalReversed} />
      </Box>
      <Typography
        fontSize={56}
        lineHeight="67.2px"
        fontWeight={600}
        align="center"
        className={styles.gradientText}
        mb="37px"
      >
        NoLemon{' '}
        <span className={styles.noGradientText}>{t('intelligence')}</span>{' '}
        {t('platform')}
      </Typography>
      <Typography
        fontSize={32}
        lineHeight="38.4px"
        fontWeight={600}
        align="center"
        mb="16px"
      >
        {t('for_smart_businesses')}
      </Typography>
      <Typography
        fontSize={20}
        lineHeight="24px"
        fontWeight={400}
        align="center"
        mb="47px"
      >
        {t('get_setup_in_minutes')}
      </Typography>
      <Button
        variant="contained"
        size="large"
        sx={{ height: 64 }}
        onClick={() => navigate(ROUTES.onboarding.claimBusiness)}
      >
        <Typography
          variant="text-lg-light"
          fontWeight={500}
          width="293px"
          color={Colors['default-gray-900']}
        >
          {t('start_your_journey_greater')}
        </Typography>
      </Button>
    </Box>
  );
};

export default React.memo(SplashScreenTemplate);
