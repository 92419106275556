import axios from 'axios';
import { type FC, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { LoaderOverlay } from 'components/atoms';
import SettingsModal from 'components/molecules/settingsModal/SettingsModal';
import { SignUpPersonalizeQuestionsTemplate } from 'components/templates';
import { updateBusinessUnit } from 'environment/api/services/business-units';
import { QUESTIONS_SCHEMA } from 'environment/validation/resolvers';
import { FormQuestions } from 'hooks/forms/default-values';
import { castQuestionsForBackend } from 'hooks/forms/helpers';
import useUnits from 'hooks/useUnits';
import { Dispatch } from 'store';

interface IProps {
  open: boolean;
  onClose: () => void;
  defaultValues: { questions: FormQuestions };
}

const EditPersonality: FC<IProps> = ({ open, onClose, defaultValues }) => {
  const form = useForm({
    defaultValues,
    values: defaultValues,
    mode: 'onChange',
    resolver: QUESTIONS_SCHEMA,
  });

  const dispatch = useDispatch<Dispatch>();

  const {
    currentUnit: { id },
  } = useUnits();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit: SubmitHandler<typeof defaultValues> = async ({
    questions,
  }) => {
    try {
      setIsLoading(true);

      const { data } = await updateBusinessUnit(id, {
        questions: castQuestionsForBackend(questions),
      });

      dispatch.units.updateCurrentBusinessUnit({
        id,
        questions: [
          ...data.questions.sort((a, b) => {
            const indexA = questions.findIndex(
              (el) => el.title.split(':').pop() === a.questionTemplate.title,
            );
            const indexB = questions.findIndex(
              (el) => el.title.split(':').pop() === b.questionTemplate.title,
            );

            return indexA - indexB;
          }),
        ],
      });

      handleClose();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        form.setError('root.serverError', {
          type: error.code,
          message: error.response?.data?.message ?? error.message,
        });
      } else {
        console.error(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    onClose();
    form.reset(defaultValues);
  };

  return (
    <SettingsModal open={open} onClose={handleClose}>
      {isLoading && <LoaderOverlay />}
      <FormProvider {...form}>
        <SignUpPersonalizeQuestionsTemplate
          onSubmit={form.handleSubmit(onSubmit)}
          onBackButtonOverride={onClose}
          withUseAsDropdown
        />
      </FormProvider>
    </SettingsModal>
  );
};

export default EditPersonality;
