import { ANALYTICS_CATEGORIES } from 'environment/constants/analytics';

export type CustomPeriod = { start: Date; end: Date };

export enum TimelinePeriod {
  Day = '24h',
  ThreeDays = '72h',
  Week = 'week',
  Month = 'month',
  AllTime = 'all-time',
}

export type Period = TimelinePeriod | CustomPeriod;

export type AnalyticsCategory = (typeof ANALYTICS_CATEGORIES)[number];

type AnalyticsBody = {
  count: number;
  change: number;
};

export type Analytics = Record<AnalyticsCategory, AnalyticsBody>;
