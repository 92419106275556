import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ViewOnlyGraph } from 'components/molecules';
import { COLORS_VIEW_CHARTS } from 'environment/constants/colorsViewCharts';
import Colors from 'styles/colors.scss';

import styles from './BusinessPromoteActivity.module.scss';

const defaultPeriods = [5, 10, 8, 12, 10, 20, 18, 45, 43, 48, 45, 46, 45, 47];

interface BusinessPromoteActivityProps {
  activity?: number;
  periods?: number[];
  change?: number;
}

const BusinessPromoteActivity = ({
  activity,
  periods,
  change = 0,
}: BusinessPromoteActivityProps) => {
  const { t } = useTranslation();
  return (
    <Box className={styles.stats}>
      <ViewOnlyGraph
        fill
        data={periods || defaultPeriods}
        colors={
          change >= 0
            ? [COLORS_VIEW_CHARTS[0].color]
            : [COLORS_VIEW_CHARTS[1].color]
        }
        gradient={
          change >= 0
            ? COLORS_VIEW_CHARTS[0].gradient
            : COLORS_VIEW_CHARTS[1].gradient
        }
        type="areaspline"
      />
      <Typography variant="text-base" align="center">
        {t('total-activity')}
      </Typography>
      <Box display="flex" alignItems="center" gap="4px">
        <Typography
          align="center"
          fontSize="22.83px"
          lineHeight="30.44px"
          fontWeight={600}
        >
          {activity || '- -'}
        </Typography>
        {change < 0 ? (
          <ArrowDropDownIcon
            sx={{ color: Colors['profile-red'] }}
            fontSize="large"
          />
        ) : (
          <ArrowDropUpIcon
            sx={{ color: Colors['HEXB8FE61'] }}
            fontSize="large"
          />
        )}
      </Box>
    </Box>
  );
};

export default React.memo(BusinessPromoteActivity);
