import {
  Autocomplete,
  Box,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GradientIcon } from 'components/molecules';

import { suggestions } from './constants';
import styles from './QuickReplyInput.module.scss';

interface IProps {
  waterfallId: string;
  comment?: string | null;
  businessUnitId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sendComment: (
    businessUnitId: string,
    commentText: string,
    owner: string,
    ownerId: string,
  ) => void;
  updateWaterfallReply: (waterfallId: string, reply: string | null) => void;
}

const QuickReplyInput = ({
  sendComment,
  comment,
  waterfallId,
  businessUnitId,
  updateWaterfallReply,
}: IProps) => {
  const { t } = useTranslation();

  const [commentText, setCommentText] = useState<string>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: any, newValue: string | null) => {
    if (newValue) {
      setCommentText(newValue);
    }
  };

  const handleChangeTextField = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newValue = event.target.value;
    if (newValue.length <= 200) {
      setCommentText(newValue);
    }
  };

  const handleSendComment = async () => {
    try {
      setIsSending(true);

      if (commentText.length >= 1) {
        const response = await sendComment(
          businessUnitId,
          commentText,
          'waterfall',
          waterfallId,
        );
        // @ts-ignore
        if (response.status === 201) {
          setIsSuccess(true);
          // @ts-ignore
          updateWaterfallReply(waterfallId, response.data.text);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsSending(false);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const clearIcon = null;

  return (
    <>
      {isSuccess ? (
        <FormControl variant="outlined" className={styles.inputComplete}>
          <OutlinedInput
            disabled
            value={commentText}
            endAdornment={
              <InputAdornment position="end">
                <GradientIcon name="check" size="24px" />
              </InputAdornment>
            }
          />
        </FormControl>
      ) : comment ? (
        <FormControl variant="outlined" className={styles.inputComplete}>
          <OutlinedInput
            disabled
            value={comment}
            endAdornment={
              <InputAdornment position="end">
                <GradientIcon name="check" size="24px" />
              </InputAdornment>
            }
          />
        </FormControl>
      ) : commentText && isSending ? (
        <FormControl variant="outlined" className={styles.inputComplete}>
          <OutlinedInput
            disabled
            value={commentText}
            endAdornment={
              <InputAdornment position="end">
                <Typography color="#B8FE61" variant="text-sm-light">
                  {t('Sending')}...
                </Typography>
              </InputAdornment>
            }
          />
        </FormControl>
      ) : (
        <Box className={styles.container}>
          <Box className={styles.wrapper}>
            <Autocomplete
              freeSolo
              options={suggestions}
              inputValue={commentText}
              onInputChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              clearOnBlur={false}
              clearIcon={clearIcon}
              className={styles.field}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={isFocused || commentText ? '' : t('Type.here')}
                  variant="outlined"
                  value={commentText}
                  onChange={handleChangeTextField}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  className={styles.input}
                  InputLabelProps={{
                    style: {
                      color: '#9f9f9f',
                      transform: 'translate(14px, 10px) scale(1)',
                    },
                  }}
                />
              )}
              PaperComponent={({ children }) => (
                <Paper style={styles.paper} className={styles.suggestions}>
                  {children}
                </Paper>
              )}
            />
            {commentText && (
              <Box className={styles.buttonSend} onClick={handleSendComment}>
                <GradientIcon name="double-right-arrows" size="24px" />
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default QuickReplyInput;
