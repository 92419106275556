import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface EmptyStateProps {
  standBy?: boolean;
}

const EmptyState = ({ standBy }: EmptyStateProps) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" alignItems="center" justifyContent="center" py="24px">
      <Typography variant="text-sm-light">
        {standBy ? t('type_something_to_search_for') : t('no_businesses_found')}
      </Typography>
    </Box>
  );
};

export default React.memo(EmptyState);
