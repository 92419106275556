import { UseFormReturn, useForm } from 'react-hook-form';

import { ADD_NEW_BUSINESS_SCHEMA } from 'environment/validation/resolvers';

import {
  businessDescription,
  businessDetails,
  businessType,
  member,
  questions,
  tags,
} from './default-values';
import { mapDaysToSchedule } from './helpers';

const defaultValues = {
  businessType,
  businessDescription,
  businessDetails,
  schedule: mapDaysToSchedule(),
  tags,
  questions,
  member,
  receipts: [] as File[],
  subscription: '',
  cardToken: '' as string | undefined,
  sameData: false,
};

export type NewBusinessFormData = typeof defaultValues;

export const useNewBusinessForm = () => {
  return useForm({
    defaultValues,
    mode: 'all',
    resolver: ADD_NEW_BUSINESS_SCHEMA,
  }) as unknown as UseFormReturn<NewBusinessFormData>;
};
