import { columns as demographicsColumns } from 'components/organisms/tables/demographicsTable/columns';
import { columns as recentActivityColumns } from 'components/organisms/tables/recentActivityReviewsTable/columns';
import { Order } from 'environment/types';
import { IReviewProcessed } from 'types';

type Property =
  | (typeof recentActivityColumns)[number]['id']
  | (typeof demographicsColumns)[number]['id'];

export const sortTableData = (
  data: IReviewProcessed[],
  orderBy: Property,
  order: Order,
) => {
  return [...data].sort((a, b) => {
    const compareValue = (a: IReviewProcessed, b: IReviewProcessed) => {
      switch (orderBy) {
        case 'user':
          // eslint-disable-next-line no-case-declarations
          const userA = a.user?.firstName || '';
          // eslint-disable-next-line no-case-declarations
          const userB = b.user?.firstName || '';

          if (order === 'asc') {
            return userA.localeCompare(userB);
          } else {
            return userB.localeCompare(userA);
          }
        case 'date':
          return (
            (order === 'asc' ? 1 : -1) *
            (new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
          );
        case 'views':
          return (
            (order === 'asc' ? 1 : -1) *
            ((b.engagement.watches.count || 0) -
              (a.engagement.watches.count || 0))
          );
        case 'comments':
          return (
            (order === 'asc' ? 1 : -1) *
            ((b.engagement.comments.count || 0) -
              (a.engagement.comments.count || 0))
          );
        case 'likesDislikes':
          return (
            (order === 'asc' ? 1 : -1) *
            ((b.engagement.likes.count || 0) +
              (b.engagement.dislikes.count || 0) -
              ((a.engagement.likes.count || 0) +
                (a.engagement.dislikes.count || 0)))
          );
        case 'score':
          return (order === 'asc' ? 1 : -1) * (b.score - a.score);
        default:
          return (
            (order === 'asc' ? 1 : -1) *
            (new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
          );
      }
    };

    return compareValue(a, b);
  });
};
