import { Typography } from '@mui/material';
import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DashboardCardWrapper } from 'components/atoms';
import { ROUTES } from 'environment/constants/routes';
import { IEngagementsQuantityForDay } from 'store/models/dashboard';
import Colors from 'styles/colors.scss';

import styles from './EngagementCard.module.scss';
import Graph from './Graph';

interface Props {
  engagementsQuantity: IEngagementsQuantityForDay[];
}

const EngagementCard: FC<Props> = ({ engagementsQuantity }) => {
  const { t } = useTranslation();

  const noEngagements = useMemo(
    () => !engagementsQuantity.some(({ count }) => !Object.is(count, 0)),
    [engagementsQuantity],
  );

  return (
    <DashboardCardWrapper
      title={t('Engagement')}
      navigateTo={ROUTES.dashboard.engagement}
      padding="24px 8px 0px 24px"
      gap="0"
    >
      <div className={styles.graphContainer}>
        {noEngagements ? (
          <div className={styles.emptyWrapper}>
            <div className={styles.emptyContainer}>
              <Typography variant="text-base-semibold">
                {t('No.engagements.yet')}
              </Typography>
              <Typography
                variant="text-sm-light"
                textAlign="center"
                color={Colors['default-gray-200']}
              >
                {t(
                  'Here.you.will.see.how.many.people.engaged.with.your.content',
                )}
              </Typography>
            </div>
          </div>
        ) : (
          <Graph
            data={engagementsQuantity.map(({ count, change }, i) => {
              return [i, Math.round(count ?? 0), Math.round(change ?? 0)];
            })}
          />
        )}
      </div>
    </DashboardCardWrapper>
  );
};

export default memo(EngagementCard);
