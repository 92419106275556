import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { LoaderOverlay2 } from 'components/atoms';
import { Tabs } from 'components/molecules';
import EmptyReview from 'components/molecules/emptyReview/EmptyReview';
import {
  Activity,
  PageVisitTab,
  StatisticsInCharts,
} from 'components/organisms';
import RecentActivityReviewsTable from 'components/organisms/tables/recentActivityReviewsTable/RecentActivityReviewsTable';
import { countDataInHoursRange } from 'environment/utils/countDataInHoursRange';
import { createTab } from 'environment/utils/createTab';
import { RootState } from 'store';
import { IEngagementCharts, IPageVisitData } from 'store/models/engagement';
import { IReviewProcessed } from 'types';
import { Analytics } from 'types/business-unit-analytics';

interface IProps {
  onTabChange: (index: number) => void;
  selectedIndex: number;

  analytics: Analytics;
  allReviews?: IReviewProcessed[];
  engagementData: IEngagementCharts;
  pageVisitData: IPageVisitData;
  currentUnitCreatedAt: string;
  getEngagementPageVisitData: (
    businessUnitId: string,
    value: string,
    startDate: string,
    endDate: string,
  ) => void;
}

const RecentActivityTemplate = ({
  onTabChange,
  selectedIndex,
  analytics,
  allReviews,
  engagementData,
  pageVisitData,
  currentUnitCreatedAt,
  getEngagementPageVisitData,
}: IProps) => {
  const { t } = useTranslation();

  const isLoading = useSelector(
    (state: RootState) =>
      state.loading.effects.engagement.getAllEngagementReviews,
  );

  const tabs = [
    createTab({
      label: t('Reviews'),
      component: (
        <LoaderOverlay2 loading={isLoading}>
          {allReviews && allReviews.length > 0 ? (
            <RecentActivityReviewsTable tableData={allReviews} />
          ) : (
            <EmptyReview />
          )}
        </LoaderOverlay2>
      ),
      badge: allReviews ? allReviews.length : 0,
    }),
    createTab({
      label: t('Likes'),
      component: (
        <StatisticsInCharts
          titleToFirstChart={t('Total.Page.Likes.as.of.today')}
          nameToFirstChart={t('Total.Page.Likes')}
          dayData={countDataInHoursRange(engagementData.like.dayData)}
          monthsData={engagementData.like.monthData}
          currentUnitCreatedAt={currentUnitCreatedAt}
          titleForDemographicBlock={'People.who.liked.your.content'}
          // TODO replace with actual data
          dataByAge={pageVisitData.dataByAge}
          userStatus={pageVisitData.userStatus}
        />
      ),
    }),
    createTab({
      label: t('Comments'),
      component: (
        <StatisticsInCharts
          titleToFirstChart={t('Total.Page.Comments.as.of.today')}
          nameToFirstChart={t('Total.Page.Comments')}
          dayData={countDataInHoursRange(engagementData.comment.dayData)}
          monthsData={engagementData.comment.monthData}
          currentUnitCreatedAt={currentUnitCreatedAt}
          titleForDemographicBlock={'People.who.commented.your.content'}
          // TODO replace with actual data
          dataByAge={pageVisitData.dataByAge}
          userStatus={pageVisitData.userStatus}
        />
      ),
    }),
    createTab({
      label: t('Share'),
      component: (
        <StatisticsInCharts
          titleToFirstChart={t('Total.Page.Share.as.of.today')}
          nameToFirstChart={t('Total.Page.Share')}
          dayData={countDataInHoursRange(engagementData.share.dayData)}
          monthsData={engagementData.share.monthData}
          currentUnitCreatedAt={currentUnitCreatedAt}
          titleForDemographicBlock={'People.who.shared.your.content'}
          // TODO replace with actual data
          dataByAge={pageVisitData.dataByAge}
          userStatus={pageVisitData.userStatus}
        />
      ),
    }),
    createTab({
      label: t('View'),
      component: (
        <StatisticsInCharts
          titleToFirstChart={t('Total.Views.as.of.today')}
          nameToFirstChart={t('Total.Views')}
          dayData={countDataInHoursRange(engagementData.view.dayData)}
          monthsData={engagementData.view.monthData}
          currentUnitCreatedAt={currentUnitCreatedAt}
          titleForDemographicBlock={'People.who.viewed.your.content'}
          // TODO replace with actual data
          dataByAge={pageVisitData.dataByAge}
          userStatus={pageVisitData.userStatus}
        />
      ),
    }),
    createTab({
      label: t('Waterfall.tag'),
      component: (
        <StatisticsInCharts
          titleToFirstChart={t('Total.Page.Waterfall.tags.as.of.today')}
          nameToFirstChart={t('Total.Page.Waterfall.tags')}
          dayData={countDataInHoursRange(engagementData.waterfallTag.dayData)}
          monthsData={engagementData.waterfallTag.monthData}
          currentUnitCreatedAt={currentUnitCreatedAt}
          titleForDemographicBlock={
            'People.who.tagged.your.business.in.Waterfall'
          }
          // TODO replace with actual data
          dataByAge={pageVisitData.dataByAge}
          userStatus={pageVisitData.userStatus}
        />
      ),
    }),
    createTab({
      label: t('Forum.tag'),
      component: (
        <StatisticsInCharts
          titleToFirstChart={t('Total.Page.Forum.tags.as.of.today')}
          nameToFirstChart={t('Total.Page.Forum.tags')}
          dayData={countDataInHoursRange(engagementData.forumTag.dayData)}
          monthsData={engagementData.forumTag.monthData}
          currentUnitCreatedAt={currentUnitCreatedAt}
          titleForDemographicBlock={'People.who.tagged.your.business.in.forum'}
          // TODO replace with actual data
          dataByAge={pageVisitData.dataByAge}
          userStatus={pageVisitData.userStatus}
        />
      ),
    }),
    createTab({
      label: t('Page.visit'),
      component: (
        <PageVisitTab
          data={pageVisitData}
          getEngagementPageVisitData={getEngagementPageVisitData}
        />
      ),
    }),
  ];

  return (
    <Box sx={{ pt: '32px' }}>
      <Activity title={t('Recent.activity')} analytics={analytics} />
      <Tabs
        tabs={tabs}
        onTabChange={onTabChange}
        selectedIndex={selectedIndex}
      />
    </Box>
  );
};

export default RecentActivityTemplate;
