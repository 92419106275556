import { getMediaById } from 'environment/api/services/media';
import { getReviewReplies } from 'environment/api/services/reviewReply';
import {
  getPersonalUserInfoById,
  getUserById,
} from 'environment/api/services/user';
import { store } from 'store';
import { IReview, IReviewProcessed } from 'types';

export const processReviewData = async (
  review: IReview,
): Promise<IReviewProcessed> => {
  try {
    // Process user's personal info
    if (review.user) {
      const { data } = await getPersonalUserInfoById(review.user.id);

      if (data) {
        review.user.about = data.about;
      }
    }

    if (review.id) {
      store.dispatch.reviewReply.getAllReviewReplies({
        reviewId: review.id,
      });
      const { data } = await getReviewReplies(review.id);
      if (data) {
        review.reply = data.text;
      }
    }

    if (review.user) {
      const user = await getUserById(review.user.id);
      review.user.engagersCount = user.data.engagersCount;
      review.user.engagingsCount = user.data.engagersCount;
    }
    // Process user's avatar
    if (review.user) {
      const user = await getUserById(review.user.id);

      const avatarId = user.data.avatar?.mediaId;

      if (avatarId) {
        const { data } = await getMediaById(avatarId);

        if (data.body && !Array.isArray(data.body)) {
          review.user.avatar = data.body.url;
        }
      }
    }

    // Process media items
    if (review.media) {
      await Promise.all(
        review.media.map(async (mediaItem) => {
          if (mediaItem.id) {
            const { data } = await getMediaById(mediaItem.id);

            if (!Array.isArray(data?.body) && data?.body?.url) {
              mediaItem.url = data.body.url;
            }
          }
        }),
      );
    }
  } catch (error) {
    console.error('Error processing review data:', error);
  }

  return review;
};
