import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import IconMark from '../icon/IconMark';

import styles from './Input.module.scss';

interface IProps
  extends Omit<TextFieldProps, 'className' | 'error' | 'helperText'> {
  name: string;
}

export const FieldProps = {
  className: styles.field,
};

export const InputProps = {
  disableUnderline: true,
  className: styles.input,
};

const Input = ({ name, type, variant = 'filled', ...rest }: IProps) => {
  const { control } = useFormContext();

  const [isPasswordShown, setPasswordShown] = useState<boolean>(false);

  const passwordAdornment = (
    <InputAdornment position="end">
      <IconButton onClick={() => setPasswordShown((v) => !v)}>
        {isPasswordShown ? (
          <IconMark name="show" size="24px" />
        ) : (
          <IconMark name="hide" size="24px" />
        )}
      </IconButton>
    </InputAdornment>
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          type={type === 'password' && isPasswordShown ? 'text' : type}
          variant={variant}
          error={Boolean(error?.message)}
          helperText={error?.message}
          InputProps={{
            endAdornment: type === 'password' ? passwordAdornment : undefined,
            ...InputProps,
          }}
          {...FieldProps}
          {...rest}
        />
      )}
    />
  );
};

export default Input;
