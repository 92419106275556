import { Box, IconButton, Popover, Typography } from '@mui/material';
import moment from 'moment';
import { type FC, type MouseEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconMark } from 'components/atoms';
import { daysNames } from 'environment/constants/dayNames';
import { DayName, Schedule } from 'environment/types';
import Colors from 'styles/colors.scss';

import { EditSchedule } from '../../modals';

import styles from './ScheduleInfo.module.scss';

interface IScheduleAnchorProps {
  currentScheduleState: 'open' | 'closed';
  closingTime: string | null;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const ScheduleAnchor: FC<IScheduleAnchorProps> = ({
  currentScheduleState,
  closingTime,
  onClick,
}) => {
  const { t } = useTranslation();

  const sx = onClick
    ? {
        ':hover': {
          cursor: 'pointer',
        },
      }
    : undefined;

  return (
    <Box className={styles.scheduleAnchor} onClick={onClick} sx={sx}>
      <Box className={styles.clockIcon}>
        <IconMark name="clock" size="24px" />
      </Box>
      <Box className={styles.openClosed}>
        <Typography variant="text-sm-light">
          {currentScheduleState === 'open' ? t('Open') : t('Closed')}
        </Typography>
        <Typography variant="text-sm-light">•</Typography>
        <Typography variant="text-sm-light">
          {t('Closed')} {closingTime}
        </Typography>
      </Box>
      <IconMark name="arrow-down" size="32px" color={Colors['white']} />
    </Box>
  );
};

interface IProps {
  schedule: Schedule;
}

const ScheduleInfo: FC<IProps> = ({ schedule }) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const [open, setOpen] = useState<boolean>(false);

  const currentDayName = moment().format('dddd').toLowerCase() as DayName;
  const { from, to, closed } = schedule[currentDayName];

  const isDayOff = closed;
  const isCurrentDayClosed =
    moment().isBefore(moment.utc(from)) && moment().isAfter(moment.utc(to));

  const formattedCloseTime = moment.utc(to).format('h A');

  const currentIndex = daysNames.indexOf(currentDayName);
  const nextDays = daysNames
    .slice(currentIndex)
    .concat(daysNames.slice(0, currentIndex)) as DayName[];

  return (
    <>
      <EditSchedule
        open={open}
        onClose={() => setOpen(false)}
        defaultValues={{ schedule }}
      />

      <Box className={styles.scheduleContainer}>
        <Box className={styles.scheduleTitle}>
          <Typography variant="text-xl">{t('Schedule')}</Typography>
          <IconButton sx={{ padding: '0' }} onClick={() => setOpen(true)}>
            <IconMark name="edit-2" size="24px" color={Colors['HEXB8FE61']} />
          </IconButton>
        </Box>
        <ScheduleAnchor
          currentScheduleState={isCurrentDayClosed ? 'closed' : 'open'}
          closingTime={isDayOff ? null : formattedCloseTime}
          onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
        />
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          slotProps={{ paper: { className: styles.schedulePopover } }}
        >
          <ScheduleAnchor
            currentScheduleState={isCurrentDayClosed ? 'closed' : 'open'}
            closingTime={isDayOff ? null : formattedCloseTime}
            onClick={() => setAnchorEl(null)}
          />
          <Box className={styles.scheduleDays}>
            {nextDays.map((dayName) => {
              const { from, to, closed } = schedule[dayName];

              const formattedOpenTime = moment.utc(from).format('hA');
              const formattedCloseTime = moment.utc(to).format('hA');

              return (
                <Box key={dayName} className={styles.oneScheduleDay}>
                  <Typography>{t(dayName)}</Typography>
                  <Typography>
                    {closed
                      ? t('Closed')
                      : `${formattedOpenTime}–${formattedCloseTime}`}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Popover>
      </Box>
    </>
  );
};

export default ScheduleInfo;
