import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import PaymentCardFormBody, {
  PaymentCardFormProps,
} from './PaymentCardFormBody';

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY as string,
);

const PaymentCardForm = (props: PaymentCardFormProps) => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentCardFormBody {...props} />
    </Elements>
  );
};

export default PaymentCardForm;
