import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import anyRestaurant from 'assets/icons/anyRestaurant.svg';
import spot from 'assets/icons/spot.svg';
import yourRestaurant from 'assets/icons/yourRestaurant.svg';

import RestaurantRate from '../restaurantRate/RestaurantRate';

import styles from './PlaceMarker.module.scss';

interface IProps {
  isOwner: boolean;
  title?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any; // TODO add type
  showRate?: boolean;
  imageSize?: React.CSSProperties;
  imageCardSize?: React.CSSProperties;
  textStyles?: React.CSSProperties;
  backgroundStyles?: React.CSSProperties;
}

const PlaceMarker = ({
  isOwner,
  title,
  data,
  showRate = false,
  imageSize,
  imageCardSize,
  textStyles,
  backgroundStyles,
}: IProps) => {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const renderImage = () => {
    if (isOwner) {
      return (
        <Box className={styles.uniqueImageCard} style={imageCardSize}>
          <Box
            component="img"
            src={yourRestaurant}
            className={styles.uniqueImage}
            style={imageSize}
          />
        </Box>
      );
    } else {
      return (
        <Box style={{ position: 'relative' }}>
          <Box className={styles.imageWrapper}>
            <Box
              component="img"
              src={anyRestaurant}
              className={styles.basicImage}
              style={imageSize}
            />
            <Box component="img" src={spot} className={styles.spot} />
          </Box>

          {isHovered && showRate && (
            <Box className={styles.rate}>
              <RestaurantRate
                title={title}
                amount={data.amount}
                percent={data.percent}
              />
            </Box>
          )}
        </Box>
      );
    }
  };

  const renderTitle = () => {
    if (isOwner) {
      return (
        <Box>
          <Box className={styles.textBackground}>
            <Typography className={styles.uniqueText} style={textStyles}>
              {t('Your.restaurant')}
            </Typography>
          </Box>
          <Box className={styles.backgroundBorder} style={backgroundStyles} />
        </Box>
      );
    } else {
      return (
        <Typography className={styles.basicText} style={textStyles}>
          {title}
        </Typography>
      );
    }
  };

  return (
    <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Box className={styles.container}>
        {renderImage()}
        {renderTitle()}
      </Box>
    </Box>
  );
};

export default PlaceMarker;
