import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeader, SettingsModal, Tabs } from 'components/molecules';
import EmptyWaterfallPreview from 'components/molecules/emptyWaterfallPreview/EmptyWaterfallPreview';
import FeaturedWaterfallPreview from 'components/molecules/featuredWaterfalls/featuredWaterfallPreview/FeaturedWaterfallPreview';
import FeaturedWaterfallView from 'components/molecules/featuredWaterfalls/FeaturedWaterfallView';
import FeaturedWaterfallsButtons from 'components/molecules/featuredWaterfallsButtons/FeaturedWaterfallsButtons';
import FeaturedWaterfallsTable from 'components/organisms/tables/featuredWaterfallsTable/FeaturedWaterfallsTable';
import CustomCarousel from 'components/organisms/waterfallCarousel/CustomCarousel';
import { ROUTES } from 'environment/constants/routes';
import { createTab } from 'environment/utils/createTab';
import { IWaterfall } from 'types/waterfall';

import styles from './FeaturedWaterfalls.module.scss';

interface IProps {
  tableWaterfallData: IWaterfall[];
  mostRecentWaterfallData: IWaterfall[];
  featuredWaterfallData: IWaterfall[];
  page: number;
  setPage: (page: number) => void;
  rowsPerPage: number;
  setRowsPerPage: (rowPerPage: number) => void;
  handleDeleteWaterfall: (waterfallId: string) => void;
  handleReportWaterfall: (waterfallId: string) => void;
  onChangeAutoWaterfall: (autoFeaturing: boolean) => void;
  autoFeaturing: boolean;
  toggleWaterfall: (
    businessUnitId: string,
    waterfallId: string,
    featured: boolean,
  ) => void;
  sendQuickCommentToWaterfall: (
    businessUnitId: string,
    commentText: string,
    ownerId: string,
    owner: string,
  ) => void;
  updateWaterfallReply: (waterfallId: string, reply: string | null) => void;
}

const FeaturedWaterfallsTemplate = ({
  tableWaterfallData,
  mostRecentWaterfallData,
  featuredWaterfallData,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  handleDeleteWaterfall,
  handleReportWaterfall,
  onChangeAutoWaterfall,
  autoFeaturing,
  toggleWaterfall,
  sendQuickCommentToWaterfall,
  updateWaterfallReply,
}: IProps) => {
  const { t } = useTranslation();

  const [openPreviewWaterfall, setOpenPreviewWaterfall] =
    useState<boolean>(false);
  const [openCarouselWaterfall, setOpenCarouselWaterfall] =
    useState<boolean>(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [clickedItemIndex, setClickedItemIndex] = useState<number>(0);

  const tabs = [
    createTab({
      label: t('Most.Recent'),
      component: (
        <>
          {mostRecentWaterfallData.length > 0 ? (
            <Box className={styles.waterfallsList}>
              {mostRecentWaterfallData.map((waterfall, index) => (
                <FeaturedWaterfallView
                  key={waterfall.id}
                  waterfallSrc={waterfall?.media?.url}
                  waterfallType={waterfall?.media?.type}
                  handleDeleteWaterfall={() =>
                    handleDeleteWaterfall(waterfall.id)
                  }
                  handleReportWaterfall={() =>
                    handleReportWaterfall(waterfall.id)
                  }
                  handleOpenCarousel={() => handleOpenCarousel(index, 0)}
                />
              ))}
            </Box>
          ) : (
            <EmptyWaterfallPreview
              title="Here.you.will.see.your.Most.Recent"
              width="825px"
              height="500px"
            />
          )}
        </>
      ),
      badge: '72h',
    }),
    createTab({
      label: t('Featured'),
      component: (
        <>
          {featuredWaterfallData.length > 0 ? (
            <Box className={styles.waterfallsList}>
              {featuredWaterfallData.map((waterfall, index) => (
                <FeaturedWaterfallView
                  key={waterfall.id}
                  waterfallSrc={waterfall?.media?.url}
                  waterfallType={waterfall?.media?.type}
                  handleDeleteWaterfall={() =>
                    handleDeleteWaterfall(waterfall.id)
                  }
                  handleReportWaterfall={() =>
                    handleReportWaterfall(waterfall.id)
                  }
                  handleOpenCarousel={() => handleOpenCarousel(index, 1)}
                />
              ))}
            </Box>
          ) : (
            <EmptyWaterfallPreview
              title="Here.you.will.see.your.Feature.Waterfall"
              width="825px"
              height="500px"
            />
          )}
        </>
      ),
      badge: '30d',
    }),
  ];

  const handleOpenPreviewWaterfall = (index: number) => {
    setSelectedTabIndex(index);
    setOpenPreviewWaterfall(true);
  };

  const handleOpenCarousel = (index: number, tabIndex: number) => {
    setSelectedTabIndex(tabIndex);
    setOpenCarouselWaterfall(true);
    setClickedItemIndex(index);
  };

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.header}>
        <PageHeader
          title={t('Featured.Waterfall')}
          backButtonNavigateTo={ROUTES.dashboard.home}
          isToggle={true}
          autoFeaturing={autoFeaturing}
          onChangeAutoWaterfall={onChangeAutoWaterfall}
          rightSection={
            <FeaturedWaterfallsButtons
              handleOpenPreviewWaterfall={handleOpenPreviewWaterfall}
            />
          }
        />

        <SettingsModal
          title={t('Preview.Waterfall')}
          open={openPreviewWaterfall}
          onClose={() => setOpenPreviewWaterfall(false)}
          childrenBoxProps={{ gap: 0 }}
          withCloseButton
        >
          <Tabs
            tabs={tabs}
            onTabChange={setSelectedTabIndex}
            selectedIndex={selectedTabIndex}
          />
        </SettingsModal>

        <SettingsModal
          leftSection={
            selectedTabIndex === 0
              ? t('72hr.Waterfall')
              : t('Featured.Waterfall')
          }
          open={openCarouselWaterfall}
          onClose={() => setOpenCarouselWaterfall(false)}
          withCloseButton
        >
          <CustomCarousel
            loop={false}
            data={
              selectedTabIndex === 0
                ? mostRecentWaterfallData
                : featuredWaterfallData
            }
            clickedItemIndex={clickedItemIndex}
            sendQuickCommentToWaterfall={sendQuickCommentToWaterfall}
            updateWaterfallReply={updateWaterfallReply}
          />
        </SettingsModal>

        {mostRecentWaterfallData.length > 0 ? (
          <Box className={styles.waterfalls}>
            {mostRecentWaterfallData.map((waterfall, index) => (
              <FeaturedWaterfallPreview
                key={waterfall.id}
                handleDeleteWaterfall={() =>
                  handleDeleteWaterfall(waterfall.id)
                }
                handleReportWaterfall={() =>
                  handleReportWaterfall(waterfall.id)
                }
                handleOpenCarousel={() => handleOpenCarousel(index, 0)}
                mediaSrc={waterfall?.media?.url}
                mediaType={waterfall?.media?.type}
              />
            ))}
          </Box>
        ) : (
          <EmptyWaterfallPreview
            height="214px"
            title="Here.you.will.see.your.Most.Recent"
          />
        )}
      </Box>

      <FeaturedWaterfallsTable
        toggleWaterfall={toggleWaterfall}
        tableData={tableWaterfallData}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        sendQuickCommentToWaterfall={sendQuickCommentToWaterfall}
        updateWaterfallReply={updateWaterfallReply}
      />
    </Box>
  );
};

export default FeaturedWaterfallsTemplate;
