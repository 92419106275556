import { Typography } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { LoaderOverlay } from 'components/atoms';
import { PaymentPage } from 'components/organisms';
import {
  signUpBusinessCardToken,
  signUpBusinessComplete,
} from 'environment/api/services/auth';
import { ROUTES } from 'environment/constants/routes';
import { getOnboardingCalculation } from 'environment/utils/getOnboardingCalculation';
import { SignUpBusinessFormData } from 'hooks/forms';
import { Dispatch } from 'store';

const SignUpCardDetails = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const [calculation, setCalculation] = useState<
    { title: string; value: number }[]
  >([]);

  const { control } = useFormContext<SignUpBusinessFormData>();

  const selected = useWatch({ control, name: 'subscription' });

  useEffect(() => {
    (async () => {
      if (!selected) return;
      const result = await getOnboardingCalculation(selected);

      setCalculation(result);
    })();
  }, [selected]);

  const onSubmit = async (cardToken: string) => {
    try {
      setError('');
      setIsLoading(true);

      await signUpBusinessCardToken({ cardToken });

      const { data } = await signUpBusinessComplete();

      localStorage.setItem('access_token', data.id_token);

      dispatch.units.getBusinessUnits();

      navigate(ROUTES.dashboard.home);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message = error.response?.data?.message ?? error.message;
        setError(message);
      } else {
        console.error(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <LoaderOverlay />}
      <PaymentPage
        onAddCard={onSubmit}
        calculation={calculation}
        title={t('activate_your_plan_to_join_nolemon')}
      />
      {error && <Typography variant="text-sm-lighter">{error}</Typography>}
    </>
  );
};

export default SignUpCardDetails;
