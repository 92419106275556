import { Popover } from '@mui/material';
import { CSSProperties, FC, MouseEvent, useState } from 'react';

import threeDots from 'assets/icons/dots-three.svg';
import ReportDropdown from 'components/atoms/reportDropdown/ReportDropdown';

import styles from './WaterfallDropdown.module.scss';

interface Props {
  handleReportWaterfall: () => void;
  handleDeleteWaterfall: () => void;
  style?: CSSProperties;
}

const WaterfallDropdown: FC<Props> = ({
  handleReportWaterfall,
  handleDeleteWaterfall,
  style,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpenDropdown = ({
    currentTarget,
  }: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <button
        style={style}
        className={styles.iconWrapper}
        onClick={handleOpenDropdown}
      >
        <img src={threeDots} alt="menu" />
      </button>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseDropdown}
        slotProps={{ paper: { sx: { backgroundColor: 'transparent' } } }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ReportDropdown
          handleReportWaterfall={handleReportWaterfall}
          handleDeleteWaterfall={handleDeleteWaterfall}
          onClose={handleCloseDropdown}
        />
      </Popover>
    </>
  );
};

export default WaterfallDropdown;
