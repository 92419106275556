import { Box, BoxProps } from '@mui/material';
import { type ReactNode } from 'react';
import { type LinkProps, Link as RouterLink } from 'react-router-dom';

import styles from './Link.module.scss';

interface IProps extends Omit<BoxProps, 'className'> {
  children: ReactNode;
  navigateTo: string;
  target?: LinkProps['target'];
}

const Link = ({ children, navigateTo, target, ...rest }: IProps) => {
  return (
    <Box
      className={styles.text}
      component={RouterLink}
      to={navigateTo}
      target={target}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default Link;
