import moment from 'moment';

export const countWaterfallTime = (createdAt: string, type: string) => {
  const currentTime = moment();
  const createdAtMoment = moment(createdAt);

  let remainingTime: string;
  let progress: number;

  if (type === 'mostRecent') {
    const duration = moment.duration(currentTime.diff(createdAtMoment));
    const hoursRemaining = 72 - duration.asHours();
    if (hoursRemaining > 0) {
      remainingTime = `${Math.round(hoursRemaining)}h`;
      progress = (hoursRemaining / 72) * 100;
    } else {
      remainingTime = 'Data expired';
      progress = 100;
    }
  } else if (type === 'featured') {
    const duration = moment.duration(currentTime.diff(createdAtMoment));
    const daysRemaining = 30 - duration.asDays();
    if (daysRemaining > 0) {
      remainingTime = `${Math.round(daysRemaining)}d`;
      progress = (daysRemaining / 30) * 100;
    } else {
      remainingTime = 'Data expired';
      progress = 100;
    }
  } else {
    remainingTime = 'Invalid type';
    progress = 0;
  }

  return { remainingTime, progress };
};
