import { Box, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import emptyCircle from 'assets/icons/emptyCircle.svg';
import { TitleAndProgress } from 'components/atoms';
import { DonutChart } from 'components/molecules';
import { countPercentNewUsers } from 'environment/utils/countPercentNewUsers';

import ChartSet from '../chartSet/ChartSet';

import styles from './ChartCard.module.scss';
import { items } from './constants';

export interface Endorsements {
  comments: number;
  forumTag: number;
  likes: number;
  pageVisit: number;
  reviews: number;
  shares: number;
  storyTag: number;
  watches: number;
}

interface IProps {
  statistics: { allUsers: number; newUsers: number };
  ageRange: number;
  endorsements: Endorsements;
}

const ChartCard = ({ statistics, ageRange, endorsements }: IProps) => {
  const { t } = useTranslation();

  const cardTitle = useMemo(() => {
    switch (ageRange) {
      case 21:
        return 'Under 21';
      case 25:
        return '21-24';
      case 31:
        return '25-31 age';
      case 37:
        return '32-37 age';
      case 44:
        return '38-44 age';
      case 100:
        return '45+';
      default:
        return '';
    }
  }, [ageRange]);

  const allDataCount = useCallback(
    () =>
      Object.values(endorsements).reduce(
        (total: number, value: number) => total + value,
        0,
      ),
    [endorsements],
  );

  const percentNewUsers = countPercentNewUsers(
    statistics.allUsers,
    statistics.newUsers,
  );

  const getData = () => {
    return items.map((item) => {
      const translatedTitle = t(item.title);
      return {
        name: translatedTitle,
        y: endorsements[item.value],
      };
    });
  };

  const allYValuesAreZero = getData().every((data) => data.y === 0);
  return (
    <Box className={styles.wrapper}>
      <Box className={styles.header}>
        <Typography variant="text-lg">{cardTitle}</Typography>
      </Box>

      <Box className={styles.chartCard}>
        <Box className={styles.chartContainer}>
          {!allYValuesAreZero ? (
            <DonutChart
              data={getData()}
              rounded={true}
              colors={items.map((item) => item.color)}
              height={104}
              size="small"
              title={allDataCount()}
            />
          ) : (
            <Box className={styles.emptyBlock}>
              <Box component="img" src={emptyCircle} sx={{ width: 97 }} />
              <Box className={styles.zeroUsersBlock}>
                <Typography variant="text-lg">0</Typography>
              </Box>
            </Box>
          )}
        </Box>
        <TitleAndProgress
          data={statistics.allUsers}
          title={t('All.users')}
          position="flex-start"
          percent={percentNewUsers}
          lowText
        />
      </Box>

      <ChartSet itemsData={endorsements} />
    </Box>
  );
};

export default ChartCard;
