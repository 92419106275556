import { Box, Typography } from '@mui/material';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import IconMark from 'components/atoms/icon/IconMark';
import Colors from 'styles/colors.scss';

import styles from './GoBackLink.module.scss';

interface Props {
  size?: 'small' | 'large';
  navigateTo?: string;
  onClick?: () => void;
}

const Content: FC<Props> = ({ size = 'small' }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center" gap="8px">
      <IconMark
        name="chevron-back"
        color={Colors['default-green-500']}
        size={size === 'small' ? '15px' : '20px'}
      />
      <Typography
        color={Colors['default-green-500']}
        variant={size === 'small' ? 'text-sm-light' : 'text-base-light'}
      >
        {t('Back')}
      </Typography>
    </Box>
  );
};

const GoBackLink: FC<Props> = ({ size = 'small', navigateTo, onClick }) => {
  return navigateTo ? (
    <Box
      className={styles.wrapper}
      component={Link}
      to={navigateTo}
      onClick={onClick}
    >
      <Content size={size} />
    </Box>
  ) : (
    <Box className={styles.wrapper} onClick={onClick}>
      <Content size={size} />
    </Box>
  );
};

export default memo(GoBackLink);
