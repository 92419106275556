import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { RecentActivityTemplate } from 'components/templates';
import { generateMonthsString } from 'environment/utils/generateMonthsString';
import { getMonthArray } from 'environment/utils/getMonthsArray';
import useEngagement from 'hooks/useEngagement';
import useUnits from 'hooks/useUnits';
import { RootState } from 'store';

const RecentActivity = () => {
  const { currentUnit } = useUnits();

  const { analytics } = useSelector((state: RootState) => state.recentActivity);

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  const handleTabChange = (index: number) => {
    setSelectedTabIndex(index);
  };

  const {
    allReviews,
    like,
    comment,
    share,
    forumTag,
    waterfallTag,
    view,
    pageVisit,
    getEngagementData,
    getEngagementPageVisitData,
    getAllReviews,
  } = useEngagement();

  const monthArray = getMonthArray(currentUnit.createdAt);
  const monthString = generateMonthsString(monthArray);

  const invokeFunctionBasedOnTab = (): void => {
    const now = moment();
    switch (selectedTabIndex) {
      case 0:
        getAllReviews(currentUnit.id, 100);
        break;
      case 1:
        getEngagementData(currentUnit.id, 'like', monthString);
        break;
      case 2:
        getEngagementData(currentUnit.id, 'comment', monthString);
        break;
      case 3:
        getEngagementData(currentUnit.id, 'share', monthString);
        break;
      case 4:
        getEngagementData(currentUnit.id, 'view', monthString);
        break;
      case 5:
        getEngagementData(currentUnit.id, 'waterfallTag', monthString);
        break;
      case 6:
        getEngagementData(currentUnit.id, 'forumTag', monthString);
        break;
      case 7:
        getEngagementPageVisitData(
          currentUnit.id,
          'pageVisitByData',
          now.clone().subtract(24, 'hours').toISOString(),
          now.toISOString(),
        );
        getEngagementPageVisitData(currentUnit.id, 'pageVisitByAge');
        getEngagementPageVisitData(currentUnit.id, 'pageVisitUserStatus');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    invokeFunctionBasedOnTab();
  }, [selectedTabIndex, currentUnit]);

  const engagementData = {
    like,
    comment,
    share,
    forumTag,
    waterfallTag,
    view,
  };

  return (
    <RecentActivityTemplate
      onTabChange={handleTabChange}
      selectedIndex={selectedTabIndex}
      analytics={analytics}
      allReviews={allReviews}
      engagementData={engagementData}
      pageVisitData={pageVisit}
      currentUnitCreatedAt={currentUnit.createdAt}
      getEngagementPageVisitData={getEngagementPageVisitData}
    />
  );
};

export default RecentActivity;
