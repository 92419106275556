import { Box, Divider, IconButton, Typography } from '@mui/material';
import axios from 'axios';
import { FC, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  IconMark,
  LoaderOverlay,
  ScrollContainer,
  SettingsSectionButton,
} from 'components/atoms';
import { SettingsSection } from 'components/molecules';
import { Member } from 'environment/types/business';
import { MemberFormData, useMemberForm } from 'hooks/forms';
import { Dispatch, RootState } from 'store';
import Colors from 'styles/colors.scss';

import AddMember from '../../modals/addMember/AddMember';

import styles from './Members.module.scss';

interface IProps {
  members: Member[];
  businessUnitName: string;
  businessUnitId: string;
}

const Members: FC<IProps> = ({ members, businessUnitName, businessUnitId }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch<Dispatch>();

  const form = useMemberForm();

  const [open, setOpen] = useState<'' | 'add' | 'edit'>('');
  const [selectedMemberId, setSelectedMemberId] = useState<string | null>(null);

  const filteredMembers = useMemo(
    () =>
      members.filter(({ scopes }) =>
        scopes.some(({ businessUnitId: unitId }) => unitId === businessUnitId),
      ),
    [members, businessUnitId],
  );

  const {
    createMember: isCreateMemberLoading,
    updateMember: isUpdateMemberLoading,
    getMembers: isMembersFetching,
  } = useSelector((state: RootState) => state.loading.effects.business);

  const isMembersSectionLoading =
    isCreateMemberLoading || isUpdateMemberLoading || isMembersFetching;

  const handleClose = () => {
    setOpen('');
    form.reset();
  };

  const onAddSubmit: SubmitHandler<MemberFormData> = async ({
    member: { email, fullName, permission: scope },
  }) => {
    if (!scope) return;

    try {
      await dispatch.business.createMember({
        fullName: fullName.trim(),
        email: email.trim(),
        scope,
        businessUnitId,
      });

      handleClose();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        form.setError('root.serverError', {
          type: error.code,
          message: error.response?.data?.message ?? error.message,
        });
      } else {
        console.error(error);
      }
    }
  };

  const onEditSubmit: SubmitHandler<MemberFormData> = async ({
    member: { fullName, permission: scope },
  }) => {
    if (!selectedMemberId || !scope) return;

    try {
      const memberId = selectedMemberId;

      await dispatch.business.updateMember({
        fullName: fullName.trim(),
        scope,
        memberId,
        businessUnitId,
      });

      handleClose();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        form.setError('root.serverError', {
          type: error.code,
          message: error.response?.data?.message ?? error.message,
        });
      } else {
        console.error(error);
      }
    }
  };

  return (
    <FormProvider {...form}>
      <AddMember
        open={Boolean(open)}
        onClose={handleClose}
        onSubmit={
          open === 'add'
            ? form.handleSubmit(onAddSubmit)
            : form.handleSubmit(onEditSubmit)
        }
        heading={open === 'add' ? t('Add.member') : t('Edit.member')}
        buttonText={open === 'add' ? t('Add.Member') : t('Save.&.Update')}
        isLoading={isCreateMemberLoading || isUpdateMemberLoading}
        businessUnitName={businessUnitName}
      />

      <SettingsSection>
        {isMembersSectionLoading && <LoaderOverlay />}
        <Box className={styles.header}>
          <Typography variant="text-xl">{t('Members')}</Typography>
          <SettingsSectionButton
            text={t('Add.new.member')}
            onClick={() => setOpen('add')}
          />
        </Box>

        {filteredMembers.length > 0 ? (
          <>
            <Divider sx={{ marginY: '16px' }} />

            <ScrollContainer className={styles.membersList}>
              {filteredMembers.map(({ email, fullName, scopes }) => {
                return scopes.map(
                  ({ id, scope, memberId, businessUnitId: unitId }) => {
                    if (unitId === businessUnitId) {
                      return (
                        <Box key={id} className={styles.member}>
                          <Box className={styles.memberFullNameAndTitle}>
                            <Typography
                              variant="text-base-light"
                              className={styles.memberFullName}
                            >
                              {fullName}
                            </Typography>
                            <Typography
                              component="span"
                              variant="text-base-light"
                              color={Colors['default-gray-300']}
                            >
                              ({t(scope)})
                            </Typography>
                          </Box>

                          <Box className={styles.organizationContainer}>
                            <Typography
                              variant="text-base-light"
                              className={styles.organization}
                            >
                              {scope === 'ADMIN'
                                ? t('All.businesses')
                                : businessUnitName}
                            </Typography>
                          </Box>
                          <Box className={styles.pencilIconContainer}>
                            <IconButton
                              sx={{ padding: 0 }}
                              className={styles.pencilIcon}
                              onClick={() => {
                                setOpen('edit');
                                setSelectedMemberId(memberId);
                                form.setValue('member', {
                                  email,
                                  fullName,
                                  permission: scope,
                                });
                              }}
                            >
                              <IconMark name="edit-2" size="24px" />
                            </IconButton>
                          </Box>
                        </Box>
                      );
                    } else {
                      return null;
                    }
                  },
                );
              })}
            </ScrollContainer>
          </>
        ) : (
          <Box className={styles.emptyMembers}>
            <Box className={styles.emptyMembersText}>
              <Typography
                variant="text-lg"
                sx={{ fontWeight: 400, width: '290px' }}
              >
                {t('Here.you.will.see.members.of.your.businesses')}
              </Typography>

              <Typography
                variant="text-sm-light"
                color={Colors['default-gray-300']}
              >
                {t(
                  'Add.members.and.set.permissions.to.allow.them.manage.your.businesses',
                )}
              </Typography>
            </Box>
          </Box>
        )}
      </SettingsSection>
    </FormProvider>
  );
};

export default Members;
