import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'environment/constants/routes';
import usePayments from 'hooks/usePayments';
import { SubscriptionStatus } from 'types/payments/payments';

import Message from './Message';

const DashboardNotifications = () => {
  const { unitSubscription, unitPayments } = usePayments();
  const subscriptionExpired =
    unitSubscription?.status !== SubscriptionStatus.active &&
    unitSubscription?.status !== SubscriptionStatus.trialing;

  const paymentFailed = useMemo(
    () => unitPayments.some((payment) => !payment.paid),
    [unitPayments],
  );
  const { t } = useTranslation();
  return (
    <Box display="flex" flex={1} flexDirection="column" gap="12px" mb="24px">
      {subscriptionExpired && (
        <Message
          title={t('subscription_expired')}
          description={t(
            'renew_your_subscription_to_continue_using_the_platform_o',
          )}
        />
      )}
      {paymentFailed && (
        <Message
          title={t('payment-failed')}
          description={t(
            'please-finish-your-payment-to-continue-using-the-platfor',
          )}
          link={ROUTES.payment}
        />
      )}
    </Box>
  );
};

export default React.memo(DashboardNotifications);
