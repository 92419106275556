import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, IconButton, Typography } from '@mui/material';
import { type ReactNode } from 'react';

import styles from './HeaderModal.module.scss';

interface IProps {
  title?: string;
  leftSection?: ReactNode;
  onClose: () => void;
}

const HeaderModal = ({ title, leftSection, onClose }: IProps) => {
  return (
    <Box className={styles.flex}>
      {leftSection}
      {title && (
        <>
          <Typography variant="text-lg">{title}</Typography>
          <IconButton onClick={onClose} className={styles.closeButton}>
            <CloseRoundedIcon />
          </IconButton>
        </>
      )}
    </Box>
  );
};

export default HeaderModal;
