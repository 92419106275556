import { Location } from 'environment/types/api/shared';

export function stringifyLocation(location: Location | null) {
  if (location) {
    const { number, street, state, zipcode } = location;
    return `${number ? `${number} ` : ''}${street}, ${
      state ? `${state} ` : ''
    }${zipcode}`;
  } else {
    return '';
  }
}
