import { Box, Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './FormActions.module.scss';

interface FormActionsProps {
  onCancel: () => void;
  onSubmit: () => void;
  submitText?: string;
  cancelText?: string;
  disableSubmit?: boolean;
}

const FormActions = ({
  onCancel,
  onSubmit,
  cancelText,
  submitText,
  disableSubmit,
}: FormActionsProps) => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      gap="20px"
      width="100%"
      justifyContent="center"
      className={styles.actionBar}
    >
      <Button
        fullWidth
        variant="outlined"
        size="large"
        onClick={onCancel}
        sx={{ maxWidth: 262 }}
      >
        {cancelText || t('back')}
      </Button>
      <Button
        fullWidth
        variant="contained"
        size="large"
        onClick={onSubmit}
        disabled={disableSubmit}
        sx={{ maxWidth: 262 }}
      >
        {submitText || t('Next')}
      </Button>
    </Box>
  );
};

export default React.memo(FormActions);
