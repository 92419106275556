import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IconMark, Input } from 'components/atoms';
import Colors from 'styles/colors.scss';

import styles from './MemberInfo.module.scss';

interface IProps {
  businessName: string;
  fullNameFieldName: string;
  emailFieldName: string;
}

const MemberInfo: FC<IProps> = ({
  businessName,
  fullNameFieldName,
  emailFieldName,
}) => {
  const { t } = useTranslation();

  return (
    <Box className={styles.container}>
      <Box className={styles.companyName}>
        <Typography variant="text-base-light">{businessName}</Typography>
        <IconMark
          name="arrow-down"
          size="24px"
          color={Colors['default-gray-200']}
        />
      </Box>
      <Input
        name={fullNameFieldName}
        label={t('fields.fullName')}
        placeholder={t('fields.fullName')}
      />
      <Input
        name={emailFieldName}
        label={t('fields.email')}
        placeholder={t('fields.email')}
      />
    </Box>
  );
};

export default MemberInfo;
