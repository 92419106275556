import { Box, Typography } from '@mui/material';
import { FC } from 'react';

import { IconMark, Otp } from 'components/atoms';
import Colors from 'styles/colors.scss';

interface IProps {
  otpLength: number;
  messageType: 'success' | 'error' | null;
  message?: string;
  onComplete?: (value: string) => void;
}

const OtpWithMessage: FC<IProps> = ({
  otpLength,
  messageType,
  message,
  onComplete,
}) => {
  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Otp length={otpLength} onComplete={onComplete} />

      {messageType ? (
        <Box display="flex" justifyContent="center">
          {messageType === 'error' && (
            <Box display="flex" alignItems="center" gap="12px">
              <IconMark
                name="close-circle"
                size="32px"
                color={Colors['profile-red']}
              />
              <Typography variant="text-sm-light" color={Colors['profile-red']}>
                {message}
              </Typography>
            </Box>
          )}

          {messageType === 'success' && (
            <IconMark name="tick" size="32px" color={Colors['HEXB8FE61']} />
          )}
        </Box>
      ) : null}
    </Box>
  );
};

export default OtpWithMessage;
