import axios from 'axios';
import { useSnackbar } from 'notistack';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { OnboardingWrapper } from 'components/atoms';
import { SignUpPersonalizeQuestionsTemplate } from 'components/templates';
import { ROUTES } from 'environment/constants/routes';
import { Question } from 'environment/types/claim';
import { QUESTIONS_SCHEMA } from 'environment/validation/resolvers';
import { questions as defaultQuestions } from 'hooks/forms/default-values';
import { castQuestionsForBackend } from 'hooks/forms/helpers';
import { Dispatch, RootState } from 'store';

const QuestionsTemplate = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<Dispatch>();

  const { questions } = useSelector((state: RootState) => state.onboarding);

  const values = questions.length
    ? { questions }
    : { questions: defaultQuestions };

  const form = useForm({
    values,
    mode: 'onChange',
    resolver: QUESTIONS_SCHEMA,
  });

  const handleNext = async ({ questions }: { questions: Question[] }) => {
    try {
      const questionsToSend = castQuestionsForBackend(questions);
      await dispatch.onboarding.setClaimQuestions(questionsToSend);
      await dispatch.onboarding.completeClaim();
      navigate(ROUTES.dashboard.home);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(error.response?.data?.message ?? error.message, {
          variant: 'error',
        });
      }
    }
  };

  const handleSkip = async () => {
    try {
      await dispatch.onboarding.completeClaim();
      navigate(ROUTES.dashboard.home);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(error.response?.data?.message ?? error.message, {
          variant: 'error',
        });
      }
    }
  };

  return (
    <OnboardingWrapper
      onBack={() => navigate(ROUTES.onboarding.tagsDetailed)}
      onSkip={handleSkip}
    >
      <FormProvider {...form}>
        <SignUpPersonalizeQuestionsTemplate
          onSubmit={form.handleSubmit(handleNext)}
          withBackButton={false}
          hidePromotion
        />
      </FormProvider>
    </OnboardingWrapper>
  );
};

export default QuestionsTemplate;
