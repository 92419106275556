import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ClaimBusinessAutocomplete } from 'components/atoms';
import { searchToClaim } from 'environment/api/services/claim';
import { ROUTES } from 'environment/constants/routes';
import { IBusinessUnit } from 'environment/types/business';
import { BUSINESS_UNIT_CLAIM_SOURCE } from 'environment/types/claim';
import { Dispatch, RootState } from 'store';
import Colors from 'styles/colors.scss';

const ClaimBusinessTemplate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch<Dispatch>();

  const { businessUnit, onboardingType } = useSelector(
    (state: RootState) => state.onboarding,
  );

  const [units, setUnits] = useState<IBusinessUnit[]>([]);

  const handleOnBusinessUnitSelect = (businessUnit: IBusinessUnit | null) => {
    dispatch.onboarding.setOnboarding({ businessUnit });
  };

  const handleOnFilter = async (value: string) => {
    if (value) {
      handleOnBusinessUnitSelect(null);
      const result = await searchToClaim(value);
      setUnits(result.data);
    } else {
      setUnits([]);
    }
  };

  const handleSubmit = async () => {
    navigate(ROUTES.onboarding.confirmBusiness);
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const handleCantFindBusiness = () => {
    if (onboardingType === BUSINESS_UNIT_CLAIM_SOURCE.from_landing_page) {
      navigate(ROUTES.signup.home);
    } else {
      navigate(ROUTES.settings);
      dispatch.dashboard.setAddBusinessModalOpen();
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      maxWidth="760px"
      gap="8px"
      px="12px"
    >
      <Box display="flex" alignItems="center">
        <ClaimBusinessAutocomplete
          data={units}
          onBusinessSelect={handleOnBusinessUnitSelect}
          onFilter={handleOnFilter}
          value={businessUnit?.id}
        />
        <Button
          variant="contained"
          size="large"
          sx={{
            height: 56,
            borderRadius: '0 8px 8px 0',
            width: matches ? 239 : 'auto',
          }}
          onClick={handleSubmit}
          disabled={!businessUnit}
        >
          <Typography
            variant="text-lg-light"
            fontWeight={500}
            color={Colors['default-gray-900']}
          >
            {matches ? t('claim_your_business') : t('claim')}
          </Typography>
        </Button>
      </Box>
      <Typography
        variant="text-base-light"
        color={Colors['HEXB8FE61']}
        sx={{ cursor: 'pointer' }}
        onClick={handleCantFindBusiness}
      >
        {t('cant_find_your_business')}
      </Typography>
    </Box>
  );
};

export default React.memo(ClaimBusinessTemplate);
