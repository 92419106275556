import axios from 'axios';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { LoaderOverlay } from 'components/atoms';
import { SignUpOtpTemplate } from 'components/templates';
import {
  signUpEmailVerification,
  signUpGeneral,
} from 'environment/api/services/auth';
import { ROUTES } from 'environment/constants/routes';
import { SignUpFormData } from 'hooks/forms';
import { useSignUpRoutes } from 'hooks/signUpNavigation';

const SignUpOtp = () => {
  const navigate = useNavigate();

  const signUpRoutes = useSignUpRoutes();

  const [isLoading, setIsLoading] = useState(false);

  const [status, setStatus] = useState<'success' | 'error' | null>(null);
  const [message, setMessage] = useState('');

  const { control } = useFormContext<SignUpFormData>();
  const [email, password] = useWatch({ control, name: ['email', 'password'] });

  useEffect(() => {
    if (!email) {
      navigate(ROUTES.signup.home);
    }
  }, [email, navigate]);

  const handleComplete = async (code: string) => {
    try {
      setIsLoading(true);

      const {
        data: { token },
      } = await signUpEmailVerification({
        email: email.trim(),
        code: code.trim(),
      });

      localStorage.setItem('access_token', token);
      setStatus('success');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setStatus('error');
        setMessage(error.response?.data?.message ?? '');
      } else {
        console.error(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleResend = async () => {
    try {
      await signUpGeneral({ email, password });
    } catch (error) {
      console.error(error);
    }
  };

  const handleNext = () => {
    navigate(signUpRoutes.type);
  };

  return (
    <>
      {isLoading && <LoaderOverlay />}
      <SignUpOtpTemplate
        status={status}
        message={message}
        onComplete={handleComplete}
        onResend={handleResend}
        onNext={handleNext}
      />
    </>
  );
};

export default SignUpOtp;
