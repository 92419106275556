import { toSortedTags } from './utils';

const KIDS_AREA = [
  {
    id: 'family-friendly',
    emoji: 'bg-familyfriendly',
  },
  {
    id: 'child-focused',
    emoji: 'bg-childfocused',
  },
  {
    id: 'childrens-corner',
    emoji: 'bg-childrenscorner',
  },
  {
    id: 'kids-play-zone',
    emoji: 'bg-kidsplayzone',
  },
  {
    id: 'kids-activities',
    emoji: 'bg-kidsactivities',
  },
  {
    id: 'safe-for-children',
    emoji: 'bg-safeforchildren',
  },
  {
    id: 'kid-friendly',
    emoji: 'bg-kidfriendly',
  },
];

export default toSortedTags('kidsAreaLabels', KIDS_AREA);
