import { Box } from '@mui/material';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { ServerErrorMessage } from 'components/atoms';
import { PriceTable } from 'components/molecules';
import { NewBusinessFormData } from 'hooks/forms';
import usePayments from 'hooks/usePayments';

interface IProps {
  onSubmit: () => void;
}

const SignUpPlansTemplate: FC<IProps> = ({ onSubmit }) => {
  const { getPlans, plans, isPlansLoading } = usePayments();

  useEffect(() => {
    getPlans();
  }, []);

  const {
    formState: { errors },
    setValue,
  } = useFormContext<NewBusinessFormData>();

  const handleSelectSubscription = (planId: string) => {
    setValue('subscription', planId);
    onSubmit();
  };

  return (
    <Box display="flex" flexDirection="column" gap="40px" alignItems="center">
      <PriceTable
        plans={plans}
        loading={isPlansLoading}
        onSubscriptionSelect={handleSelectSubscription}
      />

      {errors.root?.serverError?.message ? (
        <ServerErrorMessage message={errors.root.serverError.message} />
      ) : null}
    </Box>
  );
};

export default SignUpPlansTemplate;
