import { ExpandMoreOutlined } from '@mui/icons-material';
import { Autocomplete, Box } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { InputWithTopLabel } from 'components/atoms';
import { InputProps } from 'components/atoms/inputWithTopLabel/InputWithTopLabel';
import { AutocompleteOption } from 'environment/types';

import styles from './FormAutocomplete.module.scss';

interface IProps {
  name: string;
  labelTitle: string;
  options: AutocompleteOption[];
  inputAutoComplete?: string;
  afterSuccessfulChange?: () => void;
}

const FormAutocomplete = ({
  name,
  labelTitle,
  options,
  inputAutoComplete,
  afterSuccessfulChange,
}: IProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange, ...field } }) => (
        <Autocomplete
          {...field}
          sx={{
            flexGrow: 1,
            '& .MuiInput-root .MuiInput-input': {
              paddingBottom: '12px',
            },
            '& .MuiAutocomplete-endAdornment': {
              right: '13px',
            },
          }}
          value={options.find((option) => option.name === value) || null}
          onChange={(_, newValue) => {
            onChange(newValue?.name || null);
            afterSuccessfulChange?.();
          }}
          popupIcon={<ExpandMoreOutlined />}
          clearIcon={null}
          options={options}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          getOptionLabel={(option) => option.name}
          slotProps={{
            paper: {
              className: styles.statesDropdown,
            },
          }}
          renderInput={(params) => (
            <InputWithTopLabel
              {...params}
              labelTitle={labelTitle}
              name={name}
              InputProps={{ ...params.InputProps, ...InputProps }}
              autoComplete={inputAutoComplete}
              sx={{ height: '50px' }}
            />
          )}
          renderOption={(props, option) => (
            <Box
              {...props}
              className={styles.state}
              sx={{
                '&.Mui-focusVisible': {
                  backgroundColor: '#444',
                },
              }}
              component="li"
            >
              <span className={styles.stateAbbreviation}>
                {option.abbreviation}
              </span>
              <span>{option.name}</span>
            </Box>
          )}
        />
      )}
    />
  );
};

export default FormAutocomplete;
