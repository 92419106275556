import { Box, Tabs as MUITabs, Tab } from '@mui/material';
import React from 'react';

import { Badge } from 'components/atoms';

import styles from './Tabs.module.scss';

interface TabsValues {
  label: string;
  component: React.ReactNode;
  badge: number | string | null;
}

interface IProps {
  tabs: TabsValues[];
  onTabChange: (index: number) => void;
  selectedIndex: number;
}

const Tabs = ({ tabs, onTabChange, selectedIndex }: IProps) => {
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    onTabChange(newValue);
  };

  const renderTabs = tabs.map(({ label, badge }) => (
    <Tab
      key={label}
      className={styles.button}
      label={label}
      icon={badge !== null ? <Badge value={badge} /> : ''}
      iconPosition="end"
    />
  ));

  return (
    <>
      <MUITabs
        value={selectedIndex}
        onChange={handleChange}
        TabIndicatorProps={{ hidden: true }}
        variant="fullWidth"
        className={styles.wrapper}
        classes={{ indicator: styles.indicator }}
      >
        {renderTabs}
      </MUITabs>
      {tabs.map(({ component }, index) => {
        return (
          <Box key={index} hidden={selectedIndex !== index}>
            {selectedIndex === index && component}
          </Box>
        );
      })}
    </>
  );
};

export default Tabs;
