import axios from 'axios';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { LoaderOverlay } from 'components/atoms';
import { SignUpMembersTemplate } from 'components/templates';
import { signUpBusinessMembers } from 'environment/api/services/auth';
import { SignUpBusinessFormData } from 'hooks/forms';
import { castSignUpMembersForBackend } from 'hooks/forms/helpers';
import { useSignUpRoutes } from 'hooks/signUpNavigation';

const SignUpMembers = () => {
  const navigate = useNavigate();
  const signUpRoutes = useSignUpRoutes();

  const { control, setError, trigger, clearErrors } =
    useFormContext<SignUpBusinessFormData>();

  const members = useWatch({ control, name: 'members' });

  const isMembersNotEmpty = members.some(
    (member) => member.email || member.fullName,
  );

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      if (isMembersNotEmpty && !(await trigger('members'))) {
        return;
      }

      setIsLoading(true);

      await signUpBusinessMembers({
        members: isMembersNotEmpty ? castSignUpMembersForBackend(members) : [],
      });

      navigate(signUpRoutes.subscription);

      clearErrors('members');
      clearErrors('root.serverError');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError('root.serverError', {
          type: error.code,
          message: error.response?.data?.message ?? error.message,
        });
      } else {
        console.error(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <LoaderOverlay />}
      <SignUpMembersTemplate
        onSubmit={handleSubmit}
        isMembersNotEmpty={isMembersNotEmpty}
      />
    </>
  );
};

export default SignUpMembers;
