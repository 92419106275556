import {
  ADD_WATERFALL_URL,
  AUTO_FEATUING_WATERFALL,
  DELETE_WATERFALL_URL,
  SEND_QUICK_COMMENT_URL,
  WATERFALL_PAGINATED_URL,
  WATERFALL_REPLY,
  WATERFALL_TYPE_URL,
} from 'environment/constants/url';
import { IWaterfallReply } from 'types/waterfall';

import axios from '../axios';

export const getWaterfallPaginatedRequest = (
  businessUnitId: string,
  take: number,
  cursorId?: string,
) => {
  return axios.get(
    WATERFALL_PAGINATED_URL(businessUnitId).WATERFALL_PAGINATED,
    {
      params: {
        take,
        cursorId,
      },
    },
  );
};

export const getWaterfallMostRecentRequest = (
  businessUnitId: string,
  type: string,
) => {
  return axios.get(WATERFALL_TYPE_URL(businessUnitId, type).WATERFALL_BY_TYPE);
};

export const getWaterfallFeaturedRequest = (
  businessUnitId: string,
  type: string,
) => {
  return axios.get(WATERFALL_TYPE_URL(businessUnitId, type).WATERFALL_BY_TYPE);
};

export const setToggleFeaturedFlagRequest = (
  businessUnitId: string,
  waterfallId: string,
  flag: boolean,
) => {
  return axios.put(
    ADD_WATERFALL_URL(businessUnitId, waterfallId, flag).ADD_WATERFALL,
  );
};

export const sendQuickComment = (
  businessUnitId: string,
  commentText: string,
  owner: string,
  ownerId: string,
) => {
  const textData = {
    text: commentText,
    ownerId: ownerId,
    owner: owner,
  };
  return axios.post(
    SEND_QUICK_COMMENT_URL(businessUnitId).SEND_QUICK_COMMENT,
    textData,
  );
};

export const deleteWaterfall = (waterfallId: string) => {
  return axios.delete(DELETE_WATERFALL_URL(waterfallId).DELETE_WATERFALL);
};

export const updateBusinessUnitFeaturedWaterfall = (
  businessUnitId: string,
  autoFeaturing: boolean,
) => {
  const auto = {
    autoFeaturing: autoFeaturing,
  };

  return axios.patch(
    AUTO_FEATUING_WATERFALL(businessUnitId).UPDATE_BUSINESS_UNIT,
    auto,
  );
};

export const getWaterfallReplyList = (waterfallId: string) => {
  return axios.get<IWaterfallReply>(WATERFALL_REPLY(waterfallId));
};
