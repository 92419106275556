import Stripe from 'stripe';

export interface Plan extends Omit<Stripe.Product, 'default_price'> {
  metadata: {
    payment_link?: string;
    promoted?: string;
    onboardingFee?: string;
  };
  default_price: {
    id: string;
    unit_amount: number;
  };
  unit_amount?: number;
}

export interface PaymentCard {
  cardNumber: string;
  expiryDate: string;
  securityCode: string;
}

export enum SubscriptionStatus {
  active = 'active',
  canceled = 'canceled',
  ended = 'ended',
  incomplete = 'incomplete',
  incomplete_expired = 'incomplete_expired',
  past_due = 'past_due',
  paused = 'paused',
  trialing = 'trialing',
  unpaid = 'unpaid',
}

export interface Subscription {
  id: string;
  stripeCustomerId: string;
  stripeSubscriptionId: string;
  stripePriceId: string;
  startDate: Date;
  endDate?: Date;
  status: SubscriptionStatus;
  businessId: string;
}

export interface PaymentMethod {
  id: string;
  brand: string;
  display_brand: string;
  last4: string;
}

type Status = 'draft' | 'open' | 'paid' | 'uncollectible' | 'void';

export interface Payment {
  id: string;
  type: string;
  createdAt: Date;
  updatedAt: Date;
  status: Status;
  paid: boolean;
  amount: number;
  stripeInvoice: string;
  stripeInvoiceUrl: string;
}
