import Colors from 'styles/colors.scss';

import { Endorsements } from './ChartCard';

type ValidEndorsementKey = keyof Endorsements;

type Item = {
  title: string;
  value: ValidEndorsementKey;
  color: string;
};

export const items: Item[] = [
  {
    title: 'Review',
    value: 'reviews',
    color: Colors['landing-green1'],
  },
  {
    title: 'Comments',
    value: 'comments',
    color: Colors['profile-blue'],
  },
  {
    title: 'Views',
    value: 'watches',
    color: Colors['HEXB8FE61'],
  },
  {
    title: 'Likes',
    value: 'likes',
    color: Colors['profile-red'],
  },
  {
    title: 'Story.Tag',
    value: 'storyTag',
    color: Colors['default-green-200'],
  },
  {
    title: 'Forum.Tag',
    value: 'forumTag',
    color: Colors['default-green-700'],
  },
  {
    title: 'Share',
    value: 'shares',
    color: Colors['profile-purple'],
  },
  {
    title: 'Page.visit',
    value: 'pageVisit',
    color: Colors['profile-lemon'],
  },
];
