import { Box } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Map, { Marker } from 'react-map-gl';

import { DashboardCardWrapper, PlaceMarker } from 'components/atoms';
import { ROUTES } from 'environment/constants/routes';

import styles from './MapContainer.module.scss';

import 'mapbox-gl/dist/mapbox-gl.css';

const MAPBOX_TOKEN = process.env.REACT_APP_TEST_MAPBOX_ACCESS_TOKEN;

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentRestaurant: any; //TODD add types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nearbyRestaurant: any; //TODD add types
}

const MapContainer = ({ currentRestaurant, nearbyRestaurant }: IProps) => {
  const { t } = useTranslation();

  const { latitude, longitude } = currentRestaurant;

  return (
    <DashboardCardWrapper
      title={t('Business.boosts.in.your.area')}
      navigateTo={ROUTES.dashboard.boosts}
    >
      <Box className={styles.map}>
        <Map
          mapboxAccessToken={MAPBOX_TOKEN}
          initialViewState={{
            longitude: -89,
            latitude: 43,
            zoom: 3.5,
          }}
          mapStyle="mapbox://styles/mapbox/dark-v10"
        >
          <Marker longitude={longitude} latitude={latitude} anchor="bottom">
            <PlaceMarker isOwner={true} />
          </Marker>
          {/* TODO add types */}
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {nearbyRestaurant.map((item: any, index: number) => {
            return (
              <Marker
                key={index + 1}
                longitude={item.longitude}
                latitude={item.latitude}
                anchor="bottom"
              >
                <PlaceMarker
                  isOwner={false}
                  title={item.name}
                  data={item.data}
                  showRate={true}
                />
              </Marker>
            );
          })}
        </Map>
      </Box>
    </DashboardCardWrapper>
  );
};

export default memo(MapContainer);
