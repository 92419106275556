import { Box } from '@mui/material';

import { MediaTypes } from '../../../../environment/constants';
import WaterfallDropdown from '../WaterfallDropdown';

import styles from './FeaturedWaterfallPreview.module.scss';

interface IProps {
  mediaSrc?: string | null;
  mediaType?: MediaTypes | null;
  handleOpenCarousel: () => void;
  handleDeleteWaterfall: () => void;
  handleReportWaterfall: () => void;
}

const FeaturedWaterfallPreview = ({
  mediaSrc,
  mediaType,
  handleOpenCarousel,
  handleReportWaterfall,
  handleDeleteWaterfall,
}: IProps) => {
  return (
    <Box className={styles.story}>
      {mediaType === MediaTypes.Video
        ? mediaSrc && (
            <video
              className={styles.media}
              src={mediaSrc}
              loop
              muted
              onClick={handleOpenCarousel}
            />
          )
        : mediaSrc && (
            <img
              className={styles.media}
              src={mediaSrc}
              alt="waterfall"
              draggable={false}
              onClick={handleOpenCarousel}
            />
          )}

      <WaterfallDropdown
        handleReportWaterfall={handleReportWaterfall}
        handleDeleteWaterfall={handleDeleteWaterfall}
        style={{ top: '8px', right: '8px' }}
      />
    </Box>
  );
};

export default FeaturedWaterfallPreview;
