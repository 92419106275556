import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
  Box,
  BoxProps,
  Dialog,
  IconButton,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { FC, PropsWithChildren, ReactNode, useMemo } from 'react';

import ComingSoon from '../comingSoon/ComingSoon';

import styles from './SettingsModal.module.scss';

interface Props {
  open: boolean;
  onClose: () => void;
  title?: string;
  leftSection?: ReactNode;
  withCloseButton?: boolean | ReactNode;
  containerBoxProps?: BoxProps<'div'>;
  childrenBoxProps?: BoxProps<'div'>;
  comingSoon?: boolean;
  paperPropsStyles?: SxProps<Theme>;
}

const SettingsModal: FC<PropsWithChildren<Props>> = ({
  children,
  open,
  onClose,
  title,
  leftSection,
  withCloseButton,
  containerBoxProps,
  childrenBoxProps,
  comingSoon,
  paperPropsStyles,
}) => {
  withCloseButton ??= false;
  const header = useMemo(
    () => (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {leftSection ? <Box width="15%">{leftSection}</Box> : null}
        <Box
          width="70%"
          display="flex"
          justifyContent={leftSection ? 'center' : 'flex-start'}
        >
          {title ? <Typography variant="text-lg">{title}</Typography> : null}
        </Box>
        <Box width="15%" display="flex" justifyContent="flex-end">
          {typeof withCloseButton === 'boolean' && withCloseButton ? (
            <IconButton onClick={onClose} className={styles.closeButton}>
              <CloseRoundedIcon />
            </IconButton>
          ) : (
            withCloseButton
          )}
        </Box>
      </Box>
    ),
    [leftSection, onClose, title, withCloseButton],
  );

  const content = useMemo(
    () => (
      <Box className={styles.container} padding="40px" {...containerBoxProps}>
        {header}
        <Box
          display="flex"
          flexDirection="column"
          gap="40px"
          {...childrenBoxProps}
        >
          {children}
        </Box>
      </Box>
    ),
    [children, childrenBoxProps, containerBoxProps, header],
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ root: styles.backdrop, paper: styles.paper }}
      PaperProps={{
        sx: {
          background: 'transparent',
          maxWidth: 'none',
          ...paperPropsStyles,
        },
      }}
    >
      {comingSoon ? <ComingSoon>{content}</ComingSoon> : content}
    </Dialog>
  );
};

export default SettingsModal;
