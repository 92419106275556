import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import Colors from 'styles/colors.scss';

import styles from './ColumnChart.module.scss';

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  isEmpty?: boolean;
}

const ColumnChart = ({ data, isEmpty = false }: IProps) => {
  const categories = ['Under 21', '21-24', '25-31', '32-37', '38-44', '45+'];

  interface SeriesData {
    name: string;
    data: number[];
  }

  const seriesData: SeriesData[] = [
    {
      name: 'Users',
      data: Array.from({ length: categories.length }, (_, index) => {
        const ageRange = categories[index];
        let sum = 0;
        if (ageRange === 'Under 21') {
          for (const key in data) {
            const age = parseInt(key);
            if (age < 21) {
              sum += data[age];
            }
          }
        } else {
          const ageRangeKey = ageRange.includes('+')
            ? parseInt(ageRange.split('+')[0])
            : ageRange.split('-').map(Number);
          for (const key in data) {
            const age = parseInt(key);
            if (Array.isArray(ageRangeKey)) {
              if (age >= ageRangeKey[0] && age <= ageRangeKey[1]) {
                sum += data[age];
              }
            } else {
              if (age >= ageRangeKey) {
                sum += data[age];
              }
            }
          }
        }
        return sum;
      }),
    },
  ];

  const options = {
    chart: {
      type: 'column',
      backgroundColor: 'transparent',
      width: 450,
    },
    title: {
      text: null,
    },
    xAxis: {
      crosshair: false,
      categories: categories,
      labels: {
        style: {
          color: Colors['default-gray-200'],
          fontSize: '12px',
        },
      },
    },
    yAxis: {
      labels: {
        style: {
          color: Colors['default-gray-200'],
          fontSize: '12px',
        },
        formatter: (context: Highcharts.AxisLabelsFormatterContextObject) => {
          return +context.value >= 1000
            ? `${+context.value / 1000}k`
            : context.value;
        },
      },
      lineWidth: 0,
      gridLineColor: Colors['default-gray-700'],
      title: {
        enabled: false,
      },
    },
    tooltip: isEmpty
      ? {
          enabled: false,
        }
      : {
          headerFormat: '',
          pointFormat: '<b>{point.y}</b>',
          useHTML: true,
          borderWidth: 1,
          borderColor: ' #ffffff33',
          backgroundColor: '#616161',
          padding: 10,
          borderRadius: 8,
          style: {
            color: '#FFF',
            fontSize: '12px',
          },
        },
    legend: { enabled: false },
    plotOptions: {
      column: {
        borderRadiusTopLeft: '50%',
        borderRadiusBottomLeft: '50%',
        borderRadiusTopRight: '50%',
        borderRadiusBottomRight: '50%',
        borderRadius: '50%',
        borderWidth: 0,
        dataLabels: {
          enabled: false,
        },
        groupPadding: 0.05,
        pointWidth: 9.27,
        color: isEmpty
          ? 'transparent'
          : {
              linearGradient: {
                x1: 1,
                y1: 0,
                x2: 0,
                y2: 0,
              },
              stops: [
                [0, 'rgba(52, 211, 153, 1)'],

                [1, 'rgba(255, 249, 97, 1)'],
              ],
            },
      },
    },
    credits: {
      enabled: false,
    },
    series: seriesData,
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      containerProps={{ className: styles.chartContainer }}
    />
  );
};

export default ColumnChart;
