import { Typography } from '@mui/material';
import {
  FC,
  MouseEventHandler,
  PropsWithChildren,
  useEffect,
  useRef,
} from 'react';

import styles from './TimePickerButton.module.scss';

interface Props {
  active?: boolean;
  scrollBehavior?: ScrollBehavior;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const TimePickerButton: FC<PropsWithChildren<Props>> = ({
  children,
  active = false,
  scrollBehavior = 'auto',
  onClick,
}) => {
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    active && ref.current?.scrollIntoView({ behavior: scrollBehavior });
  }, [active, scrollBehavior]);

  return (
    <button
      ref={ref}
      onClick={onClick}
      className={`${styles.timePickerButton}${
        active ? ` ${styles.active}` : ''
      }`}
    >
      <Typography paddingX="12px">{children}</Typography>
    </button>
  );
};

export default TimePickerButton;
