import { Box, Typography } from '@mui/material';

import { roundNumberToDecimal } from 'environment/utils/roundNumberToDecimal';

import styles from './AllUsers.module.scss';

interface IProps {
  amount: number;
  percent: number;
}

const AllUsers = ({ amount, percent }: IProps) => {
  return (
    <Box className={styles.wrapper}>
      <Typography variant="text-xs">{roundNumberToDecimal(amount)}</Typography>
      <Box className={styles.percentBlock}>
        <Box
          className={`${styles.triangle} ${percent < 0 ? styles.negative : ''}`}
        />
        <Typography
          variant="text-xs"
          className={`${styles.percent} ${percent < 0 ? styles.negative : ''}`}
        >
          {percent < 0 ? percent * -1 : percent}%
        </Typography>
      </Box>
    </Box>
  );
};

export default AllUsers;
