import { MAPS } from 'environment/constants/url';

import axios from '../axios';

export const getAddressSuggestions = (inputQuery: string, country: string) => {
  return axios.get(
    `${MAPS.SUGGESTED_ADDRESSES}?inputQuery=${inputQuery}&country=${country}`,
  );
};

export const getAddressSuggestionsByMap = (lat: number, lng: number) => {
  return axios.get(`${MAPS.LOCATION_SUGGESTIONS}?geolocation=${lat},${lng}`);
};

export const pickAddress = (address: string, googlePlaceId: string) => {
  return axios.post(MAPS.PICK_ADDRESS, {
    address,
    googlePlaceId,
  });
};
