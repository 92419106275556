import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { PriceTable } from 'components/molecules';
import { SignUpBusinessFormData } from 'hooks/forms';
import usePayments from 'hooks/usePayments';

interface PlansProps {
  onSubmit: (planId: string) => void;
}

const Plans = ({ onSubmit }: PlansProps) => {
  const { getPlans, plans, isPlansLoading } = usePayments();

  useEffect(() => {
    getPlans();
  }, []);

  const { setValue } = useFormContext<SignUpBusinessFormData>();

  const handleSelectSubscription = (planId: string) => {
    setValue('subscription', planId);
    onSubmit(planId);
  };

  return (
    <PriceTable
      plans={plans}
      loading={isPlansLoading}
      onSubscriptionSelect={handleSelectSubscription}
    />
  );
};

export default Plans;
