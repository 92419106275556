import { UseFormReturn, useForm } from 'react-hook-form';

import { ADD_MEMBER_SCHEMA } from 'environment/validation/resolvers';

import { FormMember } from './default-values';

const defaultValues = {
  member: {
    fullName: '',
    email: '',
    permission: '',
  } as FormMember,
};

export type MemberFormData = typeof defaultValues;

export const useMemberForm = () => {
  return useForm({
    defaultValues,
    mode: 'all',
    resolver: ADD_MEMBER_SCHEMA,
  }) as unknown as UseFormReturn<MemberFormData>;
};
