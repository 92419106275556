import { Box, Button, Typography } from '@mui/material';
import { type FC, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ServerErrorMessage } from 'components/atoms';
import Colors from 'styles/colors.scss';

import styles from './SignUpBusinessDescriptionForm.module.scss';

interface IProps {
  onSubmit: () => void;
  defaultValue?: string;
  name?: string;
}

const SignUpBusinessDescriptionForm: FC<IProps> = ({
  onSubmit,
  defaultValue,
  name,
}) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState(
    defaultValue ? defaultValue : 'none',
  );
  const { setValue } = useFormContext();

  useEffect(() => {
    name
      ? setValue(name, { variant: selected })
      : setValue('businessDescription', { variant: selected });
  }, [selected]);

  useEffect(() => {
    !!defaultValue && setSelected(defaultValue);
  }, [defaultValue]);

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Controller
        name="businessDescription"
        control={control}
        render={() => (
          <div className={styles.wrapper}>
            <Box className={styles.buttonWrapper}>
              <Button
                fullWidth
                className={styles.button}
                variant={selected === 'light-bite' ? 'contained' : 'text'}
                onClick={() => setSelected('light-bite')}
              >
                <Typography
                  variant="text-base-semibold"
                  className={
                    selected !== 'light-bite'
                      ? styles.gradient
                      : styles.inactiveButtonContent
                  }
                >
                  {t('Light_bite')}
                </Typography>
              </Button>
            </Box>
            <Box className={styles.buttonWrapper}>
              <Button
                fullWidth
                className={styles.button}
                variant={selected === 'date-night' ? 'contained' : 'text'}
                onClick={() => setSelected('date-night')}
              >
                <Typography
                  variant="text-base-semibold"
                  className={
                    selected !== 'date-night'
                      ? styles.gradient
                      : styles.inactiveButtonContent
                  }
                >
                  {t('Date_night')}
                </Typography>
              </Button>
            </Box>
          </div>
        )}
      />
      <Typography
        variant="text-base"
        color={Colors['default-gray-200']}
        align="center"
      >
        {t('if_youandaposre_a_coffee_or_tea_shop_please_select_light')}
      </Typography>
      <Box>
        {errors.root?.serverError?.message ? (
          <ServerErrorMessage message={errors.root.serverError.message} />
        ) : null}
        <Button
          fullWidth
          disabled={selected === 'none'}
          variant="contained"
          onClick={onSubmit}
        >
          <Typography
            variant="text-base-semibold"
            color={Colors['default-gray-900']}
          >
            {t('Next')}
          </Typography>
        </Button>
      </Box>
    </>
  );
};

export default SignUpBusinessDescriptionForm;
