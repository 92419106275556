import { Box, Typography } from '@mui/material';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SignUpBusinessDescriptionForm } from 'components/organisms';

import styles from './SignUpBusinessDescription.module.scss';

interface IProps {
  onSubmit: () => void;
  defaultValue?: string;
  name?: string;
}

const SignUpBusinessDescriptionTemplate: FC<IProps> = ({
  onSubmit,
  defaultValue,
  name,
}) => {
  const { t } = useTranslation();

  return (
    <Box className={styles.container}>
      <Box className={styles.header}>
        <Typography variant="text-2xl">
          {t('Which_of_these_best_describes_your_business')}
        </Typography>
      </Box>
      <SignUpBusinessDescriptionForm
        onSubmit={onSubmit}
        defaultValue={defaultValue}
        name={name}
      />
    </Box>
  );
};

export default SignUpBusinessDescriptionTemplate;
