import { Box, Skeleton, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Colors from 'styles/colors.scss';
import { Plan } from 'types/payments/payments';

import Product from './Product';

interface PriceTableProps {
  plans: Plan[];
  loading?: boolean;
  onSubscriptionSelect: (id: string) => void;
}

const LoadingPlaceholders = () => (
  <Skeleton
    animation="wave"
    variant="rounded"
    height={590}
    width={334}
    sx={{
      bgcolor: Colors['bg-color'],
      '&:after': {
        bgcolor: Colors['default-gray-700'],
      },
    }}
  />
);

const PriceTable = ({
  plans,
  loading,
  onSubscriptionSelect,
}: PriceTableProps) => {
  const { t } = useTranslation();

  const showLoading = !plans || plans.length === 0 || loading;

  if (showLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        flexWrap="wrap"
        gap="40px"
        width="100%"
      >
        <LoadingPlaceholders />
        <LoadingPlaceholders />
        <LoadingPlaceholders />
      </Box>
    );
  }
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="40px"
      alignItems="center"
      width="100%"
    >
      <Typography
        variant="app-title"
        maxWidth={600}
        lineHeight="40px"
        textAlign="center"
      >
        {t('start_doubling_your_customer_base_without_ever_hiring')}
      </Typography>
      <Typography
        variant="text-lg-light"
        lineHeight="32px"
        mb="16px"
        textAlign="center"
        color={Colors['default-gray-100']}
      >
        {t('select_a_plan_that_scales_with_your_needs')}
      </Typography>
      <Box
        justifyContent="center"
        flexWrap="wrap"
        gap="40px"
        width="100%"
        maxWidth="1200px"
        display="flex"
      >
        {plans.map((item) => (
          <Product
            key={item.id}
            {...item}
            onSubscriptionSelect={onSubscriptionSelect}
          />
        ))}
      </Box>
    </Box>
  );
};

export default React.memo(PriceTable);
