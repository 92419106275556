import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import SpeedometerProgress from '../charts/speedometer-progress/SpeedometerProgress';

import styles from './SpeedometerCard.module.scss';

interface IProps {
  header: string;
  value: number;
  amount: number;
  title: string;
}

const SpeedometerCard = ({ header, value, amount, title }: IProps) => {
  const { t } = useTranslation();

  return (
    <Box className={styles.container}>
      <Typography variant="text-lg">{header}</Typography>

      <Box className={styles.speedometer}>
        <SpeedometerProgress
          value={value}
          backgroundColor="#1b1b1b"
          amount={amount}
        />
      </Box>

      <Box className={styles.textBlock}>
        <Typography variant="text-base">{t(title)}</Typography>
        <Typography variant="text-sm" className={styles.description}>
          0h
        </Typography>
      </Box>
    </Box>
  );
};

export default SpeedometerCard;
