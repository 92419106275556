import { toSortedTags } from './utils';

const DIET_OPTIONS = [
  {
    id: 'keto',
    emoji: 'bg-keto',
  },
  {
    id: 'paleo',
    emoji: 'bg-paleo',
  },
  {
    id: 'vegetarian',
    emoji: 'bg-vegetarian',
  },
  {
    id: 'vegan',
    emoji: 'bg-vegan',
  },
  {
    id: 'gluten-free',
    emoji: 'bg-glutenfree',
  },
  {
    id: 'plant-based',
    emoji: 'bg-plantbased',
  },
  {
    id: 'low-carb',
    emoji: 'bg-lowcarb',
  },
  {
    id: 'low-fat',
    emoji: 'bg-lowfat',
  },
  {
    id: 'mediterranean',
    emoji: 'bg-mediterraneandiet',
  },
  {
    id: 'flexitarian',
    emoji: 'bg-flexitarian',
  },
  {
    id: 'pescatarian',
    emoji: 'bg-pescatarian',
  },
  {
    id: 'raw-food',
    emoji: 'bg-rawfood',
  },
  {
    id: 'dash',
    emoji: 'bg-dash',
  },
  {
    id: 'fodmap',
    emoji: 'bg-fodmap',
  },
  {
    id: 'specific-carbs',
    emoji: 'bg-specificcarbs',
  },
  {
    id: 'halal',
    emoji: 'bg-halal',
  },
  {
    id: 'kosher',
    emoji: 'bg-kosher',
  },
  {
    id: 'salads',
    emoji: 'bg-breakfastvariety',
  },
];

export default toSortedTags('dietOptionsLabel', DIET_OPTIONS);
