import { Button, Typography } from '@mui/material';
import { type ReactNode } from 'react';

import styles from './FormButton.module.scss';

interface IProps {
  type?: 'submit' | 'button' | 'reset';
  children: ReactNode;
  fullWidth?: boolean;
  onClick?: () => void;
}

const FormButton = ({
  type = 'submit',
  children,
  fullWidth,
  onClick,
}: IProps) => {
  return (
    <Button
      type={type}
      className={styles.button}
      variant="contained"
      fullWidth={fullWidth}
      onClick={onClick}
    >
      <Typography variant="text-base-semibold">{children}</Typography>
    </Button>
  );
};

export default FormButton;
