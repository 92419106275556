import { Box, IconButton, Typography } from '@mui/material';
import { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconMark } from 'components/atoms';
import { SettingsModal } from 'components/molecules';
import Colors from 'styles/colors.scss';

import styles from './ReportDropdown.module.scss';

interface Props {
  handleReportWaterfall: () => void;
  handleDeleteWaterfall: () => void;
  onClose?: () => void;
}

const ReportDropdown: FC<Props> = ({
  handleReportWaterfall,
  handleDeleteWaterfall,
  onClose,
}) => {
  const { t } = useTranslation();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const toggleDeleteModalOpen = () => setDeleteModalOpen((v) => !v);

  return (
    <>
      <Box className={styles.wrapper}>
        <button
          className={styles.dropdownButton}
          onClick={() => {
            handleReportWaterfall();
            onClose?.();
          }}
        >
          <IconMark
            name="exclamation-circle"
            size="24px"
            color={Colors['tw-yellow-400']}
          />
          <Typography variant="text-sm" sx={{ fontWeight: 300 }}>
            {t('Report.this.waterfall')}
          </Typography>
        </button>

        <button
          className={styles.dropdownButton}
          onClick={toggleDeleteModalOpen}
        >
          <Box className={styles.binIcon}>
            <IconMark name="bin" color={Colors['white']} size="19px" />
          </Box>
          <Typography variant="text-sm" sx={{ fontWeight: 300 }}>
            {t('Delete.this.waterfall')}
          </Typography>
        </button>
      </Box>

      <SettingsModal
        open={deleteModalOpen}
        onClose={() => {
          toggleDeleteModalOpen();
          onClose?.();
        }}
        containerBoxProps={{ padding: '16px', maxWidth: '338px' }}
        withCloseButton={
          <IconButton
            sx={{ padding: 0, '&:active': { transform: 'scale(0.95)' } }}
            onClick={onClose}
          >
            <IconMark
              name="close-circle1"
              size="24px"
              color={Colors['default-gray-400']}
            />
          </IconButton>
        }
      >
        <Box display="flex" flexDirection="column" gap="24px">
          <div className={styles.deleteModalText}>
            <Typography variant="text-lg" textAlign="center">
              {t('Sure.you.want.to.delete.this.Waterfall.from.Featured')}
            </Typography>
            <Typography
              variant="text-base-light"
              textAlign="center"
              color={Colors['default-gray-200']}
            >
              {t('Once.you.delete.the.Waterfall.it.cannot.be.undone')}
            </Typography>
          </div>
          <Box display="flex">
            <button className={styles.deleteModalButton} onClick={onClose}>
              <Typography variant="text-sm" color={Colors['HEXB8FE61']}>
                {t('Cancel')}
              </Typography>
            </button>
            <button
              className={styles.deleteModalButton}
              onClick={() => {
                handleDeleteWaterfall();
                onClose?.();
              }}
            >
              <Typography variant="text-sm" color={Colors['profile-red']}>
                {t('Delete')}
              </Typography>
            </button>
          </Box>
        </Box>
      </SettingsModal>
    </>
  );
};

export default memo(ReportDropdown);
