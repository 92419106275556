import { Box, Button, Typography } from '@mui/material';
import { type FC, type PropsWithChildren } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FilledCheckbox, ServerErrorMessage } from 'components/atoms';
import IconMark from 'components/atoms/icon/IconMark';
import { businessTypes } from 'environment/constants/businessTypes';
import Colors from 'styles/colors.scss';

import styles from './BusinessTypeForm.module.scss';

interface IProps {
  onSubmit: () => void;
  hideThirdCategory?: boolean;
}

const BusinessTypeForm: FC<IProps> = ({
  onSubmit,
  hideThirdCategory = false,
}) => {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [type, checked] = useWatch({
    control,
    name: ['businessType.type', 'businessType.checked'],
  });

  const isNextButtonDisabled = !type || (!checked && type !== 'one-location');

  const types = hideThirdCategory ? businessTypes.slice(0, 2) : businessTypes;

  return (
    <>
      <Controller
        name="businessType"
        control={control}
        render={({ field: { value, onChange } }) => (
          <>
            {types.map(({ id, label, icon, checkboxes }) => {
              const open = value.type === id;

              return (
                <AccordionButton
                  key={id}
                  open={open}
                  onOpenChange={
                    open
                      ? () => onChange({ type: '', checked: '' })
                      : () => onChange({ type: id, checked: '' })
                  }
                  controllerText={t(label)}
                  controllerIconName={icon}
                >
                  {checkboxes ? (
                    <Box
                      display={open ? 'flex' : 'none'}
                      className={styles.accordionContent}
                    >
                      {checkboxes.map(({ name, label }) => {
                        const checked = value.checked === name;

                        return (
                          <FilledCheckbox
                            key={name}
                            label={t(label)}
                            checked={checked}
                            onChange={
                              checked
                                ? () =>
                                    onChange({ type: value.type, checked: '' })
                                : () =>
                                    onChange({
                                      type: value.type,
                                      checked: name,
                                    })
                            }
                          />
                        );
                      })}
                    </Box>
                  ) : null}
                </AccordionButton>
              );
            })}
          </>
        )}
      />
      <Box>
        {errors.root?.serverError?.message ? (
          <ServerErrorMessage message={errors.root.serverError.message} />
        ) : null}
        <Button
          fullWidth
          disabled={isNextButtonDisabled}
          variant="contained"
          onClick={onSubmit}
        >
          <Typography
            variant="text-base-semibold"
            color={Colors['default-gray-900']}
          >
            {t('Next')}
          </Typography>
        </Button>
      </Box>
    </>
  );
};

interface IAccordionButtonProps {
  open: boolean;
  onOpenChange: () => void;
  controllerText: string;
  controllerIconName: string;
}

const AccordionButton: FC<PropsWithChildren<IAccordionButtonProps>> = ({
  open,
  onOpenChange,
  controllerText,
  controllerIconName,
  children,
}) => {
  return (
    <>
      <Box className={styles.buttonWrapper}>
        <Button
          fullWidth
          className={styles.button}
          variant={open ? 'contained' : 'text'}
          onClick={onOpenChange}
        >
          <Typography
            variant="text-base-semibold"
            className={!open ? styles.gradient : styles.inactiveButtonContent}
          >
            {controllerText}
          </Typography>
          <IconMark
            size="32px"
            name={controllerIconName}
            className={!open ? styles.gradient : styles.inactiveButtonContent}
          />
        </Button>
      </Box>
      {children}
    </>
  );
};

export default BusinessTypeForm;
