import { Box, InputAdornment, TextFieldProps } from '@mui/material';
import { InputBaseComponentProps } from '@mui/material/InputBase';
import { alpha, useTheme } from '@mui/material/styles';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import { StripeCardExpiryElementChangeEvent } from '@stripe/stripe-js';
import * as React from 'react';

import creditCardIcon from 'assets/icons/credit-card-icon.svg';

import TextInputDefault from '../textInputDefault/TextInputDefault';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StripeInput = React.forwardRef<any, InputBaseComponentProps>(
  function StripeInput(props, ref) {
    const { component: Component, options, ...other } = props;
    const theme = useTheme();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [mountNode, setMountNode] = React.useState<any | null>(null);

    React.useImperativeHandle(
      ref,
      () => ({
        focus: () => mountNode.focus(),
      }),
      [mountNode],
    );

    return (
      <Component
        onReady={setMountNode}
        options={{
          ...options,
          style: {
            base: {
              color: theme.palette.text.primary,
              fontSize: '16px',
              lineHeight: '1.4375em', // 23px
              fontFamily: theme.typography.fontFamily,
              '::placeholder': {
                color: alpha(theme.palette.text.primary, 0),
              },
            },
            invalid: {
              color: theme.palette.text.primary,
            },
          },
        }}
        {...other}
      />
    );
  },
);

export default StripeInput;

type StripeElement =
  | typeof CardCvcElement
  | typeof CardExpiryElement
  | typeof CardNumberElement;

interface StripeTextFieldProps<T extends StripeElement>
  extends Omit<TextFieldProps, 'onChange' | 'inputComponent' | 'inputProps'> {
  inputProps?: React.ComponentProps<T>;
  labelErrorMessage?: string;
  onChange?: React.ComponentProps<T>['onChange'];
  stripeElement?: T;
  icon?: React.ReactNode;
}

export const StripeTextField = <T extends StripeElement>(
  props: StripeTextFieldProps<T>,
) => {
  const { InputLabelProps, inputProps, stripeElement, ...other } = props;

  const [inputState, setInputState] = React.useState({
    focused: false,
    empty: true,
    error: '',
  });

  const handleChange = (changeObj: StripeCardExpiryElementChangeEvent) => {
    setInputState((prev) => ({
      ...prev,
      empty: changeObj.empty,
      error: changeObj.error ? changeObj.error.message : '',
    }));
  };

  const handleFocus = () => {
    setInputState((prev) => ({
      ...prev,
      focused: true,
    }));
  };

  const handleBlur = () => {
    setInputState((prev) => ({
      ...prev,
      focused: false,
    }));
  };

  const shrink = inputState.focused || !inputState.empty;
  return (
    <TextInputDefault
      InputLabelProps={{
        ...InputLabelProps,
        shrink,
        focused: inputState.focused,
        sx: props?.icon && {
          '&.MuiInputLabel-root': {
            transform: inputState.empty
              ? 'translate(44px, 16px)'
              : 'translate(44px, 7px) scale(0.75)',
          },
          '&.Mui-focused': {
            transform: 'translate(44px, 7px) scale(0.75)',
          },
          '&.MuiInputLabel-root:not(.Mui-focused) ~ .MuiInputBase-root .MuiOutlinedInput-notchedOutline legend':
            {
              maxWidth: 0,
            },
        },
      }}
      fullWidth
      error={!!inputState.error}
      InputProps={{
        startAdornment: props?.icon && (
          <InputAdornment position="start" sx={{ marginTop: '0 !important' }}>
            {props.icon}
          </InputAdornment>
        ),
        inputProps: {
          ...inputProps,
          component: stripeElement,
        },
        inputComponent: StripeInput,
      }}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleChange}
      helperText={inputState.error}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...(other as any)}
    />
  );
};

export function StripeTextFieldNumber(
  props: StripeTextFieldProps<typeof CardNumberElement>,
) {
  return (
    <StripeTextField
      label="Card number"
      stripeElement={CardNumberElement}
      icon={<Box component="img" src={creditCardIcon} />}
      {...props}
    />
  );
}

export function StripeTextFieldExpiry(
  props: StripeTextFieldProps<typeof CardExpiryElement>,
) {
  return (
    <StripeTextField
      label="MM/YY"
      stripeElement={CardExpiryElement}
      {...props}
    />
  );
}

export function StripeTextFieldCVC(
  props: StripeTextFieldProps<typeof CardCvcElement>,
) {
  return (
    <StripeTextField label="CVC" stripeElement={CardCvcElement} {...props} />
  );
}
