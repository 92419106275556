import { Route } from 'react-router-dom';

import { ROUTES } from 'environment/constants/routes';
import { Login, NewPassword, PasswordRecovery } from 'pages';

export const loginRoutes = (
  <Route path={ROUTES.login.home}>
    <Route index element={<Login />} />
    <Route
      path={ROUTES.login.passwordRecovery}
      element={<PasswordRecovery />}
    />
    <Route path={ROUTES.login.newPassword} element={<NewPassword />} />
  </Route>
);
