import { Box, Typography } from '@mui/material';
import { useState } from 'react';

import fakeGraph from 'assets/images/fakeGraph.png';
import { UnavailableForYou } from 'components/atoms';
import {
  BasicChartWithBenchmark,
  ViewMonthlyCharts,
} from 'components/molecules';
import DemographicsBlock from 'components/molecules/demographicsBlock/DemographicsBlock';
import { checkAndGenerateArray } from 'environment/utils/checkAndGenerateArray';
import { IDataByAge, IMonthData, IUserStatus } from 'store/models/engagement';
import Colors from 'styles/colors.scss';

import styles from './StatisticsInCharts.module.scss';

interface IProps {
  titleToFirstChart: string;
  dayData: [string, number][];
  nameToFirstChart?: string | undefined;
  monthsData: IMonthData;
  dataByAge: IDataByAge[];
  userStatus: IUserStatus;
  titleForDemographicBlock: string;
  currentUnitCreatedAt: string;
}

const StatisticsInCharts = ({
  titleToFirstChart,
  dayData,
  nameToFirstChart,
  monthsData,
  dataByAge,
  userStatus,
  titleForDemographicBlock,
  currentUnitCreatedAt,
}: IProps) => {
  const [isPremium, setIsPremium] = useState<boolean>(false);

  const categories = dayData.map((item) => item[0]);

  const totalAmount = dayData.reduce((accumulator, [, count]) => {
    return accumulator + count;
  }, 0);

  const { modifiedArray, allZeros } = checkAndGenerateArray(dayData);

  return (
    <>
      <ViewMonthlyCharts
        data={monthsData}
        currentUnitCreatedAt={currentUnitCreatedAt}
      />
      <BasicChartWithBenchmark
        data={modifiedArray}
        isEmpty={allZeros}
        categories={categories}
        colors={[Colors.HEXB8FE61]}
        name={nameToFirstChart}
        title={
          <Typography component="h2" variant="text-lg">
            {titleToFirstChart}
            {': '}
            <Typography
              className={styles.titleValue}
              component="span"
              variant="text-lg"
            >
              {totalAmount}
            </Typography>
          </Typography>
        }
        gradient={[
          [0, Colors['linear-green1']],
          [1, Colors['linear-green2']],
        ]}
      />
      <UnavailableForYou
        isUnavailable={!isPremium}
        onClick={() => setIsPremium(false)} // todo change when ready 'upgrade to premium' flow
        borderRadius={16}
      >
        {!isPremium ? (
          <Box className={styles.imageWrapper}>
            <Box className={styles.image} component="img" src={fakeGraph} />
          </Box>
        ) : (
          <DemographicsBlock
            isUnavailable={isPremium}
            dataByAge={dataByAge}
            userStatus={userStatus}
            title={titleForDemographicBlock}
          />
        )}
      </UnavailableForYou>
    </>
  );
};
export default StatisticsInCharts;
