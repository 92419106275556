import { Box } from '@mui/material';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

import { Header } from 'components/organisms';
import useFolders from 'hooks/useFolders';
import useUnits from 'hooks/useUnits';
import { Dispatch } from 'store';

import styles from './LayoutWithHeader.module.scss';

const LayoutWithHeaderTemplate = () => {
  const dispatch = useDispatch<Dispatch>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, setMobileOpen] = useState<boolean>(false);

  const { businessUnits, currentUnit, setCurrentBusinessUnit } = useUnits();
  const {
    getAllBusinessFolders,
    allFolders,
    createNewFolder,
    editFolderName,
    moveUnitToFolder,
  } = useFolders();

  useEffect(() => {
    getAllBusinessFolders();
    dispatch.units.getBusinessUnits();
  }, [dispatch.units]);

  const handleDrawerToggle = () => {
    setMobileOpen((prev) => !prev);
  };

  const { pathname } = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Box className={styles.background}>
      <Header
        handleDrawerToggle={handleDrawerToggle}
        businessUnits={businessUnits}
        currentUnit={currentUnit}
        setCurrentBusinessUnit={setCurrentBusinessUnit}
        allFolders={allFolders}
        editFolderName={editFolderName}
        createNewFolder={createNewFolder}
        moveUnitToFolder={moveUnitToFolder}
      />
      <Box className={styles.container}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default LayoutWithHeaderTemplate;
