import { createModel } from '@rematch/core';
import { AxiosError } from 'axios';

import {
  getReviewReplies,
  replyOnReview,
} from 'environment/api/services/reviewReply';
import { IReviewReply } from 'types';

import type { RootModel } from '.';

interface IReviewReplyState {
  allReviewReplies: IReviewReply[];
  error: null | string;
}

type ReviewReplyPayload = {
  businessUnitId: string;
  text: string;
  ownerId: string;
};

export type TReviewReplyRequestBody = {
  text: string;
  ownerId: string;
};

const defaultReviewRepliesState: IReviewReplyState = {
  allReviewReplies: [],
  error: null,
};

export const reviewReply = createModel<RootModel>()({
  state: defaultReviewRepliesState,
  reducers: {
    updateAllReplies: (state, payload) => {
      return {
        ...state,
        allReviewReplies: payload,
      };
    },
    clearReviewReplies: () => defaultReviewRepliesState,
    setError: (state, payload: string) => ({
      ...state,
      error: payload,
    }),
  },
  effects: {
    async getAllReviewReplies(payload) {
      try {
        const { reviewId } = payload;
        const { data } = await getReviewReplies(reviewId);
        this.updateAllReplies([data]);
      } catch (error) {
        if (error instanceof AxiosError) {
          this.setError(error.message);
        }
        console.log(error);
      }
    },
    async replyOnReview(payload: ReviewReplyPayload) {
      try {
        const { businessUnitId, text, ownerId } = payload;
        const requestBody = {
          text: text.trim(),
          ownerId,
          owner: 'review',
        };
        await replyOnReview(businessUnitId, requestBody);
        Promise.resolve();
      } catch (error) {
        if (error instanceof AxiosError) {
          this.setError(error.message);
        }
        this.setError('something went wrong');
        Promise.reject();
      }
    },
  },
});
