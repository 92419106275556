import { Box, Button, Typography } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'environment/constants/routes';
import { Dispatch, RootState } from 'store';
import Colors from 'styles/colors.scss';

import styles from './CopyBusiness.module.scss';

const CopyBusinessTemplate = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const { isByNolemon } = useSelector((state: RootState) => state.onboarding);

  const handleDoManually = () => {
    dispatch.onboarding.setOnboarding({ copyData: false });
    navigate(ROUTES.onboarding.subscription);
  };

  const handleCopyData = async () => {
    try {
      dispatch.onboarding.setOnboarding({ copyData: true });
      if (isByNolemon) {
        await dispatch.onboarding.completeClaim();
        navigate(ROUTES.dashboard.home);
      } else {
        navigate(ROUTES.onboarding.describe);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(error.response?.data?.message ?? error.message, {
          variant: 'error',
        });
      } else {
        console.error(error);
      }
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      maxWidth="762px"
      gap="40px"
      p="40px"
      borderRadius="16px"
      bgcolor={Colors['bg-color']}
    >
      <Box display="flex" flexDirection="column" gap="16px">
        <Typography variant="text-2xl" align="center">
          {t('do_you_want_to_use_the_same_data')}
        </Typography>
        <Typography
          variant="text-base"
          align="center"
          color={Colors['default-gray-100']}
        >
          {t('use_the_same_email_address_password_and_card_details_as_')}
        </Typography>
      </Box>
      <Box
        display="flex"
        gap="20px"
        width="100%"
        maxWidth="540px"
        className={styles.actionBar}
      >
        <Button
          fullWidth
          variant="outlined"
          size="large"
          onClick={handleDoManually}
        >
          {t('no_do_it_manually')}
        </Button>
        <Button
          fullWidth
          variant="contained"
          size="large"
          onClick={handleCopyData}
        >
          {t('yes_use_the_same_data')}
        </Button>
      </Box>
    </Box>
  );
};

export default React.memo(CopyBusinessTemplate);
