import { Box, Button, Typography } from '@mui/material';
import { FC } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  GoBackLink,
  GradientOutlinedButton,
  ServerErrorMessage,
} from 'components/atoms';
import { MemberInfo } from 'components/molecules';
import { SignUpBusinessFormData } from 'hooks/forms';
import { useSignUpRoutes } from 'hooks/signUpNavigation';
import Colors from 'styles/colors.scss';

interface IProps {
  onSubmit: () => void;
  isMembersNotEmpty: boolean;
}

const SignUpMembersTemplate: FC<IProps> = ({ onSubmit, isMembersNotEmpty }) => {
  const { t } = useTranslation();

  const signUpRoutes = useSignUpRoutes();

  const {
    control,
    formState: { errors },
    clearErrors,
  } = useFormContext<SignUpBusinessFormData>();

  const businessName = useWatch({
    control,
    name: 'businessDetails.companyName',
  });

  const { fields } = useFieldArray({ control, name: 'members' });

  return (
    <Box width="460px">
      <GoBackLink
        navigateTo={signUpRoutes.mantras}
        onClick={() => clearErrors('root.serverError')}
      />
      <Box display="flex" flexDirection="column" gap="40px">
        <Box display="flex" flexDirection="column" gap="24px">
          <Typography variant="text-2xl" textAlign="center">
            {t('Add.members')}
          </Typography>
          <Box display="flex" flexDirection="column" gap="12px">
            <Typography variant="text-base-semibold">
              {t('Invite.members.of.your.organisation.to.your.account')}
            </Typography>
            <Typography
              variant="text-base-light"
              color={Colors['default-gray-200']}
            >
              {t('You.can.set.their.permissions.under.Settings.later')}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap="20px">
          {fields.map(({ id }, index) => (
            <MemberInfo
              key={id}
              businessName={businessName}
              fullNameFieldName={`members.${index}.fullName`}
              emailFieldName={`members.${index}.email`}
            />
          ))}
        </Box>

        {errors.root?.serverError?.message ? (
          <ServerErrorMessage message={errors.root.serverError.message} />
        ) : null}

        {errors.members?.message ? (
          <ServerErrorMessage message={errors.members.message} />
        ) : null}

        {isMembersNotEmpty ? (
          <Button variant="contained" fullWidth onClick={onSubmit}>
            <Typography
              variant="text-base-semibold"
              color={Colors['default-gray-900']}
            >
              {t('Create.Business')}
            </Typography>
          </Button>
        ) : (
          <GradientOutlinedButton onClick={onSubmit}>
            {t('Skip.and.Create.Business')}
          </GradientOutlinedButton>
        )}
      </Box>
    </Box>
  );
};

export default SignUpMembersTemplate;
