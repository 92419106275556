import { Box } from '@mui/material';
import moment from 'moment';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DayName } from 'environment/types';

import ScrollContainer from '../scrollContainer/ScrollContainer';

import styles from './TimePicker.module.scss';
import TimePickerButton from './TimePickerButton';
import TimePickerPopover from './TimePickerPopover';
import {
  convertTo24Hours,
  get12Hours,
  getMode,
  useMondayToFridayChange,
} from './utils';

interface Props {
  name: string;
  value: string;
  onChange: (value: string) => void;
}

const TimePickerControllerWrapper: FC<Pick<Props, 'name'>> = ({ name }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => {
        return <TimePicker value={value} onChange={onChange} name={name} />;
      }}
    />
  );
};

const TimePicker: FC<Props> = ({ name, value, onChange }) => {
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [, dayName, toFrom] = name.split('.') as [any, DayName, 'to' | 'from'];

  const { performMondayToFridayChange, handlePopoverClose } =
    useMondayToFridayChange(toFrom, dayName);

  const date = moment.utc(value);
  const hours = date.hours();
  const minutes = date.minutes();
  const mode = getMode(hours);

  const label = toFrom === 'from' ? t('From') : t('To');
  const strValue = `${get12Hours(hours)}:${minutes
    .toString()
    .padStart(2, '0')} ${mode}`;

  return (
    <TimePickerPopover
      label={label}
      value={strValue}
      onClose={handlePopoverClose}
    >
      <Box display="flex" gap="6px" maxHeight="232px">
        <ScrollContainer className={styles.buttons}>
          {Array.from({ length: 12 }, (_, index) => index + 1).map((number) => {
            const isActive = get12Hours(hours) === number;

            return (
              <TimePickerButton
                key={number}
                active={isActive}
                onClick={() => {
                  date.hours(convertTo24Hours(number, mode));
                  onChange(date.toISOString());
                  performMondayToFridayChange?.(date.toISOString());
                }}
              >
                {number}
              </TimePickerButton>
            );
          })}
        </ScrollContainer>
        <ScrollContainer className={styles.buttons}>
          {Array.from({ length: 12 }, (_, index) => index * 5).map((number) => {
            const isActive = minutes === number;

            return (
              <TimePickerButton
                key={number}
                active={isActive}
                scrollBehavior="smooth"
                onClick={() => {
                  date.minutes(number);
                  onChange(date.toISOString());
                  performMondayToFridayChange?.(date.toISOString());
                }}
              >
                {number.toString().padStart(2, '0')}
              </TimePickerButton>
            );
          })}
        </ScrollContainer>
        <ScrollContainer className={styles.buttons}>
          <TimePickerButton
            active={mode === 'AM'}
            onClick={() => {
              if (mode === 'AM') {
                return;
              }

              date.hours(convertTo24Hours(hours, 'PM', true));
              onChange(date.toISOString());
              performMondayToFridayChange?.(date.toISOString());
            }}
          >
            AM
          </TimePickerButton>
          <TimePickerButton
            active={mode === 'PM'}
            onClick={() => {
              if (mode === 'PM') {
                return;
              }

              date.hours(convertTo24Hours(hours, 'PM', true));
              onChange(date.toISOString());
              performMondayToFridayChange?.(date.toISOString());
            }}
          >
            PM
          </TimePickerButton>
        </ScrollContainer>
      </Box>
    </TimePickerPopover>
  );
};

export default TimePickerControllerWrapper;
