import { toSortedTags } from './utils';

const HEALTH_DRINKS = [
  {
    id: 'fresh-juices',
    emoji: 'bg-freshjuices',
  },
  {
    id: 'herbal-teas',
    emoji: 'bg-herbalteas',
  },
  {
    id: 'mocktails',
    emoji: 'bg-mocktails',
  },
  {
    id: 'smoothies',
    emoji: 'bg-smoothies',
  },
  {
    id: 'sparkling-water',
    emoji: 'bg-sparklingwater',
  },
  {
    id: 'iced-teas',
    emoji: 'bg-icedteas',
  },
  {
    id: 'lemonades',
    emoji: 'bg-lemonades',
  },
  {
    id: 'infused-waters',
    emoji: 'bg-infusedwaters',
  },
  {
    id: 'milkshakes',
    emoji: 'bg-milkshakes',
  },
  {
    id: 'protein-shakes',
    emoji: 'bg-proteinshakes',
  },
  {
    id: 'bubble-tea',
    emoji: 'bg-bubble_tea',
  },
];

export default toSortedTags('healthDrinksLabel', HEALTH_DRINKS);
