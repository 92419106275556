import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Colors from 'styles/colors.scss';

import IconMark from '../icon/IconMark';

import styles from './UnavailableForYou.module.scss';

interface IProps {
  onClick: () => void;
  borderRadius?: number;
  isUnavailable: boolean;
  children: React.ReactNode;
  blur?: number;
}

const UnavailableForYou = ({
  children,
  borderRadius = 0,
  isUnavailable,
  blur = 1,
  onClick,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ position: 'relative' }}>
      {children}
      <Box
        sx={{
          borderRadius: borderRadius,
          display: isUnavailable ? 'block' : 'none',
          backdropFilter: `blur(${blur}px)`,
        }}
        className={styles.blurWrapper}
      />
      <Box
        sx={{
          display: isUnavailable ? 'block' : 'none',
        }}
        className={styles.buttonPosition}
      >
        <Box className={styles.textContainer}>
          <IconMark
            name="lock"
            color={Colors['default-gray-200']}
            size="24px"
          />
          <Typography component="p" variant="text-base-light">
            {t('Unavailable.for.you')}
          </Typography>
        </Box>
        <Button onClick={onClick} className={styles.button} variant="contained">
          <Typography variant="text-sm-semibold">
            {t('Upgrade.to.premium')}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default UnavailableForYou;
