import { Box, IconButton, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';

import { getDefaultAvatarByRating } from 'environment/utils/getDefaultAvatarByRating';
import { getRatingColor } from 'environment/utils/getRatingColor';
import Colors from 'styles/colors.scss';
import { IUserProfile } from 'types';

import CardProfile from '../cardProfile/CardProfile';
import Popover from '../popover/Popover';

import styles from './Profile.module.scss';

interface Value {
  firstName: string;
  lastName: string;
  profile?: IUserProfile;
  avatar?: string | null;
  username?: string;
  rating: number;
  about?: string | null;
  engagersCount?: number;
  engagingsCount?: number;
  createdAt: string;
}

interface IProps {
  value: Value;
  imageSize?: number;
  clickable?: boolean;
  hideUsername?: boolean;
  hideProfession?: boolean;
}

const Profile = ({
  value,
  imageSize = 40,
  clickable = false,
  hideUsername = false,
}: IProps) => {
  const { firstName, lastName, avatar, username, rating, about } = value;

  const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement | null>(
    null,
  );

  const handleProfileClick = ({
    currentTarget,
  }: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchor(currentTarget);
  };

  const renderImage = useMemo(
    () =>
      avatar ? (
        <Box
          component="button"
          sx={{ width: `${imageSize}px`, height: `${imageSize}px` }}
          className={`${styles.buttonImage} ${
            clickable ? styles.clickable : ''
          }`}
          onClick={handleProfileClick}
        >
          <Box component="img" src={avatar} className={`${styles.image}`} />
        </Box>
      ) : (
        <IconButton
          sx={{ width: `${imageSize}px`, height: `${imageSize}px` }}
          className={`${styles.buttonImage} ${
            clickable ? styles.clickable : ''
          }`}
          onClick={handleProfileClick}
        >
          <Box
            component="img"
            src={getDefaultAvatarByRating(rating)}
            width={25}
            height={25}
          />
        </IconButton>
      ),
    [clickable, avatar, imageSize],
  );

  return (
    <>
      <Popover anchorEl={popoverAnchor} onClose={() => setPopoverAnchor(null)}>
        <CardProfile value={value} />
      </Popover>

      <Box className={styles.container}>
        {renderImage}
        <Box display="flex" flexDirection="column" gap="4px">
          <Box display="flex" alignItems="center" gap="8px">
            <Typography
              color={(rating && getRatingColor(rating)) as string}
              noWrap
              variant="text-sm-light"
            >
              {firstName + ' ' + lastName}
            </Typography>
            {!hideUsername && username && (
              <Typography noWrap variant="text-xs">
                @{username}
              </Typography>
            )}
          </Box>
          {about !== null && (
            <Typography
              color={Colors['default-gray-200']}
              alignSelf="flex-start"
              noWrap
              variant="text-xs"
            >
              {about}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Profile;
