export const demographicRanges = [
  {
    value: '18-25',
    label: 'under 21',
  },
  {
    value: '25-30',
    label: '25 - 30',
  },
  {
    value: '30-35',
    label: '30 - 35',
  },
  {
    value: '35-40',
    label: '35 - 40',
  },
];

export const mockDataDemographics = {
  '18-25': {
    data: [
      19008, 19008, 2000, 1, 1, 2, 9, 13, 50, 170, 299, 438, 841, 1169, 1703,
      2422, 3692, 5543, 7345, 12298, 18638, 22229, 25540, 28133, 29463, 31139,
      31175, 31255, 295, 19008, 13708, 11511, 50979, 10904, 11011, 10903, 10732,
      10685, 10577, 10526, 10457, 10027, 8570, 8360, 7853, 5709, 5273, 5113,
      5066, 4897, 4881, 4804, 4717, 4571, 4018, 3822, 3785, 3805, 3750, 3708,
      3708,
    ],
  },

  '25-30': {
    data: [
      19008, 19008, 18638, 22229, 25540, 28133, 29463, 31139, 31175, 31255,
      19008, 13708, 11511, 10979, 10904, 11011, 10903, 10732, 10685, 10577,
      20113, 20113, 20113, 20113, 20113, 20113, 20113, 20113, 20113,
    ],
  },

  '30-35': {
    data: [
      19008, 19008, 2000, 1, 1, 2, 9, 13, 50, 170, 299, 438, 841, 1169, 1703,
      2422, 3692, 5543, 7345, 12298, 18638, 22229, 25540, 28133, 29463, 31139,
      31175, 31255, 295, 19008, 13708, 11511, 10979, 10904, 11011, 10903, 10732,
      10685, 10577, 10526, 10457, 10027, 8570, 8360, 7853, 5709, 5273, 5113,
      5066, 4897, 4881, 4804, 4717, 4571, 4018, 3822, 3785, 3805, 3750, 3708,
      3708,
    ],
  },

  '35-40': {
    data: [
      19008, 19008, 2000, 1, 1, 2, 9, 13, 50, 170, 299, 438, 841, 1169, 1703,
      2422, 3692, 5543, 7345, 12298, 18638, 22229, 25540, 28133, 29463, 31139,
      31175, 31255, 295, 19008, 13708, 11511, 10979, 10904, 11011, 10903, 10732,
      10685, 10577, 10526, 10457, 10027, 8570, 8360, 7853, 5709, 5273, 5113,
      5066, 4897, 4881, 4804, 4717, 4571, 4018, 3822, 3785, 3805, 3750, 3708,
      3708,
    ],
  },
};
