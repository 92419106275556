import axios from 'axios';
import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { NewPasswordTemplate } from 'components/templates';
import { loginResetPasswordChange } from 'environment/api/services/auth';
import { ROUTES } from 'environment/constants/routes';
import { PASSWORD_RECOVERY_SCHEMA } from 'environment/validation/resolvers';

const defaultValues = {
  newPassword: '',
  confirmNewPassword: '',
};

const NewPassword = () => {
  const form = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: PASSWORD_RECOVERY_SCHEMA,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get('token');

    if (!localStorage.getItem('reset_token') && !token) {
      navigate(ROUTES.login.home);
      return;
    }

    if (token) {
      localStorage.setItem('reset_token', token);
      navigate(ROUTES.login.newPassword);
    }
  }, [searchParams, navigate]);

  const onSubmit: SubmitHandler<typeof defaultValues> = async ({
    newPassword: password,
  }) => {
    try {
      const token = localStorage.getItem('reset_token');

      if (!token) {
        return;
      }

      setIsLoading(true);
      await loginResetPasswordChange({ password, token });
      localStorage.removeItem('reset_token');
      navigate(ROUTES.login.home);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        form.setError('root.serverError', {
          type: error.code,
          message: error.response?.data?.message ?? error.message,
        });
      } else {
        console.error(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <NewPasswordTemplate isLoading={isLoading} />
      </form>
    </FormProvider>
  );
};

export default NewPassword;
