import { USER_BY_ID, USER_PERSONAL_INFO } from 'environment/constants/url';

import axios from '../axios';

export const getUserById = (userId: string) => {
  return axios.get(USER_BY_ID(userId).USER);
};

export const getPersonalUserInfoById = (userId: string) => {
  return axios.get(USER_PERSONAL_INFO(userId).ABOUT);
};
