import { Box } from '@mui/material';
import React, { createContext, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { Logo, Stepper } from 'components/atoms';
import { SignUpSuccess } from 'components/molecules';

import styles from './SignUpLayout.module.scss';

type ContextValue = [boolean, (value: boolean) => void];
const initialState: ContextValue = [false, () => {}];

const SUBSCRIPTION_STEP_NUMBER = 10;
const CARD_DETAILS_STEP_NUMBER = 11;

export const SuccessContext = createContext(initialState);

const SignUpLayout = () => {
  const { pathname } = useLocation();
  const activeStep = Number(pathname.split('/').pop()) - 1 || 0;

  const [success, setSuccess] = useState(false);
  // For demo purposes. Waiting for new design to be implemented
  const isNewDesign =
    activeStep === SUBSCRIPTION_STEP_NUMBER ||
    activeStep === CARD_DETAILS_STEP_NUMBER;

  return (
    <SuccessContext.Provider value={[success, setSuccess]}>
      {isNewDesign ? (
        <div className={styles.fullPageWrapper}>
          <Outlet />
        </div>
      ) : (
        <Box className={styles.wrapper}>
          <Box className={styles.container}>
            {success ? (
              <SignUpSuccess />
            ) : (
              <>
                <Box className={styles.header}>
                  <Logo />
                  <Stepper activeStep={activeStep} steps={9} />
                </Box>
                <Outlet />
              </>
            )}
          </Box>
        </Box>
      )}
    </SuccessContext.Provider>
  );
};

export default SignUpLayout;
