import { Box, Divider, Typography } from '@mui/material';
import { type FC, type PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { IconMark, SettingsSectionButton } from 'components/atoms';
import SettingsSection from 'components/molecules/settingsSection/SettingsSection';
import { changeEmailVerify } from 'environment/api/services/settings';
import { Schedule, SettingsModals } from 'environment/types';
import { BUSINESS_UNIT_CLAIM_SOURCE } from 'environment/types/claim';
import { NewBusinessFormData } from 'hooks/forms';
import { Dispatch, RootState } from 'store';
import Colors from 'styles/colors.scss';

import AddBusiness from '../../modals/addBusiness/AddBusiness';

import BusinessInfo from './BusinessInfo';
import GeneralSection from './GeneralSection';
import NotificationsSection from './NotificationsSection';
import ScheduleInfo from './ScheduleInfo';
import styles from './YourLocations.module.scss';

interface IProps {
  id: string;
  name: string;
  email: string;
  location: string;
  schedule: Schedule;
  businessDetails: NewBusinessFormData['businessDetails'];
  refetchBusiness: () => void;
}

const YourLocations: FC<IProps> = ({
  id,
  name,
  email,
  location,
  schedule,
  businessDetails,
  refetchBusiness,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch<Dispatch>();

  const [isGeneralSectionExpanded, setGeneralSectionExpanded] =
    useState<boolean>(false);
  const [isNotificationsSectionExpanded, setNotificationsSectionExpanded] =
    useState<boolean>(false);

  const { addBusinessModalOpen } = useSelector(
    (state: RootState) => state.dashboard,
  );

  // these three states are responsible for email/password modals flow
  const [modal, setModal] = useState<SettingsModals | null>(null);
  const [prevModal, setPrevModal] = useState<SettingsModals | null>(null);
  const closeModal = () => setModal(null);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get('token');
    searchParams.delete('token');

    const action = searchParams.get('action');
    searchParams.delete('action');

    if (!token || !action) {
      return;
    }

    setSearchParams('');
    setGeneralSectionExpanded(true);

    if (action === 'password_recovery') {
      localStorage.setItem('reset_token', token);
      setModal('password-recovery');
    }

    if (action === 'change_email') {
      changeEmailVerify(token).then(refetchBusiness).catch(console.error);
    }
  }, [searchParams, setSearchParams, navigate, refetchBusiness]);

  const handleStartDashboardOnboarding = () => {
    dispatch.onboarding.setOnboarding({
      onboardingType: BUSINESS_UNIT_CLAIM_SOURCE.from_dashboard_settings,
    });
    navigate('/onboarding/claim-business');
  };

  return (
    <>
      <SettingsSection>
        <Box className={styles.header}>
          <Typography variant="text-xl">{t('Your.locations')}</Typography>
          <SettingsSectionButton
            text={t('Add.new.business')}
            onClick={handleStartDashboardOnboarding}
          />
        </Box>
        <Divider orientation="horizontal" sx={{ marginY: '16px' }} />
        <BusinessInfo
          id={id}
          name={name}
          location={location}
          businessDetails={businessDetails}
        />
        <ScheduleInfo schedule={schedule} />
        <Divider
          orientation="horizontal"
          sx={{ marginBottom: '20px', background: Colors['default-gray-600'] }}
        />
        <Box className={styles.accordions}>
          <SectionAccordion
            title={t('General')}
            opened={isGeneralSectionExpanded}
            toggleOpened={() => setGeneralSectionExpanded((v) => !v)}
          >
            <GeneralSection
              email={email}
              modal={modal}
              prevModal={prevModal}
              setModal={setModal}
              setPrevModal={setPrevModal}
              closeModal={closeModal}
            />
          </SectionAccordion>
          <SectionAccordion
            title={t('Notifications')}
            opened={isNotificationsSectionExpanded}
            toggleOpened={() => setNotificationsSectionExpanded((v) => !v)}
          >
            <NotificationsSection />
          </SectionAccordion>
        </Box>
      </SettingsSection>

      <AddBusiness
        open={addBusinessModalOpen}
        toggleOpen={() => dispatch.dashboard.setAddBusinessModalOpen()}
      />
    </>
  );
};

interface ISectionAccordionProps {
  title: string;
  opened: boolean;
  toggleOpened: () => void;
}

const SectionAccordion: FC<PropsWithChildren<ISectionAccordionProps>> = ({
  title,
  opened,
  toggleOpened,
  children,
}) => {
  return (
    <Box>
      <Box
        onClick={toggleOpened}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
        }}
      >
        <Typography variant="text-xl">{title}</Typography>
        <IconMark name="arrow-down" size="32px" color={Colors['white']} />
      </Box>
      {opened && children}
    </Box>
  );
};

export default YourLocations;
