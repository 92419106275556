import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Colors from 'styles/colors.scss';

import styles from './BusinessPromoteStats.module.scss';

interface BusinessPromoteActivityProps {
  views?: string;
  likes?: string;
  comments?: string;
}

const BusinessPromoteStats = ({
  comments,
  likes,
  views,
}: BusinessPromoteActivityProps) => {
  const { t } = useTranslation();
  return (
    <Box className={styles.stats} display="flex" flexDirection="column">
      <Typography variant="text-xl" color={Colors['HEXB8FE61']} align="center">
        {t('your-business-has-received')}
      </Typography>
      <Box className={styles.box}>
        <Typography
          variant="text-lg-light"
          className={!views && styles.blurred}
        >
          {views || '--'} {t('views')}
        </Typography>
        <Typography variant="text-lg-light" className={styles.blurred}>
          {likes || '--'} {t('likes')}
        </Typography>
        <Typography variant="text-lg-light" className={styles.blurred}>
          {comments || '--'} {t('comments')}
        </Typography>
      </Box>
    </Box>
  );
};

export default React.memo(BusinessPromoteStats);
