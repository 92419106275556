import { Box, Select, Typography } from '@mui/material';
import { type FC, useState } from 'react';
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EmojiIcon } from 'components/atoms';

import TagDropdownLabel from '../tagDropdownLabel/TagDropdownLabel';

import styles from './TagDropdown.module.scss';
import TooltipMenuItem from './TooltipMenuItem';

type TagDropdownItem = {
  id: string;
  emoji: string | string[];
};

type TagDropdownUpdatedItem = {
  id: string;
  emoji: string | string[];
  isSelected: boolean;
};

interface IProps {
  label: string;
  emoji: string | string[];
  category: string;
  items?: Array<TagDropdownItem>;
  withGradient?: boolean;
  withChevron?: boolean;
  disabled?: boolean;
  size?: 'small' | 'large';
  field?: ControllerRenderProps;
  blurUnselected?: boolean;
}

const TagDropdown: FC<IProps> = ({
  label,
  emoji,
  items,
  withGradient,
  category,
  withChevron,
  disabled,
  size,
  field,
  blurUnselected,
}) => {
  const { t } = useTranslation();

  const updatedItems = items?.map((item) => ({ ...item, isSelected: false }));

  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(
    null,
  );

  const handleItemClick = (index: number, item: TagDropdownUpdatedItem) => {
    field?.onChange(item.id);
    setSelectedItemIndex(index);
  };

  const handleDropdownOpen = () => {
    field?.onChange('');
    setSelectedItemIndex(null);
  };

  return (
    <TagDropdownLabel
      text={label}
      emoji={emoji}
      withGradient={withGradient}
      withChevron={withChevron}
      size={size}
      isBlured={blurUnselected && field?.value === ''}
    >
      <Select
        {...(field ? field : { value: '' })}
        disabled={disabled}
        disableUnderline
        MenuProps={{ slotProps: { paper: { className: styles.dropdown } } }}
        IconComponent={() => null}
        onOpen={handleDropdownOpen}
        className={
          blurUnselected && field?.value === '' ? styles.blurredWrapper : ''
        }
      >
        {updatedItems?.map(({ id, emoji, isSelected }, index) => (
          <TooltipMenuItem
            key={id}
            value={id}
            description={t(`tagsDescriptions.${category}.${id}`)}
            onClick={() =>
              handleItemClick(index, {
                id,
                emoji,
                isSelected,
              })
            }
          >
            <Box
              className={`${styles.dropdownItem} ${
                index === selectedItemIndex ? styles.selectedItem : ''
              }`}
            >
              <EmojiIcon size={24} name={emoji} />
              <Typography className={styles.itemLabel}>
                {t(`tagsLabels.${category}.${id}`)}
              </Typography>
            </Box>
          </TooltipMenuItem>
        ))}
      </Select>
    </TagDropdownLabel>
  );
};

interface IWrapperProps {
  label: string;
  emoji: string | string[];
  category: string;
  items?: Array<TagDropdownItem>;
  name?: string;
  size?: 'small' | 'large';
  withGradient?: boolean;
  blurUnselected?: boolean;
}

const TagDropdownWrapper: FC<IWrapperProps> = ({
  label,
  emoji,
  items,
  category,
  name,
  size,
  withGradient = false,
  blurUnselected,
}) => {
  const { t } = useTranslation();

  const form = useFormContext();

  return name ? (
    <Controller
      name={name}
      control={form.control}
      render={({ field }) => {
        const tagObjectByValue = items?.find(({ id }) => id === field.value);

        return (
          <TagDropdown
            field={field}
            category={category}
            label={t(
              tagObjectByValue?.id
                ? `tagsLabels.${category}.${tagObjectByValue.id}`
                : label,
            )}
            emoji={tagObjectByValue?.emoji || emoji}
            items={items}
            withGradient={!!tagObjectByValue}
            withChevron={!tagObjectByValue && !!name}
            size={size}
            blurUnselected={blurUnselected}
          />
        );
      }}
    />
  ) : (
    <TagDropdown
      disabled={!name}
      label={t(label)}
      category={category}
      emoji={emoji}
      items={items}
      size={size}
      withGradient={withGradient}
      blurUnselected={blurUnselected}
    />
  );
};

export default TagDropdownWrapper;
