import { Box, Button, Typography } from '@mui/material';
import { FC, memo, useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GoBackLink } from 'components/atoms';
import { OtpWithMessage } from 'components/molecules';
import { formatDuration } from 'environment/utils/formatDuration';
import { type SignUpFormData } from 'hooks/forms';
import { useSignUpRoutes } from 'hooks/signUpNavigation';
import Colors from 'styles/colors.scss';

interface IProps {
  status: 'success' | 'error' | null;
  message?: string;
  onComplete?: (value: string) => void;
  onResend: () => void;
  onNext?: () => void;
  hideControls?: boolean;
  hideGoBack?: boolean;
}

const SignUpOtpTemplate: FC<IProps> = ({
  status,
  message,
  onComplete,
  onResend,
  onNext,
  hideControls,
  hideGoBack,
}) => {
  const { t } = useTranslation();

  const signUpRoutes = useSignUpRoutes();

  const { control } = useFormContext<SignUpFormData>();
  const email = useWatch({ control, name: 'email' });

  return (
    <Box display="flex" flexDirection="column" gap="40px">
      <Box display="flex" flexDirection="column" gap="20px">
        {!hideGoBack && <GoBackLink navigateTo={signUpRoutes.home} />}

        <Box display="flex" flexDirection="column" gap="16px">
          <Box display="flex" flexDirection="column" gap="24px">
            <Box display="flex" flexDirection="column" gap="12px">
              <Typography variant="text-2xl" color={Colors.white}>
                {t('Weve.sent.you.a.code')}
              </Typography>
              <Typography
                variant="text-base"
                color={Colors['default-gray-200']}
              >
                {`${t('Enter.it.below.to.verify')} ${email}`}
              </Typography>
            </Box>

            {status !== 'success' && <ResendOtpButton onResend={onResend} />}
          </Box>

          <OtpWithMessage
            otpLength={6}
            messageType={status}
            message={message}
            onComplete={onComplete}
          />
        </Box>
      </Box>

      {!hideControls && (
        <Button
          variant="contained"
          fullWidth
          disabled={status !== 'success'}
          onClick={onNext}
        >
          <Typography
            variant="text-base-semibold"
            color={Colors['default-gray-900']}
          >
            {t('Next')}
          </Typography>
        </Button>
      )}
    </Box>
  );
};

const RESEND_TIMEOUT = 30;

// eslint-disable-next-line react/prop-types
const ResendOtpButton: FC<{ onResend: () => void }> = memo(({ onResend }) => {
  const { t } = useTranslation();

  const intervalId = useRef<NodeJS.Timer>();
  const [timer, setTimer] = useState<number>(0);

  const handleResend = () => {
    if (timer === 0) {
      onResend();
      setTimer(RESEND_TIMEOUT);
    }

    intervalId.current = setInterval(() => setTimer((v) => v - 1), 1000);
  };

  if (timer === 0 && intervalId.current) {
    clearInterval(intervalId.current);
  }

  useEffect(() => {
    return () => {
      clearInterval(intervalId.current);
    };
  }, []);

  const isDisabled = timer > 0;

  const timerStr = isDisabled ? ` (${formatDuration(timer)})` : '';

  return (
    <Box
      component="button"
      sx={{ ':disabled': { cursor: 'default' } }}
      disabled={isDisabled}
      alignSelf="flex-start"
      onClick={handleResend}
    >
      <Typography
        variant="text-sm-light"
        sx={{ opacity: isDisabled ? 0.75 : 1 }}
        color={Colors.HEXB8FE61}
      >
        {t('Resend.code') + timerStr}
      </Typography>
    </Box>
  );
});

ResendOtpButton.displayName = 'ResendOtpButton';

export default SignUpOtpTemplate;
