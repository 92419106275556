import { Box } from '@mui/material';

import styles from './PaymentsBalance.module.scss';

interface IProps {
  balance: string;
  addMoney: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PaymentsBalance = ({ balance, addMoney }: IProps) => {
  // const { t } = useTranslation();

  return (
    <Box className={styles.wrapper}>
      {/* TODO: uncomment when will be needed */}
      {/* <Box className={styles.container}>
        <Typography variant="text-base-semibold" className={styles.title}>
          {t('Your.balance')}
        </Typography>
        <Typography className={styles.balance}>$ {balance}</Typography>
        <Button
          type="submit"
          className={styles.button}
          variant="contained"
          size="large"
          onClick={addMoney}>
          {t('Add.money')}
        </Button>
      </Box> */}
    </Box>
  );
};

export default PaymentsBalance;
