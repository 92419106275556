import { Box } from '@mui/material';
import { type FC, type PropsWithChildren } from 'react';

import styles from './SettingsSection.module.scss';

interface IProps {
  padding?: string;
}

const SettingsSection: FC<PropsWithChildren<IProps>> = ({
  padding = '40px',
  children,
}) => {
  return (
    <Box component="section" className={styles.container} padding={padding}>
      {children}
    </Box>
  );
};

export default SettingsSection;
