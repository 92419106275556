import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'components/atoms';
import { GiveAdvice } from 'components/molecules';
import ReferBusiness from 'components/molecules/referBusiness/ReferBusiness';
import { DashboardTemplate } from 'components/templates';
import {
  GET_BOOST_SCHEMA,
  REFER_SCHEMA,
} from 'environment/validation/resolvers';
import useUnits from 'hooks/useUnits';
import { Dispatch, RootState } from 'store';

export type AdviceFormData = {
  advice: string;
};

export type ReferFormData = {
  email: string;
};

export type GetBoostFormValues = {
  boost: string;
  multiplier?: string;
  multiplierInput?: string;
};

const Dashboard = () => {
  const dispatch = useDispatch<Dispatch>();

  const [openBoostModal, setOpenBoostModal] = useState<boolean>(false);
  const [openReferModal, setOpenReferModal] = useState<boolean>(false);
  const [openGiveAdviceModal, setOpenGiveAdviceModal] =
    useState<boolean>(false);

  const {
    totalActivity,
    engagementsQuantity,
    recentActivity,
    consumerDemographics,
    comparedToWaterfalls,
  } = useSelector((state: RootState) => state.dashboard);

  const { currentUnit } = useUnits();

  const adviceForm = useForm<AdviceFormData>({
    defaultValues: { advice: '' },
  });

  const getBoostMethods = useForm<GetBoostFormValues>({
    mode: 'onChange',
    defaultValues: {
      boost: '',
      multiplier: '1',
      multiplierInput: '',
    },
    resolver: GET_BOOST_SCHEMA,
  });

  const referMethods = useForm<ReferFormData>({
    mode: 'onChange',
    defaultValues: { email: '' },
    resolver: REFER_SCHEMA,
  });

  const handleBoostModal = (value: boolean) => {
    getBoostMethods.reset();
    setOpenBoostModal(value);
  };
  const handleReferModal = (value: boolean) => {
    setOpenReferModal(value);
    referMethods.reset();
  };
  const handleGiveAdviceModal = (value: boolean) => {
    setOpenGiveAdviceModal(value);
  };

  const onSubmitBoost = () => {
    handleBoostModal(false);
  };

  const onSubmitRefer: SubmitHandler<ReferFormData> = async () => {
    try {
      handleReferModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmitGiveAdvice: SubmitHandler<AdviceFormData> = async () => {
    try {
      setOpenGiveAdviceModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!currentUnit.id) {
      return;
    }

    dispatch.dashboard.getDashboardEngagementsQuantity(currentUnit.id);
    dispatch.dashboard.getDashboardTotalActivity(currentUnit.id);
    dispatch.dashboard.getDashboardConsumerDemographics(currentUnit.id);
    dispatch.dashboard.getDashboardComparedToWaterfalls(currentUnit.id);
    dispatch.dashboard.getDashboardRecentActivity(currentUnit.id);
    dispatch.dashboard.getAllRecentActivity(currentUnit.id);

    return () => {
      dispatch.dashboard.resetState();
    };
  }, [currentUnit.id, dispatch.dashboard]);

  return (
    <>
      <Modal open={openGiveAdviceModal}>
        <FormProvider {...adviceForm}>
          <form onSubmit={adviceForm.handleSubmit(onSubmitGiveAdvice)}>
            <GiveAdvice onClose={() => handleGiveAdviceModal(false)} />
          </form>
        </FormProvider>
      </Modal>

      <FormProvider {...referMethods}>
        <ReferBusiness
          open={openReferModal}
          onClose={() => handleReferModal(false)}
          onSubmit={referMethods.handleSubmit(onSubmitRefer)}
        />
      </FormProvider>

      <DashboardTemplate
        onSubmitBoost={getBoostMethods.handleSubmit(onSubmitBoost)}
        getBoostMethods={getBoostMethods}
        openBoostModal={openBoostModal}
        handleBoostModal={handleBoostModal}
        handleReferModal={handleReferModal}
        handleGiveAdviceModal={handleGiveAdviceModal}
        totalActivity={totalActivity}
        engagementsQuantity={engagementsQuantity}
        consumerDemographics={consumerDemographics}
        recentActivity={recentActivity}
        currentUnit={currentUnit}
        currentUnitCategory={currentUnit.category}
        comparedToWaterfalls={comparedToWaterfalls}
      />
    </>
  );
};

export default Dashboard;
