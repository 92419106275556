import { LoaderOverlay } from 'components/atoms';
import { AuthContainer } from 'components/molecules';
import { CheckYourEmail, PasswordRecoveryForm } from 'components/organisms';

interface IProps {
  formSent: boolean;
  isLoading?: boolean;
}

const PasswordRecoveryTemplate = ({ formSent, isLoading }: IProps) => {
  return (
    <AuthContainer>
      {formSent ? (
        <CheckYourEmail type="password-recovery" />
      ) : (
        <>
          {isLoading && <LoaderOverlay />}
          <PasswordRecoveryForm />
        </>
      )}
    </AuthContainer>
  );
};

export default PasswordRecoveryTemplate;
