import Colors from 'styles/colors.scss';

export const donutChartColors = [
  Colors['HEXB8FE61'],
  Colors['profile-blue'],
  Colors['profile-purple'],
  Colors['profile-red'],
];

export const zeroDataArray = [
  {
    group: {
      min: 0,
      max: 21,
    },
    quantity: 0,
  },
  {
    group: {
      min: 22,
      max: 30,
    },
    quantity: 0,
  },
  {
    group: {
      min: 31,
      max: 45,
    },
    quantity: 0,
  },
  {
    group: {
      min: 45,
      max: 100,
    },
    quantity: 0,
  },
];
