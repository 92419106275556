import { Box, Button, Divider, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as MasterCard } from 'assets/icons/mastercard.svg';
import { ReactComponent as Visa } from 'assets/icons/visa.svg';
import { SettingsSection } from 'components/molecules';
import usePayments from 'hooks/usePayments';
import useUnits from 'hooks/useUnits';

import { AddPaymentMethodModal } from '../../modals';

import styles from './PaymentMethods.module.scss';

const PaymentMethods = () => {
  const { t } = useTranslation();

  const { unitPaymentMethods, removeUnitPaymentMethod } = usePayments();

  const [open, setOpen] = useState<boolean>(false);

  const { currentUnit } = useUnits();

  const isCanBeDeleted = unitPaymentMethods.length > 1;

  const handleRemoveMethod = async (paymentMethodId: string) => {
    await removeUnitPaymentMethod(currentUnit.id, paymentMethodId);
  };

  return (
    <>
      <AddPaymentMethodModal open={open} onClose={() => setOpen(false)} />

      <SettingsSection>
        <Typography variant="text-xl">{t('Payment.methods')}</Typography>

        <Divider sx={{ marginY: '24px' }} />
        <div className={styles.paymentMethodContainer}>
          {unitPaymentMethods.length > 0 ? (
            unitPaymentMethods.map((method) => (
              <Box className={styles.paymentMethod} key={method.id}>
                <Box className={styles.iconWithText}>
                  {method.brand === 'visa' ? <Visa /> : <MasterCard />}
                  <Box className={styles.left}>
                    <Typography
                      variant="text-base-light"
                      sx={{ textTransform: 'capitalize' }}
                    >
                      {method.display_brand}
                    </Typography>
                    <Typography className={styles.dimmed}>
                      ****{method.last4}
                    </Typography>
                  </Box>
                </Box>
                {isCanBeDeleted && (
                  <Button
                    type="button"
                    variant="text"
                    className={styles.button}
                  >
                    <Typography
                      variant="text-sm-light"
                      onClick={() => handleRemoveMethod(method.id)}
                    >
                      {t('Delete')}
                    </Typography>
                  </Button>
                )}
              </Box>
            ))
          ) : (
            <Typography variant="text-base-light">
              {t('no_payment_methods_added')}
            </Typography>
          )}
        </div>
        <Divider sx={{ marginY: '24px' }} />

        <Button
          type="button"
          variant="text"
          className={styles.button}
          onClick={() => setOpen(true)}
        >
          <Typography variant="text-sm-light">
            {t('Add.a.payment.method')}
          </Typography>
        </Button>
      </SettingsSection>
    </>
  );
};

export default PaymentMethods;
