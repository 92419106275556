import { useForm } from 'react-hook-form';

const defaultValues = {
  nameOnCard: '',
  cardNumber: '',
  expiryDate: '',
  securityCode: '',
};

export type AddPaymentMethodFormData = typeof defaultValues;

export const useAddPaymentMethodForm = () => {
  return useForm({
    defaultValues,
    mode: 'onBlur',
    // resolver: ADD_PAYMENT_METHOD_SCHEMA, // TODO: for demo purposes. remove after new design is implemented
  });
};
