import { IMonthData } from 'store/models/engagement';

import { countDataInMonthsRange } from './countDataInMonthsRange';
import { getMonthArray } from './getMonthsArray';

export const handleMonthsForCharts = (createdAt: string, data: IMonthData) => {
  const monthArray = getMonthArray(createdAt);

  const dataInMonthsRange = countDataInMonthsRange(data);

  return monthArray.length === 1
    ? [dataInMonthsRange[1]]
    : dataInMonthsRange.reverse();
};
