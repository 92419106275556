import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Colors from 'styles/colors.scss';

interface MessageProps {
  title: string;
  description: string;
  link?: string;
}

const Message = ({ title, description, link }: MessageProps) => {
  const { t } = useTranslation();
  return (
    <Box
      bgcolor="#271A1A"
      display="flex"
      flex={1}
      flexDirection="column"
      gap="5px"
      borderRadius="16px"
      py="12px"
      px="24px"
    >
      <Box display="flex" gap="10px" alignItems="center">
        <ReportOutlinedIcon
          fontSize="large"
          sx={{ color: Colors['profile-red'] }}
        />
        <Typography variant="text-lg">{title}</Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap="5px" pl="44px">
        <Typography variant="text-base">
          {description}{' '}
          {link && (
            <Box component={Link} to={link}>
              <Typography
                variant="text-base"
                color={Colors['HEXB8FE61']}
                sx={{ cursor: 'pointer' }}
              >
                {t('view_details')}
              </Typography>
            </Box>
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default React.memo(Message);
