import { Button, Divider, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { SettingsSection } from 'components/molecules';
import { getPriceString } from 'environment/utils/getPriceString';
import usePayments from 'hooks/usePayments';
import useUnits from 'hooks/useUnits';
import Colors from 'styles/colors.scss';
import { SubscriptionStatus } from 'types/payments/payments';

import styles from './Subscriptions.module.scss';

const Subscriptions = () => {
  const { t } = useTranslation();

  const { currentUnit } = useUnits();

  const {
    getPlans,
    plans,
    unitSubscription,
    cancelUnitSubscription,
    switchUnitSubscription,
    renewSubscription,
  } = usePayments(currentUnit.id);

  useEffect(() => {
    getPlans();
  }, []);

  const status = unitSubscription?.status;

  const isActive =
    status === SubscriptionStatus.active ||
    status === SubscriptionStatus.trialing;

  const blocked = status === SubscriptionStatus.incomplete;

  const handleChangeSubscription = async (current: boolean, price: string) => {
    if (isActive) {
      if (current) {
        await cancelUnitSubscription(currentUnit.id);
      } else {
        await switchUnitSubscription(currentUnit.id, price);
      }
    } else {
      await renewSubscription(currentUnit.id, price);
    }
  };
  return (
    <SettingsSection>
      <Typography variant="text-xl">{t('subscriptions')}</Typography>

      <Divider sx={{ marginY: '24px' }} />
      <div className={styles.subscriptions}>
        {plans.map(({ id, name, default_price }) => {
          const current = default_price.id === unitSubscription?.stripePriceId;
          const actionLabel = current && isActive ? t('Cancel') : t('get');
          return (
            <div className={styles.subscription} key={id}>
              <div className={styles.info}>
                <Typography variant="text-base-light">
                  {name}{' '}
                  {current && (
                    <Typography variant="text-xs" color={Colors['HEXB8FE61']}>
                      {status}
                    </Typography>
                  )}
                </Typography>
                <Typography
                  variant="text-sm"
                  color={Colors['default-gray-300']}
                >
                  {t(getPriceString(default_price.unit_amount))}
                </Typography>
              </div>

              <Button
                type="button"
                variant="text"
                className={styles.button}
                disabled={blocked}
                sx={{
                  opacity: blocked ? 0.5 : 1,
                }}
                onClick={() =>
                  handleChangeSubscription(current, default_price.id)
                }
              >
                <Typography variant="text-sm-light">{actionLabel}</Typography>
              </Button>
            </div>
          );
        })}
      </div>
    </SettingsSection>
  );
};

export default Subscriptions;
