import { useDispatch, useSelector } from 'react-redux';

import { Dispatch, RootState } from 'store/index';
import { AddUnitToFolderOptions } from 'store/models/folders';

const useFolders = () => {
  const dispatch = useDispatch<Dispatch>();

  const { allFolders } = useSelector((state: RootState) => state.folders);

  const getAllBusinessFolders = () => {
    dispatch.folders.getAllFolders();
  };

  const createNewFolder = (folderName: string) => {
    dispatch.folders.postCreateNewFolder({ folderName });
  };

  const editFolderName = (folderId: string, folderName: string) => {
    dispatch.folders.updateFolderName({ folderId, folderName });
  };

  const moveUnitToFolder = (options: AddUnitToFolderOptions) => {
    dispatch.folders.addUnitToFolder(options);
  };

  return {
    allFolders,
    getAllBusinessFolders,
    createNewFolder,
    editFolderName,
    moveUnitToFolder,
  };
};

export default useFolders;
