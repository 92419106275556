import axios from 'axios';
import { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { LoginTemplate } from 'components/templates';
import { login } from 'environment/api/services/auth';
import { ROUTES } from 'environment/constants/routes';
import { LOGIN_SCHEMA } from 'environment/validation/resolvers';
import { Dispatch } from 'store';

const defaultValues = {
  email: '',
  password: '',
};

const Login = () => {
  const form = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: LOGIN_SCHEMA,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit: SubmitHandler<typeof defaultValues> = async ({
    email,
    password,
  }) => {
    try {
      setIsLoading(true);

      const {
        data: { id_token },
      } = await login({ email: email.trim(), password });

      localStorage.setItem('access_token', id_token);
      dispatch.units.getBusinessUnits();
      navigate(ROUTES.home);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (
          error.response?.data?.statusCode === 403 ||
          error.response?.data?.statusCode === 400
        ) {
          form.setError('root.serverError', {
            type: error.code,
            message: 'Wrong email or password',
          });
        } else {
          form.setError('root.serverError', {
            type: error.code,
            message: error.response?.data?.message ?? error.message,
          });
        }
      } else {
        console.error(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <LoginTemplate isLoading={isLoading} />
      </form>
    </FormProvider>
  );
};

export default Login;
