import { Route } from 'react-router-dom';

import { OnboaringLayout } from 'components/templates';
import { ROUTES } from 'environment/constants/routes';
import {
  ClaimBusinessPage,
  ConfirmBusinessPage,
  CopyBusinessPage,
  CredentialsPage,
  DescribePage,
  GetStartedPage,
  PaymentDetailsPage,
  QuestionsPage,
  SubscriptionPage,
  TagsDetailedPage,
  TagsPage,
  VerifyPage,
} from 'pages';

export const onboardingRoutes = (
  <Route element={<OnboaringLayout />}>
    <Route path={ROUTES.onboarding.getSarted} element={<GetStartedPage />} />
    <Route
      path={ROUTES.onboarding.claimBusiness}
      element={<ClaimBusinessPage />}
    />
    <Route
      path={ROUTES.onboarding.confirmBusiness}
      element={<ConfirmBusinessPage />}
    />
    <Route
      path={ROUTES.onboarding.copyBusiness}
      element={<CopyBusinessPage />}
    />
    <Route
      path={ROUTES.onboarding.subscription}
      element={<SubscriptionPage />}
    />
    <Route
      path={ROUTES.onboarding.paymentDetails}
      element={<PaymentDetailsPage />}
    />
    <Route path={ROUTES.onboarding.credentials} element={<CredentialsPage />} />
    <Route path={ROUTES.onboarding.verify} element={<VerifyPage />} />
    <Route path={ROUTES.onboarding.describe} element={<DescribePage />} />

    <Route path={ROUTES.onboarding.tags} element={<TagsPage />} />
    <Route
      path={ROUTES.onboarding.tagsDetailed}
      element={<TagsDetailedPage />}
    />
    <Route path={ROUTES.onboarding.questions} element={<QuestionsPage />} />
  </Route>
);
