import { ExpendituresTemplate } from 'components/templates';
import {
  basicAreaData,
  donutChartRaceData,
} from 'environment/configs/mockData';

const Expenditures = () => {
  return (
    <ExpendituresTemplate
      // TODO: update chart data from dashboard
      chartData={donutChartRaceData}
      areasData={basicAreaData}
    />
  );
};

export default Expenditures;
