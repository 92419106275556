import {
  Checkbox,
  type CheckboxProps,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { type FC, type ReactNode, forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import styles from './FilledCheckbox.module.scss';

interface IProps extends Omit<CheckboxProps, 'label' | 'name' | 'className'> {
  name?: string;
  label?: ReactNode;
  className?: string;
}

const FilledCheckbox = forwardRef<HTMLButtonElement, IProps>(
  ({ label, className, ...rest }, forwardedRef) => {
    return (
      <FormGroup row>
        <FormControlLabel
          className={styles.formControl}
          control={
            <Checkbox
              className={`${styles.checkbox} ${className ?? ''}`}
              ref={forwardedRef}
              {...rest}
            />
          }
          label={label}
        />
      </FormGroup>
    );
  },
);

FilledCheckbox.displayName = 'FilledCheckbox';

const FilledCheckboxWrapper: FC<IProps> = ({ name, ...rest }) => {
  const form = useFormContext();

  return name ? (
    <Controller
      name={name}
      control={form.control}
      render={({ field: { value, ...field } }) => (
        <FilledCheckbox checked={value} {...field} {...rest} />
      )}
    />
  ) : (
    <FilledCheckbox {...rest} />
  );
};

export default FilledCheckboxWrapper;
