import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';

import { IBusinessUnit } from 'environment/types/business';
import { stringifyLocation } from 'environment/utils/stringifyLocation';
import Colors from 'styles/colors.scss';

import styles from './BusinessItem.module.scss';

interface BusinessItemProps {
  onBusinessSelect: (business: IBusinessUnit) => void;
  data: IBusinessUnit;
}

const BusinessItem = ({ onBusinessSelect, data }: BusinessItemProps) => {
  const address = useMemo(
    () => stringifyLocation(data.location),
    [data.location],
  );
  const title = data.companyName || data.business.title;
  return (
    <Box
      display="flex"
      flexDirection="column"
      p="7px 16px"
      className={styles.business}
      onMouseDown={() => onBusinessSelect(data)}
    >
      <Typography variant="text-sm-light">{title}</Typography>
      <Typography variant="text-sm-light" color={Colors['default-green-200']}>
        {address}
      </Typography>
    </Box>
  );
};

export default React.memo(BusinessItem);
