import i18n from 'environment/plugins/i18n';

export function toSortedTags(
  category: string,
  items: {
    id: string;
    emoji: string | string[];
  }[],
) {
  return [...items].sort((a, b) => {
    return i18n
      .t(`tagsLabels.${category}.${a.id}`)
      .localeCompare(i18n.t(`tagsLabels.${category}.${b.id}`), undefined, {
        sensitivity: 'base',
      });
  });
}
