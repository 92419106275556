import { createModel } from '@rematch/core';

import {
  addPaymentMethod,
  cancelSubscription,
  getPaymentMethods,
  getPayments,
  getSubscription,
  getSubscriptionProducts,
  removePaymentMethod,
  renewSubscription,
  switchSubscription,
} from 'environment/api/services/payments';
import {
  Payment,
  PaymentMethod,
  Plan,
  Subscription,
} from 'types/payments/payments';

import type { RootModel } from '.';

interface IPaymentState {
  plans: Plan[];
  paymentMethods: PaymentMethod[];
  subscription: Subscription | null;
  payments: Payment[];
}

const defaultPaymentState: IPaymentState = {
  plans: [],
  paymentMethods: [],
  subscription: null,
  payments: [],
};

export const payments = createModel<RootModel>()({
  state: defaultPaymentState,
  reducers: {
    setPlans: (state, plans) => {
      return {
        ...state,
        plans,
      };
    },
    setMethods: (state, paymentMethods: PaymentMethod[]) => {
      return {
        ...state,
        paymentMethods,
      };
    },
    addMethod: (state, paymentMethod: PaymentMethod) => {
      return {
        ...state,
        paymentMethods: [...state.paymentMethods, paymentMethod],
      };
    },
    removeMethod: (state, paymentMethodId: string) => {
      return {
        ...state,
        paymentMethods: state.paymentMethods.filter(
          (method) => method.id !== paymentMethodId,
        ),
      };
    },
    setSubscription: (state, subscription) => {
      return {
        ...state,
        subscription,
      };
    },
    setPayments: (state, payments) => {
      return {
        ...state,
        payments,
      };
    },
  },
  effects: (dispatch) => ({
    async getPlans() {
      try {
        const { data } = await getSubscriptionProducts();
        this.setPlans(data);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async getPaymentMethods(payload: string) {
      try {
        const { data } = await getPaymentMethods(payload);
        this.setMethods(data);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async addPaymentMethod(payload: { cardToken: string; unitId: string }) {
      try {
        const { data } = await addPaymentMethod(payload);
        this.addMethod(data);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async removePaymentMethod(payload: {
      paymentMethodId: string;
      unitId: string;
    }) {
      try {
        await removePaymentMethod(payload);
        this.removeMethod(payload);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async getSubscription(payload: string) {
      try {
        const { data } = await getSubscription(payload);
        this.setSubscription(data);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async switchSubscription(payload: { unitId: string; priceId: string }) {
      try {
        await switchSubscription(payload);
        await dispatch.payments.getSubscription(payload.unitId);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async cancelSubscription(payload: string) {
      try {
        await cancelSubscription(payload);
        await dispatch.payments.getSubscription(payload);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async renewSubscription(payload: { unitId: string; priceId: string }) {
      try {
        await renewSubscription(payload);
        await dispatch.payments.getSubscription(payload.unitId);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getPayments(payload: string) {
      try {
        const { data } = await getPayments(payload);
        this.setPayments(data);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
  }),
});
