import { Box, Typography } from '@mui/material';

import { LikesDislikes } from 'components/atoms';
import AllUsers from 'components/atoms/allUsers/AllUsers';
import i18n from 'environment/plugins/i18n';
import { countPercentNewUsers } from 'environment/utils/countPercentNewUsers';
import { roundNumberToDecimal } from 'environment/utils/roundNumberToDecimal';
import { CounterData } from 'store/models/consumer-demographics';
import Colors from 'styles/colors.scss';

type TableEntity = CounterData;

export const columns = [
  {
    id: 'age',
    label: i18n.t('Age'),
    sortable: false,
    alignCenter: false,
    renderCell(row: TableEntity) {
      const ageRange = row.group.max;
      let cardTitle = '';
      switch (ageRange) {
        case 21:
          cardTitle = 'Under 21';
          break;
        case 25:
          cardTitle = '21-24';
          break;
        case 31:
          cardTitle = '25-31 age';
          break;
        case 37:
          cardTitle = '32-37 age';
          break;
        case 44:
          cardTitle = '38-44 age';
          break;
        case 100:
          cardTitle = '45+';
          break;
      }

      return (
        <Typography whiteSpace="nowrap" variant="text-xs">
          {cardTitle}
        </Typography>
      );
    },
  },
  {
    id: 'allUsers',
    label: i18n.t('All.users'),
    sortable: true,
    alignCenter: true,
    renderCell(row: TableEntity) {
      const value = row.statistics[this.id];
      const persent = countPercentNewUsers(
        row.statistics.allUsers,
        row.statistics.newUsers,
      );
      return <AllUsers percent={persent} amount={value} />;
    },
  },
  {
    id: 'newUsers',
    label: i18n.t('New.users'),
    sortable: true,
    alignCenter: true,
    renderCell(row: TableEntity) {
      const value = row.statistics[this.id];
      return (
        <Typography whiteSpace="nowrap" variant="text-xs">
          {roundNumberToDecimal(value)}
        </Typography>
      );
    },
  },
  {
    id: 'reviews',
    label: i18n.t('Reviews'),
    sortable: true,
    alignCenter: true,
    renderCell(row: TableEntity) {
      const value = row.endorsements[this.id];
      return (
        <Typography whiteSpace="nowrap" variant="text-xs">
          {roundNumberToDecimal(value)}
        </Typography>
      );
    },
  },
  {
    id: 'watches',
    label: i18n.t('Views'),
    sortable: true,
    alignCenter: true,
    renderCell(row: TableEntity) {
      const value = row.endorsements[this.id];
      return (
        <Typography whiteSpace="nowrap" variant="text-xs">
          {roundNumberToDecimal(value)}
        </Typography>
      );
    },
  },
  {
    id: 'shares',
    label: i18n.t('Share'),
    sortable: true,
    alignCenter: true,
    renderCell(row: TableEntity) {
      const value = row.endorsements[this.id];
      return (
        <Typography whiteSpace="nowrap" variant="text-xs">
          {roundNumberToDecimal(value)}
        </Typography>
      );
    },
  },
  {
    id: 'comments',
    label: i18n.t('Comments'),
    sortable: true,
    alignCenter: true,
    renderCell(row: TableEntity) {
      const value = row.endorsements[this.id];
      return (
        <Typography whiteSpace="nowrap" variant="text-xs">
          {roundNumberToDecimal(value)}
        </Typography>
      );
    },
  },
  {
    id: 'likes',
    label: (
      <Box>
        <Typography variant="text-xs">{i18n.t('Likes')}</Typography>
        <Typography
          color={Colors['default-gray-200']}
          variant="text-xs"
        >{` ${i18n.t('(vs.dislikes)')}`}</Typography>
      </Box>
    ),
    sortable: true,
    alignCenter: true,
    renderCell(row: TableEntity) {
      const value = row.endorsements[this.id];
      // TODO clarify how to count the percentage
      return <LikesDislikes likes={value} percent="0" />;
    },
  },
  {
    id: 'storyTag',
    label: i18n.t('Waterfall.tag'),
    sortable: true,
    alignCenter: true,
    renderCell(row: TableEntity) {
      const value = row.endorsements[this.id];
      return (
        <Typography whiteSpace="nowrap" variant="text-xs">
          {value}
        </Typography>
      );
    },
  },
  {
    id: 'forumTag',
    label: i18n.t('Forum.tag'),
    sortable: true,
    alignCenter: true,
    renderCell(row: TableEntity) {
      const value = row.endorsements[this.id];
      return (
        <Typography whiteSpace="nowrap" variant="text-xs">
          {value}
        </Typography>
      );
    },
  },
  {
    id: 'pageVisit',
    label: i18n.t('Page.visit'),
    sortable: true,
    alignCenter: true,
    renderCell(row: TableEntity) {
      const value = row.endorsements[this.id];
      return (
        <Typography whiteSpace="nowrap" variant="text-xs">
          {roundNumberToDecimal(value)}
        </Typography>
      );
    },
  },
] as const;
