import { Box } from '@mui/material';
import { type FC, type PropsWithChildren } from 'react';

import styles from './ScrollContainer.module.scss';

interface IProps {
  className?: string;
}

const ScrollContainer: FC<PropsWithChildren<IProps>> = ({
  className,
  children,
}) => {
  return (
    <Box className={`${styles.container}${className ? ` ${className}` : ''}`}>
      {children}
    </Box>
  );
};

export default ScrollContainer;
