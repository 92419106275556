import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';

import Percent from 'components/atoms/percentBlock/Percent';

import styles from './SpeedometerProgress.module.scss';

interface IProps {
  backgroundColor: string;
  amount: number;
  value: number;
}

const SpeedometerProgress = ({ backgroundColor, amount, value }: IProps) => {
  const count = 3;
  const turns = (1 / count) * 0.375;
  const strokeWidth = 5;

  const radialSeparators = () => {
    return Array(count)
      .fill(1)
      .map((_, index) => (
        <div
          key={index}
          style={{
            position: 'absolute',
            height: '101%',
            transform: `rotate(${index * turns - 0.25 / 2}turn)`,
          }}
        >
          <div
            style={{
              backgroundColor: backgroundColor,
              width: '7px',
              height: `${strokeWidth + 1}%`,
            }}
          />
        </div>
      ));
  };

  return (
    <CircularProgressbarWithChildren
      value={value}
      circleRatio={0.5}
      strokeWidth={strokeWidth}
      styles={{
        ...buildStyles({
          rotation: 0.75,
          strokeLinecap: 'butt', // round
          trailColor: '#444',
          pathColor: '#63DC8C',
        }),
      }}
    >
      {radialSeparators()}
      <div className={styles.titleContainer}>
        <h2 className={styles.title}>{amount}</h2>
        {value > 0 && <Percent value={value} />}
      </div>
    </CircularProgressbarWithChildren>
  );
};

export default SpeedometerProgress;
