import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ExpandMore } from 'assets/icons/expandMore.svg';
import { TextLogo } from 'components/atoms';
import IconMark from 'components/atoms/icon/IconMark';
import { IBusinessUnit } from 'environment/types/business';
import useAuth from 'hooks/useAuth';
import { AddUnitToFolderOptions, IFolder } from 'store/models/folders';
import Colors from 'styles/colors.scss';

import FoldersDropdown from '../foldersDropdown/FoldersDropdown';

import styles from './HeaderDropdown.module.scss';

interface IProps {
  businessUnits: IBusinessUnit[] | null;
  currentUnit: IBusinessUnit;
  setCurrentBusinessUnit: (unit: IBusinessUnit) => void;
  allFolders: IFolder[];
  createNewFolder: (folderName: string) => void;
  editFolderName: (folderId: string, folderName: string) => void;
  moveUnitToFolder: (options: AddUnitToFolderOptions) => void;
}

const HeaderDropdown = ({
  businessUnits,
  currentUnit,
  setCurrentBusinessUnit,
  allFolders,
  createNewFolder,
  editFolderName,
  moveUnitToFolder,
}: IProps) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(false);

  const { handleLogout } = useAuth();

  return (
    <FormControl className={`${styles.wrapper} ${open ? styles.open : ''}`}>
      <Select
        disableUnderline
        classes={{ select: styles.select }}
        className={styles.selectContainer}
        MenuProps={{
          autoFocus: false,
          PaperProps: { className: styles.menu },
        }}
        IconComponent={({ className }) => (
          <ExpandMore className={`${className} ${styles.arrow}`} />
        )}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        value={currentUnit.id}
        onChange={(event) => {
          const selectedUnitId = event.target.value;
          const selectedUnit = businessUnits?.find(
            (unit) => unit.id === selectedUnitId,
          );
          if (selectedUnit) {
            setCurrentBusinessUnit(selectedUnit);
          }
        }}
      >
        <MenuItem sx={{ display: 'none' }} value={currentUnit.id}>
          <TextLogo text={currentUnit.business.title} />
          <Box className={styles.title}>
            <Typography variant="text-base-light">
              {currentUnit.companyName}
            </Typography>
          </Box>
        </MenuItem>

        {businessUnits &&
          businessUnits.map(
            (unit) =>
              unit.id !== currentUnit?.id && (
                <MenuItem
                  sx={{ display: 'none' }}
                  key={unit.id}
                  value={unit.id}
                >
                  <TextLogo text={unit.business.title} />
                  <Box className={styles.title}>
                    <Typography variant="text-base-light">
                      {currentUnit.companyName}
                    </Typography>
                    <Typography
                      variant="text-xs"
                      color={Colors['default-gray-300']}
                    >
                      {`${currentUnit.location.number} ${currentUnit.location.street}, ${currentUnit.location.city}, ${currentUnit.location.country}`}
                    </Typography>
                  </Box>
                </MenuItem>
              ),
          )}

        <FoldersDropdown
          businessUnits={businessUnits}
          setCurrentBusinessUnit={setCurrentBusinessUnit}
          allFolders={allFolders}
          createNewFolder={createNewFolder}
          editFolderName={editFolderName}
          moveUnitToFolder={moveUnitToFolder}
        />

        <MenuItem
          value={'logOut'}
          className={styles.logOut}
          onClick={handleLogout}
        >
          <IconMark name="log-out" color={Colors['profile-red']} size="24px" />
          <Typography variant="text-base-light">{t('Log.Out')}</Typography>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default HeaderDropdown;
