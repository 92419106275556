import { Box, Button, Typography } from '@mui/material';
import axios from 'axios';
import { FC, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Input, LoaderOverlay, ServerErrorMessage } from 'components/atoms';
import SettingsModal from 'components/molecules/settingsModal/SettingsModal';
import { changeEmailConfirmPassword } from 'environment/api/services/settings';
import { CHANGE_EMAIL_CONFIRM_PASSWORD_SCHEMA } from 'environment/validation/resolvers';
import Colors from 'styles/colors.scss';

import styles from './ChangeEmailModals.module.scss';

const defaultValues = {
  password: '',
};

interface IProps {
  open: boolean;
  onClose: () => void;
  onForgot: () => void;
  onContinue: () => void;
}

const ChangeEmailConfirmPasswordModal: FC<IProps> = ({
  open,
  onClose,
  onForgot,
  onContinue,
}) => {
  const { t } = useTranslation();

  const form = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: CHANGE_EMAIL_CONFIRM_PASSWORD_SCHEMA,
  });

  const errors = form.formState.errors;

  const isValid = form.formState.isValid;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit: SubmitHandler<typeof defaultValues> = async (data) => {
    try {
      setIsLoading(true);
      await changeEmailConfirmPassword(data);
      onContinue();
      form.reset();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          form.setError('root.serverError', {
            type: error.code,
            message: t('Wrong.password'),
          });
        } else {
          form.setError('root.serverError', {
            type: error.code,
            message: error.response?.data?.message ?? error.message,
          });
        }
      } else {
        console.error(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    onClose();
    form.reset();
  };

  return (
    <SettingsModal
      leftSection={<div />}
      title={t('Change.email')}
      open={open}
      onClose={handleClose}
      withCloseButton
    >
      {isLoading && <LoaderOverlay />}
      <FormProvider {...form}>
        <form className={styles.wrapper} onSubmit={form.handleSubmit(onSubmit)}>
          <Box className={styles.container}>
            <Typography
              variant="text-sm-light"
              color={Colors['default-gray-200']}
            >
              {t('Enter.your.password.to.confirm.your.identity')}
            </Typography>

            <Input
              name="password"
              type="password"
              label={t('fields.password')}
              placeholder={t('fields.password')}
              autoComplete="current-password"
            />

            <Typography
              onClick={onForgot}
              className={styles.forgotPassword}
              color={Colors['HEXB8FE61']}
              component="button"
              variant="text-sm-light"
              type="button"
            >
              {t('Forgot.password')}
            </Typography>
          </Box>

          {errors.root?.serverError?.message ? (
            <ServerErrorMessage message={errors.root.serverError.message} />
          ) : null}

          <Button
            type="submit"
            size="large"
            variant="contained"
            disabled={!isValid}
            className={styles.submitButton}
          >
            <Typography
              color={Colors['default-gray-900']}
              variant="text-base-semibold"
            >
              {t('Continue')}
            </Typography>
          </Button>
        </form>
      </FormProvider>
    </SettingsModal>
  );
};

export default ChangeEmailConfirmPasswordModal;
