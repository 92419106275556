import { createModel } from '@rematch/core';

import {
  getWaterfallFeaturedRequest,
  getWaterfallMostRecentRequest,
  getWaterfallPaginatedRequest,
  setToggleFeaturedFlagRequest,
} from 'environment/api/services/waterfalls';
import { processAllWaterfall } from 'environment/utils/processAllWaterfall';
import { IWaterfall } from 'types/waterfall';

import type { RootModel } from '.';

interface IWaterfallState {
  waterfallsPaginated: IWaterfall[];
  waterfallsMostRecent: IWaterfall[];
  waterfallsFeatured: IWaterfall[];
}

const initialState: IWaterfallState = {
  waterfallsPaginated: [],
  waterfallsMostRecent: [],
  waterfallsFeatured: [],
};

export const waterfalls = createModel<RootModel>()({
  state: initialState,
  reducers: {
    updateWaterfallsPaginated: (state, payload: IWaterfall[]) => {
      return {
        ...state,
        waterfallsPaginated: payload,
      };
    },

    updateWaterfallsMostRecent: (state, payload: IWaterfall[]) => {
      return {
        ...state,
        waterfallsMostRecent: payload,
      };
    },

    updateWaterfallsFeatured: (state, payload: IWaterfall[]) => {
      return {
        ...state,
        waterfallsFeatured: payload,
      };
    },

    toggleFeaturedFlag: (state, waterfallId: string) => {
      return {
        ...state,
        waterfallsPaginated: state.waterfallsPaginated.map<IWaterfall>((wf) =>
          wf.id === waterfallId ? { ...wf, featured: !wf.featured } : wf,
        ),
      };
    },

    updateWaterfallReply: (
      state,
      payload: { waterfallId: string; reply: string | null },
    ) => {
      const { waterfallId, reply } = payload;

      const updateReplyForWaterfallArray = (waterfallArray: IWaterfall[]) => {
        return waterfallArray.map((waterfall) => {
          if (waterfall.id === waterfallId) {
            return {
              ...waterfall,
              reply,
            };
          }
          return waterfall;
        });
      };

      return {
        ...state,
        waterfallsPaginated: updateReplyForWaterfallArray(
          state.waterfallsPaginated,
        ),
        waterfallsMostRecent: updateReplyForWaterfallArray(
          state.waterfallsMostRecent,
        ),
        waterfallsFeatured: updateReplyForWaterfallArray(
          state.waterfallsFeatured,
        ),
      };
    },
  },
  effects: {
    async getWaterfallPaginated(payload) {
      try {
        const { businessUnitId, take, cursorId } = payload;
        const { data } = await getWaterfallPaginatedRequest(
          businessUnitId,
          take,
          cursorId,
        );
        const updatedWaterfall = await processAllWaterfall(data);
        this.updateWaterfallsPaginated(updatedWaterfall);
      } catch (error) {
        console.log(error);
      }
    },

    async getWaterfallMostRecent(payload) {
      try {
        const { businessUnitId, type } = payload;
        const { data } = await getWaterfallMostRecentRequest(
          businessUnitId,
          type,
        );
        const updatedWaterfall = await processAllWaterfall(data);
        this.updateWaterfallsMostRecent(updatedWaterfall);
      } catch (error) {
        console.log(error);
      }
    },

    async getWaterfallFeatured(payload) {
      try {
        const { businessUnitId, type } = payload;
        const { data } = await getWaterfallFeaturedRequest(
          businessUnitId,
          type,
        );
        const updatedWaterfall = await processAllWaterfall(data);
        this.updateWaterfallsFeatured(updatedWaterfall);
      } catch (error) {
        console.log(error);
      }
    },

    async toggleWaterfallOnFeatured({
      businessUnitId,
      waterfallId,
      featured,
    }: {
      businessUnitId: string;
      waterfallId: string;
      featured: boolean;
    }) {
      try {
        const request = setToggleFeaturedFlagRequest(
          businessUnitId,
          waterfallId,
          featured,
        );

        this.toggleFeaturedFlag(waterfallId);

        return Promise.resolve((await request).data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
});
