import { yupResolver } from '@hookform/resolvers/yup';
import { Box, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';
import * as yup from 'yup';

import i18n from 'environment/plugins/i18n';
import { IBusinessUnit } from 'environment/types/business';
import Colors from 'styles/colors.scss';

import BusinessItem from './BusinessItem';
import styles from './ClaimBusinessAutocomplete.module.scss';
import EmptyState from './EmptyState';

const InputProps = {
  disableUnderline: true,
  className: styles.input,
};

interface ClaimBusinessAutocompleteProps {
  onBusinessSelect: (unit: IBusinessUnit) => void;
  data: IBusinessUnit[];
  onFilter: (value: string) => void;
  value?: string | null;
}

const BUSINESS_UNIT_CLAIM_FILTER = yup
  .string()
  .max(32, i18n.t('validation.maxName', { number: 32 }));

const defaultValues = {
  filter: '',
};

const ClaimBusinessAutocomplete = ({
  onBusinessSelect,
  data,
  onFilter,
  value,
}: ClaimBusinessAutocompleteProps) => {
  const [focused, setFocused] = useState(false);

  const {
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      yup.object({
        filter: BUSINESS_UNIT_CLAIM_FILTER,
      }),
    ),
  });

  const filter = watch('filter');

  const onFilterChange = useDebouncedCallback((value) => {
    onFilter(value);
  }, 500);

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue('filter', e.target.value);
    onFilterChange(e.target.value);
  };

  const handleSelectBusiness = (unit: IBusinessUnit) => {
    onBusinessSelect(unit);
    setValue('filter', unit.companyName);
  };

  const isEmpty = data.length === 0;
  const selected = value && filter;

  return (
    <Box display="flex" flex={1} flexDirection="column" position="relative">
      <TextField
        fullWidth
        type="text"
        variant="standard"
        placeholder="Enter your business name"
        onChange={handleChangeValue}
        value={filter}
        error={Boolean(errors.filter?.message)}
        helperText={errors.filter?.message}
        sx={{
          '& .MuiInputBase-root': {
            borderRadius: `8px 0 0 ${focused ? 0 : 8}px`,
            background:
              selected &&
              'linear-gradient(120deg, rgba(243, 244, 246, 0.20) 40.05%, rgba(184, 254, 97, 0.42) 125.4%)',
          },
        }}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        InputProps={InputProps}
      />
      {focused && (
        <Box
          display="flex"
          flexDirection="column"
          p="16px 0"
          position="absolute"
          left={0}
          right={0}
          top={55}
          bgcolor={Colors['bg-color']}
          border={`1px solid ${Colors['default-gray-600']}`}
          maxHeight="306px"
          className={styles.businesses}
        >
          {data.map((business) => (
            <BusinessItem
              key={business.id}
              onBusinessSelect={handleSelectBusiness}
              data={business}
            />
          ))}
          {isEmpty && <EmptyState standBy={!filter} />}
        </Box>
      )}
    </Box>
  );
};

export default React.memo(ClaimBusinessAutocomplete);
