import axios from 'environment/api/axios';
import { MEMBERS_URL, SETTINGS_URL } from 'environment/constants/url';
import {
  CreateMemberRequestBody,
  UpdateMemberRequestBody,
} from 'environment/types/api/business';
import { Member } from 'environment/types/business';

export const changePassword = (body: {
  oldPassword: string;
  newPassword: string;
}) => {
  return axios.patch(SETTINGS_URL.CHANGE_PASSWORD, body);
};

export const changeEmailConfirmPassword = (body: { password: string }) => {
  return axios.post(SETTINGS_URL.CHANGE_EMAIL_CONFIRM_PASSWORD, body);
};

export const changeEmailSendVerification = (body: { email: string }) => {
  return axios.post(SETTINGS_URL.CHANGE_EMAIL_SEND_VERIFICATION, body);
};

export const changeEmailVerify = (token: string) => {
  return axios.get(`${SETTINGS_URL.CHANGE_EMAIL_VERIFY}?token=${token}`);
};

export const settingsResetPasswordGetDeeplink = (body: { email: string }) => {
  return axios.post(SETTINGS_URL.RESET_PASSWORD_GET_DEEPLINK, body);
};

export const settingsResetPassword = (
  body: { password: string },
  token: string,
) => {
  return axios.put(`${SETTINGS_URL.RESET_PASSWORD}?token=${token}`, body);
};

export const createMember = (body: CreateMemberRequestBody) => {
  return axios.post<Member>(MEMBERS_URL.MEMBER, body);
};

export const fetchMembers = () => {
  return axios.get<Member[]>(MEMBERS_URL.MEMBER);
};

export const deleteMember = (memberId: string) => {
  return axios.delete(MEMBERS_URL.MEMBER_ID(memberId));
};

export const updateMember = ({
  memberId,
  ...body
}: UpdateMemberRequestBody & { memberId: string }) => {
  return axios.patch<Member>(MEMBERS_URL.MEMBER_ID(memberId), body);
};
