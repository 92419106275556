import { Box, Button, Typography } from '@mui/material';
import { type FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GoBackLink, InfoLabel, ServerErrorMessage } from 'components/atoms';
import { SignUpScheduleForm } from 'components/organisms';
import { type SignUpBusinessFormData } from 'hooks/forms';
import { useSignUpRoutes } from 'hooks/signUpNavigation';
import Colors from 'styles/colors.scss';

import styles from './SignUpSchedule.module.scss';

interface IProps {
  onSubmit: () => void;
}

const SignUpScheduleTemplate: FC<IProps> = ({ onSubmit }) => {
  const { t } = useTranslation();

  const signUpRoutes = useSignUpRoutes();

  const {
    formState: { errors },
    clearErrors,
  } = useFormContext<SignUpBusinessFormData>();

  return (
    <>
      <Box className={styles.container}>
        <Box>
          <GoBackLink
            navigateTo={signUpRoutes.details}
            onClick={() => clearErrors('root.serverError')}
          />
          <Typography component="p" textAlign="center" variant="text-2xl-light">
            {t('What.times.are.you.open?')}
          </Typography>
        </Box>
        <SignUpScheduleForm />
        <InfoLabel
          text={t(
            'Dont.worry.you.will.always.be.able.to.update.your.schedule.later',
          )}
        />
        {errors.root?.serverError?.message ? (
          <ServerErrorMessage message={errors.root.serverError.message} />
        ) : null}
      </Box>

      <Button variant="contained" fullWidth onClick={onSubmit}>
        <Typography
          variant="text-base-semibold"
          color={Colors['default-gray-900']}
        >
          {t('Next')}
        </Typography>
      </Button>
    </>
  );
};

export default SignUpScheduleTemplate;
