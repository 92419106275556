import {
  MEDIA_URL,
  MEDIA_URL_BY_ID,
  MEDIA_WATERFALL_URL,
} from 'environment/constants/url';
import {
  IMediaByPublicationIdParams,
  IMediaResponse,
  IMediaUploadRequestBody,
  TMediaUploadBody,
  TMediaUploadResponse,
} from 'types/media';

import axios from '../axios';
import mediaAxios from '../axiosMedia';

export const getMediaById = (mediaId: string) => {
  return axios.get<IMediaResponse>(MEDIA_URL(mediaId).MEDIA_BY_ID);
};

export const getMediaUploadUrlAndId = (data: IMediaUploadRequestBody) => {
  return axios.post<IMediaResponse>(MEDIA_URL().MEDIA, data);
};

export const getMediaWaterfallRequest = (mediaId: string) => {
  return mediaAxios.get(MEDIA_WATERFALL_URL(mediaId).WATERFALL_URL);
};

export const getMediaByIdRequest = (mediaId: string) => {
  return mediaAxios.get(MEDIA_URL_BY_ID(mediaId).MEDIA_BY_ID);
};

export const getMediaWaterfallThumbnailRequest = (mediaId: string) => {
  return mediaAxios.get(MEDIA_WATERFALL_URL(mediaId).WATERFALL_URL_THUMBNAIL);
};

export const uploadMedia = (body: TMediaUploadBody) => {
  return mediaAxios.post<TMediaUploadResponse>(MEDIA_URL().MEDIA, body);
};

export const getMediaByPublicationId = (
  params: IMediaByPublicationIdParams,
) => {
  return mediaAxios.get<IMediaResponse>(MEDIA_URL().MEDIA, { params });
};
