import {
  Box,
  Typography,
  TypographyPropsVariantOverrides,
} from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { OverridableStringUnion } from '@mui/types';

import Percent from 'components/atoms/percentBlock/Percent';
import { roundNumberToDecimal } from 'environment/utils/roundNumberToDecimal';
import Colors from 'styles/colors.scss';

import styles from './TitleAndProgress.module.scss';

interface IProps {
  additionalText?: string;
  data: number;
  title: string;
  titleVariant?: OverridableStringUnion<
    Variant | 'inherit',
    TypographyPropsVariantOverrides
  >;
  color?: string;
  position?: 'center' | 'flex-start';
  percent?: number | null;
  isPercentValue?: boolean;
  noWrap?: boolean;
  lowText?: boolean;
}

const TitleAndProgress = ({
  additionalText,
  data,
  title,
  color = '',
  position = 'center',
  titleVariant = 'text-sm-light',
  percent,
  isPercentValue = true,
  noWrap = false,
  lowText = false,
}: IProps) => {
  return (
    <Box sx={{ alignItems: position }} className={styles.wrapper}>
      <Box className={styles.titleBlock}>
        {color && (
          <Box className={styles.color} sx={{ backgroundColor: color }} />
        )}
        <Typography
          noWrap={noWrap}
          className={styles.title}
          variant={titleVariant}
        >
          {title}
        </Typography>
      </Box>
      <Box className={styles.statsBlock}>
        <Typography
          textTransform="uppercase"
          variant="text-2xl"
          className={styles.text}
        >
          {roundNumberToDecimal(data)}
        </Typography>
        <Box sx={{ paddingTop: lowText ? '6.5px' : '13.2px' }}>
          {data > 0 && (
            <Percent value={percent ?? 0} isValue={isPercentValue} />
          )}
        </Box>
      </Box>
      <Typography color={Colors['default-gray-300']} variant="text-xs">
        {additionalText}
      </Typography>
    </Box>
  );
};

export default TitleAndProgress;
