import { type Breakpoint, Dialog } from '@mui/material';
import React from 'react';

import styles from './Modal.module.scss';

interface IProps {
  open: boolean;
  children: React.JSX.Element;
  maxWidth?: false | Breakpoint;
  fullWidth?: boolean;
  onClose?: () => void;
}

const Modal = ({ open, children, maxWidth, fullWidth, onClose }: IProps) => {
  return (
    <Dialog
      transitionDuration={300}
      open={open}
      disablePortal
      classes={{ root: styles.backdrop, paper: styles.wrapper }}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      onClose={onClose}
    >
      {children}
    </Dialog>
  );
};

export default Modal;
