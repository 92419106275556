import { type UseFormReturn, useForm } from 'react-hook-form';

import { CONTINUE_SIGN_UP_SCHEMA } from 'environment/validation/resolvers';

import {
  businessDescription,
  businessDetails,
  businessType,
  members,
  questions,
  tags,
} from './default-values';
import { mapDaysToSchedule } from './helpers';

const defaultValues = {
  isFetched: false,
  businessType,
  businessDescription,
  businessDetails: {
    ...businessDetails,
    websiteUrl: '',
    firstName: '',
    lastName: '',
  },
  schedule: mapDaysToSchedule(),
  tags,
  questions,
  members,
  subscription: '',
  cardToken: '',
  receipts: [] as File[],
};

export type SignUpBusinessFormData = typeof defaultValues;

export const useSignUpBusinessForm = () => {
  return useForm({
    defaultValues,
    mode: 'all',
    resolver: CONTINUE_SIGN_UP_SCHEMA,
  }) as unknown as UseFormReturn<SignUpBusinessFormData>;
};
