import { Box, Switch, Typography } from '@mui/material';

import { ToggleLabels } from 'environment/types';

import styles from './Toogle.module.scss';

interface IProps {
  checked: boolean;
  label?: ToggleLabels;
  onChange?: () => void;
}

const Toggle = ({ checked, label = {}, onChange }: IProps) => {
  const renderLabel = (text: string | undefined) =>
    text && (
      <Typography fontWeight={500} variant="text-xs">
        {text}
      </Typography>
    );

  return (
    <Box className={styles.wrapper}>
      {renderLabel(label.inactive)}
      <Switch
        classes={{
          root: styles.root,
          switchBase: styles.switchBase,
          track: styles.track,
          checked: styles.checked,
        }}
        disableRipple
        checked={checked}
        onChange={onChange}
      />
      {renderLabel(label.active)}
    </Box>
  );
};

export default Toggle;
