import { CategoriesOfTags, PartialQuestion } from './api/shared';
import { IBusinessUnit } from './business';

export enum BUSINESS_UNIT_CLAIM_SOURCE {
  from_landing_page = 'from_landing_page',
  from_dashboard_settings = 'from_dashboard_settings',
}

export enum BUSINESS_UNIT_CLAIM_STAGES {
  paymentSet = 'payment_set',
  subscriptionSet = 'subscription_set',
  passwordAndEmailSendingSet = 'password_and_email_sending_set',
  emailVerificationSet = 'email_verification_set',
  scheduleSet = 'schedule_set',
  categorySet = 'category_set',
  labelsSet = 'labels_set',
  mantrasSet = 'mantras_set',
}

export enum BUSINESS_UNIT_CATEGORY_TAG {
  dataNight = 'date-night',
  lightBite = 'light-bite',
}

export interface StartClaimPayload {
  businessUnitId: string;
  businessClaimProgressType: BUSINESS_UNIT_CLAIM_SOURCE;
}

export interface SubscriptionPayload {
  id: string;
  businessClaimProgressType: BUSINESS_UNIT_CLAIM_SOURCE;
  stripeSubscriptionId: string;
}

export interface PaymentPayload {
  id: string;
  businessClaimProgressType: BUSINESS_UNIT_CLAIM_SOURCE;
  cardToken: string;
}

export interface CredetialsPayload {
  id: string;
  businessClaimProgressType: BUSINESS_UNIT_CLAIM_SOURCE;
  email: string;
  password: string;
}

export interface VerifyPayload {
  id: string;
  email: string;
  code: string;
}

export interface CategoryTagPayload {
  categoryTag: BUSINESS_UNIT_CATEGORY_TAG;
  registrationProgressId: string;
  source: BUSINESS_UNIT_CLAIM_SOURCE;
}

export interface TagsPayload {
  labels: CategoriesOfTags;
  registrationProgressId: string;
  source: BUSINESS_UNIT_CLAIM_SOURCE;
}

export interface QuestionsPayload {
  questions: PartialQuestion[];
  registrationProgressId: string;
  source: BUSINESS_UNIT_CLAIM_SOURCE;
}

export interface Question {
  title: string;
  content: string;
}

export interface Claim {
  id: string | null;
  isShadow: boolean;
  isByNolemon: boolean;
  email: string | null;
  email_verified: boolean;
  password: string | null;
  stages: BUSINESS_UNIT_CLAIM_STAGES[];
  categoryTag: BUSINESS_UNIT_CATEGORY_TAG | null;
  businessUnitPickedLabels: CategoriesOfTags;
  businessUnitPickedLabelsId: null;
  businessUnitId: string | null;
  questions: Question[];
  subscription: string | null;
  cardToken: string | null;
  createdAt: Date;
}

export interface ClaimData extends Claim {
  businessUnit: IBusinessUnit | null;
}

export interface ClaimDataResponse extends Claim {
  progress: ClaimData;
  token: string;
}

export interface PromotionStats {
  featuredTopWaterfallPictureUrl: null;
  totalActivity: TotalActivityCount;
  totalComments: TotalEngagement;
  totalLikes: TotalEngagement;
  totalViews: TotalEngagement;
}

export interface TotalActivityCount {
  change: number;
  total: number;
  last7days: Last7Day[];
}

export interface TotalEngagement {
  total_count: string;
}

export interface Last7Day {
  date: Date;
  count: number;
}
