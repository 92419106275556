import { Box, Button } from '@mui/material';
import { FC } from 'react';

import { RANGE_BUTTONS } from 'environment/constants/rangeButtons';
import { Period } from 'types/business-unit-analytics';

import CalendarButton from '../calendarButton/CalendarButton';

import styles from './RangeButtons.module.scss';

interface Props {
  period: Period;
  setPeriod: (period: Period) => void;
}

const RangeButtons: FC<Props> = ({ period, setPeriod }) => {
  return (
    <Box className={styles.container}>
      {RANGE_BUTTONS.map(({ value, label }) => (
        <Button
          className={`${styles.button} ${
            period === value ? styles.active : ''
          }`}
          key={value}
          size="medium"
          variant="outlined"
          onClick={() => setPeriod(value)}
        >
          {label}
        </Button>
      ))}
      <CalendarButton
        period={typeof period !== 'string' ? period : null}
        setPeriod={setPeriod}
      />
    </Box>
  );
};

export default RangeButtons;
