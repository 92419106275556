import { useLocation, useNavigate } from 'react-router-dom';

import { ROUTES } from 'environment/constants/routes';
import { SignUpStages } from 'environment/types/api/auth';

export function useSignUpRoutes() {
  const { pathname } = useLocation();

  if (pathname.startsWith(ROUTES.businessSignUp.home)) {
    return ROUTES.businessSignUp;
  } else {
    return ROUTES.signup;
  }
}

export function useSignUpRedirect() {
  const navigate = useNavigate();
  const signUpRoutes = useSignUpRoutes();

  return (stages: SignUpStages[]) => {
    if (stages.includes('subscription_set')) {
      navigate(signUpRoutes.cardDetails);
    } else if (stages.includes('members_set')) {
      navigate(signUpRoutes.subscription);
    } else if (stages.includes('mantras_set')) {
      navigate(signUpRoutes.members);
    } else if (stages.includes('labels_set')) {
      navigate(signUpRoutes.mantras);
    } else if (stages.includes('schedule_set')) {
      navigate(signUpRoutes.threeTags);
    } else if (stages.includes('details_set')) {
      navigate(signUpRoutes.schedule);
    } else if (stages.includes('category_tag_set')) {
      navigate(signUpRoutes.details);
    } else if (stages.includes('type_set')) {
      navigate(signUpRoutes.description);
    } else if (stages.includes('description_set')) {
      navigate(signUpRoutes.description);
    } else {
      navigate(signUpRoutes.type);
    }
  };
}
