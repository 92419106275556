import { Box } from '@mui/material';
import React from 'react';

import styles from './BusinessPromoteImage.module.scss';

interface BusinessPromoteImageProps {
  blurred?: boolean;
  src: string;
}

const BusinessPromoteImage = ({ src, blurred }: BusinessPromoteImageProps) => {
  return (
    <Box
      component="img"
      src={src}
      className={`${styles.imageBox} ${blurred && styles.blurred}`}
    />
  );
};

export default React.memo(BusinessPromoteImage);
