import { createModel } from '@rematch/core';

import {
  createNewFolderRequest,
  getAllFoldersRequest,
  moveUnitToFolderRequest,
  updateFolderNameRequest,
} from 'environment/api/services/folders';
import { IBusinessUnit } from 'environment/types/business';

import type { RootModel } from '.';

export interface AddUnitToFolderOptions {
  businessUnitId: string;
  folderId: string;
  order: number;
}

export interface IFolder {
  name: string;
  businessId: string;
  businessUnits: IBusinessUnit[];
  childFolders: string[];
  parentFolderId: string | null;
  createdAt: string;
  updatedAt: string;
  id: string;
}

export interface IFolders {
  allFolders: IFolder[];
}

const initialState: IFolders = {
  allFolders: [],
};

export const folders = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setAllFolders: (state, payload: IFolder[]) => {
      return {
        ...state,
        allFolders: payload,
      };
    },
  },
  effects: {
    async getAllFolders() {
      try {
        const { data } = await getAllFoldersRequest();
        this.setAllFolders(data);
      } catch (error) {
        console.log(error);
      }
    },

    async postCreateNewFolder(payload) {
      try {
        const { folderName } = payload;
        await createNewFolderRequest(folderName);
        const { data } = await getAllFoldersRequest();
        this.setAllFolders(data);
      } catch (error) {
        console.log(error);
      }
    },

    async updateFolderName(payload) {
      try {
        const { folderId, folderName } = payload;
        await updateFolderNameRequest(folderId, folderName);
        const { data } = await getAllFoldersRequest();
        this.setAllFolders(data);
      } catch (error) {
        console.log(error);
      }
    },

    async addUnitToFolder(payload: AddUnitToFolderOptions) {
      try {
        const { businessUnitId, folderId, order } = payload;
        await moveUnitToFolderRequest(businessUnitId, folderId, order);
        const { data } = await getAllFoldersRequest();
        this.setAllFolders(data);
      } catch (error) {
        console.log(error);
      }
    },
  },
});
