import { REVIEW_REPLY_URL } from 'environment/constants/url';
import { TReviewReplyRequestBody } from 'store/models/reviewReplies';

import axios from '../axios';

export const replyOnReview = (
  businessUnitId: string,
  requestBody: TReviewReplyRequestBody,
) => {
  return axios.post(REVIEW_REPLY_URL(businessUnitId).REVIEW_REPLY, requestBody);
};

export const getReviewReplies = (reviewId: string) => {
  const owner = '&owner=review';
  const url = `${REVIEW_REPLY_URL(reviewId).REVIEW_REPLIES_LIST}${owner}`;

  return axios.get(url);
};
