import { Box } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import loaderSrc from 'assets/loader.gif';

import styles from './LoaderOverlay2.module.scss';

interface Props {
  loading: boolean;
}

// GET RID OF THE FIRST ONE, USE THIS INSTEAD
// todo: REFACTOR AND REMOVE THE FIRST ONE
const LoaderOverlay2: FC<PropsWithChildren<Props>> = ({
  children,
  loading,
}) => {
  const { t } = useTranslation();

  if (loading) {
    return (
      <Box className={styles.container}>
        <Box className={styles.overlay}>
          <img src={loaderSrc} alt={t('Loading...')} />
        </Box>

        {children}
      </Box>
    );
  }

  return <>{children}</>;
};

export default LoaderOverlay2;
