import { DevTool } from '@hookform/devtools';
import { useEffect, useState } from 'react';
import { FormProvider, useWatch } from 'react-hook-form';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';

import { LoaderOverlay } from 'components/atoms';
import { signUpBusinessProgress } from 'environment/api/services/auth';
import { useSignUpBusinessForm } from 'hooks/forms';
import {
  castBusinessTypeForFrontend,
  castQuestionsForFrontend,
  castScheduleForFrontend,
  castSignUpMembersForFrontend,
  castTagsForFrontend,
} from 'hooks/forms/helpers';
import { useSignUpRedirect, useSignUpRoutes } from 'hooks/signUpNavigation';

const BusinessProvider = () => {
  const { control, setValue, ...form } = useSignUpBusinessForm();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isFetched = useWatch({ control, name: 'isFetched' });

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const signUpRoutes = useSignUpRoutes();
  const signUpRedirect = useSignUpRedirect();

  useEffect(() => {
    if (isFetched) {
      return;
    }

    setIsLoading(true);

    signUpBusinessProgress()
      .then(({ data }) => {
        signUpRedirect(data.stages);

        if (data.operationHours.length > 0) {
          setValue('schedule', castScheduleForFrontend(data.operationHours));
        }

        if (data.businessUnitPickedLabels) {
          setValue('tags', castTagsForFrontend(data.businessUnitPickedLabels));
        }

        if (data.type) {
          setValue('businessType', castBusinessTypeForFrontend(data.type));
        }

        if (data.questions?.length > 0) {
          setValue('questions', castQuestionsForFrontend(data.questions));
        }

        if (data.businessMembers?.length > 0) {
          setValue(
            'members',
            castSignUpMembersForFrontend(data.businessMembers),
          );
        }

        if (data.location) {
          setValue('businessDetails', {
            firstName: data.firstName || '',
            lastName: data.lastName || '',
            websiteUrl: data.websiteUrl || '',
            companyName: data.companyName || '',
            phone: data.phoneNumber || '',
            country: data.location.country || '',
            city: data.location.city || '',
            state: data.location.state || '',
            zipcode: data.location.zipcode || '',
            street: data.location.street || '',
            number: data.location.number || '',
          });
        }

        setValue('isFetched', true);
      })
      .catch(() => {
        localStorage.removeItem('access_token');
        navigate(signUpRoutes.home);
      })
      .finally(() => setIsLoading(false));
  }, [
    navigate,
    setValue,
    isFetched,
    searchParams,
    signUpRedirect,
    signUpRoutes.home,
  ]);

  return (
    <FormProvider control={control} setValue={setValue} {...form}>
      {isLoading && <LoaderOverlay />}
      <Outlet />
      <DevTool control={control} />
    </FormProvider>
  );
};

export default BusinessProvider;
