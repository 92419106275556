import { Box } from '@mui/material';
import { type FC } from 'react';

import emoji from 'styles/emoji-sprites/css-sprites.module.css';

import styles from './EmojiIcon.module.scss';

const SPRITE_SIZE = 72;

interface IWrapperProps {
  size: number;
  name: string | string[];
}

interface IProps {
  size: number;
  name: string;
}

const EmojiIconWrapper: FC<IWrapperProps> = ({ size, name }) => {
  if (Array.isArray(name)) {
    return (
      <Box display="flex">
        {name.map((n) => (
          <EmojiIcon key={n} size={size} name={n} />
        ))}
      </Box>
    );
  }

  return <EmojiIcon size={size} name={name} />;
};

const EmojiIcon: FC<IProps> = ({ size, name }) => {
  return (
    <Box
      className={styles.container}
      style={{
        width: `${size}px`,
        height: `${size}px`,
      }}
    >
      <Box
        style={{
          transform: `scale(${(size / SPRITE_SIZE) * 100}%)`,
        }}
        className={`${emoji[name]} ${styles.icon}`}
      ></Box>
    </Box>
  );
};

export default EmojiIconWrapper;
