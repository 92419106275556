import { Box, Button, Slider, Typography } from '@mui/material';
import axios from 'axios';
import { ChangeEventHandler, FC, useRef, useState } from 'react';
import Cropper, { Area } from 'react-easy-crop';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as CameraIcon } from 'assets/icons/camera.svg';
import { LoaderOverlay, ServerErrorMessage } from 'components/atoms';
import { SettingsModal } from 'components/molecules';
import { getCroppedImageBlob } from 'environment/utils/getCroppedImageBlob';
import { Dispatch, RootState } from 'store';
import Colors from 'styles/colors.scss';

import BusinessUnitAvatar from './BusinessUnitAvatar';
import styles from './BusinessUnitAvatarHandler.module.scss';

const ZOOM_STEP = 0.1;
const MIN_ZOOM = 1;
const MAX_ZOOM = 3;

interface Props {
  ownerId: string;
  mediaId?: string;
}

const BusinessUnitAvatarHandler: FC<Props> = ({ ownerId, mediaId }) => {
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  const [imageSrc, setImageSrc] = useState<string>('');

  const [modalOpen, setModalOpen] = useState(false);

  const [zoom, setZoom] = useState(MIN_ZOOM);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });

  const handleCropComplete = (croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const dispatch = useDispatch<Dispatch>();

  const [error, setError] = useState('');

  const isUploading = useSelector(
    (state: RootState) => state.loading.effects.units.setBusinessUnitAvatar,
  );

  const handleClose = () => {
    setModalOpen(false);
    URL.revokeObjectURL(imageSrc);
  };

  const openFileExplorer = () => {
    inputRef.current?.click();
  };

  const handleDeleteAvatar = () => {
    dispatch.units.deleteBusinessUnitAvatar(ownerId);
  };

  const handleChosenFile: ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = e.target.files?.[0];

    if (!file) {
      return;
    }

    const src = URL.createObjectURL(file);
    setImageSrc(src);

    setModalOpen(true);

    if (inputRef.current) {
      inputRef.current.type = 'text';
      inputRef.current.type = 'file';
    }
  };

  const handleSendPhoto = async () => {
    try {
      const blob = await getCroppedImageBlob(imageSrc, croppedAreaPixels);

      if (!blob || !blob.file) {
        return;
      }

      const { file, type } = blob;

      await dispatch.units.setBusinessUnitAvatar({ ownerId, file, type });
      setModalOpen(false);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data.message) {
        setError(error.response?.data.message);
      } else {
        setError(
          'An unknown error occured while uploading the logo. Please try again later',
        );
      }

      console.error(error);
    }
  };

  return (
    <>
      {mediaId ? (
        <BusinessUnitAvatar
          mediaId={mediaId}
          onFileClick={openFileExplorer}
          onDeleteClick={handleDeleteAvatar}
        />
      ) : (
        <Box
          className={styles.avatarButton}
          component="button"
          onClick={openFileExplorer}
        >
          <CameraIcon />
        </Box>
      )}
      <input
        ref={inputRef}
        style={{ display: 'none' }}
        type="file"
        accept="image/*"
        onChange={handleChosenFile}
      />
      <SettingsModal
        open={modalOpen}
        onClose={handleClose}
        containerBoxProps={{
          paddingX: '56px',
          paddingY: '24px',
          maxWidth: '462px',
        }}
      >
        {isUploading && <LoaderOverlay />}
        <Box position="relative" className={styles.avatar}>
          <Cropper
            classes={{
              containerClassName: styles.avatar,
              cropAreaClassName: styles.cropArea,
            }}
            showGrid={false}
            image={imageSrc}
            crop={crop}
            aspect={1}
            zoom={zoom}
            onCropChange={setCrop}
            onCropComplete={(_, croppedAreaPixels) =>
              handleCropComplete(croppedAreaPixels)
            }
          />
        </Box>
        <Box display="flex" alignItems="center" gap="20px">
          <Box
            component="button"
            onClick={() => {
              if (zoom > MIN_ZOOM) {
                setZoom((z) => z - ZOOM_STEP);
              }
            }}
          >
            <Typography>-</Typography>
          </Box>
          <Slider
            min={MIN_ZOOM}
            step={ZOOM_STEP}
            max={MAX_ZOOM}
            onChange={(_, value) => {
              if (Array.isArray(value)) return;
              setZoom(value);
            }}
            value={zoom}
            classes={{
              rail: styles.rail,
              root: styles.sliderRoot,
              thumb: styles.thumb,
            }}
          />
          <Box
            component="button"
            onClick={() => {
              if (zoom < MAX_ZOOM) {
                setZoom((z) => z + ZOOM_STEP);
              }
            }}
          >
            <Typography>+</Typography>
          </Box>
        </Box>

        {error && <ServerErrorMessage message={error} />}

        <Box className={styles.buttonsBlock}>
          <Button variant="contained" onClick={handleSendPhoto}>
            <Typography variant="text-sm" color={Colors['bg-color']}>
              {t('Set.photo')}
            </Typography>
          </Button>
          <Box component="button" onClick={handleClose}>
            <Typography variant="text-sm" color={Colors.HEXB8FE61}>
              {t('Cancel')}
            </Typography>
          </Box>
        </Box>
      </SettingsModal>
    </>
  );
};

export default BusinessUnitAvatarHandler;
