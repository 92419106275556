import { Box, Button, Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import Colors from 'styles/colors.scss';

import styles from './GradientOutlinedButton.module.scss';

interface IProps {
  onClick?: () => void;
}

const GradientOutlinedButton: FC<PropsWithChildren<IProps>> = ({
  children,
  onClick,
}) => {
  return (
    <Box className={styles.container}>
      <Button fullWidth className={styles.button} onClick={onClick}>
        <Typography variant="text-base-light" color={Colors['HEXB8FE61']}>
          {children}
        </Typography>
      </Button>
    </Box>
  );
};

export default GradientOutlinedButton;
