import { Box, Typography } from '@mui/material';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { BusinessTypeForm } from 'components/organisms';
import Colors from 'styles/colors.scss';

import styles from './SignUpBusinessType.module.scss';

interface IProps {
  onSubmit: () => void;
}

const SignUpBusinessTypeTemplate: FC<IProps> = ({ onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Box className={styles.container}>
      <Box className={styles.header}>
        <Typography variant="text-2xl">
          {t('What.is.your.business.type?')}
        </Typography>
        <Typography
          textAlign="center"
          variant="text-base-light"
          color={Colors['default-gray-200']}
        >
          {t(
            'For.food.trucks.with.more.than.1.location.select.franchise/multiple.locations',
          )}
        </Typography>
      </Box>
      <BusinessTypeForm onSubmit={onSubmit} />
    </Box>
  );
};

export default SignUpBusinessTypeTemplate;
