import { BoostsTemplate } from 'components/templates';
import {
  currentRestaurant,
  nearbyRestaurant,
  speedometerData,
} from 'environment/configs/mockData';

const Boosts = () => {
  return (
    <BoostsTemplate
      currentRestaurant={currentRestaurant}
      nearbyRestaurant={nearbyRestaurant}
      speedometerData={speedometerData}
    />
  );
};

export default Boosts;
