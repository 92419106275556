export enum EngagementTypes {
  Review = 'review',
  Like = 'like',
  Comment = 'comment',
  Share = 'share',
  Watch = 'watch',
  PageVisit = 'pageVisit',
  ForumTag = 'forumTag',
  StoryTag = 'storyTag',
}
