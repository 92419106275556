import { Box, Divider, Typography } from '@mui/material';
import { type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FilledCheckbox } from 'components/atoms';
import { MemberInfo } from 'components/molecules';
import Colors from 'styles/colors.scss';

import styles from './AddMembersForm.module.scss';

interface IProps {
  companyName?: string;
}

const AddMembersForm: FC<IProps> = ({ companyName }) => {
  const { t } = useTranslation();

  const { control, getValues } = useFormContext();

  const businessName = companyName || getValues('businessDetails.companyName');

  return (
    <Box className={styles.container}>
      <Box display="flex" flexDirection="column" gap="24px">
        <Typography variant="text-base-semibold">
          {t('Invite.members.of.your.organisation.to.your.account')}
        </Typography>
        <MemberInfo
          businessName={businessName}
          fullNameFieldName="member.fullName"
          emailFieldName="member.email"
        />
      </Box>

      <Divider orientation="horizontal" sx={{ marginY: '24px' }} />

      <Box className={styles.permissionsContainer}>
        <Typography variant="text-xl">{t('Set.permissions')}</Typography>
        <Box className={styles.permissions}>
          <Controller
            name="member.permission"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <>
                <FilledCheckbox
                  checked={value === 'VRO'}
                  onChange={
                    value === 'VRO' ? () => onChange('') : () => onChange('VRO')
                  }
                  label={
                    <Typography
                      variant="text-base-light"
                      color={Colors['default-gray-100']}
                    >
                      {t('View.and.reply.only')}{' '}
                      <Typography
                        variant="inherit"
                        component="span"
                        color={Colors['default-gray-300']}
                      >
                        ({t('VRO')})
                      </Typography>
                    </Typography>
                  }
                />
                <FilledCheckbox
                  checked={value === 'FA'}
                  onChange={
                    value === 'FA' ? () => onChange('') : () => onChange('FA')
                  }
                  label={
                    <Typography
                      variant="text-base-light"
                      color={Colors['default-gray-100']}
                    >
                      {`${t('Full.Access.to')} ${businessName}`}{' '}
                      <Typography
                        variant="inherit"
                        component="span"
                        color={Colors['default-gray-300']}
                      >
                        ({t('FA')})
                      </Typography>
                    </Typography>
                  }
                />
                <FilledCheckbox
                  checked={value === 'ADMIN'}
                  onChange={
                    value === 'ADMIN'
                      ? () => onChange('')
                      : () => onChange('ADMIN')
                  }
                  label={
                    <Typography
                      variant="text-base-light"
                      color={Colors['default-gray-100']}
                    >
                      {t('Access.to.all.businesses')}{' '}
                      <Typography
                        variant="inherit"
                        component="span"
                        color={Colors['default-gray-300']}
                      >
                        ({t('Admin')})
                      </Typography>
                    </Typography>
                  }
                />

                {error && (
                  <Typography
                    variant="text-sm-lighter"
                    color={Colors['profile-red']}
                  >
                    {error.message}
                  </Typography>
                )}
              </>
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AddMembersForm;
