import {
  ENGAGEMENT_COMMENT_URL,
  ENGAGEMENT_FORUM_TAG_URL,
  ENGAGEMENT_LIKE_URL,
  ENGAGEMENT_PAGE_VISIT_URL,
  ENGAGEMENT_REVIEW_URL,
  ENGAGEMENT_SHARE_URL,
  ENGAGEMENT_VIEW_URL,
  ENGAGEMENT_WATERFALL_TAG_URL,
} from 'environment/constants/url';

import axios from '../axios';

// review

export const getEngagementReviewForDayRequest = (businessUnitId: string) => {
  return axios.get(ENGAGEMENT_REVIEW_URL(businessUnitId).REVIEW_DAY);
};

export const getEngagementReviewForMonthRequest = (
  businessUnitId: string,
  months: string,
) => {
  return axios.get(ENGAGEMENT_REVIEW_URL(businessUnitId, months).REVIEW_MONTH);
};

export const getAllEngagementReviews = (
  businessUnitId: string,
  take: number,
  scoreSort?: 'asc' | 'desc',
  scoreFilter?: number,
  cursorId?: string,
) => {
  const scoreSortQuery = scoreSort ? `scoreSort=${scoreSort}` : '';
  const scoreFilterQuery = scoreFilter ? `&scoreFilter=${scoreFilter}` : '';
  const cursorIdQuery = cursorId ? `&cursorId=${cursorId}&` : '';

  const url = `${
    ENGAGEMENT_REVIEW_URL(businessUnitId).ALL_REVIEWS
  }?${scoreSortQuery}${scoreFilterQuery}${cursorIdQuery}take=${take}`;

  return axios.get(url);
};

// share

export const getEngagementShareForDayRequest = (businessUnitId: string) => {
  return axios.get(ENGAGEMENT_SHARE_URL(businessUnitId).SHARE_DAY);
};

export const getEngagementShareForMonthRequest = (
  businessUnitId: string,
  months: string,
) => {
  return axios.get(ENGAGEMENT_SHARE_URL(businessUnitId, months).SHARE_MONTH);
};

// comment

export const getEngagementCommentForDayRequest = (businessUnitId: string) => {
  return axios.get(ENGAGEMENT_COMMENT_URL(businessUnitId).COMMENT_DAY);
};

export const getEngagementCommentForMonthRequest = (
  businessUnitId: string,
  months: string,
) => {
  return axios.get(
    ENGAGEMENT_COMMENT_URL(businessUnitId, months).COMMENT_MONTH,
  );
};

// like

export const getEngagementLikeForDayRequest = (businessUnitId: string) => {
  return axios.get(ENGAGEMENT_LIKE_URL(businessUnitId).LIKE_DAY);
};

export const getEngagementLikeForMonthRequest = (
  businessUnitId: string,
  months: string,
) => {
  return axios.get(ENGAGEMENT_LIKE_URL(businessUnitId, months).LIKE_MONTH);
};

// forum tag

export const getEngagementForumTagForDayRequest = (businessUnitId: string) => {
  return axios.get(ENGAGEMENT_FORUM_TAG_URL(businessUnitId).TAG_DAY);
};

export const getEngagementForumTagForMonthRequest = (
  businessUnitId: string,
  months: string,
) => {
  return axios.get(ENGAGEMENT_FORUM_TAG_URL(businessUnitId, months).TAG_MONTH);
};

// waterfall tag

export const getEngagementWaterfallTagForDayRequest = (
  businessUnitId: string,
) => {
  return axios.get(ENGAGEMENT_WATERFALL_TAG_URL(businessUnitId).TAG_DAY);
};

export const getEngagementWaterfallTagForMonthRequest = (
  businessUnitId: string,
  months: string,
) => {
  return axios.get(
    ENGAGEMENT_WATERFALL_TAG_URL(businessUnitId, months).TAG_MONTH,
  );
};

// view

export const getEngagementViewForDayRequest = (businessUnitId: string) => {
  return axios.get(ENGAGEMENT_VIEW_URL(businessUnitId).VIEW_DAY);
};

export const getEngagementViewForMonthRequest = (
  businessUnitId: string,
  months: string,
) => {
  return axios.get(ENGAGEMENT_VIEW_URL(businessUnitId, months).VIEW_MONTH);
};

// page visit

export const getEngagementPageVisitByDateRequest = (
  businessUnitId: string,
  startDate: string,
  endDate: string,
) => {
  return axios.get(
    ENGAGEMENT_PAGE_VISIT_URL(businessUnitId, startDate, endDate)
      .PAGE_VISIT_BY_DATE,
  );
};

export const getEngagementPageVisitByAgeRequest = (businessUnitId: string) => {
  return axios.get(ENGAGEMENT_PAGE_VISIT_URL(businessUnitId).PAGE_VISIT_BY_AGE);
};

export const getEngagementPageVisitUserStatusRequest = (
  businessUnitId: string,
) => {
  return axios.get(
    ENGAGEMENT_PAGE_VISIT_URL(businessUnitId).PAGE_VISIT_USER_STATUS,
  );
};
