import { MuiOtpInput } from 'mui-one-time-password-input';
import { FC, useState } from 'react';

import styles from './Otp.module.scss';

interface IProps {
  length: number;
  onComplete?: (value: string) => void;
}

const Otp: FC<IProps> = ({ length, onComplete }) => {
  const [value, setValue] = useState('');

  const handleChange = (value: string) => setValue(value);

  return (
    <MuiOtpInput
      className={styles.container}
      sx={{ border: 'none', '& fieldset': { border: 'none' } }}
      TextFieldsProps={{
        className: styles.field,
        InputProps: { slotProps: { input: { className: styles.input } } },
      }}
      length={length}
      value={value}
      onChange={handleChange}
      onComplete={onComplete}
    />
  );
};

export default Otp;
