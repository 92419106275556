import { Box } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { LoaderOverlay } from 'components/atoms';
import { ROUTES } from 'environment/constants/routes';
import { BUSINESS_UNIT_CLAIM_SOURCE } from 'environment/types/claim';
import { RootState } from 'store';
import Colors from 'styles/colors.scss';

const OnboaringLayout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { businessUnit, onboardingType, loading } = useSelector(
    (state: RootState) => state.onboarding,
  );

  const startPage = useMemo(
    () => pathname.includes(ROUTES.onboarding.getSarted),
    [pathname],
  );

  useEffect(() => {
    if (!businessUnit) {
      if (!localStorage.getItem('access_token')) {
        if (!startPage) {
          navigate(ROUTES.onboarding.claimBusiness);
        }
        return;
      }

      if (
        onboardingType === BUSINESS_UNIT_CLAIM_SOURCE.from_dashboard_settings
      ) {
        navigate(ROUTES.onboarding.claimBusiness);
        return;
      }

      navigate(ROUTES.dashboard.home);
    }
  }, [businessUnit, pathname]);

  return (
    <>
      {loading && <LoaderOverlay />}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        bgcolor={Colors.bg}
      >
        <Outlet />
      </Box>
    </>
  );
};

export default React.memo(OnboaringLayout);
