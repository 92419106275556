import { Box, Button, IconButton, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  HeaderModal,
  Profile,
  RollScore,
  TextLogo,
  Textarea,
} from 'components/atoms';
import EmojiPicker from 'components/atoms/emojiPicker/EmojiPicker';
import IconMark from 'components/atoms/icon/IconMark';
import {
  AddReplyForm,
  AddReplyFormSubmitter,
} from 'components/organisms/tables/recentActivityReviewsTable/hooks/useAddReplyForm';
import { getRatingColor } from 'environment/utils/getRatingColor';
import useUnits from 'hooks/useUnits';
import Colors from 'styles/colors.scss';
import {
  IReviewMediaDataProcessed,
  IReviewReply,
  IReviewUserProcessed,
} from 'types';

import { MediaTypes } from '../../../environment/constants';
import ReceiptsList from '../receiptsList/ReceiptsList';

import styles from './AddReply.module.scss';

interface IProps {
  text: string;
  textLiked: string;
  textDisLiked: string;
  mediaData: IReviewMediaDataProcessed[] | null;
  user: IReviewUserProcessed;
  rollScore: number;
  reviewReplies?: IReviewReply[];
  onSubmit: AddReplyFormSubmitter;
  onClose: () => void;
}

const AddReply = ({
  text,
  textLiked,
  textDisLiked,
  mediaData,
  user,
  rollScore,
  reviewReplies,
  onSubmit,
  onClose,
}: IProps) => {
  const { t } = useTranslation();

  const { currentUnit } = useUnits();

  const { control, getValues, setValue, trigger } =
    useFormContext() as AddReplyForm;

  const handleEmojiSelect = ({ native }: { native: string }) => {
    const reply = getValues('reply') as string;
    setValue('reply', reply + native);
    trigger();
  };

  const media = mediaData?.at(0);

  const renderReplies = () => {
    if (!reviewReplies) {
      return;
    }
    return (
      <>
        {reviewReplies.map((reply) => {
          return (
            <Box
              key={reply.id}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '30px',
                marginBottom: '30px',
              }}
            >
              <TextLogo
                text={
                  currentUnit.business.title
                    ? currentUnit.business.title.slice(0, 1)
                    : 'Nolemon Nomelon'
                } //TODO logo businessunit
                dark
                size="medium"
              />
              <Box
                sx={{
                  marginLeft: '10px',
                }}
              >
                <Box
                  sx={{
                    marginBottom: '10px',
                  }}
                >
                  {/* TODO: color of rating businessunit when ready on backend*/}
                  <Typography
                    color={
                      (currentUnit.rating &&
                        getRatingColor(currentUnit.rating)) as string
                    }
                    noWrap
                    variant="text-sm-light"
                    className={styles.comment}
                  >
                    {currentUnit.business.title && ''}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    className={styles.comment}
                    variant="text-base-light"
                  >
                    {reply.text}
                  </Typography>
                </Box>
              </Box>
            </Box>
          );
        })}
      </>
    );
  };

  return (
    <>
      <Box className={styles.headerBlock}>
        <HeaderModal title={t('Answer')} onClose={onClose} />
        <Box className={styles.commentBlock}>
          {media &&
            (media.type === MediaTypes.Photo ? (
              <img className={styles.media} src={media.url} alt="Media" />
            ) : (
              <video className={styles.media} src={media.url} />
            ))}

          <Box sx={{ flex: 1 }}>
            <Box className={styles.profileBlock}>
              <Profile
                hideProfession
                hideUsername
                value={user}
                imageSize={48}
              />
              <RollScore score={rollScore} size="small" />
            </Box>
            <Box sx={{ maxHeight: '96px', overflow: 'auto' }}>
              <Typography className={styles.comment} variant="text-base-light">
                {text}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: 2,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: 'gray',
                  flex: 2,
                  alignSelf: 'flex-start',
                  maxWidth: '51px',
                }}
              >
                {t('like.it')} :
              </Typography>

              <Typography
                className={styles.comment}
                variant="body2"
                sx={{ flex: 10, fontWeight: 300 }}
              >
                {textLiked}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: 2,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: 'gray',
                  flex: 2,
                  alignSelf: 'flex-start',
                  minWidth: '90px',
                }}
              >
                {t('dislike.it')} :
              </Typography>

              <Typography
                className={styles.comment}
                variant="body2"
                sx={{ flex: 10, fontWeight: 300 }}
              >
                {textDisLiked}
              </Typography>
            </Box>
          </Box>
        </Box>
        {reviewReplies && renderReplies()}
      </Box>
      <Box className={styles.main}>
        <Box
          component="form"
          onSubmit={control.handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <Box className={styles.formContent}>
            <Box className={styles.images}>
              <ReceiptsList fieldName="images" imageSize={80} />
            </Box>
            <Box className={styles.addReplyBlock}>
              <TextLogo
                text={
                  currentUnit.business.title
                    ? currentUnit.business.title.slice(0, 1)
                    : 'Nolemon Nomelon'
                } //TODO logo businessunit
                dark
                size="medium"
              />
              <Textarea
                placeholder={t('Add.your.reply!')}
                name="reply"
                fullWidth
                rows={5}
              />
            </Box>
          </Box>

          <Box className={styles.footer}>
            <Box className={styles.iconsContainer}>
              {/* <FileInput fieldName="images" accept="image" multiple>
                <IconButton>
                  <IconMark name="gallery" color={Colors['default-gray-200']} />
                </IconButton>
              </FileInput> */}
              {/* <IconButton>
                <IconMark name="gif" color={Colors['default-gray-200']} />
              </IconButton> */}
              <EmojiPicker onEmojiSelect={handleEmojiSelect}>
                <IconButton>
                  <IconMark name="emoji" color={Colors['default-gray-200']} />
                </IconButton>
              </EmojiPicker>
            </Box>
            <Button
              size="large"
              variant="contained"
              className={styles.button}
              type="submit"
            >
              {t('Reply')}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddReply;
