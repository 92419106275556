import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { SignUpPersonalizeThreeTagsTemplate } from 'components/templates';
import { useSignUpRoutes } from 'hooks/signUpNavigation';
import { useLockBodyScroll } from 'hooks/useLockBodyScroll';

const SignUpPersonalizeTags = () => {
  const navigate = useNavigate();

  useLockBodyScroll();

  const signUpRoutes = useSignUpRoutes();

  const onSubmit = () => {
    navigate(signUpRoutes.fifteenTags);
  };

  return (
    <Box sx={{ width: '836px' }}>
      <SignUpPersonalizeThreeTagsTemplate onSubmit={onSubmit} />
    </Box>
  );
};

export default SignUpPersonalizeTags;
