import moment from 'moment';

import { IDayDataItem } from 'store/models/engagement';

export function countDataInLast30Days(
  data: IDayDataItem[],
): [string, number][] {
  const dayCount: Record<string, number> = {};

  data.forEach((item) => {
    const createdAt = moment.utc(item.createdAt);
    const formattedDay = createdAt.format('MM-DD');

    if (dayCount[formattedDay]) {
      dayCount[formattedDay]++;
    } else {
      dayCount[formattedDay] = 1;
    }
  });

  const result: [string, number][] = [];

  const now = moment();
  const startDate = now.clone().subtract(29, 'days');

  while (startDate.isSameOrBefore(now)) {
    const formattedDay = startDate.format('MM-DD');
    const count = dayCount[formattedDay] || 0;
    result.push([formattedDay, count]);
    startDate.add(1, 'day');
  }

  return result;
}
