import { Box, Typography } from '@mui/material';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DashboardCardWrapper } from 'components/atoms';
import { BarChart } from 'components/molecules';
import { ROUTES } from 'environment/constants/routes';

import { categories, mockData, mockData2 } from './constants';
import styles from './ExpendituresCard.module.scss';

const ExpendituresCard = () => {
  const { t } = useTranslation();

  const renderExpendituresByCategories = useMemo(() => {
    return Object.entries(mockData2).map(([key, value]) => {
      type KeyType = keyof typeof mockData;
      return (
        <Box className={styles.expendituresByCategoriesWrapper} key={key}>
          <Typography variant="text-xs">
            {categories[key as KeyType]}
          </Typography>
          <Typography variant="text-xs">${value.toFixed(2)}</Typography>
        </Box>
      );
    });
  }, []);

  // todo add checking during api connect
  const isData = false;

  return (
    <DashboardCardWrapper
      title={t('Expenditures')}
      navigateTo={ROUTES.dashboard.expenditures}
    >
      <Box className={styles.chartBlock}>
        <Box className={styles.barChartContainer}>
          {isData ? (
            <BarChart
              data={Object.values(mockData)}
              categories={Object.values(categories)}
            />
          ) : (
            <BarChart
              isEmpty={true}
              data={Object.values(mockData)}
              categories={Object.values(categories)}
            />
          )}
        </Box>
        <Box className={styles.expendituresWrapper}>
          <Typography variant="text-base-light">{t('Total')}</Typography>
          <Typography
            component="p"
            fontWeight={700}
            variant="text-base-semibold"
          >
            0
          </Typography>
          {renderExpendituresByCategories}
        </Box>
      </Box>
    </DashboardCardWrapper>
  );
};

export default memo(ExpendituresCard);
