import moment from 'moment';

import { IDayDataItem } from 'store/models/engagement';

export function countDataInWeekRange(data: IDayDataItem[]): [string, number][] {
  const dayCount: Record<string, number> = {};

  data.forEach((item) => {
    const createdAt = moment.utc(item.createdAt);
    const formattedDay = createdAt.format('dddd');

    if (dayCount[formattedDay]) {
      dayCount[formattedDay]++;
    } else {
      dayCount[formattedDay] = 1;
    }
  });

  const result: [string, number][] = [];

  for (let i = 6; i >= 0; i--) {
    const formattedDay = moment().subtract(i, 'days').format('dddd');
    const count = dayCount[formattedDay] || 0;
    result.push([formattedDay, count]);
  }

  return result;
}
