import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { daysNames } from 'environment/constants/dayNames';
import { DayName } from 'environment/types';

export function useMondayToFridayChange(
  toFrom: 'to' | 'from',
  currDay: DayName,
) {
  const {
    formState: { dirtyFields },
    setValue,
  } = useFormContext();

  const [isMondayPopoverDirty, setMondayPopoverDirty] = useState(false);

  for (let i = 1; i < daysNames.length - 2; i++) {
    if ('schedule' in dirtyFields) {
      const schedule = dirtyFields['schedule'];
      const dayName = daysNames[i];

      if (dayName in schedule) {
        if (schedule[dayName]?.[toFrom]) {
          return {
            performMondayToFridayChange: undefined,
            handlePopoverClose: undefined,
          };
        }
      }
    }
  }

  const handlePopoverClose =
    currDay === 'monday' ? () => setMondayPopoverDirty(true) : undefined;

  const performMondayToFridayChange = (newValue: string) => {
    if (currDay === 'monday' && !isMondayPopoverDirty) {
      for (let i = 1; i < daysNames.length - 2; i++) {
        setValue(`schedule.${daysNames[i]}.${toFrom}`, newValue);
      }
    }
  };

  return { performMondayToFridayChange, handlePopoverClose };
}

export function convertTo24Hours(
  value: number,
  mode: 'AM' | 'PM',
  pure24?: boolean,
) {
  if (!pure24 && value === 12 && mode === 'AM') {
    return 0;
  }

  if (!pure24 && value === 12 && mode === 'PM') {
    return 12;
  }

  if (mode === 'PM') {
    return value + 12;
  }

  return value;
}

export function get12Hours(value: number) {
  if (value === 0 || value === 12) {
    return 12;
  }

  return value % 12;
}

export function getMode(value: number): 'AM' | 'PM' {
  if (value < 12) {
    return 'AM';
  }

  return 'PM';
}
