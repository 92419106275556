import { Box, Skeleton, Typography } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useDispatch, useSelector } from 'react-redux';

import { DashboardCardWrapper } from 'components/atoms';
import { ViewOnlyColumn } from 'components/molecules';
import { basicAreaData } from 'environment/configs/mockData';
import { ROUTES } from 'environment/constants/routes';
import { countActionsByTime } from 'environment/utils/countActionByTime';
import { getRatingColor } from 'environment/utils/getRatingColot';
import { roundNumberToDecimal } from 'environment/utils/roundNumberToDecimal';
import { Dispatch, RootState } from 'store';
import { RecentActivityItem } from 'store/models/dashboard';
import Colors from 'styles/colors.scss';

import { ConnectedUserAvatar } from '..';

import {
  RecentActivityEngagementTypeKeywordMap,
  UnknownKeyword,
} from './constants';
import styles from './RecentActivityCard.module.scss';

interface IProps {
  activityData: RecentActivityItem[];
  allActivity: number;
}

const RecentActivityCard = ({ activityData, allActivity }: IProps) => {
  const { t } = useTranslation();

  return (
    <DashboardCardWrapper
      isRecentActivity={true}
      title={t('Recent.activity')}
      navigateTo={ROUTES.dashboard.recentAcivity}
    >
      <Box display="flex" flexDirection="column" gap="4px">
        <Typography className={styles.statsTitle} variant="text-base-light">
          {t('Users.reached')}
        </Typography>
        <Typography textTransform="uppercase" variant="extra-large">
          {roundNumberToDecimal(allActivity)}
        </Typography>
      </Box>
      <Box>
        <ViewOnlyColumn data={basicAreaData} />
      </Box>
      {allActivity === 0 ? (
        <Box className={styles.emptyBlock}>
          <Typography
            variant="text-base-semibold"
            color={Colors['default-gray-200']}
          >
            {t('No.activity.yet')}
          </Typography>
        </Box>
      ) : (
        <RecentActivityList activityData={activityData} />
      )}
    </DashboardCardWrapper>
  );
};

const RecentActivityList = ({ activityData }: Pick<IProps, 'activityData'>) => {
  const { t } = useTranslation();

  const dispatch = useDispatch<Dispatch>();

  const hasNextPage = useSelector(
    (state: RootState) => state.dashboard.recentActivity.hasNextPage,
  );

  const loading = useSelector(
    (state: RootState) =>
      state.loading.effects.dashboard.getMoreDashboardRecentActivity,
  );

  const getActionText = (activity: RecentActivityItem): string => {
    const text =
      RecentActivityEngagementTypeKeywordMap[activity.engagementType];
    if (!text) {
      return UnknownKeyword;
    }

    if (!activity.owner) {
      return text;
    }

    if (activity.ownerMediaType) {
      return `${text}_${activity.owner}_${activity.ownerMediaType}`;
    }

    return `${text}_${activity.owner}`;
  };

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: dispatch.dashboard.getMoreDashboardRecentActivity,
    rootMargin: '0px 0px 20px 0px',
  });

  return (
    <Box ref={rootRef}>
      <Box className={styles.usersWrapper}>
        {activityData.map((data) => (
          <Box key={data.id} className={styles.userFlex}>
            <Box className={styles.userBlock}>
              <ConnectedUserAvatar
                mediaId={data.mediaId}
                userRating={data.rating}
              />

              <Typography
                sx={{ marginLeft: '12px', maxWidth: '156px' }}
                noWrap
                variant="text-sm-light"
                color={getRatingColor(data.rating)}
              >{`${data.firstName} ${data.lastName}`}</Typography>
              <Typography variant="text-sm-light" paddingLeft="4px">
                {t(getActionText(data))}
              </Typography>
            </Box>
            <Typography className={styles.userTimeText} variant="text-xs">
              {countActionsByTime(data.createdAt)}
            </Typography>
          </Box>
        ))}
        {(loading || hasNextPage) && (
          <Skeleton
            ref={sentryRef}
            animation="wave"
            sx={{
              height: '4px',
              marginTop: '8px',
              bgcolor: Colors.HEXB8FE619E,
              '&:after': {
                bgcolor: Colors.HEXB8FE61,
              },
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default memo(RecentActivityCard);
