import { Box } from '@mui/material';

import { ExpendituresCharts } from 'components/organisms';

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartData: any; //TODO add type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  areasData: any; //TODO add type
}

const ExpendituresTemplate = ({ chartData, areasData }: IProps) => {
  return (
    <Box sx={{ pt: '32px' }}>
      {/* <Activity
        data={chartData}
        title={t('Expenditures')}
        activityTotal={undefined}
      /> */}
      <ExpendituresCharts chartData={chartData} areasData={areasData} />
    </Box>
  );
};

export default ExpendituresTemplate;
