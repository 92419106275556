import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Textarea } from 'components/atoms';
import Colors from 'styles/colors.scss';

import styles from './GiveAdvice.module.scss';

interface IProps {
  onClose: () => void;
}

const GiveAdvice = ({ onClose }: IProps) => {
  const { t } = useTranslation();

  return (
    <Box className={styles.wrapper}>
      <IconButton onClick={onClose} className={styles.closeButton}>
        <CloseRoundedIcon />
      </IconButton>
      <Box className={styles.header}>
        <Typography variant="text-2xl" className={styles.headerText}>
          {t('Give.Advice')}
        </Typography>
        <Typography variant="text-sm-light" color={Colors['default-gray-200']}>
          {t('Have.an.idea.for.how.we.can.improve.Wed.love.to.hear.it')}
        </Typography>
      </Box>

      <Box className={styles.formBlock}>
        <Textarea
          className={styles.input}
          placeholder={t('Drop.us.a.message')}
          name="advice"
          fullWidth
          rows={9}
        />
        <Button size="large" variant="contained" type="submit">
          <Typography
            color={Colors['default-gray-900']}
            variant="text-base-semibold"
          >
            {t('Give.advice')}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default GiveAdvice;
