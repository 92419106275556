const RATING_COLORS = {
  rangPurple: '#A516FD',
  rangRed: '#FE0E18',
  rangOrange: '#FE9C0A',
  rangBlue: '#32FFFF',
  rangLemon: '#B8FE61',
};

export const getRatingColor = (rating: number) => {
  switch (true) {
    case rating >= 0 && rating <= 2.4:
      return RATING_COLORS.rangLemon;
    case rating > 2.4 && rating <= 4.8:
      return RATING_COLORS.rangBlue;
    case rating > 4.8 && rating <= 7.2:
      return RATING_COLORS.rangOrange;
    case rating > 7.2 && rating <= 9.6:
      return RATING_COLORS.rangRed;
    case rating > 9.6 && rating <= 12:
      return RATING_COLORS.rangPurple;
    default:
      return RATING_COLORS.rangLemon;
  }
};
