import { toSortedTags } from './utils';

const CUISINES = [
  {
    id: 'bars-and-pubs',
    emoji: ['bg-cocktailglass', 'bg-beermug'],
  },
  {
    id: 'italian',
    emoji: 'bg-italy',
  },
  {
    id: 'chinese',
    emoji: 'bg-china',
  },
  {
    id: 'mexican',
    emoji: 'bg-mexico',
  },
  {
    id: 'japanese',
    emoji: 'bg-japan',
  },
  {
    id: 'indian',
    emoji: 'bg-india',
  },
  {
    id: 'french',
    emoji: 'bg-france',
  },
  {
    id: 'thai',
    emoji: 'bg-thailand',
  },
  {
    id: 'mediterranean',
    emoji: 'bg-mediterranean',
  },
  {
    id: 'greek',
    emoji: 'bg-greece',
  },
  {
    id: 'spanish',
    emoji: 'bg-spain',
  },
  {
    id: 'vietnamese',
    emoji: 'bg-vietnam',
  },
  {
    id: 'middle-eastern',
    emoji: 'bg-middleeast',
  },
  {
    id: 'mixed-cuisine',
    emoji: 'bg-mixedcuisine',
  },
  {
    id: 'lebanese',
    emoji: 'bg-lebanon',
  },
  {
    id: 'turkish',
    emoji: 'bg-turkey',
  },
  {
    id: 'brazilian',
    emoji: 'bg-brazil',
  },
  {
    id: 'peruvian',
    emoji: 'bg-peru',
  },
  {
    id: 'moroccan',
    emoji: 'bg-morocco',
  },
  {
    id: 'caribbean',
    emoji: 'bg-caribbean',
  },
  {
    id: 'american',
    emoji: 'bg-usa',
  },
  {
    id: 'ukrainian',
    emoji: 'bg-ukraine',
  },
  {
    id: 'korean',
    emoji: 'bg-skorea',
  },
  {
    id: 'british',
    emoji: 'bg-uk',
  },
  {
    id: 'german',
    emoji: 'bg-germany',
  },
  {
    id: 'ethiopian',
    emoji: 'bg-ethiopia',
  },
  {
    id: 'indonesian',
    emoji: 'bg-indonesia',
  },
  {
    id: 'malaysian',
    emoji: 'bg-malaysia',
  },
  {
    id: 'australian',
    emoji: 'bg-australia',
  },
  {
    id: 'scandinavian',
    emoji: 'bg-scandinavia',
  },
  {
    id: 'fusion',
    emoji: 'bg-fusion',
  },
  {
    id: 'hong-kong',
    emoji: 'bg-hk',
  },
];

export default toSortedTags('cuisineLabel', CUISINES);
