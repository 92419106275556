import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { PaymentPage } from 'components/organisms';
import { ROUTES } from 'environment/constants/routes';
import { BUSINESS_UNIT_CLAIM_SOURCE } from 'environment/types/claim';
import { getOnboardingCalculation } from 'environment/utils/getOnboardingCalculation';
import { Dispatch, RootState } from 'store';

const PaymentDetailsTemplate = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const dispatch = useDispatch<Dispatch>();
  const { stats, subscription, onboardingType } = useSelector(
    (state: RootState) => state.onboarding,
  );

  const [calculation, setCalculation] = useState<
    { title: string; value: number }[]
  >([]);

  useEffect(() => {
    if (!subscription) return;
    (async () => {
      const result = await getOnboardingCalculation(subscription);

      setCalculation(result);
    })();
  }, [subscription]);

  const handleSubmit = async (cardToken: string) => {
    try {
      await dispatch.onboarding.setClaimPayment(cardToken);
      if (
        onboardingType === BUSINESS_UNIT_CLAIM_SOURCE.from_dashboard_settings
      ) {
        navigate(ROUTES.onboarding.describe);
      } else {
        navigate(ROUTES.onboarding.credentials);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(error.response?.data?.message ?? error.message, {
          variant: 'error',
        });
      }
    }
  };

  const activity = stats?.totalActivity.total;
  const views = stats?.totalViews.total_count;
  const comments = stats?.totalComments.total_count;
  const likes = stats?.totalLikes.total_count;
  const change = stats?.totalActivity.change;
  const periods = useMemo(
    () => stats?.totalActivity.last7days.map(({ count }) => count),
    [stats?.totalActivity],
  );

  return (
    <PaymentPage
      onAddCard={handleSubmit}
      title={t('activate_your_plan_to_join_nolemon')}
      calculation={calculation}
      views={views}
      activity={activity}
      comments={comments}
      likes={likes}
      change={change}
      periods={periods}
    />
  );
};

export default React.memo(PaymentDetailsTemplate);
