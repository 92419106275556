import { CheckOutlined } from '@mui/icons-material';
import {
  CheckboxProps,
  FormControlLabel,
  Checkbox as MuiCheckbox,
  Typography,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import styles from './Checkbox.module.scss';

interface IProps extends CheckboxProps {
  name: string;
  label?: string;
}

const Checkbox = ({ name, label, ...rest }: IProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, ...field } }) => (
        <FormControlLabel
          className={styles.checkboxWrapper}
          control={
            <MuiCheckbox
              {...field}
              checked={value}
              checkedIcon={<CheckOutlined fontSize="small" />}
              className={styles.checkbox}
              {...rest}
            />
          }
          label={<Typography variant="text-sm-light">{label}</Typography>}
        />
      )}
    />
  );
};

export default Checkbox;
