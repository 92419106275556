import 'styles/icon-font/style.css';

interface IProps {
  name: string;
  className?: string;
  color?: string;
  size?: string;
}

const IconMark = ({ name, className, color, size }: IProps) => {
  const iconStyle = {
    color: color,
    fontSize: size,
  };

  return (
    <div style={{ display: 'inline-flex' }}>
      <i
        className={`iconmoon icon-${name}${className ? ` ${className}` : ''}`}
        style={iconStyle}
      ></i>
    </div>
  );
};

export default IconMark;
