import { InputLabel, Stack, TextField, Typography } from '@mui/material';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import loaderSrc from 'assets/loader.gif';

import {
  getAddressSuggestions,
  pickAddress,
} from '../../../environment/api/services/maps';

import styles from './addressAutocomplete.module.scss';

type Address = {
  country: string;
  number: string;
  state: string;
  street: string;
  zipcode: string;
  city: string;
};

interface IProps {
  labelTitle: string;
  selectedCountry?: string;
  onItemSelect: (data: Address, fromMap?: boolean, address?: string) => void;
  defaultValue?: string;
  error?: string;
}

const InputProps = {
  disableUnderline: true,
  className: styles.input,
};

const DEBOUNCE = 500;

const AddressAutocomplete = ({
  labelTitle,
  selectedCountry,
  onItemSelect,
  defaultValue,
  error,
}: IProps) => {
  const { t } = useTranslation();
  const [result, setResult] = useState([]);
  const [focused, setFocused] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [text, setText] = useState('');
  const [value] = useDebounce(text, DEBOUNCE);

  useEffect(() => {
    if (value) {
      fetchAddress(value);
    } else {
      setResult([]);
    }
  }, [value]);

  useEffect(() => {
    defaultValue !== undefined && setText(defaultValue);
  }, [defaultValue]);

  const fetchAddress = async (inputQuery: string) => {
    setLoading(true);
    try {
      if (selectedCountry) {
        const { data } = await getAddressSuggestions(
          inputQuery,
          selectedCountry,
        );
        setResult(data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setResult([]);
    }
  };

  const onSelect = async (address: string, googlePlaceId: string) => {
    setText(address);
    const { data } = await pickAddress(address, googlePlaceId);
    onItemSelect(data, false, address);
  };

  return (
    <div className={styles.fieldGroup}>
      <Stack spacing={2.5} sx={{ flexGrow: 1 }}>
        <InputLabel
          htmlFor={'address_autocomplete'}
          variant="filled"
          className={styles.label}
        >
          {t(labelTitle)}
        </InputLabel>
        <TextField
          error={!!error}
          helperText={error}
          value={text}
          type="text"
          variant="standard"
          InputProps={{
            ...InputProps,
            onChange: (e) => setText(e.target.value),
            onFocus: () => setFocused(true),
            onBlur: () => setTimeout(() => setFocused(false), 300),
          }}
          disabled={!selectedCountry}
        />
        {error && <Typography>{error}</Typography>}
      </Stack>
      <div
        className={cn(styles.result, {
          [styles.visible]: focused,
        })}
      >
        {loading && !result?.length ? (
          <div className={styles.loaderWrapper}>
            <img
              className={styles.loader}
              src={loaderSrc}
              alt={t('Loading...')}
            />
          </div>
        ) : result?.length ? (
          result.map(({ address, googlePlaceId }) => (
            <button
              className={styles.resultItem}
              onClick={() => onSelect(address, googlePlaceId)}
              key={googlePlaceId}
            >
              <Typography variant="text-xs-weird">{address}</Typography>
            </button>
          ))
        ) : (
          <div className={styles.loaderWrapper}>
            <Typography variant="text-xs-weird">{t('not_found')}</Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddressAutocomplete;
