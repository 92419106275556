import { toSortedTags } from './utils';

const BEER = [
  {
    id: 'beer-selections',
    emoji: 'bg-beerselections',
  },
  {
    id: 'craft-beers',
    emoji: 'bg-craftbeers',
  },
  {
    id: 'local-microbrews',
    emoji: 'bg-localmicrobrews',
  },
  {
    id: 'lager',
    emoji: 'bg-lager',
  },
  {
    id: 'ale',
    emoji: 'bg-ale',
  },
  {
    id: 'stout',
    emoji: 'bg-stout',
  },
  {
    id: 'india-pale-ale',
    emoji: 'bg-ipa',
  },
  {
    id: 'wheat-beer',
    emoji: 'bg-wheatbeer',
  },
  {
    id: 'pilsner',
    emoji: 'bg-pilsner',
  },
  {
    id: 'seasonal-brews',
    emoji: 'bg-seasonalbrews',
  },
  {
    id: 'beer-flights',
    emoji: 'bg-beerflights',
  },
];

export default toSortedTags('beerLabel', BEER);
