import i18n from 'environment/plugins/i18n';

import { TimelinePeriod } from '../../types/business-unit-analytics';

export const RANGE_BUTTONS = [
  {
    value: TimelinePeriod.Day,
    label: i18n.t('24.hours'),
  },
  {
    value: TimelinePeriod.ThreeDays,
    label: i18n.t('72.hours'),
  },
  {
    value: TimelinePeriod.Week,
    label: i18n.t('Week'),
  },
  {
    value: TimelinePeriod.AllTime,
    label: i18n.t('All.time'),
  },
] as const;

export type RangeButtonsValues = (typeof RANGE_BUTTONS)[number]['value'];
