import { Box } from '@mui/material';
import { type ReactNode } from 'react';

import { Logo } from 'components/atoms';

import styles from './AuthContainer.module.scss';

interface IProps {
  children: ReactNode;
}

const AuthContainer = ({ children }: IProps) => {
  return (
    <Box className={styles.wrapper}>
      <Box className={styles.container}>
        <Logo />
        {children}
      </Box>
    </Box>
  );
};

export default AuthContainer;
