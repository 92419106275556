import { Typography } from '@mui/material';

import { formatDate } from 'environment/utils/formatDate';
import { getDisplayTextByData } from 'environment/utils/getDisplayTextByDate';

import styles from './Date.module.scss';

interface IProps {
  date: string;
}

const Date = ({ date }: IProps) => {
  const displayText = getDisplayTextByData(date);

  return (
    <div>
      <Typography
        className={styles.day}
        textAlign="center"
        component={'p'}
        variant="text-xs"
      >
        {displayText}
      </Typography>
      <Typography variant="body2" textAlign="center" component="p">
        {formatDate(date, 'hour')}
      </Typography>
    </div>
  );
};

export default Date;
