import axios from 'axios';
import { useSnackbar } from 'notistack';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { OnboardingWrapper } from 'components/atoms';
import { SignUpPersonalizeFifteenTagsTemplate } from 'components/templates';
import { ROUTES } from 'environment/constants/routes';
import { Dispatch, RootState } from 'store';

const TagsDetailedTemplate = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<Dispatch>();
  const { businessUnitPickedLabels } = useSelector(
    (state: RootState) => state.onboarding,
  );

  const form = useForm({
    defaultValues: { tags: businessUnitPickedLabels },
    values: { tags: businessUnitPickedLabels },
  });

  const handleNext = async () => {
    try {
      const { tags } = form.getValues();

      await dispatch.onboarding.setClaimTags(tags);
      navigate(ROUTES.onboarding.questions);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(error.response?.data?.message ?? error.message, {
          variant: 'error',
        });
      }
    }
  };

  const handleSkip = async () => {
    try {
      await dispatch.onboarding.completeClaim();
      navigate(ROUTES.dashboard.home);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(error.response?.data?.message ?? error.message, {
          variant: 'error',
        });
      } else {
        console.error(error);
      }
    }
  };

  return (
    <OnboardingWrapper
      onBack={() => navigate(ROUTES.onboarding.tags)}
      onSkip={handleSkip}
    >
      <FormProvider {...form}>
        <SignUpPersonalizeFifteenTagsTemplate
          onSubmit={handleNext}
          withBackButton={false}
          hidePromotion
        />
      </FormProvider>
    </OnboardingWrapper>
  );
};

export default React.memo(TagsDetailedTemplate);
