import { useDispatch, useSelector } from 'react-redux';

import { Dispatch, RootState } from 'store';

import RangeButtons from './RangeButtons';

const AnalyticsRangeButtons = () => {
  const dispatch = useDispatch<Dispatch>();

  const { period } = useSelector((state: RootState) => state.recentActivity);

  return (
    <RangeButtons
      period={period}
      setPeriod={dispatch.recentActivity.setPeriod}
    />
  );
};

export default AnalyticsRangeButtons;
