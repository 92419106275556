import qs from 'querystring';

const baseMediaUrl = process.env.REACT_APP_MEDIA_URL;

// AUTH

export const AUTH_URL = {
  LOGIN: '/auth/business/login',
  LOGIN_RESET_PASSWORD: '/auth/business/on-login/password-reset',
  LOGIN_RESET_PASSWORD_SEND_DEEPLINK:
    '/auth/business/password-reset-link/on-login/email:send',
  SIGNUP_GENERAL: '/auth/business/signup/general',
  SIGNUP_EMAIL_VERIFICATION: '/auth/business/signup/email-verification',
  SIGNUP_BUSINESS_TYPE: '/auth/business/signup/type',
  SIGNUP_BUSINESS_DETAILS: '/auth/business/signup/details',
  SIGNUP_BUSINESS_SCHEDULE: '/auth/business/signup/schedule',
  SIGNUP_BUSINESS_TAGS: '/auth/business/signup/labels',
  SIGNUP_BUSINESS_MANTRAS: '/auth/business/signup/mantras',
  SIGNUP_BUSINESS_MEMBERS: '/auth/business/signup/members',
  SIGNUP_BUSINESS_SUBSCRIPTION: '/auth/business/signup/subscription',
  SIGNUP_BUSINESS_CARD_TOKEN: '/auth/business/signup/card-token',
  SIGNUP_BUSINESS_PROGRESS: '/auth/business/signup/business-self',
  SIGNUP_COMPLETE: '/auth/business/signup/complete',
  SIGNUP_VALIDATE_LOCATION: '/auth/business/signup/location-available',
  SIGNUP_BUSINESS_DESCRIPTION: '/auth/business/signup/category-tag',
};

// BUSINESS

export const BUSINESS_URL = {
  MYSELF: '/business/myself',
};

//  BUSINESS_UNITS

export const BUSINESS_UNITS_URL = {
  BUSINESS_UNITS_MINE: '/business/myself/business-unit',
  BUSINESS_UNIT_CREATE: '/business/business-unit',
  BUSINESS_UNIT_UPDATE: (id: string) => `/business/business-unit/${id}`,
  BUSINESS_UNIT_UPDATE_LOCATION: (id: string) =>
    `/business/business-unit/${id}/location`,
  BUSINESS_UNIT_UPDATE_AVATAR: (id: string) =>
    `/business/business-unit/${id}/avatar`,
};

export const BUSINESS_UNIT_CLAIM = {
  BUSINESS_UNIT_SEARCH: '/business-units/claim-business/search',
  BUSINESS_UNIT_START_CLAIM: (id: string) => `/business-unit-claim/${id}/init`,
  BUSINESS_UNIT_CONFIRM: (id: string) =>
    `/business-units/claim-business/is-this-yours/${id}`,
  BUSINESS_UNIT_SUBSCRIPTION: (id: string) =>
    `/business-unit-claim/${id}/set-subscription`,
  BUSINESS_UNIT_PAYMENT: (id: string) =>
    `/business-unit-claim/${id}/set-payment`,
  BUSINESS_UNIT_CREDENTIALS: (id: string) =>
    `/business-unit-claim/landing/${id}/set-credentials`,
  BUSINESS_UNIT_VERIFY: (id: string) =>
    `/business-unit-claim/landing/${id}/set-email-verification`,
  BUSINESS_UNIT_CATEGORY_TAG_LANDING:
    '/business-unit-claim/landing/set-category-tag',
  BUSINESS_UNIT_CATEGORY_TAG_DASHBOARD: (id: string) =>
    `/business-unit-claim/${id}/dashboard/set-category-tag`,
  BUSINESS_UNIT_CATEGORY_LABELS_LANDING:
    '/business-unit-claim/landing/set-labels',
  BUSINESS_UNIT_CATEGORY_LABELS_DASHBOARD: (id: string) =>
    `/business-unit-claim/${id}/dashboard/set-labels`,
  BUSINESS_UNIT_CATEGORY_QUESTIONS_LANDING:
    '/business-unit-claim/landing/set-mantras',
  BUSINESS_UNIT_CATEGORY_QUESTIONS_DASHBOARD: (id: string) =>
    `/business-unit-claim/${id}/dashboard/set-mantras`,
  BUSINESS_UNIT_CATEGORY_COMPLETE_LANDING:
    '/business-unit-claim/landing/complete-claim',
  BUSINESS_UNIT_CATEGORY_COMPLETE_DASHBOARD: (id: string) =>
    `/business-unit-claim/${id}/dashboard/complete-claim`,
};

// ENGAGEMENT

export const ENGAGEMENT_REVIEW_URL = (
  businessUnitId: string,
  months?: string,
) => {
  return {
    REVIEW_DAY: `/businessUnit/${businessUnitId}/review/engagement:day`,
    REVIEW_MONTH: `/businessUnit/${businessUnitId}/review/engagement:month?months=${months}`,
    ALL_REVIEWS: `/businessUnit/${businessUnitId}/review`,
  };
};

export const ENGAGEMENT_LIKE_URL = (
  businessUnitId: string,
  months?: string,
) => {
  return {
    LIKE_DAY: `/like/engagement:day?businessUnitId=${businessUnitId}`,
    LIKE_MONTH: `/like/engagement:month?businessUnitId=${businessUnitId}&months=${months}`,
  };
};

export const ENGAGEMENT_SHARE_URL = (
  businessUnitId: string,
  months?: string,
) => {
  return {
    SHARE_DAY: `/share/engagement:day?businessUnitId=${businessUnitId}`,
    SHARE_MONTH: `/share/engagement:month?businessUnitId=${businessUnitId}&months=${months}`,
  };
};

export const ENGAGEMENT_COMMENT_URL = (
  businessUnitId: string,
  months?: string,
) => {
  return {
    COMMENT_DAY: `/comment/engagement:day?businessUnitId=${businessUnitId}`,
    COMMENT_MONTH: `/comment/engagement:month?businessUnitId=${businessUnitId}&months=${months}`,
  };
};

export const ENGAGEMENT_FORUM_TAG_URL = (
  businessUnitId: string,
  months?: string,
) => {
  return {
    TAG_DAY: `/tag/forum-tag/engagement:day?businessUnitId=${businessUnitId}`,
    TAG_MONTH: `/tag/forum-tag/engagement:month?businessUnitId=${businessUnitId}&months=${months}`,
  };
};

export const ENGAGEMENT_WATERFALL_TAG_URL = (
  businessUnitId: string,
  months?: string,
) => {
  return {
    TAG_DAY: `/tag/story-tag/engagement:day?businessUnitId=${businessUnitId}`,
    TAG_MONTH: `/tag/story-tag/engagement:month?businessUnitId=${businessUnitId}&months=${months}`,
  };
};

export const ENGAGEMENT_VIEW_URL = (
  businessUnitId: string,
  months?: string,
) => {
  return {
    VIEW_DAY: `/watch/engagement:day?businessUnitId=${businessUnitId}`,
    VIEW_MONTH: `/watch/engagement:month?businessUnitId=${businessUnitId}&months=${months}`,
  };
};

export const ENGAGEMENT_PAGE_VISIT_URL = (
  businessUnitId: string,
  startDate?: string,
  endDate?: string,
) => {
  return {
    PAGE_VISIT_BY_DATE: `/page-visit/engagement:quantity-by-date?businessUnitId=${businessUnitId}&gte=${startDate}&lte=${endDate}`,
    PAGE_VISIT_BY_AGE: `/page-visit/engagement:demographics-filtered-by-age?businessUnitId=${businessUnitId}`,
    PAGE_VISIT_USER_STATUS: `/page-visit/engagement:user-status?businessUnitId=${businessUnitId}`,
  };
};

// CONSUMER_DEMOGRAPHICS

export const CONSUMER_DEMOGRAPHICS_URL = (
  businessUnitId: string,
  startDate: string | undefined,
  endDate: string | undefined,
) => {
  const query = qs.stringify({ gte: startDate, lte: endDate });
  return {
    GRAPH: `business-unit/${businessUnitId}/demographics:graph?${query}`,
    COUNTERS: `business-unit/${businessUnitId}/demographics:counters?${query}`,
  };
};

// REVIEW_REPLY

export const REVIEW_REPLY_URL = (id?: string) => {
  return {
    REVIEW_REPLY: `/comment/business-reply/?businessUnitId=${id}`,
    REVIEW_REPLIES_LIST: `/comment/business-reply/?ownerId=${id}`,
  };
};

// MEDIA

export const MEDIA_URL = (mediaId?: string) => {
  return {
    MEDIA: `${baseMediaUrl}/media`,
    MEDIA_BY_ID: `${baseMediaUrl}/media/${mediaId}?fileType=original`,
    MEDIA_FOR_PLACE_CARD: `${baseMediaUrl}/media/:place-card`,
  };
};
// FEATURED WATERFALL

export const WATERFALL_PAGINATED_URL = (businessUnitId: string) => {
  return {
    WATERFALL_PAGINATED: `businessUnit/${businessUnitId}/waterfall`,
  };
};

export const WATERFALL_TYPE_URL = (businessUnitId: string, type: string) => {
  return {
    WATERFALL_BY_TYPE: `businessUnit/${businessUnitId}/waterfall?take=100&type=${type}`,
  };
};

// MEDIA WATERFALL

export const MEDIA_WATERFALL_URL = (mediaId: string) => {
  return {
    WATERFALL_URL: `/media/${mediaId}?fileType=original`,
    WATERFALL_URL_THUMBNAIL: `/media/${mediaId}?fileType=thumbnail`,
  };
};

// ADD WATERFALL TO FEATURED

export const ADD_WATERFALL_URL = (
  businessUnitId: string,
  waterfallId: string,
  flag: boolean,
) => {
  return {
    ADD_WATERFALL: `/businessUnit/${businessUnitId}/waterfall/${waterfallId}/featured?flag=${(!flag).toString()}`,
  };
};

// SEND QUICK COMMENT

export const SEND_QUICK_COMMENT_URL = (businessUnitId: string) => {
  return {
    SEND_QUICK_COMMENT: `/comment/business-reply/?businessUnitId=${businessUnitId}`,
  };
};

// DELETE WATERFALL

export const DELETE_WATERFALL_URL = (waterfallId: string) => {
  return {
    DELETE_WATERFALL: `/business/waterfall/${waterfallId}`,
  };
};

// AUTO FEATUING WATERFALL

export const AUTO_FEATUING_WATERFALL = (businessUnitId: string) => {
  return {
    UPDATE_BUSINESS_UNIT: `/business/business-unit/${businessUnitId}`,
  };
};

// SETTINGS

export const SETTINGS_URL = {
  CHANGE_PASSWORD: '/auth/business/change-password',
  CHANGE_EMAIL_CONFIRM_PASSWORD: '/auth/business/change-email/init',
  CHANGE_EMAIL_SEND_VERIFICATION:
    '/auth/business/change-email/send-verification',
  CHANGE_EMAIL_VERIFY: '/auth/business/change-email/verify',
  RESET_PASSWORD_GET_DEEPLINK:
    '/auth/business/password-reset-link/in-settings/email:send',
  RESET_PASSWORD: '/auth/business/in-settings/password-reset',
};

// MEMBERS

export const MEMBERS_URL = {
  MEMBER: '/member',
  MEMBER_ID: (memberId: string) => `/member/${memberId}`,
};

// DASHBOARD SECTION

export const DASHBOARD_TOTAL_ACTIVITY = (businessUnitId: string) => {
  return {
    TOTAL_ACTIVITY: `/dashboard/business-unit/${businessUnitId}/graph:totalActivityAndEngagers`,
  };
};

export const DASHBOARD_ENGAGEMENTS_QUANTITY = (businessUnitId: string) => {
  return {
    ENGAGEMENTS_QUANTITY: `/dashboard/business-unit/${businessUnitId}/graph:engagementsQuantityForToday`,
  };
};

export const DASHBOARD_RECENT_ACTIVITY = (businessUnitId: string) => {
  return {
    RECENT_ACTIVITY: `/dashboard/business-unit/${businessUnitId}/graph:recentActivity`,
    ALL_RECENT_ACTIVITY_COUNTER: `/dashboard/business-unit/${businessUnitId}/graph:recentActivityCounter`,
  };
};

export const DASHBOARD_CONSUMER_DEMOGRAPHICS = (businessUnitId: string) => {
  return {
    CONSUMER_DEMOGRAPHICS: `/dashboard/business-unit/${businessUnitId}/graph:consumerDemographics`,
  };
};

export const DASHBOARD_COMPARED_TO_WATERFALLS = (businessUnitId: string) => {
  return {
    COMPARED_TO_WATERFALLS: `/dashboard/business-unit/${businessUnitId}/graph:comparedToWaterfalls`,
  };
};

// MEDIA

export const MEDIA_URL_BY_ID = (mediaId: string) => {
  return {
    MEDIA_BY_ID: `/media/${mediaId}`,
  };
};

// USER

export const USER_BY_ID = (userId: string) => {
  return {
    USER: `/user/${userId}`,
  };
};

// FOLDERS

export const FOLDERS = (businessUnitId?: string, folderId?: string) => {
  return {
    ALL_FOLDERS: '/folder',
    NEW_FOLDER: '/folder',
    DELETE_FOLDER: `/folder/${folderId}`,
    ADD_UNIT_TO_FOLDER: `/business-units/${businessUnitId}`,
    DELETE_UNIT_FROM_FOLDER: `/business-units/${businessUnitId}/folder/${folderId}`,
  };
};

export const UPDATE_FOLDER_NAME = (folderId: string) => {
  return {
    UPDATE: `/folder/${folderId}`,
  };
};

// USER PERSONAL INFO

export const USER_PERSONAL_INFO = (userId: string) => {
  return {
    ABOUT: `/user/${userId}/personal-info`,
  };
};

// WATERFALL REPLY

export const WATERFALL_REPLY = (waterfallId: string) => {
  return `/comment/business-reply?ownerId=${waterfallId}&owner=waterfall`;
};

export const REPORTS = {
  PUBLICATION: '/report/onPublication',
};

// Maps

export const MAPS = {
  SUGGESTED_ADDRESSES: '/maps/autocomplete-suggestions/country-name',
  PICK_ADDRESS: '/maps/autocomplete-suggestions/pick',
  LOCATION_SUGGESTIONS: '/maps/get-suggested-address/geolocation-point',
};

// Payments

export const PAYMENTS = {
  PLANS: '/payments/plans',
  PLAN_BY_PRICE_ID: '/payments/plans/price',

  PAYMENT_METHODS: (unitId: string) =>
    `/business/business-unit/${unitId}/payment-methods`,
  SUBSCRIPTIONS: (unitId: string) =>
    `/business/business-unit/${unitId}/subscriptions`,
  PAYMENTS: (unitId: string) => `/business/business-unit/${unitId}/payments`,
};
