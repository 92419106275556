import { Box, Button, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Map, { type LngLat, Marker, type MarkerDragEvent } from 'react-map-gl';

import { GoBackLink } from 'components/atoms';
import { getAddressSuggestionsByMap } from 'environment/api/services/maps';
import Colors from 'styles/colors.scss';

import styles from './MapModal.module.scss';
import Pin from './Pin';

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (address: any) => void;
  onClose: () => void;
}

const MAPBOX_TOKEN = process.env.REACT_APP_TEST_MAPBOX_ACCESS_TOKEN;

const initialViewState = {
  latitude: 1.29027,
  longitude: 103.851959,
  zoom: 10,
};

const MapModal: React.FC<IProps> = ({ onSubmit, onClose }) => {
  const { t } = useTranslation();
  const [marker, setMarker] = useState({
    latitude: 1.29027,
    longitude: 103.851959,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, logEvents] = useState<Record<string, LngLat>>({});
  const [error, setError] = useState<string | null>(null);

  const onMarkerDragStart = useCallback((event: MarkerDragEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    logEvents((_events: any) => ({ ..._events, onDragStart: event.lngLat }));
  }, []);

  const onMarkerDrag = useCallback((event: MarkerDragEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    logEvents((_events: any) => ({ ..._events, onDrag: event.lngLat }));

    changeMarkerPos(event.lngLat.lng, event.lngLat.lat);
  }, []);

  const changeMarkerPos = (longitude: number, latitude: number) => {
    setMarker({
      longitude: longitude,
      latitude: latitude,
    });
  };

  const inSave = async () => {
    try {
      setError(null);
      const { data } = await getAddressSuggestionsByMap(
        marker.latitude,
        marker.longitude,
      );
      onSubmit(data);
    } catch (error) {
      setError('Address not found');
    }
  };

  const onMarkerDragEnd = useCallback((event: MarkerDragEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    logEvents((_events: any) => ({ ..._events, onDragEnd: event.lngLat }));
  }, []);
  return (
    <div className={styles.wrapper}>
      <Box sx={{ alignSelf: 'flex-start', marginTop: '12px' }}>
        <GoBackLink onClick={onClose} />
      </Box>
      <Typography
        component="p"
        textAlign="center"
        variant="text-2xl-light"
        sx={{ m: 3 }}
      >
        {t('Location')}
      </Typography>
      <Box className={styles.map}>
        <Map
          initialViewState={initialViewState}
          mapStyle="mapbox://styles/mapbox/dark-v10"
          mapboxAccessToken={MAPBOX_TOKEN}
          onClick={({ lngLat }) => changeMarkerPos(lngLat.lng, lngLat.lat)}
        >
          <Marker
            longitude={marker.longitude}
            latitude={marker.latitude}
            anchor="bottom"
            draggable
            onDragStart={onMarkerDragStart}
            onDrag={onMarkerDrag}
            onDragEnd={onMarkerDragEnd}
          >
            <Pin />
          </Marker>
        </Map>
      </Box>
      {error && (
        <Box sx={{ alignSelf: 'flex-start', marginTop: '12px' }}>
          <Typography color={'#d32f2f'} variant="text-xs-weird">
            {error}
          </Typography>
        </Box>
      )}
      <Button
        fullWidth
        variant="contained"
        onClick={() => inSave()}
        sx={{ m: 3 }}
      >
        <Typography
          variant="text-base-semibold"
          color={Colors['default-gray-900']}
        >
          {t('save_location')}
        </Typography>
      </Button>
    </div>
  );
};

export default MapModal;
