import { Box, Divider, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Toggle } from 'components/atoms';

import styles from './NotificationsSection.module.scss';

const NotificationsSection = () => {
  const { t } = useTranslation();

  const [pushChecked, setPushChecked] = useState<boolean>(false);
  const [emailChecked, setEmailChecked] = useState<boolean>(true);

  return (
    <>
      <Divider orientation="horizontal" sx={{ marginY: '16px' }} />

      <Box className={styles.container}>
        <Typography variant="text-base-light">{t('Push')}</Typography>
        <Toggle
          checked={pushChecked}
          onChange={() => setPushChecked((v) => !v)}
        />
      </Box>

      <Divider orientation="horizontal" sx={{ marginY: '16px' }} />

      <Box className={styles.container}>
        <Typography variant="text-base-light">{t('fields.email')}</Typography>
        <Toggle
          checked={emailChecked}
          onChange={() => setEmailChecked((v) => !v)}
        />
      </Box>
    </>
  );
};

export default NotificationsSection;
