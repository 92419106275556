import { getProductByPriceId } from 'environment/api/services/payments';

export const getOnboardingCalculation = async (selected: string) => {
  const plan = await getProductByPriceId(selected);

  const onboardingPrice = plan.data?.metadata?.onboardingFee;

  if (!onboardingPrice) return [];

  const onboarding = await getProductByPriceId(onboardingPrice);

  const value = onboarding.data?.unit_amount
    ? onboarding.data.unit_amount / 100
    : 0;

  return [
    {
      title: onboarding.data?.name,
      value,
    },
  ];
};
