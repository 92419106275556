export const configStyles = {
  chart: {
    backgroundColor: 'transparent',
    spacingTop: 0,
    spacingRight: 0,
    spacingBottom: 0,
    spacingLeft: 0,
  },
  xAxis: {
    lineWidth: 0,
    tickWidth: 0,
    gridLineWidth: 0,
    endOnTick: false,

    labels: {
      enabled: false,
    },
  },
  yAxis: {
    gridLineWidth: 0,
    labels: {
      enabled: false,
    },
    title: {
      enabled: false,
    },
    endOnTick: false,
  },
};
