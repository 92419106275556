import axios from 'axios';
import { useSnackbar } from 'notistack';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { OnboardingWrapper } from 'components/atoms';
import { SignUpBusinessDescriptionTemplate } from 'components/templates';
import { ROUTES } from 'environment/constants/routes';
import { BUSINESS_UNIT_CLAIM_SOURCE } from 'environment/types/claim';
import { Dispatch, RootState } from 'store';

const defaultValues = {
  categoryTag: { variant: null },
};

const DescribeTemplate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const { enqueueSnackbar } = useSnackbar();
  const { categoryTag, onboardingType, copyData } = useSelector(
    (state: RootState) => state.onboarding,
  );

  const form = useForm({
    defaultValues,
    values: { categoryTag: { variant: categoryTag } },
  });

  const handleNext = async () => {
    try {
      const { categoryTag } = form.getValues();
      if (!categoryTag.variant) return;
      await dispatch.onboarding.setClaimCategoryTag(categoryTag.variant);
      navigate(ROUTES.onboarding.tags);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(error.response?.data?.message ?? error.message, {
          variant: 'error',
        });
      }
    }
  };

  const handleBack = () => {
    if (onboardingType === BUSINESS_UNIT_CLAIM_SOURCE.from_landing_page) {
      navigate(ROUTES.onboarding.credentials);
    } else if (copyData) {
      navigate(ROUTES.onboarding.copyBusiness);
    } else {
      navigate(ROUTES.onboarding.paymentDetails);
    }
  };

  return (
    <OnboardingWrapper onBack={handleBack}>
      <FormProvider {...form}>
        <SignUpBusinessDescriptionTemplate
          onSubmit={handleNext}
          name="categoryTag"
        />
      </FormProvider>
    </OnboardingWrapper>
  );
};

export default React.memo(DescribeTemplate);
