import { Box, Button, Typography } from '@mui/material';
import { type FC } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

import { LoaderOverlay, ServerErrorMessage } from 'components/atoms';
import SettingsModal from 'components/molecules/settingsModal/SettingsModal';
import AddMembersForm from 'components/organisms/signUpForms/addMembersForm/AddMembersForm';
import Colors from 'styles/colors.scss';

import styles from './AddMember.module.scss';

interface IProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  heading: string;
  buttonText: string;
  businessUnitName: string;
  isLoading?: boolean;
}

const AddMember: FC<IProps> = ({
  open,
  onClose,
  heading,
  buttonText,
  onSubmit,
  businessUnitName,
  isLoading,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { isValid } = useFormState({ control });

  return (
    <SettingsModal open={open} onClose={onClose}>
      {isLoading && <LoaderOverlay />}
      <Box className={styles.container}>
        <Typography variant="text-2xl">{heading}</Typography>
        <AddMembersForm companyName={businessUnitName} />

        {errors.root?.serverError?.message ? (
          <ServerErrorMessage message={errors.root.serverError.message} />
        ) : null}

        <Button
          variant="contained"
          fullWidth
          onClick={onSubmit}
          disabled={!isValid}
        >
          <Typography
            variant="text-base-semibold"
            color={Colors['default-gray-900']}
          >
            {buttonText}
          </Typography>
        </Button>
      </Box>
    </SettingsModal>
  );
};

export default AddMember;
