import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Checkbox from 'components/atoms/checkbox/Checkbox';
import TimePicker from 'components/atoms/timePicker/TimePicker';

import styles from './ScheduleTimePicker.module.scss';

interface IProps {
  name: string;
  title: string;
}

const ScheduleTimePicker = ({ name, title }: IProps) => {
  const { t } = useTranslation();

  return (
    <Box className={styles.errorContainer}>
      <Box className={styles.oneDayRow}>
        <Typography variant="text-sm-light">{title}</Typography>
        <Box className={styles.pickersWithCheckboxRow}>
          <Box className={styles.pickersRow}>
            <TimePicker name={`${name}.from`} />
            <TimePicker name={`${name}.to`} />
          </Box>
          <Checkbox name={`${name}.closed`} label={t('Closed')} />
        </Box>
      </Box>
    </Box>
  );
};

export default ScheduleTimePicker;
