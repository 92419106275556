import React from 'react';

interface TabProps {
  label: string;
  component: React.ReactNode;
  badge?: number | string | null;
}

export const createTab = ({ label, component, badge = null }: TabProps) => {
  return { label, component, badge };
};
