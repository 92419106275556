import { AUTH_URL } from 'environment/constants/url';
import {
  LoginRequestBody,
  LoginResetPasswordChangeRequestBody,
  LoginResetPasswordDeeplinkRequestBody,
  SignInResponseBody,
  SignUpBusinessDescriptionRequestBody,
  SignUpBusinessDetailsRequestBody,
  SignUpBusinessMantrasRequestBody,
  SignUpBusinessScheduleRequestBody,
  SignUpBusinessTagsRequestBody,
  SignUpBusinessTypeRequestBody,
  SignUpGeneralRequestBody,
  SignUpGeneralResponseBody,
  SignUpProgress,
} from 'environment/types/api/auth';
import { PartialLocation } from 'environment/types/api/shared';

import axios from '../axios';

export const login = (body: LoginRequestBody) => {
  return axios.post<SignInResponseBody>(AUTH_URL.LOGIN, body);
};

export const loginResetPasswordDeeplink = (
  body: LoginResetPasswordDeeplinkRequestBody,
) => {
  return axios.post(AUTH_URL.LOGIN_RESET_PASSWORD_SEND_DEEPLINK, body);
};

export const loginResetPasswordChange = ({
  token,
  ...body
}: LoginResetPasswordChangeRequestBody) => {
  return axios.put(`${AUTH_URL.LOGIN_RESET_PASSWORD}?token=${token}`, body);
};

// SIGN UP

export const signUpGeneral = (body: SignUpGeneralRequestBody) => {
  return axios.put<SignUpGeneralResponseBody>(AUTH_URL.SIGNUP_GENERAL, body);
};

export const signUpEmailVerification = (params: {
  email: string;
  code: string;
}) => {
  return axios.get<SignUpGeneralResponseBody>(
    AUTH_URL.SIGNUP_EMAIL_VERIFICATION,
    { params },
  );
};

export const signUpBusinessType = (body: SignUpBusinessTypeRequestBody) => {
  return axios.put(AUTH_URL.SIGNUP_BUSINESS_TYPE, body);
};

export const signUpBusinessDescription = (
  body: SignUpBusinessDescriptionRequestBody,
) => {
  return axios.put(AUTH_URL.SIGNUP_BUSINESS_DESCRIPTION, body);
};

export const signUpBusinessDetails = (
  body: SignUpBusinessDetailsRequestBody,
) => {
  return axios.put(AUTH_URL.SIGNUP_BUSINESS_DETAILS, body);
};

export const signUpBusinessSchedule = (
  body: SignUpBusinessScheduleRequestBody,
) => {
  return axios.put(AUTH_URL.SIGNUP_BUSINESS_SCHEDULE, body);
};

export const signUpBusinessTags = (body: SignUpBusinessTagsRequestBody) => {
  return axios.put(AUTH_URL.SIGNUP_BUSINESS_TAGS, body);
};

export const signUpBusinessMantras = (
  body: SignUpBusinessMantrasRequestBody,
) => {
  return axios.put(AUTH_URL.SIGNUP_BUSINESS_MANTRAS, body);
};

export const signUpBusinessMembers = (body: {
  members: Array<{ fullName: string; email: string }>;
}) => {
  return axios.put(AUTH_URL.SIGNUP_BUSINESS_MEMBERS, body);
};

export const signUpBusinessSubscription = (body: { subscription: string }) => {
  return axios.put(AUTH_URL.SIGNUP_BUSINESS_SUBSCRIPTION, body);
};

export const signUpBusinessCardToken = (body: { cardToken: string }) => {
  return axios.put(AUTH_URL.SIGNUP_BUSINESS_CARD_TOKEN, body);
};

export const signUpBusinessProgress = () => {
  return axios.get<SignUpProgress>(AUTH_URL.SIGNUP_BUSINESS_PROGRESS);
};

export const signUpBusinessComplete = () => {
  return axios.get<SignInResponseBody>(AUTH_URL.SIGNUP_COMPLETE);
};

export const validateBusinessLocation = (location: PartialLocation) => {
  return axios.post(AUTH_URL.SIGNUP_VALIDATE_LOCATION, location);
};
