import {
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import React from 'react';

import { GradientIcon } from 'components/molecules';
import { Order } from 'environment/types';

import styles from './TableHeader.module.scss';

interface Column {
  id: string;
  label: React.ReactNode;
  sortable: boolean;
  alignCenter: boolean;
  sx?: TableCellProps['sx'];
}

interface IProps {
  columns: readonly Column[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRequestSort: (property: any) => void;
  order: Order;
  orderBy: string | number | symbol;
}

interface IconComponentProps {
  order: 'asc' | 'desc';
  orderBy: string | number | symbol;
  columnId: string;
}

const IconComponent = ({ order, orderBy, columnId }: IconComponentProps) => {
  if (orderBy === columnId) {
    return order === 'asc' ? (
      <GradientIcon name="arrow-up1" size="16px" />
    ) : (
      <GradientIcon name="arrow-down1" size="16px" />
    );
  } else {
    return <GradientIcon name="opposite-arrows" size="16px" />;
  }
};

function TableHeader({ columns, onRequestSort, order, orderBy }: IProps) {
  return (
    <TableHead>
      <TableRow>
        {columns.map(({ id, label, sortable, alignCenter, sx }) => (
          <TableCell
            key={id}
            sx={sx}
            className={styles.cell}
            align={alignCenter ? 'center' : 'left'}
            sortDirection={orderBy === id ? order : false}
          >
            {sortable ? (
              <TableSortLabel
                className={styles.sortLabel}
                IconComponent={() => (
                  <IconComponent
                    order={order}
                    orderBy={orderBy}
                    columnId={id}
                  />
                )}
                onClick={() => onRequestSort(id)}
              >
                <Typography whiteSpace="nowrap" variant="text-xs">
                  {label}
                </Typography>
              </TableSortLabel>
            ) : (
              <Typography variant="text-xs">{label}</Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default TableHeader;
