import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'environment/constants/routes';
import { Dispatch, RESET_STORE, RootState } from 'store/index';

const useAuth = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch<Dispatch>();

  const { business } = useSelector((state: RootState) => state.business);

  useEffect(() => {
    dispatch.business.getBusiness();
  }, [dispatch.business]);

  const refetchBusiness = () => {
    dispatch.business.getBusiness();
  };

  const handleLogout = () => {
    dispatch({ type: RESET_STORE });

    localStorage.removeItem('access_token');
    localStorage.removeItem('unit_id');
    navigate(ROUTES.login.home);
  };

  return { business, refetchBusiness, handleLogout };
};

export default useAuth;
