import moment from 'moment';

export function getMonthArray(startDateStr: string): number[] {
  const startDate = moment(startDateStr);
  const currentDate = moment();

  const monthsArray = [];

  while (startDate.isSameOrBefore(currentDate, 'month')) {
    monthsArray.push(startDate.month());
    startDate.add(1, 'month');
  }

  return monthsArray;
}
