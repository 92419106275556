import moment from 'moment';

export const formatDate = (
  date: string | undefined | null,
  type?: 'date' | 'day' | 'hour',
) => {
  if (!date) {
    return '';
  }

  switch (type) {
    case 'date':
      return moment(date).format('YYYY-MM-DD');
    case 'day':
      return moment(date).format('DD MMM');
    case 'hour':
      return moment(date).format('HH:mm:ss');
    default:
      return moment(date).format('YYYY-MM-DDTHH:mm');
  }
};
