import { Box, Button, Typography } from '@mui/material';
import { type FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GoBackLink, InfoLabel, ServerErrorMessage } from 'components/atoms';
import { TagDropdown, UpgradeToPremium } from 'components/molecules';
import { UseSameAsDropdown } from 'components/organisms';
import { EXTRA_DROPDOWNS } from 'environment/constants/tags';
import { SignUpBusinessFormData } from 'hooks/forms';
import { useSignUpRoutes } from 'hooks/signUpNavigation';
import Colors from 'styles/colors.scss';

import styles from './SignUpPersonalizeFifteenTags.module.scss';

interface IProps {
  onSubmit: () => void;
  withLabel?: boolean;
  withBackButton?: boolean;
  withUseAsDropdown?: boolean;
  hidePromotion?: boolean;
}

const SignUpPersonalizeFifteenTagsTemplate: FC<IProps> = ({
  onSubmit,
  withLabel = true,
  withBackButton = true,
  withUseAsDropdown = false,
  hidePromotion,
}) => {
  const { t } = useTranslation();

  const signUpRoutes = useSignUpRoutes();

  const {
    control,
    formState: { errors },
    clearErrors,
  } = useFormContext<SignUpBusinessFormData>();

  const tags = useWatch({ control, name: 'tags' });

  const overallChosenTagsAmount = Object.values(tags).reduce(
    (acc, value) => (value ? acc + 1 : acc),
    0,
  );

  return (
    <Box display="flex" flexDirection="column" gap="32px">
      <Box className={styles.container}>
        {withBackButton && (
          <Box alignSelf="flex-start">
            <GoBackLink
              navigateTo={signUpRoutes.threeTags}
              onClick={() => clearErrors('root.serverError')}
            />
          </Box>
        )}
        <Typography variant="small-app-title">
          {t('Personalize.your.business')}
        </Typography>
        <Typography variant="text-lg">{t('Select.3.more.tags')}</Typography>
        <Box width="100%">
          <Typography variant="text-base" color={Colors['default-gray-200']}>
            {t(
              'If.you.want.to.choose.more.than.6.tags.you.need.to.upgrade.to.a',
            ) + ' '}
            <Typography variant="text-base" color={Colors['HEXB8FE61']}>
              {t('Premium.Plan')}
            </Typography>
          </Typography>
        </Box>

        <Box className={styles.dropdowns}>
          {EXTRA_DROPDOWNS.map(({ name, emoji, items }) => (
            <TagDropdown
              key={name}
              name={`tags.${name}`}
              category={name}
              label={t(`tagsCategories.${name}`)}
              emoji={emoji}
              items={items}
              blurUnselected={overallChosenTagsAmount === 6}
            />
          ))}
        </Box>

        {withUseAsDropdown && (
          <UseSameAsDropdown
            field="fifteen-tags"
            label={t('Use.same.tags.as')}
          />
        )}

        {withLabel && (
          <InfoLabel
            text={t('You.can.amend.these.later.under.Settings')}
            textProps={{ variant: 'text-base-light' }}
          />
        )}

        {errors.root?.serverError?.message ? (
          <ServerErrorMessage message={errors.root.serverError.message} />
        ) : null}

        <Button
          sx={{ marginTop: '8px' }}
          variant="contained"
          fullWidth
          disabled={overallChosenTagsAmount < 6}
          onClick={onSubmit}
        >
          <Typography
            variant="text-base-semibold"
            color={Colors['default-gray-900']}
          >
            {t('Next')}
          </Typography>
        </Button>
      </Box>

      {!hidePromotion && (
        <UpgradeToPremium title={t('Unlock.more.tags.for.your.business!')} />
      )}
    </Box>
  );
};

export default SignUpPersonalizeFifteenTagsTemplate;
