import { Box } from '@mui/material';

import { BoostsActivity, ExpandedMap } from 'components/organisms';

import styles from './Boosts.module.scss';

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentRestaurant: any; //TODO add type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nearbyRestaurant: any; //TODO add type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  speedometerData: any; //TODO add type
}

const BoostsTemplate = ({
  currentRestaurant,
  nearbyRestaurant,
  speedometerData,
}: IProps) => {
  return (
    <Box className={styles.wrapper}>
      <BoostsActivity speedometerData={speedometerData} />
      <ExpandedMap
        currentRestaurant={currentRestaurant}
        nearbyRestaurant={nearbyRestaurant}
      />
    </Box>
  );
};

export default BoostsTemplate;
