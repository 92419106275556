import { Box } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { PriceTable } from 'components/molecules';
import { ROUTES } from 'environment/constants/routes';
import usePayments from 'hooks/usePayments';
import { Dispatch } from 'store';

const SubscriptionTemplate = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<Dispatch>();

  const { getPlans, plans, isPlansLoading } = usePayments();
  useEffect(() => {
    getPlans();
  }, []);

  const handleSubmit = async (subscription: string) => {
    try {
      await dispatch.onboarding.setClaimSubscription(subscription);
      navigate(ROUTES.onboarding.paymentDetails);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(error.response?.data?.message ?? error.message, {
          variant: 'error',
        });
      }
    }
  };

  return (
    <Box px="12px" py="24px" width="100%">
      <PriceTable
        plans={plans}
        loading={isPlansLoading}
        onSubscriptionSelect={handleSubmit}
      />
    </Box>
  );
};

export default React.memo(SubscriptionTemplate);
