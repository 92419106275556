import { Box } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AnalyticsRangeButtons,
  ColumnChart,
  PageHeader,
} from 'components/molecules';
import { ROUTES } from 'environment/constants/routes';
import { Analytics } from 'types/business-unit-analytics';

import AnalyticsTiles from '../analyticsTiles/AnalyticsTiles';

import styles from './DemographicsActivity.module.scss';

interface Props {
  columnChartData: { [key: string]: number };
  analytics: Analytics;
  // todo: ASK BACKEND TO MAKE THE SAME PARAMS AS ON ANALYTICS WS
  getConsumerDemographicsCounters: (
    businessUnitId: string,
    startDate: string,
    endDate: string,
  ) => void;
}

const DemographicsActivity: FC<Props> = ({ analytics, columnChartData }) => {
  const { t } = useTranslation();

  function isObjectEmpty(obj: { [key: string]: number }): boolean {
    for (const key in obj) {
      if (obj[key] !== 0) {
        return false;
      }
    }
    return true;
  }

  const isEmpty = isObjectEmpty(columnChartData);

  return (
    <Box>
      <PageHeader
        title={t('Consumer.Demographics')}
        backButtonNavigateTo={ROUTES.dashboard.home}
        rightSection={<AnalyticsRangeButtons />}
      />

      <Box className={styles.blockActivity}>
        <Box className={styles.chartContainer}>
          <ColumnChart data={columnChartData} isEmpty={isEmpty} />
          <Box className={styles.categoriesBlock}></Box>
        </Box>
        <AnalyticsTiles analytics={analytics} tileWidth="156px" />
      </Box>
    </Box>
  );
};

export default DemographicsActivity;
