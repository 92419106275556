import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const BG = require('../../../assets/images/map-preview.png');

interface IProps {
  onClick: () => void;
}

const MapPreviewButton = ({ onClick }: IProps) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography variant="text-sm-lighter">
        {t('set_address_manually')}
      </Typography>
      <Box onClick={onClick}>
        <img src={BG} alt="map" />
      </Box>
    </Box>
  );
};

export default MapPreviewButton;
