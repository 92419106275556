import { Box, Typography } from '@mui/material';
import { type FC } from 'react';

import styles from './Mantra.module.scss';

interface IProps {
  title: string;
  text: string;
}

const Mantra: FC<IProps> = ({ title, text }) => {
  return (
    <Box className={styles.wrapper}>
      <Box className={styles.container}>
        <Typography variant="text-base-light">{title}</Typography>
        <Typography className={styles.mantraText}>{text}</Typography>
      </Box>
    </Box>
  );
};

export default Mantra;
