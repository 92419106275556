import { useEffect, useState } from 'react';

import { getMediaById } from 'environment/api/services/media';

export const useMediaById = (mediaId: string | null) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!mediaId) {
      return;
    }

    setIsLoading(true);

    getMediaById(mediaId)
      .then(({ data: { body } }) => {
        if (Array.isArray(body)) {
          setImageSrc(body.at(-1)?.url ?? '');
        } else {
          setImageSrc(body?.url ?? '');
        }
      })
      .catch(console.error)
      .finally(() => setIsLoading(false));
  }, [mediaId]);

  return { isLoading, imageSrc };
};
