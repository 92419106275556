import { Button, Divider, Typography } from '@mui/material';
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TextInputDefault } from 'components/atoms';
import {
  StripeTextFieldCVC,
  StripeTextFieldExpiry,
  StripeTextFieldNumber,
} from 'components/atoms/stripeInput/StripeInput';
import Colors from 'styles/colors.scss';

import styles from './PaymentCardFormBody.module.scss';

interface CalculationItem {
  title: string;
  value: number;
}

export interface PaymentCardFormProps {
  onAddCard: (token: string) => void;
  calculation?: CalculationItem[];
  title?: string;
}

const PaymentCardFormBody = ({
  onAddCard,
  calculation,
  title,
}: PaymentCardFormProps) => {
  const { t } = useTranslation();

  const stripe = useStripe();
  const elements = useElements();

  const [name, setName] = useState('');

  const onSubmit = async () => {
    if (stripe && elements) {
      const cardNumberElement = elements.getElement(CardNumberElement);

      if (cardNumberElement) {
        const { token } = await stripe.createToken(cardNumberElement, {
          name,
        });

        if (token) {
          onAddCard(token.id);
        }
      }
    }
  };

  const calculationTotal = React.useMemo(
    () =>
      calculation?.reduce((acc, item) => {
        return acc + item.value;
      }, 0),
    [calculation],
  );

  return (
    <div className={styles.root}>
      {title && (
        <Typography
          variant="text-2xl-light"
          lineHeight="40px"
          textAlign="center"
        >
          {title}
        </Typography>
      )}
      <TextInputDefault
        value={name}
        label={t('Name.on.card')}
        autoComplete="cc-name"
        onChange={(e) => setName(e.target.value)}
      />
      <StripeTextFieldNumber />
      <div className={styles.row}>
        <StripeTextFieldExpiry />
        <StripeTextFieldCVC />
      </div>
      <Divider />
      {!!calculation?.length && (
        <div className={styles.calculation}>
          {calculation.map((item, index) => (
            <div key={index} className={styles.item}>
              <Typography
                variant="text-sm-lighter"
                color={Colors['default-gray-50']}
              >
                {item.title}
              </Typography>
              <Typography
                variant="text-sm-lighter"
                color={Colors['default-gray-50']}
              >
                HKD {item.value}
              </Typography>
            </div>
          ))}
          <Divider />
          <div className={styles.item}>
            <Typography
              variant="text-sm-lighter"
              color={Colors['default-gray-50']}
            >
              {t('Total')}
            </Typography>
            <Typography
              variant="text-sm-lighter"
              color={Colors['default-gray-50']}
            >
              HKD {calculationTotal}
            </Typography>
          </div>
        </div>
      )}
      <Button size="large" variant="contained" onClick={onSubmit}>
        <Typography
          color={Colors['default-gray-900']}
          variant="text-base-semibold"
        >
          {t('Continue')}
        </Typography>
      </Button>
    </div>
  );
};

export default PaymentCardFormBody;
