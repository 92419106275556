import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getPriceString } from 'environment/utils/getPriceString';
import Colors from 'styles/colors.scss';
import { Plan } from 'types/payments/payments';

import styles from './Product.module.scss';

interface ProductProps extends Plan {
  onSubscriptionSelect: (id: string) => void;
}

const Product = ({
  name,
  description,
  features,
  metadata,
  default_price,
  onSubscriptionSelect,
}: ProductProps) => {
  const { t } = useTranslation();

  const { promoted } = metadata;

  const background = React.useMemo(
    () => (promoted ? Colors['profile-blue'] : undefined),
    [promoted],
  );
  const color = React.useMemo(
    () => (promoted ? Colors['default-gray-900'] : undefined),
    [promoted],
  );
  const actionText = React.useMemo(
    () => (promoted ? t('free_trial', { days: 14 }) : t('get_started')),
    [promoted, t],
  );

  const price = React.useMemo(
    () => getPriceString(default_price.unit_amount),
    [default_price],
  );

  return (
    <div className={styles.root}>
      <Box
        className={promoted && styles.promotedHeader}
        height="40px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        mb="-16px"
      >
        {promoted && (
          <Typography
            variant="text-xl"
            lineHeight="20px"
            fontWeight={400}
            textTransform="capitalize"
            align="center"
          >
            {t('free_onboarding')}
          </Typography>
        )}
      </Box>
      <div className={styles.product}>
        <div className={`${styles.title} ${promoted && styles.active}`}>
          <Typography variant="text-base" color={color}>
            {name}
          </Typography>
        </div>
        <Typography
          variant="text-base"
          color={Colors['default-gray-100']}
          lineHeight="32px"
          align="center"
        >
          {description}&nbsp;
        </Typography>
        <div className={styles.price}>
          <Typography variant="small-app-title" lineHeight="40px">
            {t(price)}
          </Typography>
          <Typography
            variant="text-base"
            color={Colors['default-gray-200']}
            lineHeight="32px"
          >
            {!!default_price.unit_amount && 'Billed monthly'}&nbsp;
          </Typography>
        </div>
        <ul>
          {features.map(({ name }) => (
            <li key={name}>
              <Typography
                variant="text-base"
                color={Colors['default-gray-100']}
                lineHeight="32px"
              >
                {name}
              </Typography>
            </li>
          ))}
        </ul>
      </div>
      <Button
        variant="outlined"
        size="large"
        className={promoted && styles.active}
        onClick={() => onSubscriptionSelect(default_price.id)}
        sx={{
          background,
          color,
        }}
      >
        <Typography
          variant={promoted ? 'text-2xl' : 'text-base-semibold'}
          color={color}
        >
          {actionText}
        </Typography>
      </Button>
    </div>
  );
};

export default React.memo(Product);
