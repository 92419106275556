import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Colors from 'styles/colors.scss';

import GoBackLink from '../goBackLink/GoBackLink';

interface OnboardingWrapperProps {
  children: React.ReactNode;
  onBack?: () => void;
  onSkip?: () => void;
}

interface SkipButtonProps {
  onClick: () => void;
}

const SkipButton = ({ onClick }: SkipButtonProps) => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      alignItems="center"
      gap="8px"
      sx={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      <Typography color={Colors['default-gray-200']} variant="text-sm-light">
        {t('set_up_later')}
      </Typography>
    </Box>
  );
};

const OnboardingWrapper = ({
  children,
  onBack,
  onSkip,
}: OnboardingWrapperProps) => {
  const showNavigation = !!onBack || !!onSkip;
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      p="40px"
      bgcolor={Colors['bg-color']}
      borderRadius="16px"
      minWidth="600px"
    >
      {showNavigation && (
        <Box display="flex" justifyContent="space-between" width="100%">
          <GoBackLink onClick={onBack} />
          {onSkip && <SkipButton onClick={onSkip} />}
        </Box>
      )}
      <Box display="flex" flexDirection="column" gap="24px">
        {children}
      </Box>
    </Box>
  );
};

export default React.memo(OnboardingWrapper);
