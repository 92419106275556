import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Colors from 'styles/colors.scss';

import styles from './EmptyReview.module.scss';

const EmptyReview = () => {
  const { t } = useTranslation();

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.container}>
        <Typography variant="text-base-semibold">
          {t('No.reviews.yet')}
        </Typography>
        <Typography
          variant="text-sm-light"
          color={Colors['default-gray-200']}
          width={260}
        >
          {t('Here.you.will.see.all.reviews.that.users.left.for.your.business')}
        </Typography>
      </Box>
    </Box>
  );
};

export default EmptyReview;
