import { Box, Divider, Typography } from '@mui/material';
import { type FC } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GoBackLink, QuestionTextarea } from 'components/atoms';
import { Mantra, UpgradeToPremium } from 'components/molecules';
import UseSameAsDropdown from 'components/organisms/useSameAsDropdown/UseSameAsDropdown';
import { EXAMPLE_MANTRAS } from 'environment/constants/exampleMantras';
import { useSignUpRoutes } from 'hooks/signUpNavigation';
import Colors from 'styles/colors.scss';

import styles from './PersonalizeQuestionsForm.module.scss';

interface IProps {
  withBackButton?: boolean;
  withUseAsDropdown?: boolean;
  onBackButtonOverride?: () => void;
  hidePromotion?: boolean;
}

const PersonalizeQuestionsForm: FC<IProps> = ({
  withBackButton = true,
  withUseAsDropdown = false,
  onBackButtonOverride,
  hidePromotion,
}) => {
  const { t } = useTranslation();

  const signUpRoutes = useSignUpRoutes();

  const { control, clearErrors } = useFormContext();

  const { fields } = useFieldArray({
    control,
    name: 'questions',
  });

  const handleBackButtonClick = () => {
    onBackButtonOverride?.();
    clearErrors('root.serverError');
  };

  return (
    <Box width="756px">
      <Box className={styles.headerWithExamples}>
        <Box className={styles.header}>
          <Box className={styles.heading}>
            {withBackButton ? (
              <GoBackLink
                onClick={handleBackButtonClick}
                navigateTo={
                  onBackButtonOverride ? undefined : signUpRoutes.fifteenTags
                }
              />
            ) : (
              <Box></Box>
            )}
            <Typography
              marginLeft={withBackButton ? '-68px' : undefined}
              variant="small-app-title"
            >
              {t('Personalize.your.business')}
            </Typography>
            <Box></Box>
          </Box>

          <Box className={styles.bottomTextContainer}>
            <Typography
              variant="text-base-light"
              color={Colors['default-gray-200']}
            >
              {t(
                'Tell.customers.a.little.bit.about.what.it.would.be.like.to.visit.you.',
              )}
            </Typography>
            <Box>
              <Typography
                variant="text-base-light"
                color={Colors['default-gray-200']}
              >
                {t('These.help.you')}
              </Typography>
              <Typography
                variant="text-base-bold"
                color={Colors['default-gray-50']}
              >
                {` ${t('stand.out')} `}
              </Typography>
              <Typography
                variant="text-base-light"
                color={Colors['default-gray-200']}
              >
                {t('from.the.crowd.')}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className={styles.mantrasContainer}>
          <Typography variant="text-xl-semibold" color={Colors['HEXB8FE61']}>
            {t('Examples')}
          </Typography>
          <Box className={styles.mantras}>
            {EXAMPLE_MANTRAS.map(({ id, title, text }) => (
              <Mantra key={id} title={t(title)} text={t(text)} />
            ))}
          </Box>
        </Box>
      </Box>

      {withUseAsDropdown ? (
        <Box paddingTop="32px" paddingBottom="16px">
          <UseSameAsDropdown
            field="questions"
            label={t('Use.same.questions.as')}
          />
        </Box>
      ) : (
        <Divider orientation="horizontal" className={styles.divider} />
      )}

      <Box className={styles.questions}>
        {fields.map(({ id }, index) => (
          <QuestionTextarea key={id} name={`questions.${index}`} />
        ))}
        {!hidePromotion && (
          <UpgradeToPremium title={t('Add.more.questions!')} />
        )}
      </Box>
    </Box>
  );
};

export default PersonalizeQuestionsForm;
