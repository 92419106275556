import { Box, Typography } from '@mui/material';

import styles from './Mark.module.scss';

interface IProps {
  percentage: number;
}

const Mark = ({ percentage }: IProps) => {
  return (
    <Box className={styles.container}>
      <Typography
        variant="text-sm"
        className={styles.text}
      >{`+${percentage}%`}</Typography>
    </Box>
  );
};

export default Mark;
