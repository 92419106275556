// those strings down below are the i18n properties & ids for the backend

const WE_ARE_SEEKING = ['we-are-looking-for'];

const QUIRKS_AND_PERSONAL_TRAITS = [
  'my-most-irrational-fear-is',
  'our-darkiest-trait-is',
  'most-people-dont-know',
  'did-you-know',
  'i-am-convinced-that',
];

const CHALLENGES_AND_DARES = ['i-bet-you-cannot', 'i-would-serve-an-alien'];

const BELIEFS_AND_VALUES = ['our-mantra-is', 'my-greatest-strength'];

const WEEKENDS_AND_RELAXATION = ['typical-sunday', 'i-wind-down-by'];

const ADVENTURE_AND_RISK_TAKING = ['biggest-risk-we-have-taken'];

const ATTRACTION_AND_PERCEPTION = [
  'visiting-me-is-like',
  'the-key-to-my-heart-is',
  'i-am-loved-because',
  'i-am-feared-because',
  'haters-gonna',
];

const GENERIC = [
  'i-am-a-connoisseur-of',
  'i-am-a-firm-believer-that',
  'the-best-advice-is-that',
  'i-have-a-soft-spot-for',
  'i-have-a-theory-that',
];

// category is the i18n property
// categoryId is the value sent to backend

export const QUESTIONS = [
  {
    category: 'whatWeAreSeeking',
    categoryId: 'WE_ARE_SEEKING',
    questions: WE_ARE_SEEKING,
  },
  {
    category: 'quirksAndPersonalTraits',
    categoryId: 'QUIRKS_AND_PERSONAL_TRAITS',
    questions: QUIRKS_AND_PERSONAL_TRAITS,
  },
  {
    category: 'challengesAndDares',
    categoryId: 'CHALLENGES_AND_DARES',
    questions: CHALLENGES_AND_DARES,
  },
  {
    category: 'beliefsAndValues',
    categoryId: 'BELIEFS_AND_VALUES',
    questions: BELIEFS_AND_VALUES,
  },
  {
    category: 'weekendsAndRelaxation',
    categoryId: 'WEEKENDS_AND_RELAXATION',
    questions: WEEKENDS_AND_RELAXATION,
  },
  {
    category: 'adventureAndRiskTaking',
    categoryId: 'ADVENTURE_AND_RISK_TAKING',
    questions: ADVENTURE_AND_RISK_TAKING,
  },
  {
    category: 'attractionAndPerception',
    categoryId: 'ATTRACTION_AND_PERCEPTION',
    questions: ATTRACTION_AND_PERCEPTION,
  },
  {
    category: 'generic',
    categoryId: 'GENERIC',
    questions: GENERIC,
  },
];
