import { Box } from '@mui/material';
import cn from 'classnames';
import React, { type FC, useCallback, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  AddressAutocomplete,
  InfoLabel,
  InputWithTopLabel,
  Modal,
} from 'components/atoms';
import { MapModal, MapPreviewButton } from 'components/molecules';
import {
  InputItem,
  businessDetailsInputs,
} from 'environment/constants/businessDetails';
import { AutocompleteOption } from 'environment/types';
import { SignUpBusinessFormData } from 'hooks/forms';

import FormAutocomplete from './FormAutocomplete';
import styles from './SignUpBusinessDetailsForm.module.scss';

type Address = {
  country: string;
  number: string;
  state: string;
  street: string;
  zipcode: string;
  city: string;
};

interface IProps {
  withLabel?: boolean;
  hideFirstLastName?: boolean;
  hideWebSite?: boolean;
}

const SignUpBusinessDetailsForm: FC<IProps> = ({
  withLabel = true,
  hideFirstLastName = false,
  hideWebSite = false,
}) => {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [addressField, setAddressField] = useState<string | undefined>();
  const [addressFieldError, setAddressFieldError] = useState<
    string | undefined
  >();

  const { control, trigger, getFieldState, getValues, resetField, setValue } =
    useFormContext<SignUpBusinessFormData>();

  const businessType = useWatch({ control, name: 'businessType.type' });

  const renderInput = useCallback((inputItem: InputItem) => {
    const { name, labelTitle, autoComplete } = inputItem;

    return (
      <InputWithTopLabel
        name={`businessDetails.${name}`}
        labelTitle={labelTitle}
        autoComplete={autoComplete}
        type="text"
      />
    );
  }, []);

  const { isTouched } = getFieldState('businessDetails.zipcode');

  const handleAfterSuccessfulCountryChange = () => {
    if (isTouched) {
      trigger('businessDetails.zipcode');
    }

    resetAddressFields();
  };

  const resetAddressFields = () => {
    resetField('businessDetails.number');
    resetField('businessDetails.state');
    resetField('businessDetails.street');
    resetField('businessDetails.zipcode');
    resetField('businessDetails.city');

    setAddressField('');
  };

  const selectedCountry = getValues(
    `businessDetails.${businessDetailsInputs.country.name}`,
  );

  const onItemSelect = (data: Address, fromMap?: boolean, textStr?: string) => {
    setValue('businessDetails.country', data.country);
    setValue('businessDetails.number', data.number);
    setValue('businessDetails.state', data.state);
    setValue('businessDetails.street', data.street);
    setValue('businessDetails.zipcode', data.zipcode);
    setValue('businessDetails.city', data.city);

    if (!data.street) {
      setAddressFieldError(t('invalid_address'));
    } else {
      setAddressFieldError(undefined);
    }
    let addressStr = '';

    if (data.street) {
      addressStr = addressStr + data.street;
    }

    if (data.number) {
      addressStr = addressStr?.length
        ? addressStr + ' ' + data.number
        : addressStr + data.number;
    }

    if (data.city) {
      addressStr = addressStr?.length
        ? addressStr + ', ' + data.city
        : addressStr + data.city;
    }

    if (data.state) {
      addressStr = addressStr?.length
        ? addressStr + ', ' + data.state
        : addressStr + data.state;
    }

    if (data.zipcode) {
      addressStr = addressStr?.length
        ? addressStr + ', ' + data.zipcode
        : addressStr + data.zipcode;
    }

    fromMap ? setAddressField(addressStr) : setAddressField(textStr);
  };

  return (
    <>
      <Box className={styles.container}>
        {renderInput(businessDetailsInputs.companyName)}
        {renderInput(businessDetailsInputs.phone)}
        {!hideFirstLastName && (
          <Box className={styles.rowItems}>
            {renderInput(businessDetailsInputs.firstName)}
            {renderInput(businessDetailsInputs.lastName)}
          </Box>
        )}

        {!hideWebSite && renderInput(businessDetailsInputs.websiteUrl)}
        <FormAutocomplete
          name={`businessDetails.${businessDetailsInputs.country.name}`}
          labelTitle={businessDetailsInputs.country.labelTitle}
          inputAutoComplete={businessDetailsInputs.country.autoComplete}
          options={
            businessDetailsInputs.country.options as AutocompleteOption[]
          }
          afterSuccessfulChange={handleAfterSuccessfulCountryChange}
        />
        <AddressAutocomplete
          labelTitle="business.address"
          selectedCountry={selectedCountry}
          onItemSelect={onItemSelect}
          defaultValue={addressField}
          error={addressFieldError}
        />
        <Box className={cn(styles.rowItems, styles.hidden)}>
          {renderInput(businessDetailsInputs.street)}
          {renderInput(businessDetailsInputs.number)}
        </Box>
        <Box className={styles.rowItems}>
          {renderInput(businessDetailsInputs.city)}
          {renderInput(businessDetailsInputs.state)}
        </Box>
        {renderInput(businessDetailsInputs.zipcode)}
        <MapPreviewButton onClick={() => setOpenModal(true)} />
      </Box>

      {withLabel && businessType && (
        <InfoLabel text={t(getLabel(businessType))} />
      )}
      <Modal open={openModal}>
        <MapModal
          onClose={() => setOpenModal(false)}
          onSubmit={(address: Address) => {
            onItemSelect(address, true);
            setOpenModal(false);
          }}
        />
      </Modal>
    </>
  );
};

function getLabel(
  businessType: SignUpBusinessFormData['businessType']['type'],
) {
  if (businessType === 'food-truck') {
    return 'put.your.most.frequent.location';
  } else if (businessType === 'one-location') {
    return 'You.can.always.add.more.locations';
  } else if (businessType === 'multiple-locations') {
    return 'Dont.worry.you.can.always.add.more.locations';
  } else {
    return '';
  }
}

export default SignUpBusinessDetailsForm;
