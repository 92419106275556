import { FC } from 'react';

import { LoaderOverlay } from 'components/atoms';
import { AuthContainer } from 'components/molecules';
import { LoginForm } from 'components/organisms';

interface IProps {
  isLoading?: boolean;
}

const LoginTemplate: FC<IProps> = ({ isLoading = false }) => {
  return (
    <AuthContainer>
      {isLoading && <LoaderOverlay />}
      <LoginForm />
    </AuthContainer>
  );
};

export default LoginTemplate;
