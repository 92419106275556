import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { COLORS_VIEW_CHARTS } from 'environment/constants/colorsViewCharts';
import { repeatIndexByCircle } from 'environment/utils/repeatIndexByCircle';
import { IDataByAge } from 'store/models/engagement';

import ViewOnlyGraph from '../charts/view-only-graph/ViewOnlyGraph';

import { demographicRanges, mockDataDemographics } from './constants';
import styles from './VisitorDemographics.module.scss';

interface IProps {
  data: IDataByAge[];
  title: string;
  isUnavailable: boolean;
}

const VisitorDemographics = ({ title, isUnavailable }: IProps) => {
  const { t } = useTranslation();

  const renderDemographicCards = demographicRanges.map(
    ({ value, label }, index) => {
      const colorIndex = repeatIndexByCircle(index, COLORS_VIEW_CHARTS.length);

      return (
        <Box key={value} className={styles.demographicCard}>
          <Box className={styles.demographicHeader}>
            <Typography
              className={styles.demographicRangeText}
              variant="text-sm-light"
            >
              {label}
            </Typography>
            <Typography variant="text-lg">
              {/* todo replace 0 with real data */}
              {!isUnavailable ? '25.5k' : 0}
            </Typography>
          </Box>
          <Box className={styles.viewGraphWrapper}>
            {!isUnavailable ? (
              <ViewOnlyGraph
                type="areaspline"
                data={
                  mockDataDemographics[
                    value as keyof typeof mockDataDemographics
                  ].data
                }
                colors={[COLORS_VIEW_CHARTS[colorIndex].color]}
                gradient={COLORS_VIEW_CHARTS[colorIndex].gradient}
              />
            ) : (
              // todo add ViewOnlyGraph with realData and check if all data equal 0
              ''
            )}
          </Box>
        </Box>
      );
    },
  );

  return (
    <Box className={styles.wrapper}>
      <Typography
        className={styles.title}
        component="h4"
        variant="text-base-light"
      >
        {t(title)}
      </Typography>
      <Typography component="p" variant="text-2xl">
        {/* todo replace 0 with real data */}
        {!isUnavailable ? '19.6K' : 0}
      </Typography>
      <Typography className={styles.gainedText} component="p" variant="text-xs">
        {t('You.gained')}{' '}
        <Typography className={styles.gainedValue} variant="text-xs">
          {/* todo replace 0 with real data */}
          {!isUnavailable ? '+420.00' : 0}
        </Typography>{' '}
        {t('this.week')}
      </Typography>
      <Box className={styles.demographicsBlock}>{renderDemographicCards}</Box>
    </Box>
  );
};

export default VisitorDemographics;
