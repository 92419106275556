import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import styles from './FeaturedWaterfallsButtons.module.scss';

interface IProps {
  handleOpenPreviewWaterfall: (index: number) => void;
}

const FeaturedWaterfallsButtons = ({ handleOpenPreviewWaterfall }: IProps) => {
  const { t } = useTranslation();

  return (
    <Box className={styles.buttonsWrapper}>
      <Box className={styles.buttonsContainer}>
        <Box className={styles.oneButtonWrapper}>
          <Button
            className={styles.oneButton}
            variant="outlined"
            type="button"
            onClick={() => handleOpenPreviewWaterfall(0)}
          >
            {t('View.72hr.Waterfall')}
          </Button>
        </Box>

        <Box className={styles.oneButtonWrapper}>
          <Button
            className={styles.oneButton}
            variant="outlined"
            type="button"
            onClick={() => handleOpenPreviewWaterfall(1)}
          >
            {t('View.Featured.Waterfall')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default FeaturedWaterfallsButtons;
