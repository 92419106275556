import { Box, Button, Divider, Typography } from '@mui/material';
import cn from 'classnames';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ScrollContainer } from 'components/atoms';
import { TagDropdown } from 'components/molecules';
import SettingsSection from 'components/molecules/settingsSection/SettingsSection';
import { CategoriesOfTags } from 'environment/types/api/shared';
import Colors from 'styles/colors.scss';

import EditTags from '../../modals/editTags/EditTags';

import styles from './CurrentTags.module.scss';
import { getCurrentAndAvailableTags } from './utils';

interface IProps {
  tags: Required<CategoriesOfTags>;
  categoryTag?: string;
}

const CurrentTags: FC<IProps> = ({ tags, categoryTag }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(false);

  const [currentTags, availableTags] = useMemo(
    () => getCurrentAndAvailableTags(tags),
    [tags],
  );

  return (
    <ScrollContainer className={styles.scrollContainer}>
      <EditTags
        open={open}
        onClose={() => setOpen(false)}
        defaultValues={{ tags, categoryTag: { variant: categoryTag } }}
      />
      <SettingsSection padding="40px 20px 40px 40px">
        <Box className={styles.header}>
          <Typography variant="text-xl">{t('Current.Tags')}</Typography>
          <Button
            variant="outlined"
            className={cn(styles.descriptionBtn, {
              [styles.notActive]: categoryTag === 'date-night',
            })}
          >
            <Typography
              className={cn(styles.buttonText, {
                [styles.notActive]: categoryTag === 'date-night',
              })}
            >
              {t('Light_bite')}
            </Typography>
          </Button>
          <Button
            variant="outlined"
            className={cn(styles.descriptionBtn, {
              [styles.notActive]: categoryTag === 'light-bite',
            })}
          >
            <Typography
              className={cn(styles.buttonText, {
                [styles.notActive]: categoryTag === 'light-bite',
              })}
            >
              {t('Date_night')}
            </Typography>
          </Button>
          <Button variant="outlined" onClick={() => setOpen(true)}>
            <Typography className={cn(styles.buttonText, styles.notActive)}>
              {t('Edit')}
            </Typography>
          </Button>
        </Box>

        <Box className={styles.tagsList}>
          {currentTags.map(({ name, category, emoji }) => (
            <TagDropdown
              key={name}
              category={name}
              label={t(`tagsLabels.${category}.${name}`)}
              emoji={emoji}
              size="small"
              withGradient
            />
          ))}
        </Box>

        <Divider
          orientation="horizontal"
          sx={{ marginY: '12px', background: Colors['default-gray-600'] }}
        />

        <Box className={styles.availableTagsContainer}>
          <Typography variant="text-xl">{t('Other.available.tags')}</Typography>
          <Box className={styles.tagsList}>
            {availableTags.map(({ name, emoji }) => (
              <TagDropdown
                key={name}
                category={name}
                label={t(`tagsCategories.${name}`)}
                emoji={emoji}
                size="small"
              />
            ))}
          </Box>
        </Box>
      </SettingsSection>
    </ScrollContainer>
  );
};

export default CurrentTags;
