import axios from 'axios';
import { useSnackbar } from 'notistack';
import React from 'react';
import { FieldValues, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { OnboardingWrapper } from 'components/atoms';
import { SignUpMainTemplate } from 'components/templates';
import { ROUTES } from 'environment/constants/routes';
import { useSignUpForm } from 'hooks/forms';
import { Dispatch } from 'store';

const CredentialsTemplate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<Dispatch>();

  const onSubmit = async ({ email, password }: FieldValues) => {
    try {
      await dispatch.onboarding.setClaimCredetials({ email, password });
      navigate(ROUTES.onboarding.verify);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(error.response?.data?.message ?? error.message, {
          variant: 'error',
        });
      }
    }
  };

  const form = useSignUpForm();

  return (
    <OnboardingWrapper>
      <FormProvider {...form}>
        <SignUpMainTemplate
          hideRedirectToSignIn
          onSubmit={form.handleSubmit(onSubmit)}
          heading={t('setup-your-email-and-password')}
          onSubmitText={t('Create.Account')}
        />
      </FormProvider>
    </OnboardingWrapper>
  );
};

export default React.memo(CredentialsTemplate);
