import { createModel } from '@rematch/core';
import axios from 'axios';

import {
  createBusinessUnit,
  deleteBusinessUnitAvatar,
  getBusinessUnits,
  updateBusinessUnitAvatar,
} from 'environment/api/services/business-units';
import { uploadMedia } from 'environment/api/services/media';
import { IBusinessUnit } from 'environment/types/business';
import { castArrayToObjectWithIdsAsKeys } from 'environment/utils/castArrayToObjectWithIdsAsKeys';
import { NewBusinessFormData } from 'hooks/forms';
import {
  castBusinessTypeForBackend,
  castQuestionsForBackend,
  castScheduleForBackend,
  castTagsForBackend,
} from 'hooks/forms/helpers';

import { MediaTypes } from '../../environment/constants';

import type { RootModel } from '.';

export interface UnitsState {
  businessUnits: Record<string, IBusinessUnit>;
  currentBusinessUnitId: string;
}

const initialState: UnitsState = {
  businessUnits: {},
  currentBusinessUnitId: '',
};

export const units = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setBusinessUnits: (state, payload: IBusinessUnit[]) => {
      return {
        ...state,
        businessUnits: castArrayToObjectWithIdsAsKeys(payload),
      };
    },

    updateCurrentUnitId: (state, currentBusinessUnitId: string) => {
      return { ...state, currentBusinessUnitId };
    },

    addBusinessUnit: (state, payload: IBusinessUnit) => {
      return {
        ...state,
        businessUnits: {
          ...state.businessUnits,
          ...castArrayToObjectWithIdsAsKeys([payload]),
        },
      };
    },

    updateCurrentBusinessUnit: (
      state,
      payload: Partial<IBusinessUnit> & { id: string },
    ) => {
      return {
        ...state,
        businessUnits: {
          ...state.businessUnits,
          [payload.id]: {
            ...state.businessUnits[payload.id],
            ...payload,
          },
        },
      };
    },
  },
  effects: (dispatch) => ({
    async getBusinessUnits() {
      try {
        const { data } = await getBusinessUnits();

        dispatch.units.setBusinessUnits(data);

        const id = localStorage.getItem('unit_id');

        if (id && data.some((wf) => wf.id === id)) {
          dispatch.units.updateCurrentUnitId(id);
        } else {
          dispatch.units.setCurrentBusinessUnit(data[0].id);
        }

        return Promise.resolve(data);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async createBusinessUnit(payload: NewBusinessFormData) {
      if (!payload.businessType.type) return;

      try {
        const category = castBusinessTypeForBackend(payload.businessType);
        const questions = castQuestionsForBackend(payload.questions);
        const { subscription, cardToken } = payload;
        const {
          phone: contactNumber,
          city,
          country,
          state,
          zipcode,
          street,
          number,
          companyName,
          websiteUrl,
        } = payload.businessDetails;
        const operationHours = castScheduleForBackend(payload.schedule);
        const businessUnitPickedLabels = castTagsForBackend(payload.tags);

        const { data } = await createBusinessUnit({
          categoryTag: payload.businessDescription.variant,
          category,
          companyName: companyName.trim(),
          contactNumber: contactNumber.trim(),
          websiteUrl: websiteUrl.trim() || undefined,
          location: {
            city: city.trim(),
            country: country.trim(),
            zipcode: zipcode.trim() || undefined,
            street: street.trim(),
            state: state.trim() || undefined,
            number: number.trim() || undefined,
          },
          questions,
          operationHours,
          businessUnitPickedLabels,
          subscription,
          cardToken,
        });

        dispatch.units.addBusinessUnit(data);

        dispatch.units.setCurrentBusinessUnit(data.id);

        const { email, fullName, permission: scope } = payload.member;

        if (email && fullName && scope) {
          dispatch.business
            .createMember({
              email: email.trim(),
              fullName: fullName.trim(),
              scope,
              businessUnitId: data.id,
            })
            .catch(console.error);
        }

        dispatch.folders.getAllFolders();

        return Promise.resolve({ data });
      } catch (error) {
        return Promise.reject(error);
      }
    },

    setCurrentBusinessUnit: (id: string) => {
      localStorage.setItem('unit_id', id);
      dispatch.units.updateCurrentUnitId(id);
    },

    setBusinessUnitAvatar: async ({
      ownerId,
      file,
      type,
    }: {
      ownerId: string;
      file: Blob;
      type: string;
    }) => {
      try {
        const {
          data: {
            body: { url, id: mediaId },
          },
        } = await uploadMedia({
          ownerId,
          type: MediaTypes.Photo,
          owner: 'business_unit',
        });

        await axios.put(url, file, {
          headers: {
            'Content-Type': type,
          },
        });

        const { data: avatar } = await updateBusinessUnitAvatar(ownerId, {
          mediaId,
        });

        dispatch.units.updateCurrentBusinessUnit({ id: ownerId, avatar });
        return Promise.resolve(avatar);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    deleteBusinessUnitAvatar: async (id: string) => {
      try {
        const request = deleteBusinessUnitAvatar(id);
        dispatch.units.updateCurrentBusinessUnit({ id, avatar: null });
        return Promise.resolve((await request).data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  }),
});
