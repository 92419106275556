import { UseFormReturn, useForm } from 'react-hook-form';

import { ADD_REPLY_SCHEMA } from 'environment/validation/resolvers';

const defaultValues = {
  reply: '',
  images: [] as File[],
  reviewId: '',
};

export type AddReplyFormData = typeof defaultValues;

export const useAddReplyForm = () => {
  return useForm({
    mode: 'onBlur',
    defaultValues,
    resolver: ADD_REPLY_SCHEMA,
  });
};

export type AddReplyFormSubmitter = (data: AddReplyFormData) => void;

export type AddReplyForm = UseFormReturn<AddReplyFormData>;
