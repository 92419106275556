import { Box, Divider, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import emptyWallet from 'assets/icons/empty-wallet.svg';
import usePayments from 'hooks/usePayments';
import Colors from 'styles/colors.scss';

import styles from './PaymentHistoryList.module.scss';

const humanizeAmount = (amount: number) => {
  return (amount / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'HKD',
  });
};

const PaymentHistoryList = () => {
  const { t } = useTranslation();

  const { unitPayments } = usePayments();

  if (!unitPayments.length) {
    return (
      <Box className={styles.emptyBlock}>
        <Box sx={{ width: 280, textAlign: 'center' }}>
          <Box
            component="img"
            src={emptyWallet}
            alt="empty wallet"
            width={80}
            height={80}
            sx={{ marginBottom: '10px' }}
          />
          <Typography>
            {t('Here.you.will.see.all.your.payments.activity')}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" gap="24px">
      <Grid container>
        <Grid item xs={3}>
          <Typography variant="text-lg">{t('Date')}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="text-lg">{t('type')}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="text-lg">{t('status')}</Typography>
        </Grid>
        <Grid item xs={2} textAlign="right">
          <Typography align="right" variant="text-lg">
            {t('amount')}
          </Typography>
        </Grid>
        <Grid item xs={3} textAlign="right">
          <Typography align="right" variant="text-lg">
            {t('invoice')} #
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      {unitPayments.map(
        ({
          id,
          createdAt,
          type,
          status,
          amount,
          stripeInvoice,
          stripeInvoiceUrl,
        }) => (
          <Fragment key={id}>
            <Grid container>
              <Grid item xs={3}>
                <Typography>
                  {dayjs(createdAt).format('MMMM, DD YYYY')}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography textTransform="capitalize">{type}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography textTransform="capitalize">{status}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography align="right">{humanizeAmount(amount)}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Box component={Link} target="_blank" to={stripeInvoiceUrl}>
                  <Typography
                    color={Colors['HEXB8FE61']}
                    sx={{ cursor: 'pointer' }}
                    align="right"
                  >
                    {stripeInvoice}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Divider />
          </Fragment>
        ),
      )}
    </Box>
  );
};

export default React.memo(PaymentHistoryList);
