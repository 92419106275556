import { TableBody, TableCell, TableRow } from '@mui/material';
import { useMemo, useState } from 'react';

import { TableHeader, TableRoot } from 'components/atoms/tablePrimitives';
import { TablePagination } from 'components/molecules';
import { Order } from 'environment/types';
import { sortTableData } from 'environment/utils/sortEngagementsTable';
import { IReviewProcessed } from 'types';

import { columns } from './columns';
import styles from './RecentActivityReviewsTable.module.scss';

interface IProps {
  tableData: IReviewProcessed[];
}

type Property = (typeof columns)[number]['id'];

const RecentActivityReviewsTable = ({ tableData }: IProps) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<Property>('date');
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const sortedRows = useMemo(() => {
    return sortTableData(tableData, orderBy, order);
  }, [tableData, orderBy, order]);

  const visibleRows = useMemo(() => {
    return sortedRows.slice(startIndex, endIndex);
  }, [startIndex, endIndex, sortedRows]);

  const handleRequestSort = (property: Property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (newPage: number) => setPage(newPage);

  const handleChangeRowsPerPage = (value: string) => {
    setRowsPerPage(parseInt(value, 10));
  };

  return (
    <>
      <TableRoot
        pagination={
          <TablePagination
            page={page}
            rowsPerPage={rowsPerPage}
            count={tableData.length}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        }
      >
        <TableHeader
          columns={columns}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {tableData &&
            visibleRows.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {columns.map((column, columnIndex) => (
                  <TableCell
                    key={column.id}
                    className={styles.cell}
                    align={columnIndex > 0 ? 'center' : 'left'}
                    padding="none"
                  >
                    {column.renderCell(row)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </TableRoot>
    </>
  );
};
export default RecentActivityReviewsTable;
