import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { LoaderOverlay } from 'components/atoms';
import { Plans } from 'components/organisms';
import { signUpBusinessSubscription } from 'environment/api/services/auth';
import { useSignUpRoutes } from 'hooks/signUpNavigation';

const SignUpSubscription = () => {
  const signUpRoutes = useSignUpRoutes();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (subscription: string) => {
    try {
      setIsLoading(true);

      await signUpBusinessSubscription({
        subscription,
      });

      navigate(signUpRoutes.cardDetails);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <LoaderOverlay />}
      <Plans onSubmit={onSubmit} />
    </>
  );
};

export default SignUpSubscription;
