import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { FormActions, OnboardingWrapper } from 'components/atoms';
import { SignUpOtpTemplate } from 'components/templates';
import { signUpGeneral } from 'environment/api/services/auth';
import { ROUTES } from 'environment/constants/routes';
import { BUSINESS_UNIT_CLAIM_STAGES } from 'environment/types/claim';
import { Dispatch, RootState } from 'store';

const VerifyTemplate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<Dispatch>();
  const [status, setStatus] = useState<'success' | 'error' | null>(null);
  const [message, setMessage] = useState('');

  const { email, stages, password } = useSelector(
    (state: RootState) => state.onboarding,
  );

  const form = useForm({
    values: { email },
  });

  useEffect(() => {
    if (!email) {
      navigate(ROUTES.onboarding.credentials);
    }
  }, [email]);

  const handleComplete = async (code: string) => {
    try {
      const data = await dispatch.onboarding.verifyClaimCredetials(code.trim());
      if (data) {
        localStorage.setItem('access_token', data.token);
        setStatus('success');

        if (data.progress.isByNolemon) {
          await dispatch.onboarding.completeClaim();
          navigate(ROUTES.dashboard.home);
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setStatus('error');
        setMessage(error.response?.data?.message ?? '');
        enqueueSnackbar(error.response?.data?.message ?? error.message, {
          variant: 'error',
        });
      } else {
        console.error(error);
      }
    }
  };

  const handleResend = async () => {
    try {
      if (email && password) {
        await signUpGeneral({ email, password });
      }
      enqueueSnackbar(t('code_resent'), {
        variant: 'success',
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(error.response?.data?.message ?? error.message, {
          variant: 'error',
        });
      } else {
        console.error(error);
      }
    }
  };

  const handleNext = () => {
    switch (true) {
      case stages.includes(BUSINESS_UNIT_CLAIM_STAGES.labelsSet):
        navigate(ROUTES.onboarding.questions);
        break;
      case stages.includes(BUSINESS_UNIT_CLAIM_STAGES.categorySet):
        navigate(ROUTES.onboarding.tags);
        break;
      default:
        navigate(ROUTES.onboarding.describe);
        break;
    }
  };

  return (
    <OnboardingWrapper>
      <FormProvider {...form}>
        <SignUpOtpTemplate
          status={status}
          message={message}
          onComplete={handleComplete}
          onResend={handleResend}
          onNext={handleNext}
          hideControls
          hideGoBack
        />
      </FormProvider>
      <FormActions
        onCancel={() => navigate(ROUTES.onboarding.credentials)}
        onSubmit={handleNext}
        disableSubmit={status !== 'success'}
      />
    </OnboardingWrapper>
  );
};

export default React.memo(VerifyTemplate);
