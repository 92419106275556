import { Box, Typography } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import emptyCircle from 'assets/icons/emptyCircle.svg';
import { DashboardCardWrapper, TitleAndProgress } from 'components/atoms';
import { DonutChart } from 'components/molecules';
import { ROUTES } from 'environment/constants/routes';
import { roundNumberToDecimal } from 'environment/utils/roundNumberToDecimal';
import { IConsumerDemographics } from 'store/models/dashboard';
import Colors from 'styles/colors.scss';

import { donutChartColors, zeroDataArray } from './constants';
import styles from './ConsumerDemographicsCard.module.scss';

interface IAgeGroup {
  min: number;
  max: number;
}

interface IProps {
  consumerDemographicsData: IConsumerDemographics;
}

const ConsumerDemographicsCard = ({ consumerDemographicsData }: IProps) => {
  const { t } = useTranslation();

  const actualData = consumerDemographicsData.ageGroups;

  const getTitleFromGroup = (group: IAgeGroup) => {
    const ageRanges = [
      { min: 0, max: 21, title: 'under 21' },
      { min: 22, max: 30, title: '21-30' },
      { min: 31, max: 45, title: '30-45' },
    ];

    const defaultTitle = '45+';

    const matchingRange = ageRanges.find(
      (range) => group.min >= range.min && group.max <= range.max,
    );

    return matchingRange ? t(matchingRange.title) : t(defaultTitle);
  };

  const isData = actualData.reduce((sum, { quantity }) => sum + quantity, 0);

  return (
    <DashboardCardWrapper
      title={t('Consumer.Demographics')}
      navigateTo={ROUTES.dashboard.consumerDemographics}
    >
      <Box className={styles.chartBlock}>
        {isData > 0 ? (
          <Box className={styles.chart}>
            <DonutChart
              data={actualData.map(({ quantity }) => quantity)}
              rounded={true}
              colors={donutChartColors}
              height={180}
              tooltip={false}
              subtitle={t('Total.users')}
              title={roundNumberToDecimal(
                actualData.reduce((sum, { quantity }) => sum + quantity, 0),
              )}
            />
          </Box>
        ) : (
          <Box className={styles.emptyBlock}>
            <Box component="img" src={emptyCircle} />
            <Box className={styles.zeroUsersBlock}>
              <Typography
                variant="text-sm-light"
                color={Colors['default-gray-200']}
              >
                {t('Total.users')}
              </Typography>
              <Typography variant="text-2xl">0</Typography>
            </Box>
          </Box>
        )}

        {isData ? (
          <Box className={styles.cardsWrapper}>
            {actualData.map(({ group, quantity }, index) => {
              const title = getTitleFromGroup(group);
              return (
                <Box key={title} className={styles.cardBody}>
                  <TitleAndProgress
                    data={quantity}
                    title={title}
                    color={donutChartColors[index]}
                    isPercentValue={false}
                  />
                </Box>
              );
            })}
          </Box>
        ) : (
          <Box className={styles.cardsWrapper}>
            {zeroDataArray.map(({ group, quantity }, index) => {
              const title = getTitleFromGroup(group);
              return (
                <Box key={title} className={styles.cardBody}>
                  <TitleAndProgress
                    data={quantity}
                    title={title}
                    color={donutChartColors[index]}
                    isPercentValue={false}
                  />
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </DashboardCardWrapper>
  );
};

export default memo(ConsumerDemographicsCard);
