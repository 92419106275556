import { Box, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import styles from './DashboardCardWrapper.module.scss';

interface IProps {
  navigateTo: string;
  title: string;
  children: React.ReactNode;
  isRecentActivity?: boolean;
  padding?: string;
  gap?: string;
}

const DashboardCardWrapper = ({
  navigateTo,
  title,
  children,
  isRecentActivity = false,
  padding = '24px',
  gap = '24px',
}: IProps) => {
  return (
    <Box
      component={Link}
      to={navigateTo}
      padding={padding}
      gap={gap}
      className={`${styles.wrapper} ${
        isRecentActivity ? styles.recentActivityBlock : ''
      }`}
    >
      <Box className={styles.header}>
        <Typography variant="text-lg">{title}</Typography>
      </Box>
      {children}
    </Box>
  );
};

export default DashboardCardWrapper;
