import { Box, Typography, type TypographyTypeMap } from '@mui/material';
import { type FC } from 'react';

import IconMark from '../icon/IconMark';

import styles from './InfoLabel.module.scss';

interface IProps {
  text: string;
  textProps?: TypographyTypeMap['props'];
}

const InfoLabel: FC<IProps> = ({ text, textProps }) => {
  return (
    <Box className={styles.container}>
      <IconMark name="info" size="20px" className={styles.gradient} />
      <Typography variant="text-sm-lighter" {...textProps}>
        {text}
      </Typography>
    </Box>
  );
};

export default InfoLabel;
