import { MenuItem, Tooltip } from '@mui/material';
import { FC, ReactElement, memo, useState } from 'react';
import { InView } from 'react-intersection-observer';

import styles from './TagDropdown.module.scss';
import InfoTooltip from './tooltip/InfoTooltip';

interface Props {
  value: string;
  description: string;
  onClick: () => void;
  children: ReactElement;
}

const TooltipMenuItem: FC<Props> = ({
  value,
  description,
  onClick,
  children,
}) => {
  const [hovered, setHovered] = useState(false);

  return (
    <InView>
      {({ inView, ref }) => (
        <MenuItem
          ref={ref}
          value={value}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          className={styles.dropdownItemWrapper}
          onClick={onClick}
        >
          <Tooltip
            open={inView && hovered}
            title={<InfoTooltip text={description} />}
            placement="right"
            slotProps={{
              tooltip: {
                sx: { padding: 0, backgroundColor: 'transparent' },
              },
            }}
          >
            {children}
          </Tooltip>
        </MenuItem>
      )}
    </InView>
  );
};

export default memo(TooltipMenuItem);
