import { PAYMENTS } from 'environment/constants/url';
import {
  Payment,
  PaymentMethod,
  Plan,
  Subscription,
} from 'types/payments/payments';

import axios from '../axios';

export const getSubscriptionProducts = () => {
  return axios.get<Plan[]>(PAYMENTS.PLANS);
};

export const getProductByPriceId = (priceId: string) => {
  return axios.get<Plan>(`${PAYMENTS.PLAN_BY_PRICE_ID}/${priceId}`);
};

export const getPaymentMethods = (unitId: string) => {
  return axios.get<PaymentMethod[]>(`${PAYMENTS.PAYMENT_METHODS(unitId)}`);
};

export const addPaymentMethod = ({
  cardToken,
  unitId,
}: {
  cardToken: string;
  unitId: string;
}) => {
  return axios.post<PaymentMethod>(`${PAYMENTS.PAYMENT_METHODS(unitId)}`, {
    cardToken,
  });
};

export const removePaymentMethod = ({
  paymentMethodId,
  unitId,
}: {
  paymentMethodId: string;
  unitId: string;
}) => {
  return axios.delete(`${PAYMENTS.PAYMENT_METHODS(unitId)}`, {
    data: { paymentMethodId },
  });
};

export const getSubscription = (unitId: string) => {
  return axios.get<Subscription>(`${PAYMENTS.SUBSCRIPTIONS(unitId)}`);
};

export const switchSubscription = ({
  unitId,
  priceId,
}: {
  unitId: string;
  priceId: string;
}) => {
  return axios.patch<Subscription>(`${PAYMENTS.SUBSCRIPTIONS(unitId)}`, {
    priceId,
  });
};

export const cancelSubscription = (unitId: string) => {
  return axios.delete<Subscription>(`${PAYMENTS.SUBSCRIPTIONS(unitId)}`);
};

export const renewSubscription = ({
  unitId,
  priceId,
}: {
  unitId: string;
  priceId: string;
}) => {
  return axios.post<Subscription>(`${PAYMENTS.SUBSCRIPTIONS(unitId)}`, {
    priceId,
  });
};

export const getPayments = (unitId: string) => {
  return axios.get<Payment[]>(`${PAYMENTS.PAYMENTS(unitId)}`);
};
