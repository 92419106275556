import { Box, Typography } from '@mui/material';

import styles from './Percent.module.scss';

interface IProps {
  value: number;
  isValue?: boolean;
}

const Percent = ({ value, isValue = true }: IProps) => {
  return (
    <Box className={styles.percentBlock}>
      <Box
        className={`${styles.triangle} ${
          !value ? styles.zero : value < 0 ? styles.negative : ''
        }`}
      />
      {isValue && (
        <Typography
          className={`${styles.percent} ${
            !value ? styles.zero : value < 0 ? styles.negative : ''
          }`}
        >
          {value < 0 ? value * -1 : value}%
        </Typography>
      )}
    </Box>
  );
};
export default Percent;
