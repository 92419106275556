import { useDispatch, useSelector } from 'react-redux';

import { Dispatch, RootState } from 'store/index';

const useReviewReply = () => {
  const dispatch = useDispatch<Dispatch>();

  const { allReviewReplies } = useSelector(
    (state: RootState) => state.reviewReply,
  );

  const getAllReviewReplies = (reviewId: string) => {
    dispatch.reviewReply.getAllReviewReplies({
      reviewId,
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const replyOnReview = (
    businessUnitId: string,
    text: string,
    ownerId: string,
  ) => {
    dispatch.reviewReply.replyOnReview({
      businessUnitId,
      text,
      ownerId,
    });
  };

  return {
    allReviewReplies,
    getAllReviewReplies,
    replyOnReview,
  };
};

export default useReviewReply;
