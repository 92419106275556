import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';

import styles from './TextInputDefault.module.scss';

interface TextinputDefaultProps
  extends Omit<TextFieldProps, 'className' | 'error'> {}

const TextInputDefault = ({
  helperText,
  variant = 'filled',
  InputProps,
  ...rest
}: TextinputDefaultProps) => {
  return (
    <TextField
      variant={variant}
      error={Boolean(helperText)}
      helperText={helperText}
      InputProps={{
        ...InputProps,
        disableUnderline: true,
        className: styles.input,
      }}
      className={styles.field}
      {...rest}
    />
  );
};

export default TextInputDefault;
