import { Box, LinearProgress, Typography } from '@mui/material';
import React from 'react';

import styles from './FeaturedWaterfallTablePreview.module.scss';

interface IProps {
  badgeText: string;
  progress: number;
  mediaSrc?: string | null;
  isVideo: boolean;
  thumbnail?: string | null;
}

const FeaturedWaterfallTablePreview = ({
  badgeText,
  progress,
  mediaSrc,
  isVideo,
  thumbnail,
}: IProps) => {
  return (
    <Box className={styles.container}>
      <Box className={styles.mediaWithBadge}>
        {thumbnail ? (
          <Box component="img" width="65px" height="91px" src={thumbnail} />
        ) : mediaSrc ? (
          isVideo ? (
            <video
              className={styles.media}
              src={mediaSrc}
              loop
              muted
              width="65px"
              height="91px"
            />
          ) : (
            <img className={styles.image} src={mediaSrc} alt="waterfall" />
          )
        ) : (
          <Box width="65px" height="91px" />
        )}
        <Box className={styles.badge}>
          <Typography variant="text-xs">{badgeText}</Typography>
        </Box>
      </Box>
      <LinearProgress
        className={styles.progress}
        variant="determinate"
        value={progress}
      />
    </Box>
  );
};

export default FeaturedWaterfallTablePreview;
