import AMBIANCES from './ambiance';
import BEER from './beer';
import COFFEE from './coffee';
import CUISINES from './cuisine';
import DIET_OPTIONS from './diet-options';
import DOG_FRIENDLY from './dog-friendly';
import FAMILY_OWNED from './family-owned';
import FARM_PRODUCTS from './farm-products';
import FRESH_MEAT from './fresh-meat';
import FRESH_SEAFOOD from './fresh-seafood';
import HEALTH_DRINKS from './health-drinks';
import HERBAL from './herbal';
import KIDS_AREA from './kids-area';
import SPECIALS from './specials';
import SPICY_FOODS from './spicy-foods';
import TREATS from './treats';
import VIBES_AND_SERVICES from './vibe-and-service';
import WINE from './wine';

// name is the property for backend

const MAIN_DROPDOWNS = [
  {
    name: 'cuisineLabel',
    emoji: 'bg-cuisine',
    items: CUISINES,
  },
  {
    name: 'vibeAndServiceLabel',
    emoji: 'bg-vibeandservice',
    items: VIBES_AND_SERVICES,
  },
  {
    name: 'ambianceLabel',
    emoji: 'bg-ambiance',
    items: AMBIANCES,
  },
] as const;

const EXTRA_DROPDOWNS = [
  {
    name: 'farmProductsLabel',
    emoji: 'bg-farmproducts',
    items: FARM_PRODUCTS,
  },
  {
    name: 'freshMeatLabel',
    emoji: 'bg-freshmeat',
    items: FRESH_MEAT,
  },
  {
    name: 'specialsLabel',
    emoji: 'bg-dumplings',
    items: SPECIALS,
  },
  {
    name: 'spicyFoodsLabel',
    emoji: 'bg-spicyfood',
    items: SPICY_FOODS,
  },
  {
    name: 'kidsAreaLabels',
    emoji: 'bg-kidsarea',
    items: KIDS_AREA,
  },
  {
    name: 'freshSeafoodLabel',
    emoji: 'bg-freshseafood',
    items: FRESH_SEAFOOD,
  },
  {
    name: 'herbalLabel',
    emoji: 'bg-herbalteas',
    items: HERBAL,
  },
  {
    name: 'coffeeLabel',
    emoji: 'bg-coffee',
    items: COFFEE,
  },
  {
    name: 'dietOptionsLabel',
    emoji: 'bg-dietoptions',
    items: DIET_OPTIONS,
  },
  {
    name: 'healthDrinksLabel',
    emoji: 'bg-bubble_tea',
    items: HEALTH_DRINKS,
  },
  {
    name: 'wineLabel',
    emoji: 'bg-wineselections',
    items: WINE,
  },
  {
    name: 'beerLabel',
    emoji: 'bg-beer',
    items: BEER,
  },
  {
    name: 'treatsLabels',
    emoji: 'bg-treats',
    items: TREATS,
  },
  {
    name: 'familyOwnedLabel',
    emoji: 'bg-familyoperated',
    items: FAMILY_OWNED,
  },
  {
    name: 'dogFriendlyLabel',
    emoji: 'bg-dogfriendly',
    items: DOG_FRIENDLY,
  },
] as const;

type MainTagsCaterogies = (typeof MAIN_DROPDOWNS)[number]['name'];
type ExtraTagsCategories = (typeof EXTRA_DROPDOWNS)[number]['name'];

export {
  AMBIANCES,
  BEER,
  SPECIALS,
  COFFEE,
  CUISINES,
  DIET_OPTIONS,
  DOG_FRIENDLY,
  EXTRA_DROPDOWNS,
  FAMILY_OWNED,
  FARM_PRODUCTS,
  FRESH_MEAT,
  FRESH_SEAFOOD,
  HEALTH_DRINKS,
  KIDS_AREA,
  MAIN_DROPDOWNS,
  HERBAL,
  SPICY_FOODS,
  TREATS,
  VIBES_AND_SERVICES,
  WINE,
  type MainTagsCaterogies,
  type ExtraTagsCategories,
};
