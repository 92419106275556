import { Box } from '@mui/material';
import axios from 'axios';
import { type FC, useCallback, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { GoBackLink, LoaderOverlay } from 'components/atoms';
import Stepper from 'components/atoms/stepper/Stepper';
import SettingsModal from 'components/molecules/settingsModal/SettingsModal';
import {
  SignUpBusinessDescriptionTemplate,
  SignUpPersonalizeFifteenTagsTemplate,
  SignUpPersonalizeThreeTagsTemplate,
} from 'components/templates';
import { updateBusinessUnit } from 'environment/api/services/business-units';
import { CategoriesOfTags } from 'environment/types/api/shared';
import { castTagsForBackend } from 'hooks/forms/helpers';
import useUnits from 'hooks/useUnits';
import { Dispatch } from 'store';

import styles from './EditTags.module.scss';

interface IProps {
  open: boolean;
  onClose: () => void;
  defaultValues: {
    tags: Required<CategoriesOfTags>;
    categoryTag: { variant?: string };
  };
}

const EditTags: FC<IProps> = ({ open, onClose, defaultValues }) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useDispatch<Dispatch>();

  const {
    currentUnit: { id },
  } = useUnits();

  const { setError, ...form } = useForm({
    defaultValues,
    values: defaultValues,
    mode: 'onChange',
  });

  const handleClose = useCallback(() => {
    onClose();
    form.reset(defaultValues);
    setActiveStep(0);
  }, [form, onClose, defaultValues]);

  const onThreeTagsSubmit = () => {
    setActiveStep(2);
  };

  const onFifteenTagsSubmit: SubmitHandler<typeof defaultValues> = useCallback(
    async ({ tags, categoryTag }) => {
      try {
        setIsLoading(true);

        const { data } = await updateBusinessUnit(id, {
          businessUnitPickedLabels: castTagsForBackend(tags),
          categoryTag: categoryTag.variant,
        });

        dispatch.units.updateCurrentBusinessUnit({
          id,
          businessUnitPickedLabels: data.businessUnitPickedLabels,
          categoryTag: categoryTag.variant,
        });

        handleClose();
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setError('root.serverError', {
            type: error.code,
            message: error.response?.data?.message ?? error.message,
          });
        } else {
          console.error(error);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [handleClose, setError, id, dispatch.units],
  );

  const steps = useMemo(
    () => [
      // eslint-disable-next-line react/jsx-key
      <SignUpBusinessDescriptionTemplate
        onSubmit={() => setActiveStep(1)}
        defaultValue={defaultValues.categoryTag.variant}
        name="categoryTag"
      />,
      // eslint-disable-next-line react/jsx-key
      <SignUpPersonalizeThreeTagsTemplate
        onSubmit={onThreeTagsSubmit}
        withBackButton={false}
        withUseAsDropdown
      />,
      // eslint-disable-next-line react/jsx-key
      <SignUpPersonalizeFifteenTagsTemplate
        onSubmit={form.handleSubmit(onFifteenTagsSubmit)}
        withBackButton={false}
        withLabel={false}
        withUseAsDropdown
      />,
    ],
    [form, onFifteenTagsSubmit],
  );

  return (
    <SettingsModal open={open} onClose={handleClose}>
      {isLoading && <LoaderOverlay />}
      <FormProvider setError={setError} {...form}>
        <Box className={styles.header}>
          <GoBackLink
            onClick={
              activeStep === 0
                ? () => handleClose()
                : () => setActiveStep((s) => s - 1)
            }
          />
          <Stepper activeStep={activeStep} steps={3} />
        </Box>
        {steps[activeStep]}
      </FormProvider>
    </SettingsModal>
  );
};

export default EditTags;
