import { Box, Button, Typography } from '@mui/material';
import { type FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GoBackLink, ServerErrorMessage } from 'components/atoms';
import SignUpBusinessDetailsForm from 'components/organisms/signUpForms/SignUpBusinessDetails/SignUpBusinessDetailsForm';
import { useSignUpRoutes } from 'hooks/signUpNavigation';
import Colors from 'styles/colors.scss';

import styles from './SignUpBusinessDetails.module.scss';

interface IProps {
  onSubmit: () => void;
}

const SignUpBusinessDetailsTemplate: FC<IProps> = ({ onSubmit }) => {
  const { t } = useTranslation();

  const signUpRoutes = useSignUpRoutes();

  const {
    formState: { errors },
    clearErrors,
  } = useFormContext();

  return (
    <>
      <Box className={styles.container}>
        <Box>
          <GoBackLink
            navigateTo={signUpRoutes.description}
            onClick={() => clearErrors('root.serverError')}
          />
          <Typography component="p" textAlign="center" variant="text-2xl">
            {t('your.business.details')}
          </Typography>
        </Box>
        <SignUpBusinessDetailsForm />
      </Box>
      <Box>
        {errors.root?.serverError?.message ? (
          <Box maxWidth="480px" paddingBottom="32px">
            <ServerErrorMessage message={errors.root.serverError.message} />
          </Box>
        ) : null}
        <Button variant="contained" fullWidth onClick={onSubmit}>
          <Typography
            variant="text-base-semibold"
            color={Colors['default-gray-900']}
          >
            {t('Next')}
          </Typography>
        </Button>
      </Box>
    </>
  );
};

export default SignUpBusinessDetailsTemplate;
