import axios from 'axios';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { LoaderOverlay } from 'components/atoms';
import { SignUpBusinessDescriptionTemplate } from 'components/templates';
import { signUpBusinessDescription } from 'environment/api/services/auth';
import { type SignUpBusinessFormData } from 'hooks/forms';
import { useSignUpRoutes } from 'hooks/signUpNavigation';

const SignUpBusinessDescription = () => {
  const { control, setError, clearErrors } =
    useFormContext<SignUpBusinessFormData>();

  const businessDescription = useWatch({
    control,
    name: 'businessDescription',
  });

  const signUpRoutes = useSignUpRoutes();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async () => {
    if (businessDescription.variant === '') {
      return;
    }
    try {
      setIsLoading(true);
      await signUpBusinessDescription({
        categoryTag: businessDescription.variant,
      });
      navigate(signUpRoutes.details);
      clearErrors('root.serverError');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError('root.serverError', {
          type: error.code,
          message: error.response?.data?.message ?? error.message,
        });
      } else {
        console.error(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <LoaderOverlay />}
      <SignUpBusinessDescriptionTemplate onSubmit={onSubmit} />
    </>
  );
};

export default SignUpBusinessDescription;
