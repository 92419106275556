import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import camera from 'assets/icons/tabler_video.svg';
import Colors from 'styles/colors.scss';

import styles from './EmptyWaterfallPreview.module.scss';

interface IProps {
  width?: string;
  height: string;
  title: string;
}

const EmptyWaterfallPreview = ({ width, height, title }: IProps) => {
  const { t } = useTranslation();

  return (
    <Box className={styles.wrapper} sx={{ width, height }}>
      <Box className={styles.container}>
        <Box component="img" alt="camera" src={camera} width={57} height={57} />
        <Typography variant="text-base-semibold">
          {t('No.content.yet')}
        </Typography>
        <Typography variant="text-sm-light" color={Colors['default-gray-200']}>
          {t(title)}
        </Typography>
      </Box>
    </Box>
  );
};

export default EmptyWaterfallPreview;
