import moment from 'moment';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import ConsumerDemographicsTemplate from 'components/templates/consumerDemographics/ConsumerDemographics';
import useConsumerDemographics from 'hooks/useConsumerDemographics';
import useUnits from 'hooks/useUnits';
import { RootState } from 'store';
import {
  CustomPeriod,
  Period,
  TimelinePeriod,
} from 'types/business-unit-analytics';

const isCustomPeriod = (period: Period): period is CustomPeriod =>
  typeof period !== 'string';

const getStartDate = (period: Period): Date | undefined => {
  const now = new Date();
  if (isCustomPeriod(period)) {
    return period.start;
  }

  switch (period) {
    case TimelinePeriod.Day:
      return moment(now).subtract(24, 'hours').toDate();
    case TimelinePeriod.ThreeDays:
      return moment(now).subtract(72, 'hours').toDate();
    case TimelinePeriod.Week:
      return moment(now).subtract(1, 'week').toDate();
    default:
      return now;
  }
};

const getEndDate = (period: Period): Date | undefined => {
  const now = new Date();
  if (isCustomPeriod(period)) {
    return period.end;
  }

  switch (period) {
    case TimelinePeriod.AllTime:
      return;
    default:
      return now;
  }
};

const ConsumerDemographics = () => {
  const {
    counters,
    graph,
    getConsumerDemographicsCounters,
    getConsumerDemographicsGraph,
  } = useConsumerDemographics();
  const { currentUnit } = useUnits();

  const { analytics } = useSelector((state: RootState) => state.recentActivity);
  const { period } = useSelector((state: RootState) => state.recentActivity);

  useEffect(() => {
    if (currentUnit.id && period) {
      const startDate = getStartDate(period);
      const endDate = getEndDate(period);

      const startDateISOString = startDate && startDate.toISOString();
      const endDateISOString = endDate && endDate.toISOString();

      getConsumerDemographicsCounters(
        currentUnit.id,
        startDateISOString,
        endDateISOString,
      );
      getConsumerDemographicsGraph(
        currentUnit.id,
        startDateISOString,
        endDateISOString,
      );
    }
  }, [currentUnit.id, period]);

  return (
    <ConsumerDemographicsTemplate
      analytics={analytics}
      columnChartData={graph}
      chartCardData={counters}
      getConsumerDemographicsCounters={getConsumerDemographicsCounters}
    />
  );
};

export default ConsumerDemographics;
