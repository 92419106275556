import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import location from 'assets/icons/location.svg';
import Colors from 'styles/colors.scss';

import styles from './FoodTrackLocaition.module.scss';

interface IProps {
  country?: string;
  city?: string;
  street?: string;
  number?: string;
  updateLocation: () => void;
}

const FoodTrackLocation = ({
  country,
  city,
  street,
  number,
  updateLocation,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.location}>
        <Box src={location} component="img" />
        <Typography variant="text-base-semibold">{`${
          number && number
        } ${street}, ${city}, ${country}`}</Typography>
      </Box>
      <Button variant="contained" size="small" onClick={() => updateLocation()}>
        <Typography variant="text-sm-semibold" color={Colors['bg-color']}>
          {t('Update.live.location')}
        </Typography>
      </Button>
    </Box>
  );
};

export default FoodTrackLocation;
