import {
  StyledEngineProvider,
  ThemeProvider,
  styled,
} from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { MaterialDesignContent, SnackbarProvider } from 'notistack';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import 'styles/index.scss';
import Colors from 'styles/colors.scss';
import { theme } from 'styles/mui-theme';

import App from './App';
import './environment/plugins/i18n';
import { APP_VERSION } from './app.config';
import { store } from './store';

Sentry.init({
  dsn: 'https://484d2aa7c6ba0c6822951d5c9881f132@o4506184016658432.ingest.sentry.io/4506308538204160',
  tunnel: 'https://node-js-vercel-eight.vercel.app/home/tunnel',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: process.env.REACT_APP_TYPE,
  // Performance Monitoring
  release: APP_VERSION,
  tracesSampleRate: 1.0, // Capture 100% of the transaction
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    background: `linear-gradient(270deg, ${Colors['gr-landing']}) !important`,
    color: `${Colors['default-gray-900']} !important`,
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: `${Colors['linear-red-unlikes']} !important`,
  },
}));

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <SnackbarProvider
            Components={{
              success: StyledMaterialDesignContent,
              error: StyledMaterialDesignContent,
            }}
          >
            <App />
          </SnackbarProvider>
        </StyledEngineProvider>
      </ThemeProvider>
    </Provider>
  </BrowserRouter>,
);
