import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  PageHeader,
  RangeButtons,
  SpeedometerCard,
} from 'components/molecules';
import { ROUTES } from 'environment/constants/routes';

import { TimelinePeriod } from '../../../types/business-unit-analytics';

import styles from './BoostsActivity.module.scss';

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  speedometerData: any; // TODO add type
}

const BoostsActivity = ({ speedometerData }: IProps) => {
  const { t } = useTranslation();

  return (
    <Box className={styles.container}>
      <PageHeader
        title={t('Business.boosts.in.your.area')}
        backButtonNavigateTo={ROUTES.dashboard.home}
        rightSection={
          <RangeButtons
            period={TimelinePeriod.Day}
            setPeriod={() => undefined}
          />
        }
      />

      <Box className={styles.items}>
        {/* TODO add type */}
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        {speedometerData.map((data: any, index: any) => (
          <SpeedometerCard
            key={index}
            header={data.header}
            amount={data.amount}
            value={data.value}
            title={data.title}
          />
        ))}
      </Box>
    </Box>
  );
};

export default BoostsActivity;
