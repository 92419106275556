import { Box, Grid, Typography } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import doughnutBlurred from 'assets/images/doughnut-blurred.webp';
import paymentPageCompany from 'assets/images/payment-page-company.webp';
import paymentPageMeals from 'assets/images/payment-page-meals.webp';
import statsBlurred from 'assets/images/stats-blurred.webp';
import {
  BusinessPromoteActivity,
  BusinessPromoteImage,
  BusinessPromoteStats,
  FormActions,
} from 'components/atoms';
import { getPromotionData } from 'environment/api/services/claim';
import { ROUTES } from 'environment/constants/routes';
import { BUSINESS_UNIT_CLAIM_SOURCE } from 'environment/types/claim';
import { stringifyLocation } from 'environment/utils/stringifyLocation';
import { Dispatch, RootState } from 'store';
import Colors from 'styles/colors.scss';

const ConfirmBusinessTemplate = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const dispatch = useDispatch<Dispatch>();

  const { businessUnit, stats, onboardingType } = useSelector(
    (state: RootState) => state.onboarding,
  );

  useEffect(() => {
    const fetchData = async () => {
      if (businessUnit) {
        const result = await getPromotionData(businessUnit.id);
        dispatch.onboarding.setOnboarding({ stats: result.data });
      } else {
        navigate(ROUTES.onboarding.claimBusiness);
      }
    };
    fetchData();
  }, [businessUnit]);

  const handleMoveBack = () => {
    dispatch.onboarding.setOnboarding({ businessUnit: null });
    navigate(ROUTES.onboarding.claimBusiness);
  };

  const handleSubmit = async () => {
    if (!businessUnit) return;

    try {
      await dispatch.onboarding.startUnitClaim();
      // removed the logic to go to a specific step after it was finished.
      if (
        onboardingType === BUSINESS_UNIT_CLAIM_SOURCE.from_dashboard_settings
      ) {
        navigate(ROUTES.onboarding.copyBusiness);
      } else {
        navigate(ROUTES.onboarding.subscription);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(error.response?.data?.message ?? error.message, {
          variant: 'error',
        });
      } else {
        console.error(error);
      }
    }
  };

  const activity = stats?.totalActivity.total;
  const views = stats?.totalViews.total_count;
  const comments = stats?.totalComments.total_count;
  const likes = stats?.totalLikes.total_count;
  const change = stats?.totalActivity.change;
  const periods = useMemo(
    () => stats?.totalActivity.last7days.map(({ count }) => count),
    [stats?.totalActivity],
  );

  const promoteImage =
    stats?.featuredTopWaterfallPictureUrl || paymentPageCompany;

  const address = useMemo(
    () => stringifyLocation(businessUnit?.location || null),
    [businessUnit?.location],
  );
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      maxWidth="762px"
      gap="40px"
      px="12px"
      py="24px"
    >
      <Box display="flex" flexDirection="column" gap="16px">
        <Typography variant="app-title" fontWeight={500} align="center">
          {t('is_this_your_business')}
        </Typography>
        <Typography
          variant="text-base"
          fontWeight={500}
          align="center"
          color={Colors['default-gray-100']}
        >
          {address}
        </Typography>
      </Box>
      <Grid container spacing="22.494px" columns={{ xs: 1, sm: 8, md: 12 }}>
        <Grid item xs={2} sm={4}>
          <BusinessPromoteImage src={promoteImage} />
        </Grid>
        <Grid item xs={2} sm={4}>
          <BusinessPromoteImage src={paymentPageMeals} blurred />
        </Grid>
        <Grid item xs={2} sm={4}>
          <BusinessPromoteStats
            comments={comments}
            likes={likes}
            views={views}
          />
        </Grid>
        <Grid item xs={2} sm={4}>
          <BusinessPromoteImage src={doughnutBlurred} />
        </Grid>
        <Grid item xs={2} sm={4}>
          <BusinessPromoteActivity
            activity={activity}
            change={change}
            periods={periods}
          />
        </Grid>
        <Grid item xs={2} sm={4}>
          <BusinessPromoteImage src={statsBlurred} />
        </Grid>
      </Grid>
      <FormActions
        onCancel={handleMoveBack}
        onSubmit={handleSubmit}
        submitText={t('yes')}
      />
    </Box>
  );
};

export default React.memo(ConfirmBusinessTemplate);
