import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import PaymentsBalance from 'components/molecules/paymentsBalance/PaymentsBalance';
import PaymentHistoryList from 'components/organisms/paymentHistoryList/PaymentHistoryList';

import styles from './Payment.module.scss';

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  paymentsData: any; // TODO add type
}

const PaymentTemplate = ({ paymentsData }: IProps) => {
  const { t } = useTranslation();

  const handleAddMoney = () => {}; // TODO add function

  return (
    <Box className={styles.wrapper}>
      <Typography variant="app-title">{t('Payments')}</Typography>
      <PaymentsBalance
        balance={paymentsData.mainBalance}
        addMoney={handleAddMoney}
      />
      <PaymentHistoryList />
    </Box>
  );
};

export default PaymentTemplate;
