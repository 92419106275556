export const COUNTRIES_WITH_NO_ZIPCODE = [
  'Angola',
  'Antigua And Barbuda',
  'Aruba',
  'Bahamas',
  'Belize',
  'Benin',
  'Bermuda',
  'Bolivia',
  'Botswana',
  'Burkina Faso',
  'Burundi',
  'Cameroon',
  'Central African Republic',
  'Chad',
  'Comoros',
  'Congo',
  'Congo, Democratic Republic',
  'Cook Islands',
  'Cote D"Ivoire',
  'Djibouti',
  'Dominica',
  'Equatorial Guinea',
  'Eritrea',
  'Fiji',
  'French Southern Territories',
  'Gabon',
  'Gambia',
  'Ghana',
  'Grenada',
  'Guyana',
  'Heard Island & Mcdonald Islands',
  'Hong Kong',
  'Kiribati',
  'North Korea',
  'Libya',
  'Macao',
  'Malawi',
  'Mali',
  'Mauritania',
  'Nauru',
  'Netherlands',
  'Netherlands Antilles',
  'Niue',
  'Qatar',
  'Rwanda',
  'Saint Kitts And Nevis',
  'Sao Tome And Principe',
  'Seychelles',
  'Sierra Leone',
  'Solomon Islands',
  'Somalia',
  'Suriname',
  'Syria',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad And Tobago',
  'Tuvalu',
  'Uganda',
  'United Arab Emirates',
  'Vanuatu',
  'Yemen',
  'Zimbabwe',
] as const;
