import { Models } from '@rematch/core';

import { business } from './business';
import { consumer_demographics } from './consumer-demographics';
import { dashboard } from './dashboard';
import { engagement } from './engagement';
import { folders } from './folders';
import { onboarding } from './onboarding';
import { payments } from './payments';
import { recentActivity } from './recentActivity';
import { reviewReply } from './reviewReplies';
import { units } from './units';
import { waterfalls } from './waterfalls';

export interface RootModel extends Models<RootModel> {
  units: typeof units;
  consumer_demographics: typeof consumer_demographics;
  engagement: typeof engagement;
  reviewReply: typeof reviewReply;
  waterfalls: typeof waterfalls;
  dashboard: typeof dashboard;
  folders: typeof folders;
  business: typeof business;
  recentActivity: typeof recentActivity;
  payments: typeof payments;
  onboarding: typeof onboarding;
}

export const models: RootModel = {
  units,
  consumer_demographics,
  engagement,
  reviewReply,
  waterfalls,
  dashboard,
  folders,
  business,
  recentActivity,
  payments,
  onboarding,
};
