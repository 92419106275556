import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Dispatch, RootState } from 'store';

import useUnits from './useUnits';

const usePayments = (id?: string) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<Dispatch>();
  const { enqueueSnackbar } = useSnackbar();
  const { currentUnit } = useUnits();
  const { plans, subscription, paymentMethods, payments } = useSelector(
    (state: RootState) => state.payments,
  );

  const unitId = id || currentUnit.id;

  const showSuccessMessage = (message: string) => {
    enqueueSnackbar(message, {
      variant: 'success',
    });
  };

  const showErrorMessage = (message?: string) => {
    enqueueSnackbar(message || t('something_went_wrong_during_processing'), {
      variant: 'error',
    });
  };

  const getPlans = async () => {
    try {
      await dispatch.payments.getPlans();
    } catch (error) {
      showErrorMessage();
      console.log(error);
    }
  };

  // SUBSCRIPTIONS

  const getUnitSubscription = async (unitId: string) => {
    try {
      await dispatch.payments.getSubscription(unitId);
    } catch (error) {
      showErrorMessage();
      console.log(error);
    }
  };

  const cancelUnitSubscription = async (unitId: string) => {
    try {
      await dispatch.payments.cancelSubscription(unitId);
      showSuccessMessage(t('subscription_has_been_successfully_canceled'));
    } catch (error) {
      showErrorMessage();
      console.log(error);
    }
  };

  const switchUnitSubscription = async (unitId: string, priceId: string) => {
    try {
      await dispatch.payments.switchSubscription({ unitId, priceId });
      showSuccessMessage(t('subscription_has_been_successfully_updated'));
    } catch (error) {
      showErrorMessage();
      console.log(error);
    }
  };

  const renewSubscription = async (unitId: string, priceId: string) => {
    try {
      await dispatch.payments.renewSubscription({ unitId, priceId });
      showSuccessMessage(t('subscription_has_been_successfully_renewed'));
    } catch (error) {
      showErrorMessage();
      console.log(error);
    }
  };

  const isPlansLoading = useSelector(
    (state: RootState) => state.loading.effects.payments.getPlans,
  );

  const isUnitSusbcriptionLoading = useSelector(
    (state: RootState) => state.loading.effects.payments.getSubscription,
  );

  // PAYMENT METHODS

  const getUnitPaymentMethods = async (unitId: string) => {
    try {
      await dispatch.payments.getPaymentMethods(unitId);
    } catch (error) {
      showErrorMessage();
      console.log(error);
    }
  };

  const addUnitPaymentMethod = async (unitId: string, cardToken: string) => {
    try {
      await dispatch.payments.addPaymentMethod({ unitId, cardToken });
      showSuccessMessage(t('payment_method_has_been_successfully_added'));
    } catch (error) {
      showErrorMessage();
      console.log(error);
    }
  };

  const removeUnitPaymentMethod = async (
    unitId: string,
    paymentMethodId: string,
  ) => {
    try {
      await dispatch.payments.removePaymentMethod({ unitId, paymentMethodId });
      showSuccessMessage(t('payment_method_has_been_successfully_removed'));
    } catch (error) {
      showErrorMessage();
      console.log(error);
    }
  };

  // PAYMENTS

  const getUnitPayments = async () => {
    try {
      await dispatch.payments.getPayments(unitId);
    } catch (error) {
      showErrorMessage();
      console.log(error);
    }
  };

  useEffect(() => {
    if (unitId) {
      getUnitSubscription(unitId);
      getUnitPaymentMethods(unitId);
      getUnitPayments();
    }
  }, [unitId]);

  return {
    plans,
    getPlans,
    isPlansLoading,
    unitSubscription: subscription,
    getUnitSubscription,
    isUnitSusbcriptionLoading,
    switchUnitSubscription,
    cancelUnitSubscription,
    renewSubscription,
    getUnitPaymentMethods,
    addUnitPaymentMethod,
    removeUnitPaymentMethod,
    unitPaymentMethods: paymentMethods,
    unitPayments: payments,
    getUnitPayments,
  };
};

export default usePayments;
