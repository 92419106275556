import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';

import { TextLogo } from 'components/atoms';
import Colors from 'styles/colors.scss';

interface IProps {
  name: string;
  location: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const BusinessUnitItem: FC<IProps> = ({ name, location, onClick }) => {
  return (
    <Box display="flex" gap="12px" sx={{ cursor: 'pointer' }} onClick={onClick}>
      <TextLogo text={name} />
      <Box display="flex" flexDirection="column" gap="2px">
        <Typography
          variant="text-base-light"
          color={Colors['default-gray-100']}
        >
          {name}
        </Typography>
        <Typography variant="text-xs" color={Colors['default-gray-300']}>
          {location}
        </Typography>
      </Box>
    </Box>
  );
};

export default BusinessUnitItem;
