import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PaymentCardForm } from 'components/molecules';
import SettingsModal from 'components/molecules/settingsModal/SettingsModal';
import { useAddPaymentMethodForm } from 'hooks/forms/useAddPaymentMethodForm';
import usePayments from 'hooks/usePayments';
import useUnits from 'hooks/useUnits';

import styles from './AddPaymentMethodModal.module.scss';

interface IProps {
  open: boolean;
  onClose: () => void;
}

const AddPaymentMethodModal = ({ open, onClose }: IProps) => {
  const { t } = useTranslation();
  const { addUnitPaymentMethod } = usePayments();
  const { currentUnit } = useUnits();
  const form = useAddPaymentMethodForm();

  const onSubmit = async (cardToken: string) => {
    await addUnitPaymentMethod(currentUnit.id, cardToken);
    onClose();
  };

  return (
    <SettingsModal
      title={t('Add.payment.methods')}
      open={open}
      onClose={onClose}
      withCloseButton
    >
      <FormProvider {...form}>
        <form className={styles.wrapper}>
          <PaymentCardForm onAddCard={onSubmit} />
        </form>
      </FormProvider>
    </SettingsModal>
  );
};

export default AddPaymentMethodModal;
