import { FC } from 'react';

import { LoaderOverlay } from 'components/atoms';
import { AuthContainer } from 'components/molecules';
import { NewPasswordForm } from 'components/organisms';

interface IProps {
  isLoading?: boolean;
}

const NewPasswordTemplate: FC<IProps> = ({ isLoading }) => {
  return (
    <AuthContainer>
      {isLoading && <LoaderOverlay />}
      <NewPasswordForm />
    </AuthContainer>
  );
};

export default NewPasswordTemplate;
