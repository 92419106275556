import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import i18n from 'environment/plugins/i18n';

import {
  BUSINESS_DETAILS_OBJECT,
  BUSINESS_UNIT_DETAILS_OBJECT,
  CONFIRM_PASSWORD_FIELD,
  CREDIT_CARD_OBJECT,
  MEMBER_OBJECT,
  PASSWORD_FIELD,
  QUESTIONS_OBJECT,
  REQUIRED_EMAIL_FIELD,
  SCHEDULE_OBJECT,
  SIGNUP_MEMBERS_ARRAY,
} from './validators';

export const ADD_REPLY_SCHEMA = yupResolver(
  yup.object().shape({
    reply: yup.string().required(i18n.t('Enter.reply')).trim(),
  }),
);

export const GET_BOOST_SCHEMA = yupResolver(
  yup.object().shape({
    boost: yup.string().required(),
    multiplier: yup.string(),
    multiplierInput: yup.string(),
  }),
);

export const REFER_SCHEMA = yupResolver(
  yup.object().shape({
    email: REQUIRED_EMAIL_FIELD,
  }),
);

export const LOGIN_SCHEMA = yupResolver(
  yup.object({
    email: REQUIRED_EMAIL_FIELD,
    password: PASSWORD_FIELD,
  }),
);

export const SIGN_UP_SCHEMA = yupResolver(
  yup.object({
    email: REQUIRED_EMAIL_FIELD,
    password: PASSWORD_FIELD,
    confirmPassword: CONFIRM_PASSWORD_FIELD('password'),
    agreements: yup.boolean().required().oneOf([true]),
  }),
);

export const CONTINUE_SIGN_UP_SCHEMA = yupResolver(
  yup.object({
    schedule: SCHEDULE_OBJECT,
    businessDetails: BUSINESS_DETAILS_OBJECT,
    questions: QUESTIONS_OBJECT,
    members: SIGNUP_MEMBERS_ARRAY,
  }),
);

export const QUESTIONS_SCHEMA = yupResolver(
  yup.object({
    questions: QUESTIONS_OBJECT,
  }),
);

export const UNIT_DETAILS_SCHEMA = yupResolver(
  yup.object({
    businessDetails: BUSINESS_UNIT_DETAILS_OBJECT,
  }),
);

export const ADD_NEW_BUSINESS_SCHEMA = yupResolver(
  yup.object({
    schedule: SCHEDULE_OBJECT,
    businessDetails: BUSINESS_UNIT_DETAILS_OBJECT,
    questions: QUESTIONS_OBJECT,
    member: MEMBER_OBJECT,
  }),
);

export const ADD_MEMBER_SCHEMA = yupResolver(
  yup.object({
    member: MEMBER_OBJECT,
  }),
);

export const SCHEDULE_SCHEMA = yupResolver(
  yup.object({
    schedule: SCHEDULE_OBJECT,
  }),
);

export const ADD_PAYMENT_METHOD_SCHEMA = yupResolver(CREDIT_CARD_OBJECT);

export const CHANGE_PASSWORD_SCHEMA = yupResolver(
  yup.object({
    oldPassword: PASSWORD_FIELD,
    newPassword: PASSWORD_FIELD,
    confirmNewPassword: CONFIRM_PASSWORD_FIELD('newPassword'),
  }),
);

export const CHANGE_EMAIL_CONFIRM_PASSWORD_SCHEMA = yupResolver(
  yup.object({
    password: PASSWORD_FIELD,
  }),
);

export const CHANGE_EMAIL_SCHEMA = yupResolver(
  yup.object({
    email: REQUIRED_EMAIL_FIELD,
  }),
);

export const PASSWORD_RECOVERY_EMAIL_SCHEMA = yupResolver(
  yup.object({
    email: REQUIRED_EMAIL_FIELD,
  }),
);

export const PASSWORD_RECOVERY_SCHEMA = yupResolver(
  yup.object({
    newPassword: PASSWORD_FIELD,
    confirmNewPassword: CONFIRM_PASSWORD_FIELD('newPassword'),
  }),
);
