import { Box, Button, Typography } from '@mui/material';
import { type FC } from 'react';

import Colors from 'styles/colors.scss';

import IconMark from '../icon/IconMark';

import styles from './SettingsSectionButton.module.scss';

interface IProps {
  text: string;
  onClick?: () => void;
}

const SettingsSectionButton: FC<IProps> = ({ text, onClick }) => {
  return (
    <Button
      variant="contained"
      className={styles.buttonContainer}
      onClick={onClick}
    >
      <Box component="span" className={styles.buttonContent}>
        <Typography variant="text-sm" color={Colors['bg-color']}>
          {text}
        </Typography>
        <IconMark name="plus" size="24px" color={Colors['bg-color']} />
      </Box>
    </Button>
  );
};

export default SettingsSectionButton;
