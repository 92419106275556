import { Box, Button, Typography } from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  GoBackLink,
  Input,
  LoaderOverlay,
  ServerErrorMessage,
} from 'components/atoms';
import SettingsModal from 'components/molecules/settingsModal/SettingsModal';
import { changeEmailSendVerification } from 'environment/api/services/settings';
import { CHANGE_EMAIL_SCHEMA } from 'environment/validation/resolvers';
import Colors from 'styles/colors.scss';

import styles from './ChangeEmailModals.module.scss';

const defaultValues = {
  email: '',
};

interface IProps {
  open: boolean;
  onClose: () => void;
  onBack?: () => void;
}

const ChangeEmailModal = ({ open, onClose, onBack }: IProps) => {
  const { t } = useTranslation();

  const form = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: CHANGE_EMAIL_SCHEMA,
  });

  const isValid = form.formState.isValid;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit: SubmitHandler<typeof defaultValues> = async ({ email }) => {
    try {
      setIsLoading(true);
      await changeEmailSendVerification({ email: email.trim() });
      handleClose();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        form.setError('root.serverError', {
          type: error.code,
          message: error.response?.data?.message ?? error.message,
        });
      } else {
        console.error(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    onClose();
    form.reset();
  };

  return (
    <SettingsModal
      leftSection={<GoBackLink size="large" onClick={onBack} />}
      title={t('Change.email')}
      open={open}
      onClose={handleClose}
      withCloseButton
    >
      {isLoading && <LoaderOverlay />}
      <FormProvider {...form}>
        <form className={styles.wrapper} onSubmit={form.handleSubmit(onSubmit)}>
          <Box className={styles.container}>
            <Typography
              variant="text-sm-light"
              color={Colors['default-gray-200']}
            >
              {t(
                'We.ll.send.you.a.verification.email.to.confirm.your.new.email.address',
              )}
            </Typography>

            <Input
              name="email"
              label={t('fields.email')}
              placeholder={t('fields.email')}
              autoComplete="email"
            />
          </Box>

          {form.formState.errors.root?.serverError?.message ? (
            <ServerErrorMessage
              message={form.formState.errors.root.serverError.message}
            />
          ) : null}

          <Button
            type="submit"
            size="large"
            variant="contained"
            disabled={!isValid}
            className={styles.submitButton}
          >
            <Typography
              color={Colors['default-gray-900']}
              variant="text-base-semibold"
            >
              {t('Update')}
            </Typography>
          </Button>
        </form>
      </FormProvider>
    </SettingsModal>
  );
};

export default ChangeEmailModal;
