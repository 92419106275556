import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { configStyles } from './config.styles';

interface IProps {
  data: number[];
  colors: string[];
  gradient: (string | number)[][];
  type: 'area' | 'areaspline';
  fill?: boolean;
}

const ViewOnlyGraph = ({ data, colors, gradient, type, fill }: IProps) => {
  const options = {
    colors: colors,
    chart: {
      type: type,
      ...configStyles.chart,
    },
    title: {
      margin: 0,
      text: null,
    },
    xAxis: configStyles.xAxis,
    yAxis: configStyles.yAxis,
    legend: { enabled: false }, //  dots that related to graphic
    tooltip: {
      enabled: false,
    },
    credits: {
      enabled: false, // remove highchart.com link
    },
    plotOptions: {
      series: {
        lineWidth: 1.5, // lineWidth on graphic
      },
      areaspline: {
        fillColor: fill
          ? null
          : {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 1,
                y2: 0,
              },
              stops: gradient,
            },

        fillOpacity: 0.5,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
      area: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 1,
            y2: 0,
          },
          stops: gradient,
        },
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
    },
    series: [{ data: data }],
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      containerProps={{
        style: {
          minHeight: '20px',
          height: '100%',
          flex: '1 1 100%',
          width: '100%',
        },
      }}
    />
  );
};

export default ViewOnlyGraph;
