import { toSortedTags } from './utils';

const AMBIANCES = [
  {
    id: 'cozy',
    emoji: 'bg-cozy',
  },
  {
    id: 'elegant',
    emoji: 'bg-elegant',
  },
  {
    id: 'chic',
    emoji: 'bg-chic',
  },
  {
    id: 'quaint',
    emoji: 'bg-quaint',
  },
  {
    id: 'lively',
    emoji: 'bg-lively',
  },
  {
    id: 'upscale',
    emoji: 'bg-upscale',
  },
  {
    id: 'rustic',
    emoji: 'bg-rustic',
  },
  {
    id: 'sophisticated',
    emoji: 'bg-sophisticated',
  },
  {
    id: 'vibrant',
    emoji: 'bg-vibrant',
  },
  {
    id: 'intimate',
    emoji: 'bg-intimate',
  },
  {
    id: 'chill',
    emoji: 'bg-chill',
  },
  {
    id: 'rad',
    emoji: 'bg-rad',
  },
  {
    id: 'epic',
    emoji: 'bg-epic',
  },
  {
    id: 'urban',
    emoji: 'bg-urban',
  },
  {
    id: 'picturesque',
    emoji: 'bg-picturesque',
  },
  {
    id: 'panoramic',
    emoji: 'bg-panoramic',
  },
  {
    id: 'secluded',
    emoji: 'bg-secluded',
  },
  {
    id: 'waterfront',
    emoji: 'bg-waterfront',
  },
  {
    id: 'scenic',
    emoji: 'bg-scenic',
  },
  {
    id: 'central',
    emoji: 'bg-central',
  },
  {
    id: 'tranquil-oasis',
    emoji: 'bg-tranquiloasis',
  },
  {
    id: 'breathtaking',
    emoji: 'bg-breathtaking',
  },
  {
    id: 'serene',
    emoji: 'bg-serene',
  },
  {
    id: 'al-fresco',
    emoji: 'bg-alfresco',
  },
  {
    id: 'beachfront',
    emoji: 'bg-beachfront',
  },
  {
    id: 'rooftop',
    emoji: 'bg-rooftop',
  },
  {
    id: 'quirky',
    emoji: 'bg-quirky',
  },
  {
    id: 'terrace',
    emoji: 'bg-terrace',
  },
  {
    id: 'vintage',
    emoji: 'bg-vintage',
  },
  {
    id: 'eco-friendly',
    emoji: 'bg-ecofriendly',
  },
  {
    id: 'whimsical',
    emoji: 'bg-whimsical',
  },
  {
    id: 'interactive',
    emoji: 'bg-interactive',
  },
  {
    id: 'hidden-gem',
    emoji: 'bg-hiddengem',
  },
  {
    id: 'natures-balcony',
    emoji: 'bg-naturesbalcony',
  },
  {
    id: 'breezy-lounge',
    emoji: 'bg-breezylounge',
  },
  {
    id: 'outdoor-sanctuary',
    emoji: 'bg-outdoorsanctuary',
  },
  {
    id: 'scenic-veranda',
    emoji: 'bg-scenicveranda',
  },
  {
    id: 'chill-out-spot',
    emoji: 'bg-chilloutspot',
  },
  {
    id: 'seaside-paradise',
    emoji: 'bg-seasideparadise',
  },
  {
    id: 'live-music',
    emoji: 'bg-livemusic',
  },
];

export default toSortedTags('ambianceLabel', AMBIANCES);
