import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import loaderSrc from 'assets/loader.gif';

import styles from './LoaderOverlay.module.scss';

const LoaderOverlay = () => {
  const { t } = useTranslation();

  return (
    <Box className={styles.container}>
      <img src={loaderSrc} alt={t('Loading...')} />
    </Box>
  );
};

export default LoaderOverlay;
