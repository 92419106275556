import { Box, Button, Typography } from '@mui/material';
import { type FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ServerErrorMessage } from 'components/atoms';
import { PersonalizeQuestionsForm } from 'components/organisms';
import { type SignUpBusinessFormData } from 'hooks/forms';
import Colors from 'styles/colors.scss';

import styles from './SignUpPersonalizeQuestions.module.scss';

interface IProps {
  onSubmit: () => void;
  onBackButtonOverride?: () => void;
  withBackButton?: boolean;
  withUseAsDropdown?: boolean;
  hidePromotion?: boolean;
}

const SignUpPersonalizeQuestionsTemplate: FC<IProps> = ({
  onSubmit,
  onBackButtonOverride,
  withBackButton,
  withUseAsDropdown,
  hidePromotion,
}) => {
  const { t } = useTranslation();

  const {
    formState: { errors, isDirty },
  } = useFormContext<SignUpBusinessFormData>();

  return (
    <Box className={styles.container}>
      <PersonalizeQuestionsForm
        onBackButtonOverride={onBackButtonOverride}
        withBackButton={withBackButton}
        withUseAsDropdown={withUseAsDropdown}
        hidePromotion={hidePromotion}
      />
      {errors.root?.serverError?.message ? (
        <ServerErrorMessage message={errors.root.serverError.message} />
      ) : null}
      <Button
        sx={{ marginTop: '8px' }}
        variant="contained"
        fullWidth
        disabled={Object.keys(errors).length > 0 || !isDirty}
        onClick={onSubmit}
      >
        <Typography
          variant="text-base-semibold"
          color={Colors['default-gray-900']}
        >
          {t('Next')}
        </Typography>
      </Button>
    </Box>
  );
};

export default SignUpPersonalizeQuestionsTemplate;
