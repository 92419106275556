import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import Colors from 'styles/colors.scss';

import styles from './DonutChart.module.scss';

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  colors: string[];
  rounded?: boolean;
  height: number;
  size?: 'medium' | 'small';
  title?: string | number;
  tooltip?: boolean;
  subtitle?: string;
}

const DonutChart = ({
  data,
  colors,
  rounded = false,
  height,
  size = 'medium',
  title = '',
  tooltip = true,
  subtitle = '',
}: IProps) => {
  const getSubtitle = () => {
    return `
        <p class=${styles.subtitle}>
          ${subtitle}
        </p>
        <p class=${styles.title} style="
          font-size: ${size === 'small' ? '18px' : '26px'};
          font-weight: ${size === 'small' ? 600 : 500};
         ">
          ${typeof title === 'number' ? Number(title.toFixed(2)) : title} 
        </p>`;
  };

  const sizePie = () => {
    if (rounded) {
      switch (size) {
        case 'medium':
          return {
            innerSize: '96%',
            borderWidth: 17,
            borderRadius: '50%',
            size: 155,
            borderColor: null,
          };
        case 'small':
          return {
            innerSize: '98%',
            borderWidth: 8,
            borderRadius: '50%',
            size: 86,
            borderColor: null,
          };
      }
    }
    return {
      innerSize: '70%',
      borderWidth: 0,
      borderRadius: 0,
      size: 210,
    };
  };

  const options = {
    chart: {
      type: 'pie',
      backgroundColor: 'transparent',
    },
    title: {
      text: '',
    },
    subtitle: {
      useHTML: true,
      text: getSubtitle(),
      verticalAlign: 'middle',
      style: { color: Colors.white },
    },
    tooltip: tooltip
      ? {
          outside: true,
          useHTML: true,
          headerFormat: '<b>{point.key}</b> ',
          pointFormat: '<b>{point.y}</b>',
          borderWidth: 1,
          borderColor: ' #ffffff33',
          backgroundColor: '#616161',
          padding: 10,
          borderRadius: 8,
          style: {
            color: Colors.white,
            fontSize: '12px',
          },
        }
      : { enabled: false },
    credits: {
      enabled: false, // remove highchart.com link
    },
    plotOptions: {
      pie: {
        ...sizePie(),
        dataLabels: {
          enabled: false,
        },
        states: {
          inactive: {
            opacity: rounded ? 1 : 0.2,
          },
          hover: {
            halo: {
              size: 0,
            },
          },
        },
      },
    },
    colors: colors,
    series: [{ data }],
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      containerProps={{
        style: { minHeight: height + 'px', minWidth: height + 'px' },
      }}
    />
  );
};

export default DonutChart;
