import { ExpandMoreOutlined } from '@mui/icons-material';
import { Box, Button, Popover, Typography } from '@mui/material';
import { FC, MouseEvent, PropsWithChildren, useState } from 'react';

import Colors from 'styles/colors.scss';

import styles from './TimePickerPopover.module.scss';

interface Props {
  label: string;
  value: string;
  onClose?: () => void;
}

const TimePickerPopover: FC<PropsWithChildren<Props>> = ({
  children,
  label,
  value,
  onClose,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose?.();
  };

  return (
    <>
      <button className={styles.anchorContainer} onClick={handleClick}>
        <div className={styles.anchor}>
          <Typography
            textAlign="start"
            variant="text-xs-weird"
            color={Colors['default-gray-200']}
          >
            {label}
          </Typography>
          <Typography whiteSpace="nowrap" variant="text-sm-light">
            {value}
          </Typography>
        </div>
        <Box color="#cccccc" display="inline-flex">
          <ExpandMoreOutlined color="inherit" />
        </Box>
      </button>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        slotProps={{
          paper: {
            className: styles.popover,
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {children}

        <Box display="flex" justifyContent="flex-end">
          <Button variant="outlined" onClick={handleClose}>
            OK
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default TimePickerPopover;
