import { Box } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UnavailableForYou } from 'components/atoms';
import { BasicArea } from 'components/molecules';
import DemographicsBlock from 'components/molecules/demographicsBlock/DemographicsBlock';
import { checkAndGenerateArray } from 'environment/utils/checkAndGenerateArray';
import { countDataInHoursRange } from 'environment/utils/countDataInHoursRange';
import { countDataInLast30Days } from 'environment/utils/countDataInLast30Days';
import { countDataInWeekRange } from 'environment/utils/countDataInWeekRange';
import useUnits from 'hooks/useUnits';
import { IDayDataItem, IPageVisitData } from 'store/models/engagement';
import Colors from 'styles/colors.scss';

import { rangeButtons } from './constants';
import styles from './PageVisitTab.module.scss';

interface IProps {
  data: IPageVisitData;
  getEngagementPageVisitData: (
    businessUnitId: string,
    value: string,
    startDate: string,
    endDate: string,
  ) => void;
}

const PageVisitTab = ({ data, getEngagementPageVisitData }: IProps) => {
  const { t } = useTranslation();

  const { currentUnit } = useUnits();

  const [isPremium, setIsPremium] = useState<boolean>(false);
  const [selectedRange, setSelectedRange] = useState<string>('day');

  const { dataByAge, dataByDate, userStatus } = data;

  const [updatedData, setUpdatedData] = useState(
    countDataInHoursRange(dataByDate),
  );

  const calculateDateRange = (value: string) => {
    const now = moment();
    let startDate = '';
    let endDate = '';

    switch (value) {
      case 'day':
        startDate = now.clone().subtract(24, 'hours').toISOString();
        endDate = now.toISOString();
        break;
      case 'week':
        startDate = now.clone().subtract(7, 'days').toISOString();
        endDate = now.toISOString();
        break;
      case 'month':
        startDate = now.clone().subtract(1, 'month').toISOString();
        endDate = now.toISOString();
        break;
    }

    return { startDate, endDate };
  };

  const handleDataByRange = (value: string) => {
    const rangeFunctions = {
      day: countDataInHoursRange,
      week: countDataInWeekRange,
      month: countDataInLast30Days,
    };

    const changedData = (
      rangeFunctions as Record<
        string,
        (data: IDayDataItem[]) => [string, number][]
      >
    )[value](dataByDate);
    setUpdatedData(changedData);
  };

  useEffect(() => {
    const { startDate, endDate } = calculateDateRange(selectedRange);

    getEngagementPageVisitData(
      currentUnit.id,
      'pageVisitByData',
      startDate,
      endDate,
    );

    handleDataByRange(selectedRange);
  }, [selectedRange, currentUnit.id]);

  const categories = updatedData.map((item) => item[0]);

  const { modifiedArray, allZeros } = checkAndGenerateArray(updatedData);

  return (
    <>
      <Box className={styles.chartWrapper}>
        <BasicArea
          data={modifiedArray}
          isEmpty={allZeros}
          categories={categories}
          colors={[Colors.HEXB8FE61]}
          title={t('Overview')}
          gradient={[
            [0, Colors['linear-green1']],
            [1, Colors['linear-green2']],
          ]}
          rangeButtons={rangeButtons}
          onClickBtn={(value) => {
            setSelectedRange(value);
            handleDataByRange(value);
          }}
          name={t('Visits')}
        />
      </Box>
      <UnavailableForYou
        isUnavailable={!isPremium}
        onClick={() => setIsPremium(true)}
        borderRadius={16}
      >
        <DemographicsBlock
          isUnavailable={isPremium}
          dataByAge={dataByAge}
          userStatus={userStatus}
          title="People.who.visited.your.page"
        />
      </UnavailableForYou>
    </>
  );
};

export default PageVisitTab;
