import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';

import { LoaderOverlay } from 'components/atoms';
import { ROUTES } from 'environment/constants/routes';
import { Error, Success } from 'pages';
import { Dispatch } from 'store';

import { loginRoutes } from './loginRoutes';
import { onboardingRoutes } from './onboardingRoutes';
import { protectedRoutes } from './protectedRoutes';
import { signupRoutes } from './signupRoutes';

const AppRouter = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoutes />}>{protectedRoutes}</Route>
      {signupRoutes}
      {onboardingRoutes}
      <Route element={<AuthRoutes />}>{loginRoutes}</Route>
      <Route path={ROUTES.error} element={<Error />} />
      <Route path={ROUTES.success} element={<Success />} />
    </Routes>
  );
};

export function AuthRoutes() {
  const dispatch = useDispatch<Dispatch>();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem('access_token')) {
      setIsLoading(false);
      return;
    }

    dispatch.business
      .getBusiness()
      .then(() => navigate(ROUTES.dashboard.home))
      .catch(() => localStorage.clear())
      .finally(() => setIsLoading(false));
  }, [navigate, dispatch.business]);

  if (isLoading) {
    return <LoaderOverlay />;
  }

  return <Outlet />;
}

function ProtectedRoutes() {
  const dispatch = useDispatch<Dispatch>();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem('access_token')) {
      navigate(ROUTES.login.home);
      setIsLoading(false);
      return;
    }

    dispatch.business
      .getBusiness()
      .catch(() => {
        localStorage.clear();
        navigate(ROUTES.login.home);
      })
      .finally(() => setIsLoading(false));
  }, [navigate, dispatch.business]);

  if (isLoading) {
    return <LoaderOverlay />;
  }

  return <Outlet />;
}

export default AppRouter;
