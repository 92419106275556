import { EngagementTypes } from 'environment/constants/engagement-types';

export const UnknownKeyword = 'unknown';

export const RecentActivityEngagementTypeKeywordMap = {
  [EngagementTypes.Watch]: 'recent_activity_watch',
  [EngagementTypes.Like]: 'recent_activity_like',
  [EngagementTypes.Comment]: 'recent_activity_comment',
  [EngagementTypes.Review]: 'recent_activity_review',
  [EngagementTypes.StoryTag]: 'recent_activity_story_tag',
  [EngagementTypes.ForumTag]: 'recent_activity_forum_tag',
  [EngagementTypes.PageVisit]: 'recent_activity_page_visit',
  [EngagementTypes.Share]: 'recent_activity_share',
};
