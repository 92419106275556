export const ROUTES = {
  home: '/',
  payment: '/payment',
  settings: '/settings',
  error: '/error',
  success: '/success',
  dashboard: {
    home: '/dashboard',
    recentAcivity: '/dashboard/recent-activity',
    expenditures: '/dashboard/expenditures',
    featuredWaterfalls: '/dashboard/featured-waterfalls',
    engagement: '/dashboard/engagement',
    consumerDemographics: '/dashboard/consumer-demographics',
    boosts: '/dashboard/boosts',
  },
  notFound: '*',
  login: {
    home: '/login',
    passwordRecovery: '/login/forgot-password',
    newPassword: '/login/forgot-password/new',
  },
  signup: {
    home: '/signup',
    otp: '/signup/2',
    type: '/signup/3',
    description: '/signup/4',
    details: '/signup/5',
    schedule: '/signup/6',
    threeTags: '/signup/7',
    fifteenTags: '/signup/8',
    mantras: '/signup/9',
    members: '/signup/10',
    subscription: '/signup/11',
    cardDetails: '/signup/12',
    receipt: '/signup/13',
  },
  businessSignUp: {
    home: '/business/signup',
    otp: '/business/signup/2',
    type: '/business/signup/3',
    description: 'business/signup/4',
    details: '/business/signup/5',
    schedule: '/business/signup/6',
    threeTags: '/business/signup/7',
    fifteenTags: '/business/signup/8',
    mantras: '/business/signup/9',
    members: '/business/signup/10',
    subscription: '/business/signup/11',
    cardDetails: '/business/signup/12',
    receipt: '/business/signup/13',
  },
  onboarding: {
    getSarted: '/onboarding',
    claimBusiness: '/onboarding/claim-business',
    confirmBusiness: '/onboarding/confirm-business',
    copyBusiness: '/onboarding/copy-business',
    subscription: '/onboarding/subscription',
    paymentDetails: '/onboarding/payment-details',
    credentials: '/onboarding/credentials',
    verify: '/onboarding/verify',
    describe: '/onboarding/describe',
    tags: '/onboarding/tags',
    tagsDetailed: '/onboarding/tags-detailed',
    questions: '/onboarding/questions',
  },
} as const;
