import { toSortedTags } from './utils';

const HERBAL = [
  {
    id: 'bubble-tea',
    emoji: 'bg-bubble_tea',
  },
  {
    id: 'english-tea',
    emoji: 'bg-british_tea',
  },
  {
    id: 'fruit-tea',
    emoji: 'bg-fruit_tea',
  },
  {
    id: 'herbal-tea',
    emoji: 'bg-herbal_tea',
  },
  {
    id: 'iced-tea',
    emoji: 'bg-snow',
  },
  {
    id: 'milk-tea',
    emoji: 'bg-bubble_tea',
  },
  {
    id: 'speciality-tea',
    emoji: 'bg-herbal_tea',
  },
  {
    id: 'traditional-tea',
    emoji: 'bg-british_tea',
  },
  {
    id: 'tea-fusion',
    emoji: 'bg-fusion_tea',
  },
];

export default toSortedTags('herbalLabel', HERBAL);
