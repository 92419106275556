import { createModel } from '@rematch/core';

import {
  getConsumerDemographicsCountersRequest,
  getConsumerDemographicsGraphRequest,
} from 'environment/api/services/consumerDemographics';

import type { RootModel } from '.';

export type CounterData = {
  endorsements: {
    comments: number;
    dislikes: number;
    forumTag: number;
    likes: number;
    pageVisit: number;
    reviews: number;
    shares: number;
    storyTag: number;
    watches: number;
  };
  group: {
    max: number;
    min: number;
  };
  statistics: {
    allUsers: number;
    newUsers: number;
  };
};

interface DataFromBackend {
  [key: string]: number;
}

type Consumer_Demographics_State = {
  graph: DataFromBackend;
  counters: CounterData[];
};

export const consumer_demographics = createModel<RootModel>()({
  state: {
    graph: {},
    counters: [
      {
        endorsements: {
          comments: 0,
          dislikes: 0,
          forumTag: 0,
          likes: 0,
          pageVisit: 0,
          reviews: 0,
          shares: 0,
          storyTag: 0,
          watches: 0,
        },
        group: {
          max: 0,
          min: 0,
        },
        statistics: {
          allUsers: 0,
          newUsers: 0,
        },
      },
    ],
  } as Consumer_Demographics_State,
  reducers: {
    updateGraph: (state, payload) => {
      return {
        ...state,
        graph: payload,
      };
    },
    updateCounters: (state, payload: CounterData[]) => {
      return {
        ...state,
        counters: payload,
      };
    },
  },
  effects: {
    async getConsumerDemographicsCounters(payload) {
      try {
        const { businessUnitId, startDate, endDate } = payload;
        const { data } = await getConsumerDemographicsCountersRequest(
          businessUnitId,
          startDate,
          endDate,
        );
        this.updateCounters(data);
      } catch (error) {
        console.log(error);
      }
    },

    async getConsumerDemographicsGraphData(payload) {
      try {
        const { businessUnitId, startDate, endDate } = payload;
        const { data } = await getConsumerDemographicsGraphRequest(
          businessUnitId,
          startDate,
          endDate,
        );
        this.updateGraph(data);
      } catch (error) {
        console.log(error);
      }
    },
  },
});
