import { Box } from '@mui/material';
import React from 'react';

import avatarDefaultYellow from 'assets/icons/lemon-yellow-default.svg';
import logoRegular from 'assets/icons/logo-payment-regular.svg';
import logoReversed from 'assets/icons/logo-payment-reversed.svg';
import paymentPageCompany from 'assets/images/payment-page-company.webp';
import paymentPageMeals from 'assets/images/payment-page-meals.webp';
import {
  BusinessPromoteActivity,
  BusinessPromoteImage,
  BusinessPromoteStats,
} from 'components/atoms';
import { PaymentCardForm } from 'components/molecules';
import { PaymentCardFormProps } from 'components/molecules/paymentCardForm/PaymentCardFormBody';
import Colors from 'styles/colors.scss';

import styles from './PaymentPage.module.scss';

interface PaymentPageProps extends PaymentCardFormProps {
  views?: string;
  likes?: string;
  comments?: string;
  activity?: number;
  periods?: number[];
  change?: number;
}

const PaymentPage = ({
  views,
  likes,
  comments,
  activity,
  periods,
  change,
  ...rest
}: PaymentPageProps) => {
  const isStatsProvided = Boolean(views || likes || comments || activity);
  return (
    <Box className={styles.root} mx="12px" my="24px">
      <Box className={styles.info}>
        <Box
          display="flex"
          width="80px"
          height="80px"
          borderRadius="50%"
          justifyContent="center"
          alignItems="center"
          bgcolor={Colors['bg-color']}
          position="absolute"
          top="50%"
          left="50%"
          className={styles.logo}
          zIndex={1}
        >
          <Box component="img" src={avatarDefaultYellow} width={48} />
        </Box>
        <Box className={styles.column}>
          <Box component="img" src={logoRegular} width="100%" />
          <BusinessPromoteImage
            src={paymentPageCompany}
            blurred={!isStatsProvided}
          />
          <BusinessPromoteActivity
            activity={activity}
            change={change}
            periods={periods}
          />
        </Box>
        <Box className={styles.column}>
          <BusinessPromoteStats
            comments={comments}
            likes={likes}
            views={views}
          />
          <BusinessPromoteImage
            src={paymentPageMeals}
            blurred={!isStatsProvided}
          />
          <Box component="img" src={logoReversed} width="100%" height="auto" />
        </Box>
      </Box>
      <Box className={`${styles.info} ${styles.background}`}>
        <PaymentCardForm {...rest} />
      </Box>
    </Box>
  );
};

export default PaymentPage;
