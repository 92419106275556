import Colors from 'styles/colors.scss';

export const COLORS_VIEW_CHARTS = [
  {
    color: Colors.HEXB8FE61,
    gradient: [
      [0, '#b8fe6140'],
      [1, '#1b1b1b00'],
    ],
  },
  {
    color: Colors['profile-red'],
    gradient: [
      [0, Colors['linear-red1']],
      [1, Colors['linear-red2']],
    ],
  },
  {
    color: Colors['profile-purple'],
    gradient: [
      [0, '#9c4aee40'],
      [1, '#9C4AEE00'],
    ],
  },
  {
    color: Colors['profile-blue'],
    gradient: [
      [0, '#32FFFF60'],
      [1, '#1B1B1B00'],
    ],
  },
];
