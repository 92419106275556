import _ from 'lodash';

import { BUSINESS_UNIT_CLAIM } from 'environment/constants/url';
import { SignInResponseBody } from 'environment/types/api/auth';
import { IBusinessUnit } from 'environment/types/business';
import {
  BUSINESS_UNIT_CLAIM_SOURCE,
  CategoryTagPayload,
  Claim,
  ClaimDataResponse,
  CredetialsPayload,
  PaymentPayload,
  PromotionStats,
  QuestionsPayload,
  StartClaimPayload,
  SubscriptionPayload,
  TagsPayload,
  VerifyPayload,
} from 'environment/types/claim';

import axios from '../axios';

export const searchToClaim = (query: string) => {
  return axios.get<IBusinessUnit[]>(BUSINESS_UNIT_CLAIM.BUSINESS_UNIT_SEARCH, {
    params: { query },
  });
};

export const startClaim = ({
  businessUnitId,
  businessClaimProgressType,
}: StartClaimPayload) => {
  return axios.post<Claim>(
    BUSINESS_UNIT_CLAIM.BUSINESS_UNIT_START_CLAIM(businessUnitId),
    undefined,
    {
      params: { businessClaimProgressType },
    },
  );
};

export const getPromotionData = (id: string) => {
  return axios.get<PromotionStats>(
    BUSINESS_UNIT_CLAIM.BUSINESS_UNIT_CONFIRM(id),
  );
};

export const setClaimSubscription = ({
  id,
  stripeSubscriptionId,
  businessClaimProgressType,
}: SubscriptionPayload) => {
  return axios.post<Claim>(
    BUSINESS_UNIT_CLAIM.BUSINESS_UNIT_SUBSCRIPTION(id),
    { stripeSubscriptionId },
    {
      params: { businessClaimProgressType },
    },
  );
};

export const setClaimPayment = ({
  id,
  cardToken,
  businessClaimProgressType,
}: PaymentPayload) => {
  return axios.post<Claim>(
    BUSINESS_UNIT_CLAIM.BUSINESS_UNIT_PAYMENT(id),
    { cardToken },
    {
      params: { businessClaimProgressType },
    },
  );
};

export const setClaimCredetials = ({
  id,
  businessClaimProgressType,
  ...data
}: CredetialsPayload) => {
  return axios.post<Claim>(
    BUSINESS_UNIT_CLAIM.BUSINESS_UNIT_CREDENTIALS(id),
    data,
    {
      params: { businessClaimProgressType },
    },
  );
};

export const verifyEmail = ({ id, ...params }: VerifyPayload) => {
  return axios.get<ClaimDataResponse>(
    BUSINESS_UNIT_CLAIM.BUSINESS_UNIT_VERIFY(id),
    {
      params,
    },
  );
};

export const setCategoryTag = ({
  registrationProgressId,
  categoryTag,
  source,
}: CategoryTagPayload) => {
  const url =
    source === BUSINESS_UNIT_CLAIM_SOURCE.from_landing_page
      ? BUSINESS_UNIT_CLAIM.BUSINESS_UNIT_CATEGORY_TAG_LANDING
      : BUSINESS_UNIT_CLAIM.BUSINESS_UNIT_CATEGORY_TAG_DASHBOARD(
          registrationProgressId,
        );
  return axios.put<ClaimDataResponse>(
    url,
    {
      categoryTag,
    },
    {
      params: { registrationProgressId },
    },
  );
};

export const setLabels = ({
  registrationProgressId,
  labels,
  source,
}: TagsPayload) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...businessUnitPickedLabels } = _.pickBy<unknown>(
    labels,
    _.identity,
  );
  const url =
    source === BUSINESS_UNIT_CLAIM_SOURCE.from_landing_page
      ? BUSINESS_UNIT_CLAIM.BUSINESS_UNIT_CATEGORY_LABELS_LANDING
      : BUSINESS_UNIT_CLAIM.BUSINESS_UNIT_CATEGORY_LABELS_DASHBOARD(
          registrationProgressId,
        );
  return axios.put<ClaimDataResponse>(
    url,
    { businessUnitPickedLabels },
    {
      params: { registrationProgressId },
    },
  );
};

export const setQuestions = ({
  registrationProgressId,
  questions,
  source,
}: QuestionsPayload) => {
  const url =
    source === BUSINESS_UNIT_CLAIM_SOURCE.from_landing_page
      ? BUSINESS_UNIT_CLAIM.BUSINESS_UNIT_CATEGORY_QUESTIONS_LANDING
      : BUSINESS_UNIT_CLAIM.BUSINESS_UNIT_CATEGORY_QUESTIONS_DASHBOARD(
          registrationProgressId,
        );
  return axios.put<ClaimDataResponse>(
    url,
    { questions },
    {
      params: { registrationProgressId },
    },
  );
};

export const completeClaim = (
  registrationProgressId: string,
  source: BUSINESS_UNIT_CLAIM_SOURCE,
) => {
  const url =
    source === BUSINESS_UNIT_CLAIM_SOURCE.from_landing_page
      ? BUSINESS_UNIT_CLAIM.BUSINESS_UNIT_CATEGORY_COMPLETE_LANDING
      : BUSINESS_UNIT_CLAIM.BUSINESS_UNIT_CATEGORY_COMPLETE_DASHBOARD(
          registrationProgressId,
        );
  return axios.get<SignInResponseBody>(url, {
    params: { registrationProgressId },
  });
};
