import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IconMark } from 'components/atoms';
import Colors from 'styles/colors.scss';

import styles from './SignUpSuccess.module.scss';

const SignUpSuccess: FC = () => {
  const { t } = useTranslation();

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.gradient}>
        <IconMark size="64px" name="tick-circle" />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" gap="16px">
        <Typography variant="app-title" component="p" textAlign="center">
          {t('Thank.you.for.joining')}
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography
            component="p"
            textAlign="center"
            variant="text-lg-light"
            color={Colors['default-gray-100']}
          >
            {t('Welcome.to.NoLemon')}
          </Typography>
          <Typography
            component="p"
            textAlign="center"
            variant="text-lg-light"
            color={Colors['default-gray-100']}
          >
            {t('Well.send.you.an.email.to.be.one')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SignUpSuccess;
